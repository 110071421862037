import React, { useEffect, useRef, useState } from "react";
import PropTypes, { number } from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col, Input, Button, Table } from "reactstrap";
import imgHelper from "../../assets/images/img_helper.jpg";
import imgChatBag from "../../assets/images/imgChatBag.png";
import SecondAnwerSetting from "./AnswerGuideComponents/SecondAnwerSetting";
import "./AnswerGuideComponents/AnswerGuide.scss";
import axios from "axios";
import ThirdAnswerSetting from "./AnswerGuideComponents/ThirdAnswerSetting";
import AddTabInput from "./AnswerGuideComponents/AddTabInput";
import config from "../../config/configuration";

// export const USER_ID = `7c06e135-acba-49e8-8c1d-925ceaa62e8d`;
// export const USER_ID = `8ca0ba9f-1f06-4985-b5e3-e4288cc84d03`;
// export const USER_ID = `036d3ee9-de14-498a-be44-ac819ccf2be2`;
export const USER_ID = localStorage.getItem("user_id");

// TODO: 삭제하는 것 이외의 alert들은 기획을 거쳐서 다른 로직으로 구현하는 것을 지향할 것 => alert는 너무 구시대적이다.. 라고 하심
// 이미지 업로드 시 나타나는 alert는 제외 => 파일을 선택한 시점에서 proxy 서버로 이미 보내서 저장을 시켰기 때문에 파일을 잘못 선택했다는 등의 이유로
// 서버에 저장을 하지 않게 될 경우 s3 버킷에 있는 이미지들을 지워줘야 함

const AnswerGuideSettings = () => {
    //meta title
    document.title = "Flowing";

    const [isReady, setIsReady] = useState(false);
    const [initTemplateData, setInitTemplateData] = useState({});
    const [sub2ndMenu, setSub2ndMenu] = useState({ value: null });
    const { message, subTemplate, templateId } = initTemplateData;
    const [editData, setEditData] = useState(subTemplate);
    const [subSideMenu, setSubSideMenu] = useState(null);
    // const [subSide3rdMenu, setSubSide3rdMenu] = useState({});
    const [carousel, setCarousel] = useState(0);
    const [addTemplate, setAddTemplate] = useState({
        secondTemplate: "",
        thirdTemplate: "",
    });
    const [thridTempValue, setThridTempValue] = useState([]);
    // const [selectedSecondData, setSelectedSecondData] = useState()

    /** 템플릿 GET API 호출 */
    const getTemplateData = async () => {
        setIsReady(false);
        try {
            const res = await axios.get(
                `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template`
            );
            const { template } = res.data;
            if (res.status === 204) {
                setIsReady(true);
                // 답변 분야를 건드리지 않은 유저의 status code는 204
                setInitTemplateData(() => {
                    return {
                        message: "",
                        subTemplate: [
                            { templateId: "", title: "", message: "" },
                        ],
                        templateId: "",
                    };
                });
            } else {
                setIsReady(true);
                if (!template.subTemplate) {
                    // 1단계만 설정하였을 경우
                    setInitTemplateData(() => {
                        return {
                            message: template.message,
                            subTemplate: [
                                { templateId: "", title: "", message: "" },
                            ],
                            templateId: template.templateId,
                        };
                    });
                } else {
                    setInitTemplateData(() => {
                        return template;
                    });
                }
            }
        } catch (error) {
            throw new Error(error);
        }
    };

    useEffect(() => {
        getTemplateData();
    }, []);

    useEffect(() => {
        if (!isReady) return;

        /** 최초 템플릿 선택 탭 지정 */
        const initTab = subTemplate[0].templateId;

        // TODO: 하위 컴포넌트로 내려 줄 데이터를 setState 형식을 변경하게 될 시 사용
        // const initData = subTemplate[0]
        // setSelectedSecondData(() => {return initData})

        // if (subTemplate[0].subTemplate) {
        //     if (subSideMenu !== null) {
        //         const findData = subTemplate.filter(
        //             (x) => x.templateId === subSideMenu
        //         );
        //         setSubSide3rdMenu(findData[0].subTemplate[0]);
        //     }
        // }
        setSub2ndMenu(() => {
            return { value: initTab };
        });
        setSubSideMenu(() => {
            return initTab;
        });
        setEditData(() => {
            return subTemplate;
        });
        setThridTempValue(() => {
            if (subTemplate[0].subTemplate) {
                return subTemplate[0].subTemplate;
            } else {
                return [];
            }
        });
    }, [isReady]);

    if (editData === undefined) return;

    const tabLength = document.querySelectorAll("#carouselBtn").length;
    // TODO: const tabWidth = document.querySelectorAll("#carouselBtn")[carousel].offsetWidth => 첫 바인딩 시 undefined 상태로 템플릿 전체가 렌더링 불가능함

    /** Carousel 오른쪽 이동 함수 */
    const carouselRight = () => {
        return carousel !== tabLength && setCarousel((prev) => prev + 1);
    };
    /** Carousel 왼쪽 이동 함수 */
    const carouselLeft = () => {
        return carousel !== 0 && setCarousel((prev) => prev - 1);
    };

    const selectedSecondData = editData.filter(
        (x) => x.templateId === sub2ndMenu.value
    );

    // const sideFilter = subTemplate.filter((x) => x.templateId === subSideMenu);

    if (sub2ndMenu.value === null) return;

    // 1단계 설정

    // 1단계 내용 수정
    const firstTemplateValueHandle = (target) => {
        const { name, value } = target;
        setInitTemplateData(() => {
            return {
                ...initTemplateData,
                [name]: value,
            };
        });
    };

    // 1단계 서버 저장
    const postFirstValue = (e, value) => {
        e.preventDefault();
        const postBody =
            templateId === ""
                ? { template: { message: value } }
                : { template: { templateId, message: value } };
        axios
            .put(
                `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template`,
                { ...postBody }
            )
            .catch((error) => {
                throw new Error(error);
            })
            .then(() => {
                // TODO: getTemplateData();
            });
    };

    // 2단계 설정

    // 2단계 내용 수정
    const secondValueHandle = (target, message) => {
        const targetedId = editData.findIndex(
            (temp) => temp.templateId === target
        );
        const newTemplate = [...editData];
        newTemplate[targetedId].message = message;
        setEditData(() => {
            return newTemplate;
        });
    };

    // 2단계 서버 저장
    const postSecondValue = (value) => {
        // const { message, title, parentTemplateId, templateId } = value;
        const { templateId, ...restData } = value;
        const postBody =
            typeof templateId === "number"
                ? {
                      template: { ...restData },
                  }
                : {
                      template: { ...value },
                  };
        axios
            .put(
                `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template`,
                { ...postBody }
            )
            .catch((error) => {
                throw new Error(error);
            })
            .then((res) => {
                console.log(res.data.templateId);
                if (res.data.templateId) {
                    const targetIdx = editData.findIndex(
                        (temp) => temp.templateId === templateId
                    );
                    const editTempId = [...editData];
                    editTempId[targetIdx].templateId = res.data.templateId;
                    setEditData(() => {
                        return editTempId;
                    });
                    setSub2ndMenu(() => {
                        return { value: res.data.templateId };
                    });
                }
            });
    };

    // 2단계 삭제
    const deleteSecondTemplate = (id) => {
        if (typeof id !== "number") {
            if (window.confirm("삭제하시겠습니까?")) {
                axios
                    .delete(
                        `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template/${id}`
                    )
                    .catch((error) => {
                        throw new Error(error);
                    })
                    .then(() => {
                        if (editData.length === 1) {
                            getTemplateData();
                        } else {
                            const deletedData = editData.filter(
                                (x) => x.templateId !== id
                            );
                            setEditData(() => {
                                return deletedData;
                            });
                            setSub2ndMenu(() => {
                                if (id === subTemplate[0].templateId) {
                                    return {
                                        value: subTemplate[1].templateId,
                                    };
                                } else {
                                    return {
                                        value: subTemplate[0].templateId,
                                    };
                                }
                            });
                        }
                    });
            }
        } else {
            if (editData.length === 1) {
                getTemplateData();
            } else {
                const deletedData = editData.filter((x) => x.templateId !== id);
                setEditData(() => {
                    return deletedData;
                });
                setSub2ndMenu(() => {
                    if (id === subTemplate[0].templateId) {
                        return {
                            value: subTemplate[1].templateId,
                        };
                    } else {
                        return {
                            value: subTemplate[0].templateId,
                        };
                    }
                });
            }
        }
    };

    // 2단계 이미지 삭제
    const deleteSecondImg = (value, subDatas) => {
        const requestAPI = `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template/${subDatas.templateId}/${value.id}`;
        if (window.confirm("삭제하시겠습니까?")) {
            axios
                .delete(`${requestAPI}`)
                .catch((error) => {
                    throw new Error(error);
                })
                .then(() => {
                    // getTemplateData();
                });
        }
    };

    // 2단계 이미지 추가
    const addSecondImage = (value, uploadId) => {
        if (
            selectedSecondData[0].contents &&
            selectedSecondData[0].contents.length === 5
        ) {
            alert("이미지는 다섯 개 까지 입력");
        } else {
            if (!selectedSecondData[0].contents) {
            }
            const { files } = value;
            console.log(files[0]);
            const requestAPI = `${config.api.proxy_api}/common/files/upload/flowing-web-resources/image?env=DEV`;
            const formData = new FormData();
            formData.append("upload", files[0]);
            formData.append("limitedImageSize", 1280);
            console.log(formData);
            axios
                .post(`${requestAPI}`, formData)
                .catch((error) => {
                    throw new Error(error);
                })
                .then((res) => {
                    if (window.confirm("저장하시겠습니까?")) {
                        const postImgData = {
                            message: res.data.url[0],
                            msgType: "PHOTO",
                            imgRatio: 2.164102554321289,
                        };
                        const targetedIdx = selectedSecondData.findIndex(
                            (temp) => temp.templateId === uploadId
                        );
                        const newTemplate = [...selectedSecondData];
                        newTemplate[targetedIdx].contents.push(postImgData);
                        postSecondValue(newTemplate[0]);
                    } else {
                        // s3 이미지 삭제
                    }
                });
        }
    };

    // 세번 째 템플릿 이미지 추가
    const thirdAddImg = (value, sub, flatData) => {
        const { templateId: uploadId, message, contents } = sub;
        if (contents.length === 5) {
            alert("이미지는 5개까지 입력"); // TODO: 추후 문구 수정 or alert 삭제
        } else if (message !== "") {
            const { files } = value;
            const requestAPI = `${config.api.proxy_api}/common/files/upload/flowing-web-resources/image`;
            const formData = new FormData();
            formData.append("upload", files[0]);
            formData.append("limitedImageSize", 1280);
            axios
                .post(`${requestAPI}`, formData)
                .catch((error) => {
                    throw new Error(error);
                })
                .then((res) => {
                    if (window.confirm("저장하시겠습니까?")) {
                        const postImgData = {
                            message: res.data.url[0],
                            msgType: "PHOTO",
                            imgRatio: 2.164102554321289,
                        };
                        const targetedIdx = flatData.findIndex(
                            (temp) => temp.templateId === uploadId
                        );
                        const newTemplate = [...flatData];
                        newTemplate[targetedIdx].contents.push(postImgData);
                        const postData = flatData.filter(
                            (x) => x.templateId === uploadId
                        );
                        postEditThirdTemplate(postData[0]);
                    } else {
                        // TODO: s3 사진 삭제
                    }
                });
        } else {
            alert("내용을 먼저 입력해 주세요");
        }
    };

    // 세번째 템플릿 수정
    const editThirdValueHandle = (target, value, parentId) => {
        const findDataIdx = editData.findIndex(
            (temp) => temp.templateId === parentId
        );
        const handledNewData = [...editData];
        const testidx = handledNewData[findDataIdx].subTemplate.findIndex(
            (temp) => temp.templateId === target
        );
        const testData = [...handledNewData[findDataIdx].subTemplate];
        testData[testidx].message = value;
        setEditData(() => {
            return handledNewData;
        });
    };

    // 세번 째 템플릿 삭제
    const deleteTemplate = (value, parentId) => {
        console.log(value);
        if (window.confirm("삭제하시겠습니까?")) {
            if (typeof value !== "number") {
                axios
                    .delete(
                        `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template/${value}`
                    )
                    .catch((error) => {
                        throw new Error(error);
                    })
                    .then(() => {
                        if (
                            editData.length === 1 &&
                            thridTempValue.length === 1
                        ) {
                            // getTemplateData()
                        } else {
                            // getTemplateData();
                            setEditData(() => {
                                const findIdx = editData.findIndex(
                                    (temp) => temp.templateId === parentId
                                );
                                const newData = [...editData];
                                newData[findIdx].subTemplate.filter((x) => {
                                    return x.templateId !== value;
                                });

                                return newData;
                            });
                            console.log(editData);

                            // setThridTempValue(() => {
                            //     return thridTempValue.filter(
                            //         (x) => x.templateId !== value
                            //     );
                            // });
                        }
                    });
            } else {
                // getTemplateData();
                setThridTempValue(() => {
                    return thridTempValue.filter((x) => x.templateId !== value);
                });
            }
        }
    };

    // 세번 째 템플릿 이미지 삭제
    const deleteThirdImg = (tempId, imgId) => {
        const requestAPI = `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template/${tempId}/${imgId}`;
        if (window.confirm("삭제하시겠습니까?")) {
            axios
                .delete(`${requestAPI}`)
                .catch((error) => {
                    throw new Error(error);
                })
                .then(() => {
                    // getTemplateData();
                });
        }
    };

    const addTemplateValueHandle = (values) => {
        const { name, value } = values;
        setAddTemplate({ ...values, [name]: value });
    };

    const postAddTemplate = (e, parentId, value, location) => {
        e.preventDefault();
        setAddTemplate({ ...addTemplate, [location]: "" });
        if (location === "secondTemplate") {
            if (message === "") {
                alert("1단계 설정을 먼저 저장해 주세요");
            } else {
                const tempTemplate = {
                    parentTemplateId: parentId,
                    title: value.value,
                    message: "",
                    templateId: new Date().getTime(),
                };
                if (subTemplate[0].title === "") {
                    subTemplate.shift();
                    subTemplate.push(tempTemplate);
                    setEditData(() => {
                        return subTemplate;
                    });
                    setSub2ndMenu(() => {
                        return { value: subTemplate[0].templateId };
                    });
                } else {
                    setEditData((prev) => [...prev, tempTemplate]);
                    setSub2ndMenu(() => {
                        return { value: tempTemplate.templateId };
                    });
                }
            }
        } else if (location === "thirdTemplate") {
            if (typeof parentId === "number") {
                alert("2단계 설정을 먼저 저장해 주세요");
            } else {
                const tempTemplate = {
                    parentTemplateId: parentId,
                    title: value.value,
                    message: "",
                    templateId: new Date().getTime(),
                    contents: [],
                };
                const findIdx = editData.findIndex(
                    (x) => x.templateId === parentId
                );
                const tempData = [...editData];
                if (!tempData[findIdx].subTemplate) {
                    tempData[findIdx] = {
                        subTemplate: [],
                        ...tempData[findIdx],
                    };
                }
                tempData[findIdx].subTemplate.push(tempTemplate);
                setEditData(() => {
                    return tempData;
                });
                // if (thridTempValue === null) {
                //     const newData = {
                //         ...selectedSecondData[0],
                //         subTemplate: [],
                //     };
                //     newData.subTemplate.push(tempTemplate);
                //     setThridTempValue(() => {
                //         return newData.subTemplate;
                //     });
                // } else {
                //     setThridTempValue((prev) => [...prev, tempTemplate]);
                // }
            }
        }
    };

    const postEditThirdTemplate = (value) => {
        console.log(value);
        const postData =
            typeof value.templateId === "number"
                ? {
                      template: {
                          contents: value.contents,
                          message: value.message,
                          parentTemplateId: value.parentTemplateId,
                          title: value.title,
                      },
                  }
                : { template: { ...value } };
        axios
            .put(
                `${config.api.chat_api}/talk2you/users/profile/${USER_ID}/template`,
                { ...postData }
            )
            .catch((error) => {
                if (error.response.status === 400)
                    alert("2단계를 먼저 설정해 주세요");
                throw new Error(error);
            })
            .then((res) => {
                // console.log(res);
                // const findIdx = editData.findIndex(
                //     (x) => x.templateId === value.parentTemplateId
                // );
                // const newData = [...editData];
                // newData[findIdx].subTemplate
                // getTemplateData();
                if (res.data.templateId) {
                    const findDataIdx = editData.findIndex(
                        (temp) => temp.templateId === value.parentTemplateId
                    );
                    const putNewData = [...editData];
                    const testidx = putNewData[
                        findDataIdx
                    ].subTemplate.findIndex(
                        (temp) => temp.templateId === value.templateId
                    );
                    const testData = [...putNewData[findDataIdx].subTemplate];
                    testData[testidx].templateId = res.data.templateId;
                    setEditData(() => {
                        return putNewData;
                    });
                }
            });
    };

    return (
        <>
            <li className="active">
                <Row>
                    <Col xl={7} lg={6} className="PcRightBoard">
                        <div className="chatbot-setting">
                            <p className="chatbot-setting-title">
                                <b>1단계</b> 답변 분야 안내 만들기
                            </p>
                            <div className="first-answer-wrap">
                                <div className="first-answer-wrap-top">
                                    <p>{message.length}/200</p>
                                    <button
                                        onClick={(e) => {
                                            postFirstValue(e, message);
                                        }}
                                        className="btn btn-primary post-btn btn-1st-custom"
                                    >
                                        저장
                                    </button>
                                </div>
                                <textarea
                                    // onBlur={(e)=> {
                                    //     postFirstValue(e, message)
                                    // }}
                                    className="form-control"
                                    required
                                    placeholder="답변자님의 답변 분야에 대한 간단한 안내 내용을 입력하세요."
                                    type="text"
                                    name="message"
                                    maxLength="200"
                                    onChange={(e) => {
                                        firstTemplateValueHandle(e.target);
                                    }}
                                    value={message}
                                />
                            </div>
                        </div>

                        {templateId !== "" ? (
                            <div className="chatbot-setting">
                                <p className="chatbot-setting-title">
                                    <b>2단계</b> 답변 분야 만들기
                                </p>
                                <div className="first-answer-wrap">
                                    <AddTabInput
                                        addTemplate={addTemplate}
                                        addTemplateValueHandle={
                                            addTemplateValueHandle
                                        }
                                        postAddTemplate={postAddTemplate}
                                        parentId={templateId}
                                        tempLocation={"secondTemplate"}
                                    />
                                    {editData[0].templateId !== "" ? (
                                        <>
                                            <div className="carousel-tab-top">
                                                <button
                                                    className="carousel-tab-left"
                                                    onClick={carouselLeft}
                                                >
                                                    <i className="fa fa-chevron-left"></i>
                                                </button>
                                                <div className="carousel-tab-contents">
                                                    <div>
                                                        {editData.map(
                                                            (temp, idx) => {
                                                                return (
                                                                    <button
                                                                        id="carouselBtn"
                                                                        className="btn-primary carousel-tab-title"
                                                                        key={
                                                                            idx
                                                                        }
                                                                        onClick={() => {
                                                                            setSub2ndMenu(
                                                                                () => {
                                                                                    return {
                                                                                        value: temp.templateId,
                                                                                    };
                                                                                }
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            transform: `translateX(${
                                                                                carousel *
                                                                                -200
                                                                            }px)`,
                                                                            transition: `transform 1.5s`,
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                temp.title
                                                                            }
                                                                        </p>
                                                                        <i
                                                                            className="icon-close"
                                                                            onClick={() => {
                                                                                deleteSecondTemplate(
                                                                                    temp.templateId
                                                                                );
                                                                            }}
                                                                        ></i>
                                                                    </button>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <button
                                                    className="carousel-tab-right"
                                                    onClick={carouselRight}
                                                >
                                                    <i className="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                            <SecondAnwerSetting
                                                addSecondImage={addSecondImage}
                                                deleteSecondImg={
                                                    deleteSecondImg
                                                }
                                                postSecondValue={
                                                    postSecondValue
                                                }
                                                {...(selectedSecondData && {
                                                    selectedSecondData:
                                                        selectedSecondData,
                                                })}
                                                secondValueHandle={
                                                    secondValueHandle
                                                }
                                            />
                                        </>
                                    ) : null}
                                </div>
                                {subTemplate[0].templateId !== "" ? (
                                    <div className="chatbot-setting">
                                        <p className="chatbot-setting-title">
                                            <b>3단계</b> 답변 세부 분야 만들기
                                        </p>
                                        <div className="first-answer-wrap">
                                            <div className="carousel-tab-top">
                                                <button
                                                    className="carousel-tab-left"
                                                    onClick={carouselLeft}
                                                >
                                                    <i className="fa fa-chevron-left"></i>
                                                </button>
                                                <div className="carousel-tab-contents">
                                                    <div>
                                                        {editData.map(
                                                            (temp, idx) => {
                                                                return (
                                                                    <div
                                                                        className="btn-primary carousel-tab-title"
                                                                        key={
                                                                            idx
                                                                        }
                                                                        onClick={() => {
                                                                            setSub2ndMenu(
                                                                                () => {
                                                                                    return {
                                                                                        value: temp.templateId,
                                                                                    };
                                                                                }
                                                                            );
                                                                        }}
                                                                        style={{
                                                                            transform: `translateX(${
                                                                                carousel *
                                                                                -200
                                                                            }px)`,
                                                                            transition: `transform 1.5s`,
                                                                        }}
                                                                    >
                                                                        <p>
                                                                            {
                                                                                temp.title
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                </div>
                                                <button
                                                    className="carousel-tab-right"
                                                    onClick={carouselRight}
                                                >
                                                    <i className="fa fa-chevron-right"></i>
                                                </button>
                                            </div>
                                            {subTemplate[0].templateId ? (
                                                <ThirdAnswerSetting
                                                    // to AddTabInput
                                                    addTemplate={addTemplate}
                                                    addTemplateValueHandle={
                                                        addTemplateValueHandle
                                                    }
                                                    postAddTemplate={
                                                        postAddTemplate
                                                    }
                                                    // to third template
                                                    deleteThirdImg={
                                                        deleteThirdImg
                                                    }
                                                    deleteTemplate={
                                                        deleteTemplate
                                                    }
                                                    thirdAddImg={thirdAddImg}
                                                    postEditThirdTemplate={
                                                        postEditThirdTemplate
                                                    }
                                                    // {...(selectedSecondData && {selectedSecondData:selectedSecondData})}
                                                    // targetedData={
                                                    //     selectedSecondData[0]
                                                    // }
                                                    editThirdValueHandle={
                                                        editThirdValueHandle
                                                    }
                                                    {...(selectedSecondData[0] && {
                                                        targetedData:
                                                            selectedSecondData[0],
                                                    })}
                                                    // setThridTempValue={
                                                    //     setThridTempValue
                                                    // }
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </Col>
                    <Col lg={6} xl={5}>
                        {/* TODO: 기획이 바뀌게 되는 경우 변경 후 인라인 스타일은 버릴 것 */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "10px 0",
                            }}
                        >
                            <button className="btn btn-primary">
                                안내 시나리오 보기
                            </button>
                        </div>
                        <div
                            className="drop-shadow"
                            style={{
                                minHeight: "500px",
                                width: "260px",
                                borderRadius: "28px",
                                margin: "0 auto",
                            }}
                        >
                            <div
                                style={{
                                    borderRadius: "20px",
                                    background: "#fff",
                                    border: "1px solid #eee",
                                    overflow: "hidden",
                                }}
                            >
                                <div
                                    style={{
                                        minHeight: "500px",
                                        background: "#787878",
                                        position: "relative",
                                    }}
                                >
                                    <div className="ChatTop d-flex align-items-center mt-auto">
                                        <div>
                                            <small className="d-block">
                                                <i className="fe fe-arrow-left"></i>{" "}
                                                답변방 안내
                                            </small>
                                        </div>
                                        <div className="ml-auto">
                                            <a
                                                href="/" // TODO: href 링크 추후 수정
                                                className="icon d-none d-md-inline-block "
                                            >
                                                <i className="fe fe-user"></i>
                                            </a>
                                            <a
                                                href="/" // TODO: href 링크 추후 수정
                                                className="icon d-none d-md-inline-block ml-2"
                                            >
                                                <i className="fe fe-align-justify"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="PhoneBody p-2">
                                        <div className="ChatOption d-flex align-items-center">
                                            <div>
                                                <small className="d-block">
                                                    <img src={imgChatBag} />
                                                    <span className="wordNum">
                                                        {" "}
                                                        0/100
                                                    </span>
                                                </small>
                                            </div>
                                            <div className="ml-auto">
                                                <small className="d-block">
                                                    답변톡쿠폰 : 0개
                                                </small>
                                            </div>
                                        </div>
                                        <div className="conArea mt-2">
                                            <div className="d-flex align-items-top mt-2">
                                                <img
                                                    className="avatar avatar-md mr-2"
                                                    src={imgHelper}
                                                    alt=""
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <small className="d-block">
                                                        경험 도우미
                                                    </small>
                                                    <div className="bubble">
                                                        <p>1단계 메시지</p>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "column",
                                                            }}
                                                        >
                                                            <button
                                                                // onClick={() => {
                                                                //     setSubSideMenu(
                                                                //         x.templateId
                                                                //     );
                                                                // }}
                                                                className="btn tag btn-primary"
                                                            >
                                                                2단계 타이틀
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="bubble noArrow">
                                                        <p>
                                                            2단계 타이틀 <br />{" "}
                                                            세부 분야를 <br />{" "}
                                                            선택하시겠습니까?
                                                        </p>
                                                        <div>
                                                            <button className="btn tag active">
                                                                돌아가기
                                                            </button>
                                                            <button className="btn btn-primary tag">
                                                                선택완료
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="bubble noArrow">
                                                        <img
                                                            // src={
                                                            //     img.message
                                                            // }
                                                            style={{
                                                                width: "50px",
                                                                height: "50px",
                                                            }}
                                                            alt="두번째 템플릿 이미지"
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="bubble noArrow">
                                                            <p>2단계 메시지</p>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    flexDirection:
                                                                        "column",
                                                                }}
                                                            >
                                                                <button
                                                                    className="btn btn-primary tag"
                                                                    //   onClick={() => {
                                                                    //       setSubSide3rdMenu(
                                                                    //           x
                                                                    //       );
                                                                    //   }}
                                                                >
                                                                    2단계선택
                                                                </button>
                                                                <button className="btn tag">
                                                                    돌아가기
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="bubble noArrow">
                                                        <p>
                                                            3단계 타이틀 <br />{" "}
                                                            세부 분야를 <br />{" "}
                                                            선택하시겠습니까?
                                                        </p>
                                                        <div>
                                                            <button className="btn tag active">
                                                                돌아가기
                                                            </button>
                                                            <button className="btn btn-primary tag">
                                                                선택완료
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="bubble noArrow">
                                                        <p>3단계 메시지</p>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection:
                                                                    "row",
                                                                flexWrap:
                                                                    "wrap",
                                                            }}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: "50px",
                                                                    height: "50px",
                                                                }}
                                                                //   src={
                                                                //   img.message
                                                                //   }
                                                                alt="세번 째 템플릿 이미지"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </li>
        </>
    );
};
export default withRouter(withTranslation()(AnswerGuideSettings));

AnswerGuideSettings.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
