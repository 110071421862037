import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import AddTabInput from "./AddTabInput";

const ThirdAnswerSetting = ({
    postAddTemplate,
    targetedData,
    postEditThirdTemplate,
    thirdAddImg,
    deleteTemplate,
    deleteThirdImg,
    addTemplate,
    addTemplateValueHandle,
    editThirdValueHandle,
}) => {
    const valuableData = targetedData.subTemplate
        ? targetedData.subTemplate
        : null;

    return (
        <>
            {targetedData ? (
                <div className="thrid-answer-template">
                    <div className="third-template-add">
                        <div className="first-answer-wrap">
                            <AddTabInput
                                tempLocation={"thirdTemplate"}
                                addTemplate={addTemplate}
                                addTemplateValueHandle={addTemplateValueHandle}
                                postAddTemplate={postAddTemplate}
                                parentId={targetedData.templateId}
                            />
                        </div>
                    </div>
                    {valuableData &&
                        valuableData.map((sub, idx) => {
                            return (
                                <div key={idx} className="third-template-add">
                                    <div className="first-answer-wrap">
                                        <div className="first-answer-wrap-top">
                                            <div className="first-answer-wrap-inner">
                                                <div className="first-answer-wrap-title">
                                                    {sub.title}
                                                    <button>
                                                        <i
                                                            className="icon-close"
                                                            onClick={() => {
                                                                deleteTemplate(
                                                                    sub.templateId,
                                                                    sub.parentTemplateId
                                                                );
                                                            }}
                                                        ></i>
                                                    </button>
                                                </div>
                                                <p>{sub.message.length}/200</p>
                                            </div>
                                            <button
                                                onClick={() => {
                                                    postEditThirdTemplate(sub);
                                                }}
                                                className="btn btn-primary post-btn btn-1st-custom"
                                            >
                                                저장
                                            </button>
                                        </div>
                                    </div>
                                    <div className="thrid-custom-template">
                                        <textarea
                                            placeholder="해당 답변 분야에 대한 간단한 안내 내용을 입력하세요"
                                            value={sub.message}
                                            maxLength="200"
                                            onChange={(e) => {
                                                editThirdValueHandle(
                                                    sub.templateId,
                                                    e.target.value,
                                                    sub.parentTemplateId
                                                );
                                            }}
                                            required
                                            type="text"
                                        />
                                        <div className="thrid-custom-template-contents">
                                            <div className="thrid-custom-template-img-wrap">
                                                {sub.contents &&
                                                    sub.contents.map(
                                                        (img, idx) => {
                                                            return (
                                                                <div
                                                                    key={idx}
                                                                    className="thrid-custom-template-img"
                                                                >
                                                                    <img
                                                                        src={
                                                                            img.message
                                                                        }
                                                                        alt="Thrid Template Img Data"
                                                                    />
                                                                    <i
                                                                        className="icon-close"
                                                                        onClick={() => {
                                                                            deleteThirdImg(
                                                                                sub.templateId,
                                                                                img.id
                                                                            );
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                            <div className="third-template-select-wrap">
                                                <div className="third-template-select">
                                                    <div>
                                                        <input
                                                            type="file"
                                                            id={`thirdImgInput${idx}`}
                                                            accept="image/*"
                                                            multiple
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            onChange={(e) => {
                                                                thirdAddImg(
                                                                    e.target,
                                                                    sub,
                                                                    valuableData
                                                                );
                                                            }}
                                                        />
                                                        <label
                                                            htmlFor={`thirdImgInput${idx}`}
                                                        >
                                                            <i className="icon-paper-clip icon-color"></i>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <i className="icon-emoticon-smile"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            ) : null}
        </>
    );
};

export default withRouter(withTranslation()(ThirdAnswerSetting));
ThirdAnswerSetting.propTypes = {};
ThirdAnswerSetting.defaultProps = { targetedData: [] };
