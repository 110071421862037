// import {get, post} from '../helpers/api_helper_landing';
import config from '../config/configuration';
import {get, post} from '../helpers/api_helper';

export async function getQna(userId) {
	try {
		if(!userId) return null;
		// console.log('userId ', userId);
		const result = await get(`/qna/users/${userId}`, {baseURL: config.api.common_api});
		// console.log('result ', result);
		return result && result.qnaList || [];

	} catch (err) {
		throw err;
	}
}

export async function getQnaDetail(qnaId, userId) {
	try {
		// console.log('qnaId ', qnaId);
		// console.log('userId ', userId);
		if(!qnaId || !userId) return {};
		const result = await get(`/qna/ids/${qnaId}/console?userId=${userId}`, {baseURL: config.api.common_api});
		// console.log('result2 ', result);
		return result || {};

	} catch (err) {
		throw err;
	}
}

export async function createQna(questionData) {
	try {
		return await post('/qna', questionData, {baseURL: config.api.common_api});
	} catch (err) {
		throw err;
	}
}

export async function uploadQnAImage (formData) {
	try {
		return await post(`/common/files/upload/flowing-web-resources/image?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL:config.api.proxy_api}, true)
	} catch(err) {return err}
}
