import {BehaviorSubject} from 'rxjs';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {post, postWithHeader} from '../helpers/api_helper_user';
// import {
// 	get as getCommon,
// 	post as postCommon,
// } from '../helpers/api_helper_common';
import {post, get, patch} from '../helpers/api_helper';
import config from '../config/configuration';
// import accessToken from '../helpers/jwt-token-access/accessToken';

import {setUserId} from '../utils/analytics';

import i18n from '../i18n';

// const subscriber = new BehaviorSubject(localStorage.getItem('access_token'));
const subscriber = new BehaviorSubject('');
// const subscriber = new BehaviorSubject(JSON.parse(localStorage.getItem('access_token')))

const updateAccessToken = function (accessToken) {
	subscriber.next(accessToken);
};

const setSessionTrigger = async () => {
    localStorage.setItem('session-trigger', 'changed');
    localStorage.removeItem('session-trigger');
}

const getUserId = async () => {
	try {
		const response = await post(`/auth/web/user`, {}, {baseURL: config.api.common_api}, true)
		return response
	} catch(err) {
		return err
	}
}

const getACToken = async () => {
	try {
		const response = await post(`/auth/web/refreshToken`, {}, {baseURL: config.api.common_api}, true)
		return response
	} catch(err) {
		return err
	}
}

// 챗봇 설정에서 답변 프로필을 먼저 작성했는지 체크하기 위한 API 호출
const getUserprofile = async (userId) => {
	try {
		return await get(`/talk2you/users/web/profile/${userId}`, {}, true)
	} catch(err) {return err}
}

const requestCode = async function (phone_number) {
	let body = {phone_number};
	try {
		let response = await post(`/phone/sms/pre-signup/request`, body, {baseURL: config.api.user_api});
		//toast.success('Please check your messages !');
		return i18n.t('Please check your messages !');
	} catch (err) {
		//toast.error(err.response.data.message);
		return err.response.data.place_holder;	
	}
};

const requestRenewCode = async function (userId,phone_number) {
	
	let body = {phone_number};
	console.log(`${userId}/phone/sms/renew/request`,"  ", body);
	try {
		// let response = await postWithHeader(`${userId}/phone/sms/renew/request`, body);
		let response = await post(`${userId}/phone/sms/renew/request`, body, {baseURL: config.api.user_api}, true);
		console.log("response:::", response);
		return response;
		
	} catch (err) {
		console.log("response error:::", err)
		return err.response;	
	}
};

const checkRequestedCode = async function (phone_number, authCode, ref_url) {
	try {
		let body = {
			phone_number,
			auth_code: authCode,
			ref_url,
		};
		let response = await post(`/phone/sms/pre-signup/authenticate`, body, {baseURL: config.api.user_api});
		//toast.success('Code submitted successfully');
		return 'sucess';//Code submitted successfully
	} catch (err) {
		//toast.error(err.response.data.message);
		return err.response.data.place_holder;
	}
};
const checkRequestedRenewCode = async function (userId, phone_number, authCode, ref_url) {
	try {
		let body = {
			userId,
			phone_number,
			auth_code: authCode,
			ref_url,
		};
		// return await postWithHeader(`${userId}/phone/sms/renew/authenticate`, body);
		return await post(`${userId}/phone/sms/renew/authenticate`, body, {baseURL: config.api.user_api}, true);

	} catch (err) {
		console.log("err.response", err.response);
		return err.response;
	}
};
const checkNameDuplicate = async function (name) {
	try {
		//let response = await get(`/nickname/duplicate?nickname=${name}`);
		// return await getWithHeader(`/nickname/duplicate?nickname=${name}`);
		return await get(`/nickname/duplicate?nickname=${name}`, {baseURL: config.api.user_api}, true);
		/*
		console.log("이름체크", response.status);
		if (response) {
			toast.success('You can use this name');
		}
		if (result) {
			toast.error(result.response.data.message);
		}*/
		
	} catch (err) {
		console.error(err);
		//toast.error(err.response.data.message);
		return err.response;

	}
};
const checkEmailDuplicate = async function (email) {
	try {
		//let response = await get(`/email/duplicate?email=${email}`);
		// return await getWithHeader(`/email/duplicate?email=${email}`);
		return await get(`/email/duplicate?email=${email}`, {baseURL: config.api.user_api}, true);
		/*
		if (response) {
			toast.success('You can use this email');
		}
		if (response) {
			toast.error(response.data.message);
		}
		*/
	} catch (err) {
		//console.error(err);
		//toast.error(err.response.data.message);
		return err.response;
	}
};

const login = async function (email, password) {
	try {
		let body = {
			email,
			password,
		};
		let response = await post(`/sign-in/formal`, body, {baseURL: config.api.user_api});
		// localStorage.setItem('user_id', response.user_id);
		// sessionStorage.setItem('user_id', response.user_id);
		// console.log(response, 'resp');
		if (response.user_id) {
			//Removed toast message 
			//toast.success('Login Successful');
			let tokenBody = {
				user_id: response.user_id,
				user_secret: response.user_secret,
			};
			// let token = await postCommon(`/auth/getToken`, tokenBody);
			let token = await post(`/auth/web/getToken`, tokenBody, {baseURL: config.api.common_api});
			// localStorage.setItem('access_token', token.access_token); // TODO : token 값은 WebStorage에 저장하지 않는다.
			sessionStorage.setItem('access_token', token.access_token); // TODO : token 값은 WebStorage에 저장하지 않는다.
			console.log(token)
			await getUserInfo(response.user_id);
			updateAccessToken(token.access_token);
			localStorage.setItem('expires_in', token?.expires_in);
			localStorage.setItem('refresh_expires_in', token?.refresh_expires_in);

			// console.log('accessToken', accessToken);
			console.log('accessToken', subscriber.value);
			if (token.access_token !== null) {
				setSessionTrigger()
				window.location.href = '/dashboard';
			}
		}
		return response;

	} catch (err) {
		console.error(err);
		// toast.error(err.response.data.message)
		return err;
	}
};

const logout = async function (props) {
	try {
		const logoutAPI = await post(`${config.api.common_api}/auth/web/sign-out`, {}, {}, true)
		setUserId();
		if(logoutAPI.status===201) {
			localStorage.clear();
			sessionStorage.clear()
			updateAccessToken(null);
			setSessionTrigger()
			window.location.href = '/login';
		}

	} catch (err) {
	}
};

const signUp = async function (phoneNumber, name, email, userImg) {
	try {
		let body = {
			phone_number: phoneNumber,
			nickname: name,
			email: email,
			age_group: '',
			gender: '',
			image_url: userImg,
			invitation_user_code: '',
		};

		let response = await post(`/sign-up/formal`, body, {baseURL: config.api.user_api});

		if (response) {
			// toast.success('Registration Successful');
			toast.success('회원가입을 완료했습니다.');
		}
		return response;
	} catch (err) {
		// toast.error(err.response.data.message);
		throw err;
	}
};
const getUserInfo = async function (userId) {
	try {
		let body = {
			user_id: userId,
			// user_id: userId ? userId : localStorage.getItem('user_id'),
		};
		let response = await post(`/profile/find`, body, {baseURL: config.api.user_api});
		console.log(response)
		// localStorage.setItem("user_data", JSON.stringify(response));
		sessionStorage.setItem("user_data", JSON.stringify(response));
		setUserId(response?.id);


		return response;
	} catch (err) {
		// toast.error(err.response.data.message);
	}
};
const emailSend = async function (email) {
	try {
		let body = {
			email,
		};
		return await post(`/password/email/renew/request`, body, {baseURL: config.api.user_api}, true);
		// let response = await post(`/password/email/renew/request`, body, {baseURL: config.api.user_api});
		// if (response) {
		// 	//toast.success(' Successful');
		// }
		// return response;		
	} catch (err) {
		//toast.error(err.response.data.message);

		return err;
	}
};



const requestEmailCode = async (reqBody) => {
    try {
        return await post(`/email/pre-signup/request`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) {throw error?.response || error}
}

const certificateEmailCode = async (reqBody) => {
    try {
        return await post(`/email/pre-signup/authenticate`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) { throw error?.response || error}
}

const newSignIn = async (reqBody, location) => {
    try {
        const request = await post(`/sign-in`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 200) throw r
            return r.data
        })
        if(request.user_id) {
            let tokenBody = {
                user_id: request.user_id,
                user_secret: request.user_secret
            }
            let token = await post(`/auth/web/getToken`, tokenBody, {baseURL: config.api.common_api})
            sessionStorage.setItem('access_token', token.access_token)
            const response = await getUserInfo(request.user_id)
            if(response && response.response && response.response.status > 400) return new Error(response)

            if (token.accessToken !== null) {
                setSessionTrigger();
                if(!location) {
                    window.location.href = "/dashboard"
                }
            }
        }
        return request
    } catch(error) { 
        throw error?.response || error
    }
}


const newSignUp = async (reqBody) => {
    try { 
        return await post(`/sign-up`, reqBody, {baseURL: config.api.user_api},true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) {throw error?.response || error}
}

const activateEmailRequestCode = async (userId, reqBody) => {
    try {
        return await post(`/${userId}/email/activation/request`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch (error) {
        throw error?.response || error
    }
}

const certificateActivateEmailRequestCode = async (userId, reqBody) => {
    try {
        return await post(`/${userId}/email/activation/authenticate`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch (error) {
        throw error?.response || error
    }
}

const changePassword = async (userId, reqBody) => {
    try {
        return await post(`/${userId}/reset/password`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch(error) {
        throw error?.response || error
    }
}

const addUserEmail = async (userId, reqBody) => {
    try {
        return await patch(`/${userId}/email`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status !== 201) throw r
            return r
        })
    } catch (error) {
        throw error?.response || error
    }
}

const addEmailValueableCheck = async (reqBody) => {
    try {
        return await post(`/account/check`, reqBody, {baseURL: config.api.user_api}, true).then(r => {
            if(r.status!==200) throw r
            return r
        })
    } catch(error) {throw error?.response || error}
}

export const authService = {
	login,
	logout,
	requestCode,
	requestRenewCode,
	getUserInfo,
	updateAccessToken,
	checkRequestedCode,
	checkRequestedRenewCode,
	checkNameDuplicate,
	checkEmailDuplicate,
	signUp,
	emailSend,
	getUserId,
	getACToken,
	getUserprofile,
	setSessionTrigger,
	subscriber,
	get accessToken() {
		return subscriber.value;
	},


    requestEmailCode,
    certificateEmailCode,
    newSignIn,
    newSignUp,
    activateEmailRequestCode,
    certificateActivateEmailRequestCode,
    changePassword,
    addUserEmail,
    addEmailValueableCheck
};
