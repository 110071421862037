import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import imgHelper from "../../assets/images/img_helper.jpg";
import imgChatBag from "../../assets/images/imgChatBag.png";
import _ from "lodash";
import "../../assets/css/myStyle2.css";
import { Row, Col, Input, Label, InputGroup, Button, Alert, } from "reactstrap";

//question API's
import { getService, putService, getResponsetimeData, putResponsetimeData } from "../../services/responsetimeSettings.service";
import axios from "axios";
import config from "../../config/configuration";
import { authService } from "../../services/auth.service";

const ResponsetimeSettings = () => {
    const [list, setList] = useState({});
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [exceptHoliday, setExceptHoliday] = useState("");
    const [newDays, setNewDays] = useState([]);
    // const userId = localStorage.getItem("user_id");
    // const userData = JSON.parse(localStorage.getItem("user_data"));
    const userData = JSON.parse(sessionStorage.getItem("user_data"));
    const { nickname } = userData;
    const [isRetoken, setIsRetoken] = useState("")
    const [isAlert, setIsAlert] = useState(false);

    const toggleAlert = () => {
        setIsAlert(!isAlert)
    }

    const getResTime = async () => {
        try {
            console.log(111111)
            const response = await authService.getUserId()
            console.log("response active:::", response)
            if(response.status===201) {
                const userId = response.data.user_id
                console.log(userId)
                const res = await getResponsetimeData(userId)
                console.log(res)
                // const profileRes = await axios.get(
                //     `${config.api.chat_api}/talk2you/users/web/profile/${userId}`
                // );
                const profileRes = await authService.getUserprofile(userId)
                console.log(profileRes)
                if (profileRes.status === 204 || profileRes.status === 201) {
                    console.log("답변 프로필 없음")
                    setList(() => {});
                    setStartTime("");
                    setEndTime("");
                    setNewDays(["MON", "TUE", "WED", "THU", "FRI"]);
                    setExceptHoliday(() => {
                        return "on";
                    });
                    createArray(["MON", "TUE", "WED", "THU", "FRI"]);
                } else {
                    console.log("답변 프로필 있음")
                    if (res.status === 204) {
                        setList(() => {});
                        setStartTime("");
                        setEndTime("");
                        setNewDays(["MON", "TUE", "WED", "THU", "FRI"]);
                        setExceptHoliday(() => {
                            return "on";
                        });
                        createArray(["MON", "TUE", "WED", "THU", "FRI"]);
                    } else {
                        console.log("time setting is not enough")
                        const { days, from, to } = res.data?.itemList[0];
                        setList(() => {
                            return res.data.itemList[0];
                        });
                        setNewDays(days);
                        setStartTime(from);
                        setEndTime(to);
                        setExceptHoliday(() => {
                            return res.data.itemList[0].exceptHoliday;
                        });
                        setExceptHoliday(res.data.itemList[0].exceptHoliday);
                        createArray(days);
                    }
                }
            }
        } catch (err) {
            // throw new Error(err);
        }
    };

    useEffect(() => {
        const reload = async () => {
            try {
                const response = await authService.getACToken()
                if(response.status===201) {
                    setIsRetoken(response.state)
                    getResTime().then(r=>r);
                    console.log(1)
                    setExceptHoliday(() => {
                        return list.exceptHoliday;
                    });
                }
            } catch(err){}
        }
        reload().then(r=>r)
    }, [isRetoken]);

    const handleSubmit = async () => {
        try {
            await postTimes();
            setIsAlert(true);
        } catch(e) {
            console.log(e);
        }
    };

    const checkTime = (value) => {
        const inputTime = value.split(":");
        if (inputTime.length !== 2) {
            // console.log("split error")
            return;
        } else {
            if (isNaN(inputTime[0]) || isNaN(inputTime[1])) {
                // console.log('input value is not number')
                return false;
            } else {
                if (inputTime[0].length > 2 || inputTime[0].length === 0) {
                    // console.log('input hour is error')
                    return false;
                } else {
                    if (inputTime[1].length > 2 || inputTime[1].length === 0) {
                        // console.log('input min is error')
                        return false;
                    } else {
                        if (parseInt(inputTime[0]) < 0 || parseInt(inputTime[0]) > 24) {
                            // console.log('hour set error')
                            return false;
                        } else {
                            if (
                                parseInt(inputTime[1]) < 0 ||
                                parseInt(inputTime[1]) > 59
                            ) {
                                // console.log('min set error')
                                return false;
                            } else {
                                // console.log(value)
                                // console.log(true)
                                return true;
                            }
                        }
                    }
                }
            }
        }
    };

    const postTimes = async () => {
        try {
            const response = await authService.getUserId()
            if(response.status===201) {
                const userId = response.data.user_id
                // const profileRes = await axios.get(
                //     `${config.api.chat_api}/talk2you/users/web/profile/${userId}`
                // );
                const profileRes = await authService.getUserprofile(userId)
                if (profileRes.status === 204 || profileRes.status === 201) {
                    toast.error("답변 프로필을 먼저 설정해 주세요");
                } else {
                    const responseTimeData = {
                        from: startTime ? startTime : "09:00",
                        to: endTime ? endTime : "18:00",
                        days: returnDays(newDays),
                        exceptHoliday: exceptHoliday,
                    };
                    if (responseTimeData.days.length === 0) {
                        toast.error(
                            "답변 가능한 요일을 최소 하루 이상 설정해 주세요"
                        );
                    } else {
                        const checkValue =
                            checkTime(responseTimeData.from) &&
                            checkTime(responseTimeData.to);
                        if (checkValue) {
                            if (
                                responseTimeData.from !== "" &&
                                responseTimeData.to !== ""
                            ) {
                                try {
                                    const result = await putResponsetimeData(
                                        userId,
                                        responseTimeData,
                                    );
                                    if (result.message === "Updated") {
                                        // toast.success('Амжилттай');
                                        toast.success("수정했습니다.");
                                    } else {
                                        // toast.error('Not sent');
    
                                        toast.error("다시 한번 시도해 주세요");
                                    }
                                } catch (err) {
                                    // throw new Error(err);
                                }
                            } else {
                                toast.error(
                                    "답변 가능 시간을 다시 한번 확인해 주세요"
                                );
                            }
                        } else {
                            toast.error(
                                "답변 가능 시간을 다시 한번 확인해 주세요"
                            );
                        }
                    }
                }
            }
        } catch (err) {
            throw new Error(err);
        }
    };

    const setMent = () => {
        const isHoliday =
            exceptHoliday === "on" ? "공휴일 답변 불가" : "공휴일 답변 가능";
        const allowDays = [...newDays].filter((x) => x.isFind === true);
        const allowDaysText = `${[...allowDays].map((item, idx) => {
            if (idx === allowDays.length - 1) {
                return item.text + "요일";
            }
            return " " + item.text;
        })}`;
        return {
            __html: `
				<p>지금은 답변 가능 시간이 아닙니다. <br/><br/> <b>답변 가능 시간</b></p>
				‧ ${allowDaysText}<br/>
				‧ ${startTime ? startTime : "09:00"} ~ ${endTime ? endTime : "18:00"}<br/>
				‧ ${isHoliday}<br/>
				<br/>
                <p>미리 질문을 남겨 주시면 <br/> 답변 가능 시간에 답변해 드리겠습니다.</p>
			`,
        };
    };

    // Day functions
    const clickDay = (index) => {
        let newArr = [...newDays];
        newArr[index].isFind = !newArr[index].isFind;
        setNewDays(newArr);
    };

    const createArray = (days) => {
        let dayArray = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
        let dayArrayString = ["월", "화", "수", "목", "금", "토", "일"];
        let newArray = [];
        _.forEach(dayArray, function (value, index) {
            let data = {
                value: value,
                text: dayArrayString[index],
                isFind: checkDay(days, value),
            };
            newArray.push(data);
        });
        setNewDays(newArray);
    };

    const checkDay = (days, value) => {
        let findData = _.find(days, function (day) {
            return day === value;
        });
        return findData ? true : false;
    };

    const returnDays = (dayArray) => {
        let newArray = [];
        _.forEach(dayArray, function (day, index) {
            if (day.isFind) {
                newArray.push(day.value);
            }
        });
        return newArray;
    };

    document.title = "Flowing";
    return (
        <React.Fragment>
            <li className="active">
                <Row>
                    <Col lg="6" xl="7" className="PcRightBoard">
                        <Row>
                            <Col lg="12">
                                <span>답변 시작 시간</span>
                                <InputGroup className="mb-3 mt-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="icon-clock"></i>
                                        </span>
                                    </div>
                                    <Input
                                        className="form-control time12"
                                        placeholder="EX: 11:59 (24시간 기준)"
                                        onChange={(e) => {
                                            setStartTime(e.target.value);
                                        }}
                                        value={startTime ? startTime : ""}
                                    />
                                </InputGroup>
                            </Col>
                            <Col lg="12">
                                <span>답변 종료 시간</span>
                                <InputGroup className="mb-3 mt-2">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <i className="icon-clock"></i>
                                        </span>
                                    </div>
                                    <Input
                                        className="form-control time12"
                                        placeholder="EX: 11:59 (24시간 기준)"
                                        onChange={(e) => {
                                            setEndTime(e.target.value);
                                        }}
                                        value={endTime ? endTime : ""}
                                    />
                                </InputGroup>
                            </Col>
                            <Col lg="12" className="mt-3">
                                <span className="mb-2">답변 가능 요일</span>
                                <div
                                    className="selectgroup selectgroup-pills ml-3"
                                    style={{ margin: "10px 0" }}
                                >
                                    {newDays.length === 0 ? (
                                        <div></div>
                                    ) : (
                                        newDays.map((item, index) => {
                                            return (
                                                <Label
                                                    key={index}
                                                    className="selectgroup-item"
                                                >
                                                    <Input
                                                        type="checkbox"
                                                        className="selectgroup-input"
                                                        onChange={() =>
                                                            clickDay(index)
                                                        }
                                                    />
                                                    <span
                                                        className={
                                                            item.isFind
                                                                ? "selectgroup-button day-selected"
                                                                : "selectgroup-button not-selected"
                                                        }
                                                    >
                                                        {item.text}
                                                    </span>
                                                </Label>
                                            );
                                        })
                                    )}
                                </div>
                            </Col>
                            <Col lg="12">
                                <Label className="custom-control custom-checkbox">
                                    {typeof exceptHoliday !== "undefined" ? (
                                        <>
                                            <Input
                                                type="checkbox"
                                                className="custom-control-input"
                                                defaultChecked={
                                                    exceptHoliday === "on"
                                                        ? true
                                                        : false
                                                }
                                                onChange={() => {
                                                    setExceptHoliday(
                                                        exceptHoliday === "on"
                                                            ? "off"
                                                            : "on"
                                                    );
                                                }}
                                            />
                                            <span className="custom-control-label">
                                                공휴일은 답변일에서 제외
                                            </span>
                                        </>
                                    ) : null}
                                </Label>
                            </Col>
                            <Col lg={12} className="mt-3">
                                <Button
                                    color={'dark'}
                                    onClick={handleSubmit}
                                >
                                    저장하기
                                </Button>
                            </Col>
                            <Col lg={12}>
                                <Alert className="mt-3 p-2" isOpen={isAlert} toggle={toggleAlert}>
                                    <div className="d-flex pr-4">
                                        <div className="flex-grow-0 mr-3">
                                            <i className="fa far fa-exclamation-triangle"/>
                                        </div>
                                        <div>
                                            변경된 답변 시간은 채팅 및 답변 프로필에는 실시간 반영되지만,
                                            스페이스 홈에는 "스페이스 홈 업데이트" 버튼을 클릭하여 스페이스 홈을 업데이트 하셔야 반영됩니다.
                                        </div>
                                    </div>
                                </Alert>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="6" xl="5">
                        <div className="phone02 drop-shadow">
                            <div className="phone_over">
                                <div className="PhoneArea">
                                    <div className="ChatTop d-flex align-items-center mt-auto">
                                        <div>
                                            <small className="d-block">
                                                <i className="fe fe-arrow-left"></i>
                                                {nickname}
                                            </small>
                                        </div>
                                        <div className="ml-auto">
                                            <Link
                                                to="/#"
                                                className="icon d-none d-md-inline-block"
                                            >
                                                <i className="fe fe-user"></i>
                                            </Link>
                                            <Link
                                                to="/#"
                                                className="icon d-none d-md-inline-block ml-2"
                                            >
                                                <i className="fe fe-align-justify"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="PhoneBody p-2">
                                        <div className="ChatOption d-flex align-items-center">
                                            <div>
                                                <small className="d-block">
                                                    <img
                                                        src={imgChatBag}
                                                        alt="img"
                                                    />
                                                    <span className="wordNum">
                                                        0/100
                                                    </span>
                                                </small>
                                            </div>
                                            <div className="ml-auto">
                                                <small className="d-block">
                                                    답변톡쿠폰 : 0개
                                                </small>
                                            </div>
                                        </div>
                                        <div className="conArea mt-2">
                                            <div className="d-flex align-items-top mt-2">
                                                <img
                                                    className="avatar avatar-md mr-2"
                                                    src={imgHelper}
                                                    alt="img"
                                                />
                                                <div>
                                                    <small className="d-block">
                                                        <b className="color-black">
                                                            답변 도우미
                                                        </b>
                                                    </small>
                                                    <div className="bubble">
                                                        <div
                                                            dangerouslySetInnerHTML={setMent()}
                                                            style={{
                                                                wordBreak:
                                                                    "break-all",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </li>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(ResponsetimeSettings));

ResponsetimeSettings.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
