import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link, Redirect} from 'react-router-dom';
import {
	Container,
	Card,
	CardBody,
	Row,
	Col,
	Input,
	Button,
	InputGroup,
} from 'reactstrap';

import noProfileImage from '../../assets/images/answerProfileImg.png';

import {getMyProfile} from '../../services/talk2youRooms.service';
import { authService } from '../../services/auth.service';
import {toast} from 'react-toastify';

const ProfileManagement = (props) => {
	//meta title

	const [isProfile, setIsProfile] = useState();
	const [hasContinuedData, setHasContinuedData] = useState(false);

	document.title = 'Flowing';

	useEffect(() => {
		getProfile().then(r => r);

		async function getProfile() {
			const res = await authService.getUserId()
			if(res.status===201){
				try {
					let myProfile = await getMyProfile(res.data.user_id, {
						verbose: true,
					}).then(res => {
						if (res?.status !== 200) throw res;
						return res?.data;
					});
					// let response = await getMyProfile(localStorage.getItem('user_id'));
					// console.log(response, 'WITRTER');
					if (myProfile?.itemList[0]) {
						let data = myProfile.itemList[0];
						setIsProfile(data.userId || false);
						console.log(isProfile);
					}
				} catch (e) {
					if (e.status === 201) {
						// 등록 중인 답변 프로필이 남아 있음
						// Todo: 중간 저장본이 있어도 덮어 씌우도록 API 변경 예정. 변경되면 hasContinuedData 관련 분기 제거하고 저장기능 테스트 필요
						setHasContinuedData(true);
					} else if (e.status !== 204) {
						if (e?.data?.place_holder) {
							toast.error(e?.data?.place_holder);
						} else {
							toast.error('답변자 프로필 데이터를 불러오던 중 에러가 발생했습니다.');
						}
					}
					// console.error(e);
				}
			
			}
		}
	}, []);

	// 답변프로필이 있을 경우 프로필 관리 페이지로 곧바로 이동 처리
	if (isProfile) {
		console.log('has.....profile');
		return (
			<Redirect
				to={{pathname: '/answer/WriteProfile', state: {from: props.location}}}
			/>
		);
	}

	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
			{/* <span className=""> {props.t('Manage profile')}</span> */}
				<div className="section-body">
				<div className="row">
					<div className="col-12">
						<div className="page-title-box d-sm-flex align-items-center justify-content-between">
							<h4 className="mb-0 font-size-18">{props.t('Manage profile')}</h4>
						</div>
					</div>
				</div>
				<Container fluid>
					<Row className="clearfix">
						<Col lg={12} className="text-center">
							<div className="mt-auto mb-3">
								<div className="text-center">
									<p>
										<img
											src={noProfileImage}
											alt="logo"
											className="imgNoProfile"
										/>
									</p>
									{!hasContinuedData ? (
										<div>{ props.t('You will get questions from many different people after profile registration') }</div>
									) : (
										<div style={{
											fontSize: '1.3em'
										}}>
											<b>모바일 앱</b>에서 프로필을 등록하던 중이셨군요? <br/>
											<b>모바일 앱</b>에서 프로필 정보 작성을 완료해 주세요.
										</div>
									)}

								</div>
							</div>
							{!hasContinuedData && (
								<Link to="/answer/WriteProfile" className="btn btn-secondary">
									{isProfile?"답변 프로필 수정하기":"답변 프로필 등록하기"}
								</Link>
							)}
						</Col>
					</Row>
				</Container>
			</div>
		{/* </div> */}
	</React.Fragment>
	);
};
export default withRouter(withTranslation()(ProfileManagement));

ProfileManagement.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
