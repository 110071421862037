import _ from 'lodash';

export function findMessage(messages, newMessage) {
	let msgIndex = _.findIndex(messages, {
		hashCode: newMessage?.hashCode,
	});
	let msgData = _.find(messages, function (o) {
		return o.hashCode === newMessage?.hashCode;
	});
	if (msgIndex !== -1 && msgData) {
		// console.log('newMessage', newMessage);
		msgData.messageId = newMessage?.messageId;
		msgData.createdAt = newMessage?.createdAt;
		let newArr = [...messages];
		newArr[msgIndex] = msgData;
		return newArr;
	}
	return false;
}
