import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import {getQnaDetail as getData} from '../../services/qna.service';

import {Container, Row, Table} from 'reactstrap';
import { authService } from '../../services/auth.service';

const NoticeDetail = (props) => {
	//meta title

	const [qnaList, setQnaList] = useState([]);
	const getQnaDetail = async () => {
		console.log('props.location.id : ', props.location.id); //return;
		// const userId = 'bae88cf8-9877-4b99-b022-90ece9d09b88' // TODO : 테스트용 임시 아이디 적용
		const userId = localStorage.getItem("user_id")
		const response = await authService.getUserId()
		if(response.status===201) {
			// const result = await getData(props.location.id, userId); //return;
			const result = await getData(props.location.id, response.data.user_id); //return;
			setQnaList(result);
		}
	};
	useEffect(() => {
		getQnaDetail().then(r => r);
	}, []);

	document.title = '플로잉 문의하기';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<Container fluid>
					<Row className="align-items-center">
						<div className="col text-left">
							<h1 className="page-title">플로잉 문의하기</h1>
						</div>
					</Row>
					<div className="table-responsive">
						<Table className="table table-vcenter text-nowrap tableView">
									<tbody>
									<tr>
										<th scope="row" className="text-left BoardTitle">
											{qnaList.title}
										</th>
										<td className="text-right BoardInfo">
											<span> 등록일 : {qnaList.displayedDate}</span>
										</td>
									</tr>
									<tr>
										<th scope="row" className="text-left BoardTitle">
											{qnaList.type}
										</th>
										<td className="text-right BoardInfo">
											<span> 처리상태 : {qnaList.status}</span>
										</td>
									</tr>
									<tr>
										<td colSpan={2}>
											<div dangerouslySetInnerHTML={{ __html: qnaList.inquiry }} />
										</td>
									</tr>

									<tr>
										<td colSpan={2}>
											<div dangerouslySetInnerHTML={{ __html: qnaList.answer }} />
										</td>
									</tr>
									{qnaList.attachments && Array.isArray(qnaList.attachments) && qnaList.attachments.length > 0 ? (
										<tr>
											<td colSpan={2}>
												{qnaList.attachments.map((item, index) => {
														return (
															<img src={item.imageUrl} alt="첨부파일"/>
														)
												})}
											</td>
										</tr>
										) : ''
									}
									</tbody>
						</Table>
					</div>
					<Row className="justify-content-center">
						{/*<Link to="/#" className="btn btn-outline-secondary m-1 w-auto">
							before
						</Link>*/}
						<Link
							className="btn btn-outline-secondary m-1 w-auto"
							to={'/qna/QnaView'}
						>
							목록
						</Link>
						{/*<Link className="btn btn-outline-secondary m-1 w-auto" to="/#">
							next
						</Link>*/}
					</Row>
				</Container>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(NoticeDetail));

NoticeDetail.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
	id: PropTypes.string,
	state: PropTypes.object,
};
