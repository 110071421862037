import {memo} from 'react'
import moment from 'moment';
import {displayTimeByKST, calculateDateDiff} from '../../../../utils/displayDateTime';

const ScheduleItem = ({calculatedDate, startDate, finishDate, eventTitle, append, appendBody, ...props}) => {
    calculatedDate = calculatedDate || calculateDateDiff(startDate, finishDate);

    return (
        <article className={`schedule_list__item${calculatedDate === 'OPEN' ? ' is-active' : ''}`}>
            <div className="schedule_list__header">
                <div className="schedule_list__tag">
                    <span>{ calculatedDate }</span>
                </div>
            </div>
            <div className="schedule_list__body">
                <div>
                    <div className="schedule_list__date">
                        {moment(startDate).format('MM.DD')}{props?.startTime && `(${displayTimeByKST(props.startTime)})`}
                        <span className={'mx-1'}>~</span>
                        {moment(finishDate).format('MM.DD')}{props?.finishTime && `(${displayTimeByKST(props.finishTime)})`}
                    </div>
                    <div className="schedule_list__name">
                        <a href={!!props.eventUrl ? props.eventUrl : undefined} target='_blank'>{eventTitle}</a>
                    </div>
                    {appendBody && (
                        <div className={'mt-1'}>{appendBody}</div>
                    )}
                </div>
            </div>
            {append && (
                <div className={'ml-1'}>{append}</div>
            )}
        </article>
    )
}

export default memo(ScheduleItem);