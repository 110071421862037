import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import logo from '../../../assets/images/logo_landingFoot.png';
import {daysInKr} from '../../../constants/options';
import TagList from '../../../components/previews/spacehome/TagList';
// import OutLinkItem from '../../../components/previews/spacehome/OutLinkItem';
import {OutLinkItem} from '../../../components/previews/spacehome/OutLinkItem';
import VideoFeedItem from '../../../components/previews/spacehome/VideoFeedItem';
import ScheduleList from '../../../components/previews/spacehome/ScheduleList';
import BannerItem from '../../../components/previews/spacehome/BannerItem';
import PurchaseGems from '../../../components/previews/spacehome/PurchaseGems';
import {use} from 'i18next';



//post forbidden word API's
const DesignTypePage = (props) => {

	return (

		<div className="PhoneBody hauto">
            <div className="conArea pb-100px">
                {props.menuOrder.map((item, index)=> {
                    return <MenuOrder key={index} {...props} menuName={item} />
                })}
                <div className="footlogo"><img src={logo} alt="" /></div>
            </div>
        </div>
	
	);
};

function MenuOrder({menuName, ...props}){
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];
	// console.log('outLinkList', props.info)
	const shoppingEvent = props?.info?.shoppingEvent || [];
	const eventBanner = props?.info?.eventBanner || [];
    /*const keywordList = useMemo(() => {
        return props.info?.keywordList || []
    }, [props?.info?.keywordList]);

    const regionList = useMemo(() => {
        return props.info?.regionList || []
    }, [props?.info?.regionList]);

    const outLinkList = useMemo(() => {
        return props.info?.outLink || []
    }, [props?.info?.outLink]);

    const videoFeedList = useMemo(() => {
        return props.info?.videoFeed || []
    }, [props?.info?.videoFeed]);

    const shoppingEvent = useMemo(() => {
        return props?.info?.shoppingEvent || []
    }, [props?.info?.shoppingEvent]);
    const eventBanner = useMemo(() => {
        return props?.info?.eventBanner || []
    }, [props?.info?.eventBanner]);*/

    return (
        <>
            {
                {
                    '답변톡': (
                        <>
                            <div className="txtArea2 mt-2 noView partOn">
                                {/*<div className="mb-2 sectorTit">답변톡</div>*/}
                                <div className="d-block w-100 ">
                                    <div className="d-block"><i className="fe fe-clock"></i> <b>답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                                </div>

                                <div className="d-block w-100 ">
                                    <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                                </div>
                                {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                                    <div className="d-block w-100 mt-2">
                                        <div className="d-block">
                                            공휴일에는 답변이 불가능합니다.
                                            답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                            수 있습니다. 하지만 답변자의 답변은 운영시간을
                                            기다려주세요. 감사합니다.
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="txtArea2 mt-2 noView partOn">
                                {props.info && props.info?.introduction || '(인사말)'}
                            </div>
                            <div className="mBox boxbig noView partOn">
                                <div className="titname d-block mb-2 mt-3"><b>답변키워드</b></div>
                                <div>
                                    <TagList tags={keywordList} prefix={'#'} />
                                </div>

                                <div className="titname d-block mb-2 mt-3"><b>답변지역</b></div>
                                <div>
                                    <TagList tags={regionList} keyPath={'upmyeondong_name'} />
                                </div>

                            </div>
                        </>
                    ),
                    '상품': (
                        <div className="giftInfo noView mt-3 partOn">
                            <PurchaseGems description={props.info?.goodsUserProduct?.description}
                                          depositAmount={props.info?.goodsUserProduct?.depositAmount} />
                        </div>
                    ),
                    '외부링크 블록형': (
                        <div className="giftInfo noView mt-3 partOn">
                            {outLinkList?.length ?
                                outLinkList.map((item, index) => <OutLinkItem outLink={item} key={index} />)
                                : (<div>{menuName}</div>)}
                            {videoFeedList?.length ?
                                videoFeedList.map((item, index) => <VideoFeedItem feed={item} key={index} />)
                                : (<div>비디오 피드</div>)}
                        </div>
                    ),
                    '외부링크 이미지형': (
                        <div className="giftInfo noView mt-3 partOn">
                            {eventBanner?.length ? eventBanner.map((item, index) => {
                                return <BannerItem key={index} {...item} />
                            }) : <div>{menuName}</div>}
                        </div>
                    ),
                    '외부링크 스케줄형': (
                        <div className="giftInfo noView mt-3 partOn">
                            {shoppingEvent?.length ?
                                <ScheduleList list={shoppingEvent} />
                                : <div>{menuName}</div>}
                        </div>
                    ),
                }[menuName]
            }
        </>
    )
}



export default withRouter(withTranslation()(DesignTypePage));

DesignTypePage.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
