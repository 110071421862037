import axios from "axios"
// import accessToken from "./jwt-token-access/accessToken"
import config from "../config/configuration"
import { authService } from "../services/auth.service";
import {throttle} from 'lodash'

//pass new generated access token here
// const token = accessToken

//apply base url for axios
const API_URL = config.api.chat_api

axios.defaults.withCredentials = true;
const axiosApi = axios.create({
    baseURL: API_URL
})
const token = sessionStorage.getItem("access_token")
axiosApi.defaults.headers.common["Authorization"] = token
// axiosApi.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
// axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.request.use(
    async config => {
        // console.log(config)
        const token = sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token") : null
        // console.log(token)
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': "application/json",
        }
        return config
    },
    async error => {
        Promise.reject(error)
    }
)

axiosApi.interceptors.response.use(
    async response => {
        const requestOrigin = response.config
        // console.log(response)
        if(response.status===205) {
            try {
                console.log(111)
                const refreshAccessToken = await axiosApi.post(`${config.api.common_api}/auth/web/refreshToken`)
                console.timeLog(refreshAccessToken)
                if(refreshAccessToken.status === 201) {
                    sessionStorage.setItem("access_token", refreshAccessToken.data.access_token)
                    try {
                        const resAxios = await axiosApi(requestOrigin)
                        return resAxios
                    } catch(err) {
                        throw err
                    }
                }
            } catch(err) {
                if(err.response.status===401 && window.location.pathname !=="/login") {
                    const logout = await axiosApi.post(`${config.api.common_api}/auth/web/sign-out`)
                    if(logout.status===201) {
                        localStorage.clear()
                        sessionStorage.clear()
                        window.location.href="/login"
                    }
                }
            }
        }
        return response
    },
    async error => {
        console.error(error)
        const {config: conf, response} = error

        // if(response.status===401 && window.location.pathname !== "/login") {
        //     const logout = await axiosApi.post(`${config.api.common_api}/auth/web/sign-out`)
        //     if(logout.status===201) {
        //         localStorage.clear()
        //         sessionStorage.clear()
        //         window.location.href="/login"
        //     }
        // }

        if (error?.request?.responseURL && (error?.request?.responseURL.includes('/refreshToken') || error?.request?.responseURL.includes('auth/web/user')) ) {
            if (response?.status === 401 || response?.status === 403) {
                const logout = await axiosApi.post(`${config.api.common_api}/auth/web/sign-out`).then(res => {
                    return res.status === 201;
                });
                localStorage.clear();
                sessionStorage.clear();

                throttle(() => {authService.setSessionTrigger()}, 1000)

                if (logout && window.location.pathname !== '/login') {
                    window.location.href="/login";
                }
            }
        }

        return Promise.reject(error)
    }
)

export async function get(url, config = {}, verbose = false) {
    console.log(config)
    return await axiosApi.get(url, { ...config }).then(response => verbose && response || response.data);
}

export async function post(url, data, config = {}, verbose= false) {
    return await axiosApi.post(url, data, { ...config }).then(response => verbose && response || response.data);
}

export async function put(url, data, config = {}, verbose= false) {
    return await axiosApi.put(url, data, { ...config }).then(response => verbose && response || response.data);
}

export async function patch(url, data, config = {}, verbose= false) {
    return await axiosApi.patch(url, data, {...config}).then(response => verbose && response || response.data);
}

export async function del(url, data = {}, config = {}, verbose= false) {
    return await axiosApi.delete(url, { data: {...data}}, { ...config }).then(response => verbose && response || response.data);
}

