import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';

import HeaderTypeText from './HeaderTypeText';
import HeaderTypeCustom from './HeaderTypeCustom';


import pcBg01 from '../../../assets/images/pcBg01.png';
import pcBg02 from '../../../assets/images/pcBg02.png';
import pcBg03 from '../../../assets/images/pcBg03.png';
import pcBg04 from '../../../assets/images/pcBg04.png';
import pcBg05 from '../../../assets/images/pcBg05.png';
import pcBg06 from '../../../assets/images/pcBg06.png';
import pcBg07 from '../../../assets/images/pcBg07.png';
import pcBg08 from '../../../assets/images/pcBg08.png';

//post forbidden word API's
const SelectPcBgColorMenu = (props) => {
    
	const inputRef = useRef(null);
	const inputRef2 = useRef(null);

    const selectFileFunctionCustomBgImage = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					toast.error('This file is larger than 200mb');
					return;
				}
			} else {
				if (originFileSize > 20) {
					toast.error('This file is larger than 20mb');
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                // props.setPcBgImage([props.pcBgImage[0],response.url[0]]);
                props.setPcCustomBgImage(response.url[0]);
                props.setIsChange(true)
            }
            
		}
	};

    const selectFileFunctionProfileImage = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					toast.error('This file is larger than 200mb');
					return;
				}
			} else {
				if (originFileSize > 20) {
					toast.error('This file is larger than 20mb');
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                props.setPcTextBannerProfileImage(response.url[0]);
                props.setIsChange(true)
            }
            
		}
	};

    


	return (

				
            <>
                <div className="col-lg-12">
                    <p>
                        {props.headerType === 'text' || props.headerType ==='banner'?"PC버전에서 배경에 사용할 이미지를 설정하실수 있습니다." :"PC버전의 배경색을 설정하실수 있습니다."}
                    </p>
                </div>
                {props.headerType ==='custom'?        
                    <div className="col-lg-12">
                        <div className="mt-4">

                            <div className="custom-controls d-flex">
                                <div className="sbox text-left col mr-1">
                                    <label className="custom-control custom-radio custom-control-inline m-0">
                                        <input 
                                            id="checkbg03" 
                                            type="radio" 
                                            className="custom-control-input" 
                                            name="bgcolor2" 
                                            value="option1" 
                                            checked={props.pcBgColor === 'black'}
                                            onClick={()=>{
                                                props.setPcBgColor("black")
                                                props.setIsChange(true)
                                            }}
                                            readOnly
                                        />
                                        <span className="custom-control-label">검은색 배경 </span>
                                    </label>

                                    
                                </div>
                                <div className="sbox text-left col ml-1">
                                    <label className="custom-control custom-radio custom-control-inline m-0">
                                        <input 
                                            id="checkbg04" 
                                            type="radio" 
                                            className="custom-control-input" 
                                            name="bgcolor2" 
                                            value="option2"
                                            checked={props.pcBgColor === 'white'}
                                            onClick={()=>{
                                                props.setPcBgColor("white")
                                                props.setIsChange(true)
                                            }}
                                            readOnly
                                        />
                                        <span className="custom-control-label">흰색 배경 </span>
                                    </label>
                                    
                                </div> 
                            </div>

                        </div>
                        <div id="bgcolorArea2" className="custom-controls mt-2 ">
                            <div className="sbox">
                                <span className="iconBox01"><i className="fe fe-image"></i></span> PC버전에서 사용할 배경이미지 직접 편집
                                <div className="mt-4">
                                    
                                    <div className="form-group">
                                        <label className="form-label">배경이미지 찾기</label>
                                        <div className="row gutters-xs">
                                            <div className="col">
                                                <form>
                                                    <input 
                                                        type="file" 
                                                        className="form-control file-upload-field " 
                                                        placeholder="업로드할 이미지를 찾아주세요."
                                                        ref={inputRef}
                                                        onChange={selectFileFunctionCustomBgImage}
                                                    />
                                                </form>
                                                
                                            </div>
                                            <span className="col-auto">
                                                <button 
                                                    className="btn btn-secondary" 
                                                    type="button"
                                                    onClick={() => {
                                                        inputRef.current.click();
                                                    }}
                                                >찾기</button>
                                            </span>
                                        </div>
                                        <p className="mt-2 imgGuidetxt">1920px * 664px의 최적화된 이미지를 올려주셔야 합니다.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                :
                
                    // 일반 탭형태
                    <div className="col-lg-12">
                        
                            
                        <div id="bgcolorArea3" className="sbox mt-2">
                            <span className="iconBox02"><i className="fe fe-image"></i></span> 기본 배경 선택 
                            <div className="mt-4">
                                
                                <div className="bgSelect">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[0]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[0]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[0],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[0])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                        {/* <img src={pcBg02} /> */}
                                                        <img src={props.pcBgImageList[0]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[1]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[1]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[1],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[1])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[1]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[2]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[2]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[2],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[2])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[2]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[3]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[3]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[3],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[3])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[3]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[4]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[4]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[4],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[4])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[4]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[5]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[5]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[5],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[5])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[5]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[6]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[6]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[6],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[6])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[6]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                        <div className="col-sm-3">
                                            <label className="imagecheck w-100">
                                            <input 
                                                name="imagecheck w-100" 
                                                type="radio" 
                                                value="1" 
                                                className="imagecheck-input" 
                                                //checked={props.pcBgImage[0] === props.pcBgImageList[7]}
                                                checked={props.pcBasicBgImage === props.pcBgImageList[7]}
                                                //onClick={()=>props.setPcBgImage([props.pcBgImageList[7],props.pcBgImage[1]])}
                                                onClick={()=>{
                                                    props.setPcBasicBgImage(props.pcBgImageList[7])
                                                    props.setIsChange(true)
                                                }}
                                                readOnly
                                                />
                                            <figure className="imagecheck-figure">
                                                <div className="imgBox">
                                                    <span>
                                                    <img src={props.pcBgImageList[7]} />
                                                    </span>
                                                </div>
                                            </figure>
                                            </label>
                                        </div>
                                    </div>
                                </div>    
                                
                            </div>
                            {/* m4 */}

                            <div id="bgcolorArea2" className="custom-controls mt-2 ">
                                <div className="sbox">
                                    <span className="iconBox01"><i className="fe fe-image"></i></span> PC버전에서 사용할 프로필 이미지 직접 편집{/*배경이미지 직접 편집*/}
                                    <div className="mt-4">
                                        
                                        <div className="form-group">
                                            <label className="form-label">프로필 이미지 찾기</label>
                                            <div className="row gutters-xs">
                                                <div className="col">
                                                    <form>
                                                        <input 
                                                            type="file" 
                                                            className="form-control file-upload-field " 
                                                            placeholder="업로드할 이미지를 찾아주세요."
                                                            ref={inputRef2}
                                                            onChange={selectFileFunctionProfileImage}
                                                        />
                                                    </form>
                                                    
                                                </div>
                                                <span className="col-auto">
                                                    <button 
                                                        className="btn btn-secondary" 
                                                        type="button"
                                                        onClick={() => {
                                                            inputRef2.current.click();
                                                        }}
                                                    >찾기</button>
                                                </span>
                                            </div>
                                            <p className="mt-2 imgGuidetxt">120px * 120px의 최적화된 이미지를 올려주셔야 합니다.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>
            }
            </>

	);
};
export default withRouter(withTranslation()(SelectPcBgColorMenu));

SelectPcBgColorMenu.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
