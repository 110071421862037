import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
//post forbidden word API's
const SelectMenuPageMenu = (props) => {

	return (

		<>
            <div className="col-lg-12">
                <p className="stepTit">4. 페이지의 디자인 형태를 정해 주세요.</p>
            </div>
            <div className="col-lg-6">

                <div className="custom-controls ">
                    <div className="sbox">
                        <label className="custom-control custom-radio custom-control-inline m-0">
                            <input 
                                id="checkFoot01" 
                                type="radio" 
                                className="custom-control-input" 
                                name="topDesign02" 
                                value="option1"
                                checked={props.designType === 'tab'}
                                onClick={()=>{
                                    props.setDesignType('tab')
                                    props.setIsChange(true)
                                }}
                                readOnly  
                            />
                            <span className="custom-control-label">구분형 </span>
                        </label>

                        <img src={msample03} className="topup" />
                    </div>
                    <div className="sbox">
                        <label className="custom-control custom-radio custom-control-inline m-0">
                            <input 
                                id="checkFoot02" 
                                type="radio" 
                                className="custom-control-input" 
                                name="topDesign02" 
                                value="option2" 
                                checked={props.designType === 'page'}
                                onClick={()=>{
                                    props.setDesignType('page')
                                    props.setIsChange(true)
                                }}
                                readOnly  
                            />
                            <span className="custom-control-label">원페이지형 </span>
                        </label>
                        <img src={msample04} className="topup" />
                    </div>
                    
                    
                </div>
            </div>
            </>			


	);
};
export default withRouter(withTranslation()(SelectMenuPageMenu));

SelectMenuPageMenu.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
