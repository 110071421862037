import _ from 'lodash';

//import room service
import {
	isSeenService,
	getThumbnailUrl,
	uploadVAFService,
	uploadImageService,
	sendMessagesService,
	getMessagesService,
	previewMessageService,
} from '../../../services/talk2meMessage.service';
import {isValidUrl} from '../../../helpers/Functions/check';
import { toast } from 'react-toastify';

// Sent message
export async function addMessageFunction(
	newHashCode,
	data,
	msgType,
	curMessage,
	fileName,
	jobId,
	fileOrigin,
	fileSize,
	imgRatio,
	durationResult
) {
	let checkMsg = curMessage;

	if (msgType === 'TEXT')
		if (checkMsg.substring(0, 1) === '\n') checkMsg = checkMsg.substring(1);

	const body = {
		roomId: data.roomId,
		senderId: data.user.id,
		senderImg: data.user.image_url,
		senderName: data.user.nickname,
		msgType: msgType,
		message: fileName || checkMsg,
		imgRatio: imgRatio || 0,
		duration: durationResult || 0,
		jobId: jobId,
		fileOrigin: fileOrigin || '',
		fileSize: fileSize || 0,
		hashCode: newHashCode,
		isActivated: 1,
	};
	sendMessagesService(body);

	if (msgType === 'TEXT') {
		const first = checkMsg.split(' ')[0];
		let urlCheck = isValidUrl(first);

		if (urlCheck) {
			const previewbody = {
				url: first,
				hashCode: newHashCode,
			};
			previewMessageService(previewbody);
		}
	}

	return {
		status: true,
		message: body,
	};
}

// Select file
export async function selectFileFunction(newHashCode, data, event) {
	const fileUploaded = event.target;
	const formData = new FormData();

	if (
		fileUploaded &&
		fileUploaded.files &&
		fileUploaded.files[0] &&
		fileUploaded.files[0].type
	) {
		let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
		let type = fileUploaded.files[0].type.substring(0, 5);

		if (type === 'video') {
			if (originFileSize > 200) {
				return {
					status: false,
					message: 'This file is larger than 200mb',
				};
			}
		} else {
			if (originFileSize > 20) {
				return {
					status: false,
					message: 'This file is larger than 20mb',
				};
			}
		}

		let bucketName = 'flowing-web-resources';
		let fileSize = originFileSize.toFixed(2);
		let fileName = fileUploaded?.files[0]?.name;
		formData.append('upload', fileUploaded.files[0]);

		if (fileSize < 1) {
			//fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			fileSize = (fileUploaded?.files[0]?.size ) + 'Byte';
		} else {
			fileSize += 'MB';
		}

		if (type === 'audio') {
			return audioUpload(
				newHashCode,
				data,
				formData,
				bucketName,
				fileSize,
				fileName,
			);
		} else if (type === 'video') {
			let durationResult = null;
			const vid = document.createElement('video')
			const fileURL = URL.createObjectURL(fileUploaded.files[0])
			vid.src = fileURL
			vid.ondurationchange = async function () {
				durationResult = this.duration
			}
			// TODO: return 값을 ondurationchange 함수 안에 넣으면 메시지가 바로 표시되지 않음;;
			// 크리티컬한 문제라 일단 duration을 포기함
			return videoUpload(newHashCode,data,formData,bucketName,fileSize,fileName,durationResult)
		} else if (type === 'image') {
			return imageUpload(newHashCode, data, formData, fileSize, fileName);
		} else {
			return fileUpload(
				newHashCode,
				data,
				formData,
				bucketName,
				fileSize,
				fileName,
			);
		}
	} else {
		return {
			status: false,
			message: 'Please try again',
		};
	}
}

// docs and zip etc ... other file upload
const fileUpload = async (
	newHashCode,
	data,
	formData,
	bucketName,
	fileSize,
	fileName,
) => {
	let msgType = 'FILE';
	let uploadType = 'docs';

	const fileResponse = await uploadVAFService(formData, bucketName, uploadType);

	if (fileResponse.msg === 'OK') {
		return addMessageFunction(
			newHashCode,
			data,
			msgType,
			'',
			fileName,
			'',
			fileResponse.url[0],
			fileSize,
		);
	} else {
		return {
			status: false,
			message: 'Error upload this file',
		};
	}
};

// Image upload function
const imageUpload = async (newHashCode, data, formData, fileSize, fileName) => {
	let msgType = 'PHOTO';
	formData.append('limitedImageSize', 1280);
	const imageResponse = await uploadImageService(formData).then(r=>{
		console.log(r)
		return r
	});
	if (imageResponse.msg === 'OK') {
		return addMessageFunction(
			newHashCode,
			data,
			msgType,
			imageResponse?.url[0],
			'',
			'',
			imageResponse?.url[0],
			fileSize,
			imageResponse.imgRatio[0]
		);
	} else {
		return {
			status: false,
			message: 'Error upload image',
		};
	}
};

// Audio upload function
const audioUpload = async (
	newHashCode,
	data,
	formData,
	bucketName,
	fileSize,
	fileName,
) => {
	let uploadType = 'audio';

	const audioResponse = await uploadVAFService(
		formData,
		bucketName,
		uploadType,
	);
	if (audioResponse.msg === 'OK') {
		return addMessageFunction(
			newHashCode,
			data,
			'VOICE',
			'',
			fileName,
			'',
			audioResponse.url[0],
			fileSize,
		);
	} else {
		return {
			status: false,
			message: 'Error upload audio',
		};
	}
};

// Video upload function
const videoUpload = async (
	newHashCode,
	data,
	formData,
	bucketName,
	fileSize,
	fileName,
	durationResult
) => {
	formData.append('needToConvert', 'Y');
	const videoResponse = await uploadVAFService(formData, bucketName, 'video');

	if (videoResponse && videoResponse.msg !== 'OK') {
		return {
			status: false,
			// message: 'Error sent video upload',
			message: '비디오파일을 업로드 하지 못했습니다.',
		};
	}

	let body = {
		video_url: videoResponse.src_url[0],
		bucket_name: bucketName,
	};

	const thumbnailUrlResponse = await getThumbnailUrl(body).then(r=>{console.log(r);return r});

	if (!thumbnailUrlResponse.thumbnailUrl) {
		return {
			status: false,
			// message: 'Error sent video upload get thumbnailUrl',
			message: '비디오파일에서 썸네일이미지를 추출하지 못했습니다.',
		};
	}

	return addMessageFunction(
		newHashCode,
		data,
		'VIDEO',
		'',
		thumbnailUrlResponse.thumbnailUrl,
		videoResponse.jobIds[0],
		videoResponse.url[0],
		fileSize,
		thumbnailUrlResponse.imgRatio,
		durationResult
	);
};

// Get messages
export async function getMessagesFunction(roomId, userId, messageParam) {
	const response = await getMessagesService(
		roomId,
		userId,
		messageParam.since,
		messageParam.until,
		messageParam.limit,
	);

	return {
		limit:
			response && response.limit
				? {
					transferCount: response.limit.transferCount,
					transferMaxCount: response.limit.transferMaxCount,
				}
				: {},
		messages: response && response.itemList.length > 0 ? response.itemList : [],
	};
}

// Is seen
export async function isSeenFunction(messageList, ownerId, roomId) {
	let unReadOwnerMsgCount = 0;
	let newList = [...messageList];
	let reverseMessageList = _.reverse(newList);
	for (let message of reverseMessageList) {
		if (message.senderId === ownerId) break;
		let response = await isSeenService(roomId, message.messageId);
		console.log('response', response)
		if (response && response.itemList.length > 0) {
			if (response.itemList[0].read) break;
			unReadOwnerMsgCount++;
		}
	}
	return unReadOwnerMsgCount;
}
