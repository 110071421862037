import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';
import {button} from 'aws-amplify';
import './DialogModal.scoped.scss';

const DialogModal = ({isClosable, ...props}) => {
    // console.log('DialogProps', props);

    const CloseBtn = (
        <button onClick={props.toggle} className="dialog-Message-modal__close" type="button">&times;</button>
    );

    return (
        <Modal className="dialog-Message-modal" {...props}>
            {props.children ? (
                <>
                    {props.children}
                </>
            ) : (
                <>
                    {props?.content?.title && (
                        <ModalHeader toggle={props.isClosable && props.toggle} close={CloseBtn}>
                            {props?.content?.title}
                        </ModalHeader>
                    )}
                    {props?.content?.message && (
                        <ModalBody className="text-center">
                            {props?.content?.message}
                        </ModalBody>
                    )}
                    {props?.content?.actions ? (
                        <ModalFooter>
                            {props?.content?.actions}
                        </ModalFooter>
                    ) : (
                        <ModalFooter>
                            <Button onClick={props.toggle}>닫기</Button>
                        </ModalFooter>
                    )}
                </>
            )}
        </Modal>
    );
}

export default DialogModal;