// import {get, post} from '../helpers/api_helper_chat';
import {get, post} from '../helpers/api_helper';

export async function enterRoom(body, roomId) {
	try {
		return await post(`talk2me/rooms/${roomId}/enter`, body, {}, true);
	} catch (err) {
		return err;
	}
}

export async function getRoomData(userId, roomId) {
	try {
		return await get(`/talk2me/rooms/${roomId}/fetch?userId=${userId}`)
	} catch (err) {
		console.error(err)
		throw err
	}
}

export async function retriveRoomList(userId, roomId) {
	try {
		return await get(`talk2me/rooms/${roomId}/fetch/roomId/lastMessagedAt/activeUsers?userId=${userId}`);
	} catch (err) {
		throw err;
	}
}

export async function retriveRoomListUsers(body, roomId) {
	try {
		return await get(`talk2me/rooms/${roomId}/info/users`, body);
	} catch (err) {
		throw err;
	}
}

export async function retriveMyRoomList(userId) {
	try {
		return await get(`talk2me/rooms?userId=${userId}`);
	} catch (err) {
		throw err;
	}
}

export async function retriveUnreadMessageCount(userId, body) {
	try {
		return await post(`common/users/${userId}/unread`, body);
	} catch (err) {
		throw err;
	}
}

export async function retriveQuestionList(userId) {
	try {
		return await get(`talk2me/rooms/feed?userId=${userId}=&lat=&lon=&offset=&limit=`);
	} catch (err) {
		throw err;
	}
}
