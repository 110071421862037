import React from 'react';
import {toast} from 'react-toastify';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import {blockUserFromChatRoomService} from '../../../services/talk2meMessage.service';

const BlockModal = (props) => {
	const {userId, userName, targetId, targetName, isBlockModal} = props;
	// User block
	const blockUserFunction = async () => {
		let data = {
			userId,
			userName,
			targetId,
			targetName,
		};
		const response = await blockUserFromChatRoomService(props.roomId, data);

		if (response.message === 'Deleted') {
			// toast.success('Block user success');
			toast.success('사용자를 차단했습니다.');
		}
		props.exitModal();
		props.onChange();
	};

	return (
		<Modal returnFocusAfterClose={true} isOpen={isBlockModal} centered={true}>
			<ModalBody className="text-center">
				해당 유저를 답변톡방에서 <br/> 내보내시겠습니까?
				<br/>
				내보내기를 하면 해당 이용자는 해당 <br/> 답변톡방에 입장할 수 없습니다.
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => props.exitModal()}>
					취소
				</Button>
				<Button color="primary" onClick={() => blockUserFunction()}>
					내보내기
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default withRouter(withTranslation()(BlockModal));
