import './BannerItem.scoped.scss';

export const BannerItem = ({bannerTitle, bannerImageUrl, bannerUrl, ...props}) => {

    return (
        <article className="space-home-preview-ui">
            <a href={!!bannerUrl ? bannerUrl : undefined} target={'_blank'} className="banner-link-item">
                <div className="image-wrapper">
                    <img src={bannerImageUrl} alt=""/>
                </div>
                <div className="text-area">
                    {bannerTitle}
                </div>
            </a>
        </article>
    )
}

export default BannerItem;