import React, { useRef } from "react";

const AddTabInput = ({
    parentId,
    postAddTemplate,
    addTemplateValueHandle,
    addTemplate,
    tempLocation,
}) => {
    const inputRef = useRef();
    return (
        <form
            onSubmit={(e) => {
                inputRef.current.value = null;
                postAddTemplate(e, parentId, addTemplate, tempLocation);
            }}
        >
            <div className="first-answer-wrap-top">
                <button className="btn btn-primary post-btn btn-1st-custom">
                    추가
                </button>
                {/* <p>{messageValue.length}/20</p> */}
                {/* TODO: component위치에 따라 다른 글자 수 반영할 것. */}
                <p>최대 20글자입니다.</p>
            </div>
            <input
                required
                ref={inputRef}
                name={tempLocation}
                className="form-control"
                placeholder="답변 분야 명칭을 입력하고 추가하기를 누르세요."
                type="text"
                maxLength="20"
                onChange={(e) => {
                    addTemplateValueHandle(e.target);
                }}
            />
        </form>
    );
};

export default AddTabInput;
