import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Spinearea = (props) => {
	const series = [
		{
			name: '매출',
			// data: [50, 51, 55, 52, 59, 51, 50, 55, 54, 57, 59, 52, 53, 50, 59, 48, 44, 57, 43, 53, 59,],
			data: props.salesAmountGraphData || [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
		},
		{
			name: '정산',
			// data: [19, 22, 28, 36, 34, 38, 39, 30, 28, 22, 29, 35, 38, 31, 27, 26, 21, 35,33, 32, 29,],
			data: props.settlementAmountGraphData || [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
		},
	];

	const options = {
		dataLabels: {
			enabled: false,
		},
		legend: {
			show: true,
		},
		yaxis: {
			show: true,
		},
		xaxis: {
			labels: {
				show: true,
			},
		},
		toolbar: {
			show:false,
		  },
		stroke: {
			// show: false,
			curve: 'smooth'
		},
		fill: {
			type: 'solid',
		},
		markers: {
			strokeColor: ['#b8b8b9', '#6610f2'],
			size: [1, 1],
		},
		colors: ['#b8b8b9', '#6610f2'],
	};

	return (
		<ReactApexChart
			options={options}
			series={series}
			type="area"
			height="385"
		/>
	);
};

export default Spinearea;
