import React, {useState, useEffect, useRef, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Calendar from 'react-calendar';
import moment from "moment";

import 'react-calendar/dist/Calendar.css';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import { Button } from 'reactstrap';
import ReactSelect from 'react-select';
import {selectOptions, selectTime} from '../../../constants/options';
import {checkUrlPattern, addPrefixToUrl, isNum, checkUrlScheme} from '../../../common/utils/functions';
import {ScheduleList, ScheduleListItem} from '../../../components/previews/spacehome/ScheduleList';
import {calculateDateDiff} from '../../../utils/displayDateTime';

const ShoppingEventSetting = (props) => {

	const [viewCalendar1, setViewCalendar1] = useState(false);
	const [viewCalendar2, setViewCalendar2] = useState(false);
	const [targetIndex, setTargetIndex] = useState(-1);
	const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
	const [finishDate, setFinishDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
	const [startTime, setStartTime] = useState('');
	const [finishTime, setFinishTime] = useState('');
	const [eventTitle, setEventTitle] = useState('');
	const [eventUrl, setEventUrl] = useState('');

	const [startValue, setStartValue] = useState(new Date());
	const [finishValue, setFinishValue] = useState(new Date());

	const urlRef = useRef(null)

	const toggleCalendar1 = () => {
		setViewCalendar1(!viewCalendar1)
	}
	const toggleCalendar2 = () => {
		setViewCalendar2(!viewCalendar2)
	}
	const changeStartDate = (nextValue) => {
		setStartValue(nextValue);
		setStartDate(moment(nextValue).format("YYYY-MM-DD"));
		console.log(moment(nextValue).format("YYYY-MM-DD"))
		setViewCalendar1(!viewCalendar1)
	}

	const changeFinishDate = (nextValue) => {
		setFinishValue(nextValue);
		setFinishDate(moment(nextValue).format("YYYY-MM-DD"));
		console.log(moment(nextValue).format("YYYY-MM-DD"))
		setViewCalendar2(!viewCalendar2)
	}

	const chooseStartTime =(choose) =>{
		console.log('choose', choose);
		setStartTime(choose.value);
	}
	const chooseFinishTime =(choose) =>{
		console.log('choose', choose);
		setFinishTime(choose.value);
	}

	const changeTitle = (e) => {
		setEventTitle(e.target.value)
	}
	const changeUrl = (e) => {
		setEventUrl(e.target.value)
		// setEventUrl(addPrefixToUrl(e.target.value))
	}

	const clearEvent = () => {
		setStartDate('')
		setFinishDate('')
		setStartTime('')
		setFinishTime('')
		setEventTitle('')
		setEventUrl('')
		setTargetIndex(-1)
	}

	const sortEventList = useCallback((eventList) => {
		try {
			eventList = eventList || [];
			if (!eventList?.length) return [];

			const closedEvents = [...eventList].filter(o => calculateDateDiff(o?.startDate, o?.finishDate) === 'CLOSED');
			const activeEvents = [...eventList].filter(o => calculateDateDiff(o?.startDate, o?.finishDate) !== 'CLOSED');

			closedEvents.sort((a, b) => !a.finishDate || a.finishDate < b.finishDate ? -1 : !b.finishDate || a.finishDate > b.finishDate ? 1 : 0);
			activeEvents.sort((a, b) => !a.startDate || a.startDate < b.startDate ? -1
				: !b.startDate || a.startDate > b.startDate ? 1
					: a.startDate === b.startDate ?
						(!a.finishDate || a.finishDate < b.finishDate ? -1
							: !b.finishDate || a.finishDate > b.finishDate ? 1 : 0) : 0);

			return closedEvents.concat(activeEvents);
		} catch (e) {
			console.error(e);
			return eventList;
		}
	}, [])

	const registerEvent = () => {
		// console.log('shoppingEvent : ', props.shoppingEvent);
		let shoppingEventCopy = [...props.shoppingEvent];
		// console.log('shoppingEventCopy : ', shoppingEventCopy);
		// console.log('targetIndex : ', targetIndex);

		// console.log(moment(finishDate).isBefore(startDate)); return;
		if(!startDate || !finishDate) {
			window.alert('스케줄 시작일과 종료일을 선택해 주세요.');
			console.log('스케줄 시작일과 종료일을 선택해 주세요.');
			return false;
		}
		/*if(moment(finishDate).isBefore(moment())) {
			window.alert('지난 일정은 등록할 수 없습니다.');
			console.log('지난 일정은 등록할 수 없습니다.');
			return false;
		}*/
		if(moment(finishDate).isBefore(startDate)) {
			window.alert('종료일이 시작이 보다 앞선 날짜입니다. 확인해 주세요.');
			console.log('종료일이 시작이 보다 앞선 날짜입니다. 확인해 주세요.');
			return false;
		}

		if(eventUrl) {
			if(!checkUrlPattern(eventUrl)) {
				window.alert('스케줄 링크를 URL 형식에 맞게 입력해 주세요.');
				// console.log('스케줄 링크를 URL 형식에 맞게 입력해 주세요.');
				urlRef.current.focus();
				return false;
			} else {
				if(!checkUrlScheme(eventUrl)) {
					window.alert('스케줄 링크를 URL 형식에 맞게 변경합니다.');
					// console.log('스케줄 링크를 URL 형식에 맞게 입력해 주세요.');
					setEventUrl(addPrefixToUrl(eventUrl));
					urlRef.current.focus();
					return false;
				}
			}
		}

		if(targetIndex < 0) {
			console.log('added')
			if(eventTitle){
				shoppingEventCopy.push({
					startDate,
					finishDate,
					startTime,
					finishTime,
					eventTitle,
					eventUrl,
				})
			} else {
				window.alert('스케줄 제목을 입력해 주세요.')
			}
		} else {
			console.log('replaced');
			shoppingEventCopy.splice(targetIndex, 1, {
				startDate,
				finishDate,
				startTime,
				finishTime,
				eventTitle,
				eventUrl,
			})
		}

		// 값이 없는 경우 뒤로 보내고 날짜 순 재정렬
		// shoppingEventCopy.sort((a, b) => !a.startDate || a.startDate < b.startDate ? -1 : !b.startDate || a.startDate > b.startDate ? 1 : 0);
		// shoppingEventCopy.sort((a, b) => !a.startDate || a.startDate < b.startDate ? -1
		// 												: !b.startDate || a.startDate > b.startDate ? 1
		// 													: a.startDate === b.startDate ?
		// 														(!a.finishDate || a.finishDate < b.finishDate ? -1
		// 															: !b.finishDate || a.finishDate > b.finishDate ? 1 : 0) : 0);

		props.setShoppingEvent(sortEventList(shoppingEventCopy))
		setStartDate('')
		setFinishDate('')
		setStartTime('')
		setFinishTime('')
		setEventTitle('')
		setEventUrl('')
		setTargetIndex(-1)
	}

	const modifyShoppingEvent = (index) =>{
		console.log('index', index)
		// setIsModify(true)
		setTargetIndex(index)
		console.log('targetIndex', targetIndex)
		setStartValue(new Date(props.shoppingEvent[index].startDate))
		setStartDate(props.shoppingEvent[index].startDate)
		setStartTime(props.shoppingEvent[index].startTime)
		setFinishValue(new Date(props.shoppingEvent[index].finishDate))
		setFinishDate(props.shoppingEvent[index].finishDate)
		setFinishTime(props.shoppingEvent[index].finishTime)
		setEventTitle(props.shoppingEvent[index].eventTitle)
		setEventUrl(props.shoppingEvent[index].eventUrl)
	};

	const removeShoppingEvent = useCallback((index) => {
		if(window.confirm('삭제하시겠습니까?')) {
			let shoppingEventCopy = [...props.shoppingEvent];
			shoppingEventCopy.splice(index,1);

			props.setShoppingEvent(shoppingEventCopy);
			props.setIsChange(true)
		}
	}, [props.shoppingEvent, props.setShoppingEvent, props.setIsChange]);

	const handleSaveData =() => {

		let shoppingEventCopy = [...props.shoppingEvent];
		let shoppingEventFiltered = [];
		let notConfirmedData = []
		for(let i=0; shoppingEventCopy.length > i;i++){
			if(shoppingEventCopy[i].startDate !=="" && shoppingEventCopy[i].finishDate !==""
				&& shoppingEventCopy[i].eventTitle !==""){
				shoppingEventFiltered.push(shoppingEventCopy[i]);
			} else {
				notConfirmedData.push(shoppingEventCopy[i])
			}
		}
		if(notConfirmedData.length===0) {
			props.setShoppingEvent(shoppingEventFiltered);
			props.setIsChange(true)
			window.alert('저장했습니다.')
		} else {
			toast.warning('내용을 모두 입력해 주세요.')
		}

    }

	return (
		<li className="active">
        <div className="row">
                <div className="col-lg-6">
                    {/*<p>공구이벤트를 등록해 주세요.</p>*/}
                    <div className="custom-controls mt-4">
						<div className="sbox title">
							<span className="iconBox01"><i className="fa fa-calendar"></i></span> {/*공구이벤트*/}스케줄형 링크
						</div>

						<div className="sbox">
							<ScheduleList>
								{props.shoppingEvent.map((item, idx) => (
									<ScheduleListItem key={idx} {...item}
													  appendBody={!!item?.eventUrl ? `url: ${item?.eventUrl}` : ''}
													  append={(
														  <div style={{display: 'flex', alignItems: 'center',}}>
															  <Button className="mr-1 console-icon-btn" size='sm' color="light"
																	  onClick={() => {modifyShoppingEvent(idx)}}>
																  <i className="fa fa-edit"/>
															  </Button>
															  <Button className="mr-1 console-icon-btn" size='sm' color="light"
																	  onClick={() => {removeShoppingEvent(idx)}}>
																  <i className="fa fa-remove"/>
															  </Button>
														  </div>
													  )} />
								))}
							</ScheduleList>
						</div>

						<div className="sbox">
							<div className="mt-4">

								<div className="form-group">
									<label className="form-label">스케줄 시작일</label>
									<div className="row gutters-xs">
										<div className="col-md-5">
											<input
												type="text"
												className="form-control"
												name="startDate"
												value={startDate || moment(new Date()).format("YYYY-MM-DD")}
												readOnly
												placeholder={'스케줄 시작일을 선택해주세요.'}
												// onChange ={changeStartDate}
												onClick={toggleCalendar1}
											/>
										</div>
										<div className="col-md-2">
											<Button className="mr-1 console-icon-btn" size='sm' color="light"
													onClick={toggleCalendar1}
											>
												<i className="fa fa-calendar"/>
											</Button>
										</div>
										<div className="col-md-5">
											<ReactSelect
												className="optionClass"
												onChange={chooseStartTime}
												// styles={colourStyles}
												placeholder="시작 시간 선택"
												value={
													selectTime.filter(function(option){
														return option?.value === startTime;
													})
												}
												options ={selectTime}
												formatOptionLabel={hour =>(
													<div className="selectize-control form-control custom-select single" key={hour.value}>
														<div className="selectize-input items full has-options has-items">
															<div>
																{hour.label}
															</div>
														</div>
													</div>
												)}
											/>
										</div>
										<div>
										{viewCalendar1 ?
											<Calendar
												value={startValue}
												onChange={changeStartDate}
												// locale="en-EN"
												formatDay={(locale, date) =>
													date.toLocaleString('en', { day: 'numeric' })
												}
											/>
										: '' }
										</div>

									</div>
								</div>

								<div className="form-group">
									<label className="form-label">스케줄 종료일</label>
									<div className="row gutters-xs">
										<div className="col-md-5">
											<input
												type="text"
												className="form-control"
												name="finishDate"
												value={finishDate || moment(new Date()).format("YYYY-MM-DD")}
												readOnly
												placeholder={'스케줄 종료일을 선택해주세요.'}
												// onChange ={changeFinishDate}
												onClick={toggleCalendar2}
											/>
										</div>
										<div className="col-md-2">
											<Button className="mr-1 console-icon-btn" size='sm' color="light"
													onClick={toggleCalendar2}
											>
												<i className="fa fa-calendar"/>
											</Button>
										</div>
										<div className="col-md-5">
											<ReactSelect
												className="optionClass"
												onChange={chooseFinishTime}
												placeholder="종료 시간 선택"
												value={
													selectTime.filter(function(option){
														return option?.value === finishTime;
													})
												}
												options ={selectTime}
												formatOptionLabel={hour =>(
													<div className="selectize-control form-control custom-select single" key={hour.value}>
														<div className="selectize-input items full has-options has-items">
															<div>
																{hour.label}
															</div>
														</div>
													</div>
												)}
											/>
										</div>
										<div>
											{viewCalendar2 ?
												<Calendar
													value={finishValue}
													onChange={changeFinishDate}
													formatDay={(locale, date) =>
														date.toLocaleString('en', { day: 'numeric' })
													}
												/>
												: '' }
										</div>
									</div>
								</div>

								<div className="form-group">
									<label className="form-label">스케줄 제목</label>
									<div className="row gutters-xs">
										<div className="col">
											<input
												type="text"
												className="form-control"
												name="eventTitle"
												// defaultValue={''}
												value={eventTitle || ''}
												placeholder={'스케줄 제목을 입력해주세요.'}
												onChange ={changeTitle}
											/>
										</div>
									</div>
								</div>

								<div className="form-group">
									<label className="form-label">스케줄 링크 주소(URL)</label>
									<div className="row gutters-xs">
										<div className="col">
											<input
												type="text"
												className="form-control"
												name="eventUrl"
												// defaultValue={''}
												ref={urlRef}
												value={eventUrl || ''}
												placeholder={'스케줄 링크 주소(URL)를 입력해주세요.'}
												onChange ={changeUrl}
											/>
										</div>
										{/*<p className="mt-2 iconGuide">http:// 혹은 https:// 는 편집화면에서는 자동으로 사라집니다.(실제 연결링크에는 그대로 저장됩니다.) </p>*/}
										{/* <span className="col-auto"><button className="btn btn-secondary" type="button">미리보기반영</button></span> */}
									</div>
								</div>
								<button
									className={targetIndex < 0 ? "btn btn btn-dark" : "btn btn btn-warning"}
									type="button"
									onClick={registerEvent}
								>
									스케줄 {targetIndex < 0 ? '등록' : '수정'}
								</button>
							{eventTitle.length > 0 ?
								<button
									className="btn btn btn-dark"
									type="button"
									onClick={clearEvent}
									style={{marginLeft:"5px"}}
								>
									수정 취소
								</button>
							: ''}
							</div>
						</div>

                    </div>
                </div>
                
                <div className="col-lg-6 text-center">
                    <p className="mb-2">적용 부분만 편집한 화면입니다. </p>
                    <div className="phone02 drop-shadow hauto w320 min600" style={{
                        position: 'sticky',
                        top: 10,
                        bottom: 10,
                    }}>
                        <div className="phone_over hauto">
                            <div className="PhoneArea hauto">
                                {/* <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /></div> */}
                                <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage || imgAvatarNew } alt="" /></div>
                                    
                                <div className="PhoneTop designCase01">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-lg mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-lg mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div className="d-block w-100 ">
                                            <div className="d-flex">
                                            <small className="d-block">{props.info && props.info?.userName} </small>
                                                <a href="#" className="icon d-none d-md-inline-block ml-auto"><i className="icon-home"></i></a>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small><small className="d-block ml-2"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="ChArea"><img src={char} /></div>  
                                </div>

                                <div className="PhoneTop designCase02 d-none">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div>
                                            <small className="d-block">{props.info && props.info?.userName}</small>
                                        </div>
                                        <div className="ml-auto">
                                            <a href="#" className="icon d-none d-md-inline-block ml-3"><i className="icon-home"></i></a>
                                        </div>
                                    </div>
                                    <div className="MainImg mt-2">
                                        <div className="ChArea"><img src={char} /></div>
                                        <img src="assets/img/img_landingSample_new.jpg" />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                                    </div>
                                </div>
                                <div className="PhoneBody hauto">
                                    <div className="conArea pb-100px">
                                        <div className="linkInfo noView mt-3">

                                            {/* 공구이벤트 세팅==================================================== */}
                                            <div className="mb-2 sectorTit subTit">{/*공구이벤트*/}스케줄형 링크</div>
											<ScheduleList list={props.shoppingEvent} />

                                        </div>
                                    </div>

                                    <div className="footlogo"><img src={logo} /></div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 text-right">
                    <hr />
                    <button 
                        className="btn btn btn-dark " 
                        type="button"
                        onClick={(e) => {
							e.preventDefault()
							handleSaveData()
						}}
                    >저장하기</button>
                </div>

            </div>
		</li>
	);
};


export default withRouter(withTranslation()(ShoppingEventSetting));

ShoppingEventSetting.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
