import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {get, patch} from '../helpers/api_helper_landing';
// import {getWithHeader, putWithHeader} from '../helpers/api_helper_chat';
// import {post as postApi} from '../helpers/api_helper_image';

import {post, get, put, patch} from '../helpers/api_helper';

import config from "../config/configuration"

const createColor = async function (color, valueId) {
	try {
		let body = {
			userId: valueId,
			// userId: valueId? valueId:localStorage.getItem('user_id'),
			colorType: color,
		};
		let response = await patch(`/configuration/landing-page/color`, body, {baseURL: config.api.landing_api});
		if (response.status === 200) {
			toast.success('Successful');
		}
		return response;
	} catch (err) {
		// toast.error(err.response.data.message);
	}
};
const getInfo = async function (userId) {
	try {
		return await get(`/configuration/landing-page/${userId}`, {baseURL: config.api.landing_api});
	} catch (err) {
		// toast.error(err.response.data.message);
	}
};
const createLayout = async function (type, valueId) {
	try {
		let body = {
			userId: valueId,
			// userId: valueId? valueId: localStorage.getItem('user_id'),
			layoutType: type,
		};
		let response = await patch(`/configuration/landing-page/layout/`, body, {baseURL: config.api.landing_api});
		if (response.status === 200) {
			toast.success("레이아웃 선택 완료")
		}

		if (response.status === 200) {
			toast.success("레이아웃 선택 완료")
		}
		return response;
	} catch (err) {
		// toast.error(err.response.data.message);
	}
};

//KMS add
const getLandingPageInfo = async function(userId){
	try {
		// let body = {
		// 	userId: userId,
		// };
		// return await getWithHeader(`/talk2you/users/profile/${userId}/landing-page/settings`, body);
		console.log(userId)
		return await get(`/talk2you/users/profile/${userId}/landing-page/settings`, {}, true);
	} catch (err) {
		toast.error(err.response.data.message);
	}
}

const putLandingPageInfo = async function(userId, body){
	try {

		// return await putWithHeader(`/talk2you/users/profile/${userId}/landing-page/settings`, body);
		console.log(body)
		return await put(`/talk2you/users/profile/${userId}/landing-page/settings`, body, {}, true);
	} catch (err) {
		toast.error(err.response.data.message);
	}
}

const uploadVAFService = async function(formData, bucketName, uploadType){
	try {
		// return await postApi(`/common/files/upload/${bucketName}/${uploadType}`, formData);
		return await post(`/common/files/upload/${bucketName}/${uploadType}?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api});
	} catch (err) {
		return err;
	}
}
const getLandingPageURL = async (userId) => {
	try {
		return await get(`/talk2you/users/profile/${userId}/landing-page`, {}, true)
	} catch(err) {
		return err
	}
}
const postLandingPageURL = async (body, userId) => {
	try {
		return await post(`/talk2you/users/profile/${userId}/landing-page`, body, {}, true)
	} catch(err) {return err}
}
const checkLandingPageURL = async (body) => {
	try {
		return await post(`/talk2you/users/profile/landing-page/check`, body, {}, true)
	} catch(err){return Promise.reject(err)}
}
const createLandingPage = async (userId) => {
	const token = sessionStorage.getItem("access_token")
	try {
		return await post(`/landingpage`,{access_token:token, user_id: userId}, {baseURL:config.api.landing_api}, true)
	} catch(err) {
		return err
	}
}
const newCreateLandingPage = async (userId = '') => {
	try {
		return await post(`/talk2you/users/profile/${userId}/spacehome`, {}, {baseURL:config.api.chat_api}, true)
	} catch(error) {return error}
}
const getYoutubeVideoInfo = async (url) => {
	try {
		return await get(`/common/youtube/crawling?videoUrl=${url}`, {baseURL:config.api.proxy_api}, true)
	} catch(err) {return err}
}
export const landingPageService = {
	createColor,
	createLayout,
	getInfo,
	getLandingPageInfo,
	putLandingPageInfo,
	uploadVAFService,
	getLandingPageURL,
	postLandingPageURL,
	checkLandingPageURL,
	createLandingPage,
	newCreateLandingPage,
	getYoutubeVideoInfo
};
