import {memo} from 'react';
import './ScenarioGuideContent.scoped.scss';

import img1 from '../../assets/images/scenario_tree/img_treeSample1.jpg';
import img1_2 from '../../assets/images/scenario_tree/img_treeSample1_2.jpg';
import img1_3 from '../../assets/images/scenario_tree/img_treeSample1_3.jpg';
import img3 from '../../assets/images/scenario_tree/img_treeSample3.jpg';
import img5 from '../../assets/images/scenario_tree/img_treeSample5.jpg';
import img5_2 from '../../assets/images/scenario_tree/img_treeSample5_2.jpg';
import img5_3 from '../../assets/images/scenario_tree/img_treeSample5_3.jpg';
import img6 from '../../assets/images/scenario_tree/img_treeSample6.jpg';
import img6_2 from '../../assets/images/scenario_tree/img_treeSample6_2.jpg';
import img6_3 from '../../assets/images/scenario_tree/img_treeSample6_3.jpg';
import img7 from '../../assets/images/scenario_tree/img_treeSample7.jpg';
import img8 from '../../assets/images/scenario_tree/img_treeSample8.jpg';
import img8_2 from '../../assets/images/scenario_tree/img_treeSample8_2.jpg';
import img9 from '../../assets/images/scenario_tree/img_treeSample9.jpg';

const ScenarioGuideContent = (props) => {
    return (
        <div className="flowing-ui__scenario-tree tree">
            <div className="treeStep01">
                <span>Step0 답변 분야 안내</span>
                <div className="dombox">
                    <p>사진 촬영, 편집, 보정 등 다양한 사진 작업에 대해서 문의하세요.</p>
                    <div className="chatImgBox">
                        <img alt="" src={img1}/>
                    </div>
                    <div className="chatImgBox">
                        <img alt="" src={img1_2}/>
                    </div>
                    <div className="chatImgBox">
                        <img alt="" src={img1_3}/>
                    </div>
                </div>
            </div>

            <div className="treeStep02">
                <span>Step1 답변 분야</span>
                <div className="box">
                    <div className="dombox">
                        <p className="tit">사진촬영</p>
                        <p>사진 촬영에 대한 알려드려요. 기초 상식에서 부터 분야별 노하우를 알려드립니다.</p>
                        <div className="chatImgBox">
                            <img alt="" src={img1}/>
                        </div>

                    </div>

                </div>

                <div className="box">
                    <div className="dombox">
                        <p className="tit">사진편집</p>
                        <p>사진 편집 알려드려요. 기초 상식 부터 응용 노하우까지!</p>
                        <div className="chatImgBox">
                            <img alt="" src={img3}/>
                        </div>

                    </div>

                </div>
                <div className="box">
                    <div className="dombox">
                        <p className="tit">사진보정</p>
                        <p>사진 보정 알려드려요. 당신도 이제 이제 뽀샤시 미인이 될 수 있어요.</p>
                        <div className="chatImgBox">
                            <img alt="" src={img1_3}/>
                        </div>

                    </div>

                </div>
            </div>

            <div className="treeStep03">
                <span>Step2 답변 세부 분야</span>
                <div className="box">
                    <div className="dombox">
                        <p className="tit">인물촬영</p>
                        <p>인물촬영은 인물의 장점이 살아나는 사진을 포착해야 합니다. 촬영의 각도와 색감에 대해
                            알려드립니다.</p>
                        <div className="chatImgBox">
                            <img alt="" src={img5}/>
                        </div>
                        <div className="chatImgBox">
                            <img alt="" src={img5_2}/>
                        </div>
                        <div className="chatImgBox">
                            <img alt="" src={img5_3}/>
                        </div>
                    </div>
                    <div>
                        <div className="box">
                            <div className="smallbox">
                                <p className="tit">풍경촬영</p>
                                <p>풍경촬영은 다양한 색감의 풍경이 잘 드러나야 합니다.</p>

                                <div className="chatImgBox">
                                    <img alt="" src={img6}/>
                                </div>
                                <div className="chatImgBox">
                                    <img alt=""
                                        src={img6_2}/>
                                </div>
                                <div className="chatImgBox">
                                    <img alt=""
                                        src={img6_3}/>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="box">
                    <div className="dombox">
                        <p className="tit">편집노하우</p>
                        <p>사진의 성격에 맞게 채도, 색감, 색온도를 변경해야 멋진 사진이 나올 수 있습니다. </p>
                        <div className="chatImgBox">
                            <img alt="" src={img7}/>
                        </div>
                    </div>
                    <div>
                        <div className="box">
                            <div className="smallbox">
                                <p className="tit">편집프로그램</p>
                                <p>포토샵에서 필수적으로 사용해야 하는 편집 기능을 알려드립니다. </p>

                                <div className="chatImgBox">
                                    <img alt="" src={img8}/>
                                </div>
                                <div className="chatImgBox">
                                    <img alt="" src={img8_2}/>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="box">
                    <div className="dombox">
                        <p className="tit">보정노하우</p>
                        <p>뽀샤시 효과부터 단점을 커버하는 기능까지 다양한 노하우를 알려드립니다.</p>
                        <div className="chatImgBox">
                            <img alt="" src={img9}/>
                        </div>
                    </div>

                </div>
            </div>



        </div>
    )
}

export default memo(ScenarioGuideContent);