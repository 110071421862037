import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SecondAnwerSetting = ({
    selectedSecondData,
    secondValueHandle,
    postSecondValue,
    deleteSecondImg,
    addSecondImage,
}) => {
    // useEffect(() => {
    //     console.log(selectedSecondData);
    // }, [selectedSecondData]);
    return (
        <React.Fragment>
            {selectedSecondData.map((subData, idx) => {
                return (
                    <div key={idx}>
                        <div className="second-answer-setting">
                            <div className="second-answer-setting-input">
                                <p>{subData?.message?.length || 0}/200</p>
                                <button
                                    onClick={() => {
                                        postSecondValue(subData);
                                    }}
                                    className="btn btn-primary post-btn"
                                >
                                    저장
                                </button>
                            </div>
                            <div className="second-answer-setting-contents">
                                <textarea
                                    required
                                    name="message"
                                    placeholder="해당 답변 분야에 대한 간단한 안내 내용을 입력하세요"
                                    type="text"
                                    maxLength="200"
                                    value={subData.message}
                                    onChange={(e) => {
                                        secondValueHandle(
                                            subData.templateId,
                                            e.target.value
                                        );
                                    }}
                                />
                                <div className="second-answer-setting-contents-files">
                                    <div className="second-answer-setting-contents-imgs">
                                        {subData.contents &&
                                            subData.contents.map((img, idx) => {
                                                return (
                                                    <div key={idx}>
                                                        <img
                                                            src={img.message}
                                                            alt="Second Template Img Data"
                                                        />
                                                        <i
                                                            className="icon-close"
                                                            onClick={() => {
                                                                deleteSecondImg(
                                                                    img,
                                                                    subData
                                                                );
                                                            }}
                                                        ></i>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                    <div className="second-answer-setting-contents-add-wrap">
                                        <div className="second-answer-setting-contents-add">
                                            <div>
                                                <input
                                                    type="file"
                                                    id={`secondImgInput${idx}`}
                                                    accept="image/*"
                                                    multiple
                                                    style={{ display: "none" }}
                                                    onChange={(e) => {
                                                        addSecondImage(
                                                            e.target,
                                                            subData.templateId
                                                        );
                                                    }}
                                                />
                                                <label
                                                    htmlFor={`secondImgInput${idx}`}
                                                >
                                                    <i className="icon-paper-clip"></i>
                                                </label>
                                            </div>
                                            <div>
                                                <i className="icon-emoticon-smile"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(SecondAnwerSetting));
SecondAnwerSetting.defaultProps = {};
