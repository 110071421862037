import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import moment from 'moment';
import {ToastContainer, toast} from 'react-toastify';
import {
	Card,
	CardBody,
	Pagination,
	PaginationItem,
	PaginationLink,
	Row,
	Col,
} from 'reactstrap';
import _, {map} from 'lodash';
// import images
import logo from '../../assets/images/noImg.jpg';
import avatar from '../../assets/images/xs/avatar4.jpg';
import RightQuestionContent from '../../components/RightLayout/RightQuestionContent';
import {getMyProfile} from '../../services/talk2youRooms.service';
import {displayedAt} from '../../common/utils/functions';
import { authService } from '../../services/auth.service';

const SearchQuestion = (props) => {
	const [selectedValue, setSelectedValue] = useState({});
	const [keyWordList, setKeyWordList] = useState([]);
	const [userId, setUserId] = useState("")
	const toggleRight = (action) => {
		if (action) {
			document.getElementById('mainContents').classList.toggle('sectionRight');
			document.getElementById('right-sidebar').classList.toggle('rightView');
			document.querySelector('.menu_toggle').click();

		}
	};

	const {setCurrentPage, pageSize, nCurrentPage, totalCount, lastPage, currentPageUnit} = props
	// const userId = localStorage.getItem('user_id');

	useEffect(() => {
        getProfile().then(r => r);

        async function getProfile() {
			const res = await authService.getUserId()
			if(res.status===201) {
				setUserId(()=>{return res.data.user_id})
				let response = await getMyProfile(res.data.user_id);
				// let response = await getMyProfile(userId);
				// console.log(response, 'WITRTER');
				if (response?.itemList[0]) {
					let data = response.itemList[0];
					console.log(data.keywordList);
					setKeyWordList(data.keywordList);
				
				}
			}
        }
    }, []);

	//meta title
	document.title = 'Flowing';
	return (
		<React.Fragment>
			<ToastContainer/>
			{/* <span className="table_tit"> {props.t('Recommended keywords')}</span> */}
			<div className="row align-items-center mb-2">
				<div className="col-lg-10">
					<div className="keywordArea">
						<span className="table_tit">{props.t('Recommended keywords')}</span>
						<div className="keyword">
							{keyWordList&&
								keyWordList.map((item, index) => {
								return(
									<span key={index}>{`#${item}`}</span>
								);

							})}
							{/* <span>#전화영어</span>
							<span>#화상영어</span>
							<span>#생활영어</span>
							<span>#실전영어</span> */}
						</div>
					</div>
				</div>
				<div className="col-lg-2 text-right">
					{/* <a href="#" className="btn btn-outline-secondary btn100" data-toggle="modal" data-target="#addkeyword"> <i className="fe fe-settings"></i> 키워드 설정</a> */}
					<Link to ="/answer/WriteProfile" className="btn btn-outline-secondary btn100" data-toggle="modal" data-target="#addkeyword"> 
						<i className="fe fe-settings"></i> 키워드 설정
					</Link>
					
				</div>
			</div>
			<div className="table-responsive" id="News">
				
						{props.roomList.map((item, index) => {
							// console.log('item', item)
							return (
							<Card key={index}>
								<CardBody>
								<article className="media" key={index}>
									<div className="mr-3">
										<img
											className="w150"
											style={{height: '93px', cursor: 'default'}}
											src={
												item.question === '' ? logo : item.question.images.url
											}
											alt="..."
											// onClick={(e) => {
											// 	toggleRight(true);
											// 	setSelectedValue(item);
											// }}
										/>
									</div>
									<div className="media-body">
										<div
											// className="viewRight"
										>
											<div className="list_area">
												<div className="list-body">
													<div className="listTxtTit">
														<div>
															<div>
																{item.name ? item.name : 'No title'}
															</div>
															<div>
																{' '}
															</div>
														</div>
													</div>
													<div className="mt-2 d-flex">
														<div className="listInfo fs-14">
															<p style={{color: '#545454'}}>
																<i className="fe fe-user"></i>
																{item.activeUsersCount} {props.t('People')}
															</p>
															<p style={{color: '#545454'}}>
																<i className="fe fe-grid"></i>

																{item.relCategory !== null
																	? item.relCategory.map((item, index) => {
																		return item.name;
																	}).join(', ')
																	: 'No category'}
															</p>
														</div>
														<div className="listInfo fs-14">
															<p style={{color: '#545454'}}>
																<i className="fe fe-thumbs-up"></i>0
															</p>
															<p style={{color: '#545454'}}>
																<i className="fe fe-map-pin"></i>
																{item.relRegion !== null
																	? item.relRegion.map((item, index) => {
																		return item.upmyeondongName;
																	}).join(', ')
																	: 'No location'}
															</p>
														</div>
													</div>
												</div>
												<div className="list-name">
													<div className="userImg text-justify">
														<div className="avatar">
															<img
																className="avatar"
																src={
																	item.owner.imageUrl == ''
																		? avatar
																		: item.owner.imageUrl
																}
																alt="img"
															></img>
														</div>
														{item.owner ? item.owner.nickname : 'No name'}
													</div>
													<div className="userInfo">
														{/*<span>Question</span>*/}
														<span>{/*Adoptions 17*/}
															{item.rewardType === 'dia'
															? ` 다이아 ${item.rewardCount}`
															: (item.rewardType === 'ruby'
																?
																` 루비 ${item.rewardCount}`
																: `${item.rewardCount} 포인트`)
															}
														</span>
													</div>
													<span>
														<i className="fe fe-clock"></i>
														{displayedAt(item.createdAt)}
														{/*{Math.round(
															moment(item.createdAt)
																.add(2, 'days')
																.diff(moment().format()) /
															1000 /
															60 /
															60,
														)}{' '}
														hours*/}
													</span>
												</div>
												<div className="media-foot">
													{item.owner?.id !== userId &&
														<button 
															onClick={(e) => {
																toggleRight(true);
																setSelectedValue(item);
															}}
															className="btn btn-outline-secondary round"
														>
															공개톡 참여
														</button>
													}
												</div>
											</div>
										</div>
									</div>
								</article>
							</CardBody>
						</Card>
						);
					})}
					<RightQuestionContent selectedValue={selectedValue}/>
					
				{props.roomList?.length < 1 && (
					<p className="noResult">{props.t('No matching questions found')}</p>
				)}
				{/* {props.roomList?.length > 0 && props.totalHitCount && (
					<Pagination
						aria-label="Page navigation example"
						className="Page-navigation-example"
					>
						<PaginationItem>
							<PaginationLink first onClick={() => props.offset(0)}>
								First
							</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								previous
								onClick={() =>
									props.offset(
										props.currentPage !== 0
											? props.currentPage - 5
											: props.currentPage,
									)
								}
							/>
						</PaginationItem>

						{[...Array(props.totalHitCount)].map((i, index) => (
							<PaginationItem
								key={index}
								active={props.currentPage === index * 5 ? true : false}
							>
								<PaginationLink onClick={() => props.offset(index * 5)}>
									{index + 1}
								</PaginationLink>
							</PaginationItem>
						))}

						<PaginationItem>
							<PaginationLink
								next
								onClick={() =>
									props.offset(
										props.totalHitCount * 5 - 5 > props.currentPage
											? props.currentPage + 5
											: props.currentPage,
									)
								}
							/>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink
								last
								onClick={() => props.offset(props.totalHitCount * 5 - 5)}
							>
								Last
							</PaginationLink>
						</PaginationItem>
					</Pagination>
				)} */}
				{/* 페이지네이션 */}
				{
					props.roomList?.length > 0 && totalCount && (
						// <nav aria-label="Page navigation example">
							<Pagination className="pagination justify-content-center generic-pagination pr-1">
								<PaginationItem>
									<PaginationLink
										aria-label="Previous"
										onClick={()=>{setCurrentPage(1);}}
									>
										First
									</PaginationLink>
								</PaginationItem>
								<PaginationItem>
									<PaginationLink
										aria-label="Previous"
										onClick={()=>{
											if( ((currentPageUnit -1 )*pageSize ) >= 1){
												setCurrentPage(((currentPageUnit -1 )*pageSize )); 
											}
											
										}}
									>
										<i className="fa fa-angle-left"></i>
									</PaginationLink>
								</PaginationItem>
								{[...Array(pageSize)].map((page, i) => {
									if(((currentPageUnit -1 )*pageSize + i+1) <= lastPage){
										return(
											<PaginationItem
												active={ ((currentPageUnit -1 )*pageSize + i + 1) === nCurrentPage }
												key={i}
											>
											<PaginationLink
												// className="page-link" 
												onClick={()=>{setCurrentPage(((currentPageUnit -1 )*pageSize + i+1)); }}
											>{((currentPageUnit -1 )*pageSize + i+1)}
											</PaginationLink>
										</PaginationItem>
										)
									} else {
										return (
											<div key={i} />
										)
									}
									
								})}
								<PaginationItem>
									<PaginationLink
										aria-label="Next"
										onClick={()=>{
											if(currentPageUnit*pageSize+1 <= lastPage)
											setCurrentPage(currentPageUnit*pageSize+1);
										}}
									>
										<i className="fa fa-angle-right"></i>
									</PaginationLink>
								</PaginationItem>
								<PaginationItem>
									<PaginationLink
										aria-label="Next"
										onClick={()=>{setCurrentPage(lastPage);}}
									>
										Last
									</PaginationLink>
								</PaginationItem>
							</Pagination>
						// </nav>
					)
				}
			</div>
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(SearchQuestion));
SearchQuestion.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
