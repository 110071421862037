import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';

import HeaderTypeText from './HeaderTypeText';
import HeaderTypeCustom from './HeaderTypeCustom';



//post forbidden word API's
const SelectMenuOrderMenu = (props) => {

    const inputRef = useRef(null);

    const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					toast.error('This file is larger than 200mb');
					return;
				}
			} else {
				if (originFileSize > 20) {
					toast.error('This file is larger than 20mb');
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                //props.setMobileBgImage(response.url[0]);
                props.setMobileCustomBgImage(response.url[0]);
                props.setIsChange(true)
            }
            
		}
	};

	return (
        <>
            <div className="col-lg-12">
                <p className="stepTit">
                    {/* <span>1. 구분형 </span><span className="stepArrow">&gt;</span>
                    <span>2. 노출순서 </span><span className="stepArrow">&gt;</span>
                    <span>3. 텍스트형 </span><span className="stepArrow">&gt;</span>   */}
                    2. 모바일 배경을 설정해 주세요.
                </p>
            </div>
            <div className="col-lg-6">   
                <div id="bgcolorArea" className="">

                    <div className="custom-controls d-flex">
                        <div className="sbox text-left col mr-1">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input 
                                    id="checkbg01" 
                                    type="radio" 
                                    className="custom-control-input" 
                                    name="bgcolor" 
                                    value="option1" 
                                    // checked={props.mobileBgColor[1] === 'black'}
                                    checked={props.mobileCustomBgColor === 'black'}
                                    // onClick={()=>props.setMobileBgColor([props.mobileBgColor[0], "black"])}
                                    onClick={()=>{
                                        props.setMobileCustomBgColor("black")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <span className="custom-control-label">검은색 배경 </span>
                            </label>

                            
                        </div>
                        <div className="sbox text-left col ml-1">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input 
                                    id="checkbg02" 
                                    type="radio" 
                                    className="custom-control-input" 
                                    name="bgcolor" 
                                    value="option2" 
                                    // checked={props.mobileBgColor[1] === 'white'}
                                    checked={props.mobileCustomBgColor === 'white'}
                                    // onClick={()=>props.setMobileBgColor([props.mobileBgColor[0], "white"])}
                                    onClick={()=>{
                                        props.setMobileCustomBgColor("white")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <span className="custom-control-label">흰색 배경 </span>
                            </label>
                            
                        </div> 
                    </div>

                </div>
                <div className="selectImg">
                    <div className="form-group mt-3">
                        <label className="form-label">이미지 찾기</label>
                        <div className="row gutters-xs">
                            <div className="col">
                                <form>
                                    <input 
                                        type="file" 
                                        id="input-file"
                                        className="form-control " 
                                        placeholder="업로드할 이미지를 찾아주세요."
                                        ref={inputRef}
                                        onChange={selectFileFunction}
                                    />
                                </form>
                            </div>
                            <span className="col-auto">
                                <button 
                                    className="btn btn-secondary" 
                                    type="button"
                                    onClick={() => {
                                        inputRef.current.click();
                                    }}
                                >찾기</button>
                            </span>
                        </div>
                        <p className="mt-2 imgGuidetxt" >640px * 800px의 최적화된 이미지를 올려주셔야 합니다.</p>
                    </div>
                </div>
            </div>
        </>

	);
};
export default withRouter(withTranslation()(SelectMenuOrderMenu));

SelectMenuOrderMenu.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
