const config = {
	app: {
		port: 3003,
		node_env: '',
	},
	db: {
		host: '',
		port: '',
	},
	aws: {
		region: process.env.REACT_APP_AWS_REGION,
		identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
		endpoint: process.env.REACT_APP_AWS_ENDPOINT,
	},
	api: {
		user_api: process.env.REACT_APP_USER_API_URL,
		chat_api: process.env.REACT_APP_CHAT_API_URL,
		payment_api: process.env.REACT_APP_PAYMENT_API_URL,
		search_api: process.env.REACT_APP_SEARCH_API_URL,
		landing_api: process.env.REACT_APP_LANDING_API_URL,
		image_api: process.env.REACT_APP_IMAGE_API_URL,
		common_api: process.env.REACT_APP_COMMON_API_URL,
		default_api: process.env.REACT_APP_DEFAULT_API_URL,
		proxy_api: process.env.REACT_APP_PROXY_API_URL,
		recruit_api: process.env.REACT_APP_RECRUIT_API_URL,
	},
	family_address: {
		spacehome: process.env.REACT_APP_SPACE_BASE_URL,
		recruit: process.env.REACT_APP_RECRUIT_BASE_URL,
	},
};

export default config;
