import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import {landingPageService} from '../../../services/landing-page.service';
import ReactSelect from 'react-select';
import {selectOptions} from '../../../constants/options';
import { Button } from 'reactstrap';
import {OutLinkItem} from '../../../components/previews/spacehome/OutLinkItem';
import {VideoFeedItem} from '../../../components/previews/spacehome/VideoFeedItem';

const OutLinkSetting = (props) => {
   
    const handleSaveData =(type) => {
        // if(!window.confirm("모든 항목을 입력하지 않으면 해당 카드는 저장되지 않습니다. 진행하시겠습니가?" + type)){ // TODO : 메시지가 무의미하게... 모두 저장됨
        //     return;
        // }

		if(type && type === 'link') {
			let outLinkCopy = [...props.outLink];
			let outLinkFiltered = [];
            let notConfirmedData = []
			for(let i=0; outLinkCopy.length > i;i++){
				if(outLinkCopy[i].iconLink !=="" && outLinkCopy[i].linkUrl !=="" && outLinkCopy[i].linkTitle !== '' ){
					outLinkFiltered.push(outLinkCopy[i]);
				} else {
                    notConfirmedData.push(outLinkCopy[i])
                }
			}
            if(notConfirmedData.length===0) {
                props.setOutLink(outLinkFiltered);
                props.setIsChange(true)
                window.alert('저장했습니다.')
            } else {
                toast.warning('블록 링크 내용을 모두 입력해 주세요.')
            }
		}

		/*if(type && type === 'feed') {
			let videoFeedCopy = [...props.videoFeed];
			let videoFeedFiltered = [];
            let notConfirmedData = []
			for(let i=0; videoFeedCopy.length > i;i++){
				if(videoFeedCopy[i].videoUrl !==""){
					videoFeedFiltered.push(videoFeedCopy[i]);
				} else {
                    notConfirmedData.push(videoFeedCopy[i])
                }
			}
            if(notConfirmedData.length===0) {
                props.setVideoFeed(videoFeedFiltered);
                props.setIsChange(true)
                window.alert('저장했습니다.')
            } else {
                toast.warning('영상 피드 내용을 모두 입력해 주세요')
            }
		}*/

        if(type && type === 'all') {

            // TODO: 동일 코드 반복 => 리팩토링 요망

            let outLinkCopy = [...props.outLink];
			let outLinkFiltered = [];
            let notConfirmedData = []
			for(let i=0; outLinkCopy.length > i;i++){
				if(outLinkCopy[i].iconLink !=="" && outLinkCopy[i].linkUrl !=="" && outLinkCopy[i].linkTitle !== '' ){
					outLinkFiltered.push(outLinkCopy[i]);
				} else {
                    notConfirmedData.push(outLinkCopy[i])
                }
			}
            /*let videoFeedCopy = [...props.videoFeed];
			let videoFeedFiltered = [];
            let notConfirmedVideoData = []
			for(let i=0; videoFeedCopy.length > i;i++){
				if(videoFeedCopy[i].videoUrl !==""){
					videoFeedFiltered.push(videoFeedCopy[i]);
				} else {
                    notConfirmedVideoData.push(videoFeedCopy[i])
                }
			}*/
            if(notConfirmedData.length === 0) {
                props.setOutLink(outLinkFiltered);
			}
            /*if(notConfirmedVideoData.length === 0) {
                props.setVideoFeed(videoFeedFiltered);
			}*/
            // if(notConfirmedData.length === 0 || notConfirmedVideoData.length === 0) {
            if(notConfirmedData.length === 0) {
				toast.warning('내용을 모두 입력한 데이터만 저장됩니다.')
                props.setIsChange(true)
                window.alert('저장했습니다.')
			}
        }
    }

	// console.log('outLink', props.outLink)
	return (
		<li className="active">
        <div className="row">
                <div className="col-lg-6">
                    {/*<p>업로드할 유형을 선택해주세요.</p>*/}
                    <div className="custom-controls mt-4">

                        {/* {SampleOutLinkTop(computedOutLink, props.setOutLink, handleSaveData, props.setIsChange)} */}
                        {
                            <SampleOutLinkTop
                                outLink={props.outLink}
                                setOutLink={props.setOutLink}
                                handleSaveData={handleSaveData}
                                setIsChange={props.setIsChange}
                            />
                        }
                        {
                            // props.outLink.length === 0?
                            //     SampleOutLink()
                            // :
                            props.outLink.map((item, index)=>{
                                return(
                                    // SampleOutLink(item, index, computedOutLink, props.setOutLink, props.setIsChange)
                                    <SampleOutLink
                                        key={item?.id || index}
                                        {...props}
                                        item={item}
                                        index={index}
                                        outLink={props.outLink}
                                        setOutLink={props.setOutLink}
                                        setIsChange={props.setIsChange}
                                    />
                                )
                            })
                        }

                        {/* {SampleVideoFeedTop(props.videoFeed, props.setVideoFeed, handleSaveData, props.setIsChange)} */}
                        {/*{
                            <SampleVideoFeedTop
                                videoFeed={props.videoFeed}
                                setVideoFeed={props.setVideoFeed}
                                handleSaveData={handleSaveData}
                                setIsChange={props.setIsChange}
                            />
                        }
                        {
                            // props.videoFeed.length === 0?
                            //     sampleVideoFeed()
                            // :
                            props.videoFeed.map((item, index)=>{
                                return(
                                    // SampleVideoFeed(item, index, props.videoFeed, props.setVideoFeed, props.setIsChange)
                                    <SampleVideoFeed
                                        key={item?.id || index}
                                        item={item}
                                        index={index}
                                        videoFeed={props.videoFeed}
                                        setVideoFeed={props.setVideoFeed}
                                        setIsChange={props.setIsChange}
                                    />
                                )
                            })
                        }*/}

                        {/* <div className="addBox" data-toggle="modal" data-target="#addBox">
                            <i className="fe fe-plus"></i> 블럭추가
                        </div> */}

                    </div>
                </div>
                
                <div className="col-lg-6 text-center">
                    <p className="mb-2">적용 부분만 편집한 화면입니다. </p>
                    <div className="phone02 drop-shadow hauto w320 min600" style={{
                        position: 'sticky',
                        top: 10,
                        bottom: 10,
                    }}>
                        <div className="phone_over hauto">
                            <div className="PhoneArea hauto">
                                {/* <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /></div> */}
                                <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage || imgAvatarNew } alt="" /></div>
                                    
                                <div className="PhoneTop designCase01">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-lg mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-lg mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div className="d-block w-100 ">
                                            <div className="d-flex">
                                            <small className="d-block">{props.info && props.info?.userName} </small>
                                                <a href="#" className="icon d-none d-md-inline-block ml-auto"><i className="icon-home"></i></a>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small><small className="d-block ml-2"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="ChArea"><img src={char} /></div>  
                                </div>

                                <div className="PhoneTop designCase02 d-none">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div>
                                            <small className="d-block">{props.info && props.info?.userName}</small>
                                        </div>
                                        <div className="ml-auto">
                                            <a href="#" className="icon d-none d-md-inline-block ml-3"><i className="icon-home"></i></a>
                                        </div>
                                    </div>
                                    <div className="MainImg mt-2">
                                        <div className="ChArea"><img src={char} /></div>
                                        <img src="assets/img/img_landingSample_new.jpg" />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                                    </div>
                                </div>
                                <div className="PhoneBody hauto">
                                    <div className="conArea pb-100px">
                                        <div className="linkInfo noView mt-3">


                                            {/* 외부링크 세팅==================================================== */}
                                            <div className="mb-2 sectorTit subTit">{/*외부링크*/}블록링크</div>
                                            {props.outLink.map((item, index)=>{
                                                return(
                                                    <OutLinkItem outLink={item} key={item?.id || index} />
                                                )
                                            })}


                                            {/* 피드 세팅==================================================== */}
                                            {/*<div className="mt-4 sectorTit subTit">피드</div>*/}

                                            {/*{props.videoFeed.map((item, index)=>{
                                                return(
                                                    <VideoFeedItem feed={item} key={item?.id || index} />
                                                )
                                            })}*/}
											{/*{props.videoFeed.length > 2 ?
												<div className="row">
													<div className="col-12 text-center">
														<a href="#" className="moreBtnSm">더보기</a>
													</div>
												</div> : ""
											}*/}
                                        </div>
                                    </div>

                                    <div className="footlogo"><img src={logo} /></div>
                                </div>     
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 text-right">
                    <hr />
                    <button 
                        className="btn btn btn-dark " 
                        type="button"
                        onClick={(e) => {
							e.preventDefault()
							handleSaveData('all')
						}}
                    >저장하기</button>
                </div>

            </div>
		</li>
	);
};

function SampleOutLinkTop({outLink, setOutLink, handleSaveData, setIsChange}){

    const basicOutLink = {
            selectOption:{},
            iconLink: "",
            linkUrl: "",
            linkTitle: ""
        }
    ;

    const addOutLink =() =>{
        setOutLink([...outLink, basicOutLink]);
        setIsChange(true)
    };

    return(
    <>
    {/* 링크설정 */}

    <div className="sbox title">
        <span className="iconBox01"><i className="fe fe-link"></i></span> {/*외부링크*/}블록형 링크 등록
        <div className="openBox">
			<i className="fe fe-save" onClick={(e) => {
				e.preventDefault()
				handleSaveData('link');
			}} style={{cursor:'pointer',marginRight:20}}></i>
            <i className="fe fe-plus" onClick={addOutLink} style={{cursor:'pointer'}}></i>
        </div>
    </div>
    </>
)}

function SampleOutLink({item, index, outLink, setOutLink, setIsChange}){

    // const [isManual, setIsManual] = useState(false);

    const changeValue = (target) => {
        const {value} = target
        let outLinkCopy = [...outLink]
        outLinkCopy[index].linkTitle = value
        setOutLink(outLinkCopy)
    }
    const changeUrl = (target) => {
        const {value} = target
        let outLinkCopy = [...outLink]
        outLinkCopy[index].linkUrl = value
        setOutLink(outLinkCopy)
    }
    const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			// let type = fileUploaded.files[0].type.substring(0, 5);
			let type = fileUploaded.files[0].type.split("/")[0];

			if (type === 'image') {
				if (originFileSize > 20) {
					toast.error('파일업로드는 20MB 까지 가능합니다. 파일용량을 확인해 주세요.');
					return;
				}
			} else {
				toast.error('이미지 파일만 업로드 가능합니다. 확인 후 다시 시도해 주세요.');
				return;
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                let outLinkCopy = [...outLink];
                outLinkCopy[index].iconLink = response?.url[0] || '';
                outLinkCopy[index].selectOption ={
					image: response?.url[0] || '',
					label: outLinkCopy[index].linkTitle,
					value: '99'
				};
                setOutLink(outLinkCopy);
                setIsChange(true)
				console.log('outLink['+index+']', outLink)
            }
            
		}
	};

    const removeOutLink = () =>{
        if(window.confirm('삭제하시겠습니까?')) {
            let outLinkCopy = [...outLink];
            outLinkCopy.splice(index,1);
            setOutLink(outLinkCopy);
            setIsChange(true)
        } else return;
    };

    const chooseOption =(choose) =>{
		console.log('choose', choose);

        let outLinkCopy = [...outLink];
		if(choose.value === '99') {
			outLinkCopy[index].isManual = true;
		} else {
            outLinkCopy[index].isManual = false;
        }
        outLinkCopy[index].iconLink = choose.image;
        outLinkCopy[index].selectOption = choose;
        setOutLink(outLinkCopy);
        setIsChange(true)
        console.log('outLinkCopy['+index+']', outLinkCopy[index]);
    }

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			return {
				...styles,
				backgroundColor: '#FFF',
				color: '#FFF',
				cursor: 'default',
				zIndex: 999
			};
        },
    };

    const changeOutLinkIdx = async (action) => {
        if(action === 'up') {
            if(index === 0) return;
            let outLinkCopy = [...outLink]
            let tmp = outLinkCopy[index]
            outLinkCopy[index] = outLinkCopy[index-1]
            outLinkCopy[index-1] = tmp
            setOutLink(()=>{return outLinkCopy})
            setIsChange(true)
        }
        if(action === 'down') {
            if(index === outLink.length - 1) return;
            let outLinkCopy = [...outLink]
            let tmp = outLinkCopy[index]
            outLinkCopy[index] = outLinkCopy[index+1]
            outLinkCopy[index+1] = tmp
            setOutLink(()=>{return outLinkCopy})
            setIsChange(true)
        }
    }

    return(
    <>
    {/* 링크설정 */}

    <div className="sbox" key={index}>
        <div className='d-flex justify-content-between'>
            <div className='d-flex align-items-center'>
                <span className="iconBox01"><i className="fe fe-link" /></span>{/*링크*/}블록 링크
            </div>
            <div className="openBox d-flex align-items-center">
                <Button className="mr-1 console-icon-btn" size='sm' color="light"
                        disabled={index <= 0}
                        onClick={() => {changeOutLinkIdx('up')}}>
                    <i className="fe fe-chevron-up"/>
                </Button>
                <Button className="mr-2 console-icon-btn" size='sm' color="light"
                        disabled={index >= outLink.length - 1}
                        onClick={() => {changeOutLinkIdx('down')}}>
                    <i className="fe fe-chevron-down"/>
                </Button>
                <i className="fe fe-trash-2 mr-2" onClick={removeOutLink} style={{cursor:'pointer'}} />
            </div>
        </div>
        <div className="mt-4">
            <div className="form-group">
                <label className="form-label">{/*파비콘*/}썸네일 설정</label>
                <div className="row gutters-xs">
                    <div className="col">
                        <div className="form-group">
                            <ReactSelect
                                className="optionClass"
                                onChange={chooseOption}
								placeholder="선택"
                                styles={colourStyles}
                                // defaultValue={outLink[index].selectOption === null || outLink[index].selectOption === undefined || Object.keys(outLink[index].selectOption).length === 0 ? "Select...":outLink[index].selectOption.value}
                                value={
                                    selectOptions.filter(function(option){
                                        return option?.value && outLink[index]?.selectOption?.value && Number(option?.value) === Number(outLink[index]?.selectOption?.value);
                                    })
                                }
                                options ={selectOptions}
                                formatOptionLabel={site =>(
                                    <div className="selectize-control form-control custom-select single" key={site.label}>
                                        <div className="selectize-input items full has-options has-items">
                                            <div>
                                                {/*<span className="image"><img src={outLink[index].iconLink || site.image} width="24px" height="24px" alt="" /></span>{site.label}*/}
                                                <span className="image"><img src={site.value && outLink[index]?.selectOption.value && Number(site.value) === Number(outLink[index]?.selectOption.value) ? outLink[index].iconLink : site.image} width="24px" height="24px" alt={site.label} /></span>{site.label}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {outLink[index].isManual &&
                <div className="form-group">
                    <label className="form-label">{/*이미지*/}썸네일 직접 업로드</label>
                    <div className="row gutters-xs">
                        <div className="col" style={{zIndex: 0}}>
                            <div
                                className="file-upload-wrapper"
                                data-text={
                                    outLink[index].iconLink === "" ? "업로드할 이미지를 선택해 주세요."
                                        : (outLink[index].selectOption === null
                                        || outLink[index].selectOption === undefined
                                        /*|| Object.keys(outLink[index].selectOption).length === 0 ? "" : outLink[index].iconLink}>*/
                                        || Object.keys(outLink[index].selectOption).length === 0 ? "업로드할 이미지를 선택해 주세요." : "새로운 이미지를 업로드 하려면 선택해 주세요.")}
							>
                                <button type="button" className="btn btn-secondary"><i className="fas fa-times"></i>
                                </button>
                                {/*<form>*/}
                                    <input
                                        name="file-upload-field"
                                        type="file"
                                        className="file-upload-field form-control"
                                        value={outLink[index].image}
                                        onChange={selectFileFunction}
                                    />
                                {/*</form>*/}

                            </div>
                        </div>

                    </div>
                    <p className="mt-2 iconGuide">
						{
							outLink[index].selectOption === null
							|| outLink[index].selectOption === undefined
							|| Object.keys(outLink[index].selectOption).length === 0 ?
								'파비콘 이미지 말고 자신만의 이미지를 넣으셔도 됩니다. 사이즈는 가로80px 세로80px 입니다.' :
								'직접 업로드한 파비콘 이미지를 수정하려면 새로운 이미지를 선택해 주세요.(가로80px 세로80px)'
						}
					</p>
                </div>
            }
            <div className="form-group">
                <label className="form-label">링크 제목</label>
                <div className="row gutters-xs">
                    <div className="col">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="linkTitle"
                            name="linkTitle"
                            value={outLink[index].linkTitle||''}
                            placeholder={outLink[index].linkTitle||'링크 제목을 입력해주세요.'}
                            onChange ={(e)=>{changeValue(e.target)}}
                        />
                    </div>
                </div>
            </div>

            <div className="form-group">
                <label className="form-label">링크 주소(URL)</label>
                <div className="row gutters-xs">
                    <div className="col">
                        <input
                            type="text"
                            className="form-control"
                            id="linkUrl"
                            name="linkUrl"
                            value={outLink[index].linkUrl||''}
                            placeholder={outLink[index].linkUrl||'링크 주소(URL)를 입력해주세요.'}
                            // onChange ={(e)=>outLink[index].linkUrl = e.target.value}
                            onChange ={(e)=>{changeUrl(e.target)}}
                        />
                    </div>
                    <p className="mt-2 iconGuide">http:// 혹은 https:// 는 편집화면에서는 자동으로 사라집니다.(실제 연결링크에는 그대로 저장됩니다.) </p>
                    {/* <span className="col-auto"><button className="btn btn-secondary" type="button">미리보기반영</button></span> */}
                </div>
            </div>

        </div>
    </div>
    </>
)}
        
/*function SampleVideoFeedTop({videoFeed, setVideoFeed, handleSaveData, setIsChange}){
    const basicVideoFeed =
        {
            videoTitle:"",
            videoUrl:"",
            videoThumbnail:"",
			publishDate:"",
			ownerChannelName:"",
        }
    ;

    const addVideoFeed =() =>{
        setVideoFeed([...videoFeed, basicVideoFeed]);
        setIsChange(true)
    };

    return(
        <>
        {/!* 동영상설정 *!/}
        <div className="sbox title">
            <span className="iconBox02"><i className="icon-social-youtube"></i></span> 피드
			<div className="openBox">
				<i className="fe fe-save" onClick={(e) => {
					e.preventDefault()
					handleSaveData('feed');
				}} style={{cursor:'pointer',marginRight:20}}></i>
                <i className="fe fe-plus" onClick={addVideoFeed} style={{cursor:'pointer'}}></i>
            </div>
        </div>
        </>        
    )   
}*/

/*function SampleVideoFeed({item,index,videoFeed,setVideoFeed, setIsChange}){

	const getYoutubeInfo = async (url) => {
		if(url) {
			// const rs = await axios.get(`${config.api.proxy_api}/common/youtube/crawling?videoUrl=${url}`);
            const rs = await landingPageService.getYoutubeVideoInfo(url)
			console.log('youtube', rs);
			if(rs && rs?.status === 200) {
				const videoCopy = [...videoFeed];
				videoCopy[index].videoUrl = url;
				videoCopy[index].videoThumbnail = rs?.data?.videoThumbnail || '';
				videoCopy[index].videoTitle = rs?.data?.videoTitle || '';
				videoCopy[index].publishDate = rs?.data?.publishDate || '';
				videoCopy[index].ownerChannelName = rs?.data?.ownerChannelName || '';
				setVideoFeed(videoCopy);
                setIsChange(true)
			} else {
                toast.warning('유효하지 않은 링크입니다.')
            }
		} else {
			console.log('No url')
		}
	}

    const changeValue = (target) => {
        const {value} = target
        let videoFeedCopy = [...videoFeed]
        videoFeedCopy[index].videoUrl = value
        setVideoFeed(videoFeedCopy)
    }
    /!*const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					toast.error('This file is larger than 200mb');
					return;
				}
			} else {
				if (originFileSize > 20) {
					toast.error('This file is larger than 20mb');
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                let videoFeedCopy = [...videoFeed];
                videoFeedCopy[index].videoThumbnail = response.url[0];
                setVideoFeed(videoFeedCopy);
            }
            
		}
	};*!/

    const removeVideoFeed = () =>{
        if(window.confirm('삭제하시겠습니까?')) {
            let videoFeedCopy = [...videoFeed];
            videoFeedCopy.splice(index,1);
            setVideoFeed(videoFeedCopy);
            setIsChange(true)
        } else return;
    };

    const changeVideoFeedIdx = async (action) => {
        if(action === 'up') {
            if(index === 0) return;
            let videoFeedCopy = [...videoFeed]
            let tmp = videoFeedCopy[index]
            videoFeedCopy[index] = videoFeedCopy[index-1]
            videoFeedCopy[index-1] = tmp
            setVideoFeed(()=>{return videoFeedCopy})
            setIsChange(true)
        }
        if(action === 'down') {
            if(index === videoFeed.length - 1) return;
            let videoFeedCopy = [...videoFeed]
            let tmp = videoFeedCopy[index]
            videoFeedCopy[index] = videoFeedCopy[index+1]
            videoFeedCopy[index+1] = tmp
            setVideoFeed(()=>{return videoFeedCopy})
            setIsChange(true)
        }
    }


    return(
        <>
        {/!* 동영상설정 *!/}
        <div className="sbox" key={index}>
            <span className="iconBox02"><i className="icon-social-youtube" /></span> 동영상
            <div className="openBox">
                <Button className="mr-1 console-icon-btn" size='sm' color="light"
                        disabled={index <= 0}
                        onClick={() => {changeVideoFeedIdx('up')}}>
                    <i className="fe fe-chevron-up"/>
                </Button>
                <Button className="mr-1 console-icon-btn" size='sm' color="light"
                        disabled={index >= videoFeed.length - 1}
                        onClick={() => {changeVideoFeedIdx('down')}}>
                    <i className="fe fe-chevron-down"/>
                </Button>
                <i className="fe fe-trash-2 mr-2" onClick={removeVideoFeed} style={{cursor:'pointer'}} />
            </div>

            <div className="mt-4">
                
                <div className="form-group">
                    {/!* <label className="form-label">동영상 생성</label> *!/}
                    <div className="row gutters-xs">

                        {/!* <div className="col-12 mb-2">
                            <div className="file-upload-wrapper" data-text={
                                videoFeed[index].videoThumbnail === ""?"업로드할 썸네일 이미지를 선택해 주세요.":videoFeed[index].videoThumbnail}
                                >
                                <button type="button" className="btn btn-secondary"><i className="fas fa-times"></i></button>
                                <form>
                                    <input 
                                        name="file-upload-field" 
                                        type="file" 
                                        className="file-upload-field form-control" 
                                        onChange={selectFileFunction}
                                    />

                                </form>
                            </div>
                        </div> *!/}

                        {/!* <div className="col-12 mb-2">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={videoFeed[index].videoTitle||'동영상 제목을 입력해주세요'}
                                //value={videoFeed[index].videoTitle}
                                onChange = {(e)=>videoFeed[index].videoTitle = e.target.value}
                            />
                        </div> *!/}

                        <div className="col">
                            <input 
                                type="text" 
                                className="form-control" 
                                placeholder={videoFeed[index].videoUrl||"동영상 주소를 입력해주세요." }
                                value={videoFeed[index].videoUrl || '' }
                                // onChange = {(e)=>videoFeed[index].videoUrl = e.target.value}
                                onBlur={(e) => {
									getYoutubeInfo(e.target.value).then(r => r)
								}}
                                onChange ={(e)=>{changeValue(e.target)}}
                            />
							{/!*<i className="fe fe-check-square mr-2"
							   style={{cursor:'pointer'}}
							   onChange = {(e) => {
								   getYoutubeInfo(videoFeed[index].videoUrl).then(r => r)
							   }}
							>수집</i>*!/}
                        </div>
                        <p className="mt-2 iconGuide">http:// 혹은 https:// 가 포함된 전체 주소를 입력해 주세요. </p>
                        <div style={{color:"#01a0fc", marginTop:"-3px"}}>유튜브 링크만 적용됩니다. 다른 서비스는 현재 준비중입니다.</div>
                        {/!* <span className="col-auto"><button className="btn btn-secondary" type="button">미리보기 반영</button></span> *!/}
                    </div>
                </div>
                
                
            </div>
        </div>
        </>        
    )   
}*/

export default withRouter(withTranslation()(OutLinkSetting));

OutLinkSetting.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
