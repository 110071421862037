import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	Spinner,
} from 'reactstrap';
import classnames from 'classnames';

import HeaderDesignSetting from './HeaderDesignSetting';

import OutLinkSetting from './OutLinkSetting'
import MenuPageDesign_onepage from './MenuPageDesign_onepage'
import MenuPageDesign_custom from './MenuPageDesign_custom'
import MenuPageDesign_custom2 from './MenuPageDesign_custom2'

import {userService} from '../../../services/user.service';
import ProfileUrl from './ProfileUrl';
import {getService} from '../../../services/responsetimeSettings.service';
import {landingPageService} from '../../../services/landing-page.service';

import {getMoreProfileItems} from '../../../services/answerProfile.service';
import { toast, ToastContainer } from 'react-toastify';
import { authService } from '../../../services/auth.service';
import ShoppingEventSetting from './ShoppingEventSetting';
import ShoppingBannerSetting from './ShoppingBannerSetting';
import VideoFeedSetting from './VideoFeedSetting';

const LandingPage = (props) => {
	//meta title
	const [nickname, setNickname] = useState('');
	const [userImg, setUserImg] = useState('');
	const [userId, setUserId] = useState("");
	const [districts, setDisctricts] = useState([]);
	const [keyWords, setKeyWords] = useState([]);
	const [activeDate, setActiveDate] = useState([]);
	const [activeTimeFrom, setActiveTimeFrom] = useState('');
	const [activeTimeTo, setActiveTimeTo] = useState('');
	const [exceptHoliday, setExceptHoliday] = useState('off')
	const [info, setInfo] = useState({});
	const [loading, setLoading] = useState();
	const [isChange, setIsChange] = useState(false)
	const [isReleased, setIsReleased] = useState();

	const getTime = async (valueId) => {
		try {
			// const response = await getService(userId);
			const response = await getService(valueId);
			// const response = await getService(localStorage.getItem('user_id'));
			setActiveDate(response && response?.data?.itemList[0]?.days || []);
			setActiveTimeFrom(response && response?.data?.itemList[0]?.from || '');
			setActiveTimeTo(response && response?.data?.itemList[0]?.to || '');
			setExceptHoliday(response && response?.data?.itemList[0]?.exceptHoliday || 'off');
		} catch(err){}
	};

	const getUserImg = async (valueId) => {
		const response = await userService.getUserImg(
			valueId,
			// localStorage.getItem('user_id'),
		);
		// console.log('response : ', response)
		// setUserImg(response?.image_list[0]?.image_url || '');
		setUserImg(response && response?.image_url || '');
	};
	const body = {
		userImg: userImg,
		nickname: nickname,
		userId: userId,
		districts: districts,
		keyWords: keyWords,
		activeDate: activeDate,
		activeTimeFrom: activeTimeFrom,
		activeTimeTo: activeTimeTo,
	};
	const KeyWords = async () => {
		const response = await userService.getKeyWords(
			userId,
			// localStorage.getItem('user_id'),
		);
		setKeyWords(response && response.talk_category_keyword_list || []);
	};
	const DistrictLists = async () => {
		const response = await userService.getDistrictLists(
			// localStorage.getItem('user_id'),
			userId,
		);
		setDisctricts(response && response.district_category_list || []);
	};

	const getInfo = async (valueId) => {
		// let response = await landingPageService.getInfo(localStorage.getItem('user_id'));// TODO : 더 이상 사용하지 않는 API 를 통한 데이터 조회
		// let response = await getMoreProfileItems(userId)
		let response = await getMoreProfileItems(valueId)
		// let response = await getMoreProfileItems(localStorage.getItem('user_id'))
		console.log('items', response)
		setInfo(response && response?.itemList[0] || {});
		setLoading(true);
	};

	const [verticalActiveTab, setverticalActiveTab] = useState('1');
	const toggleVertical = (tab) => {
		if (verticalActiveTab !== tab) {
			setverticalActiveTab(tab);
		}
	};

	
	//KMS add desiginType setting ==============================================================================================
	const [headerType,	setHeaderType] = useState('text');	//text(텍스트형), banner(배너형), custom(커스텀형)
	// const [designType,  setDesignType] = useState('tab'); 	//tab,page
	const [designType,  setDesignType] = useState('page'); // TODO : 메뉴 페이지 디자인 옵션이 삭제되어 page 형 한 가지만 적용
	// const [menuOrder, 	setMenuOrder] = useState(["답변톡","외부링크","상품"]);
	const [menuOrder, 	setMenuOrder] = useState(["답변톡","상품","외부링크 블록형","외부링크 영상형","외부링크 이미지형","외부링크 스케줄형"]);
	const [tabArea, 	setTabArea] = useState(0);			//선택된 현재탭

	//const [mobileBgColor, setMobileBgColor] = useState(["ffffff", "black"]); 	
	const [mobileTextBannerBgColor, setMobileTextBannerBgColor] = useState("ffffff");
	const [mobileCustomBgColor, setMobileCustomBgColor] = useState("black");

	// const [mobileBannerImage, setMobileBannerImage] = useState(landingSampleImg2);
	const [mobileBannerImage, setMobileBannerImage] = useState("https://d1vb419ubgm46b.cloudfront.net/static/img_landingpageBanner.jpg");
	const [mobileCustomBgImage, setMobileCustomBgImage] = useState("https://d1vb419ubgm46b.cloudfront.net/static/img_landingSample3.jpg");

	// const [mobileBgImage, setMobileBgImage] = useState({
	// 	mobileBannerImage : landingSampleImg2,
	// 	mobileCustomBgImage : landingSampleImg3
	// });

	//const [pcBgImage, setPcBgImage] = useState([pcBg01,landingSampleImg3]); //pcBg01 ~ pcBg08
	const [pcBasicBgImage, setPcBasicBgImage] = useState("https://d1vb419ubgm46b.cloudfront.net/static/pcBg01.png");
	const [pcTextBannerProfileImage, setPcTextBannerProfileImage] = useState("https://d1vb419ubgm46b.cloudfront.net/static/img_avatar_sample.jpg");
	const [pcCustomBgImage, setPcCustomBgImage] = useState("https://d1vb419ubgm46b.cloudfront.net/static/img_landingSample3.jpg");
	

	const [pcBgColor, setPcBgColor] = useState('black');
	// const [outLink, setOutLink] = useState([]);
    // const [videoFeed, setVideoFeed] = useState([]);
	const [outLink, setOutLink] = useState([ {
        selectOption:{},
        iconLink: "",
        linkUrl: ""
    }]);

    const [videoFeed, setVideoFeed] = useState([{
        videoTitle:"",
        videoUrl:"",
        videoThumbnail:"",
    }]);

	/*const [shoppingEvent, setShoppingEvent] = useState([{
		startDate: "",
		finishDate: "",
		eventTitle: "",
		eventUrl: "",
	}])*/
	const [shoppingEvent, setShoppingEvent] = useState([])

	const [eventBanner, setEventBanner] = useState([])

	const pcBgImageList =[
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg01.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg02.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg03.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg04.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg05.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg06.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg07.png",
		"https://d1vb419ubgm46b.cloudfront.net/static/pcBg08.png"

	];
	const moblieBgColorList  = 
		{
			"ffffff" : "whiteBg",
			"4eac6d" : "greenBg",
			"50a5f1" : "blueBg",
			"6153cc" : "purpleBg",
			"e83e8c" : "pinkBg",
			"797c8c" : "grayBg"
		};

	
	//최초데이타 가져오기
	const getLandingPageInfo = async(valueId) =>{
		const response = await landingPageService.getLandingPageInfo(
			valueId,
			// localStorage.getItem('user_id'),	
		);
		if(response.status ===200){
			console.log("ITEM:::",response.data.itemList);

			if(response?.data?.itemList[0]?.headerType !== undefined ){
				setHeaderType(response?.data?.itemList[0]?.headerType);
			}
			// TODO : 메뉴 페이지 디자인 옵션이 삭제되어 page 형 한 가지만 적용
			if(response?.data?.itemList[0]?.designType !== undefined ){
				// setDesignType(response?.data?.itemList[0]?.designType);
				setDesignType('page');
			}
			if(response?.data?.itemList[0]?.menuOrder !== undefined ){
				setMenuOrder(response?.data?.itemList[0]?.menuOrder);
			}
			// if(response?.data?.itemList[0]?.mobileBgImage !== undefined ){
			// 	setMobileBgImage(response?.data?.itemList[0]?.mobileBgImage);
			// }
			if(response?.data?.itemList[0]?.mobileBgImage?.mobileBannerImage !== undefined ){
				setMobileBannerImage(response?.data?.itemList[0]?.mobileBgImage?.mobileBannerImage);
			}

			if(response?.data?.itemList[0]?.mobileBgImage?.mobileCustomBgImage !== undefined ){
				setMobileCustomBgImage(response?.data?.itemList[0]?.mobileBgImage?.mobileCustomBgImage);
			}

			// if(response?.data?.itemList[0]?.mobileBgColor !== undefined ){
			// 	setMobileBgColor(response?.data?.itemList[0]?.mobileBgColor);
			// }

			if(response?.data?.itemList[0]?.mobileBgColor?.mobileTextBannerBgColor !== undefined ){
				setMobileTextBannerBgColor(response?.data?.itemList[0]?.mobileBgColor?.mobileTextBannerBgColor);
			}

			if(response?.data?.itemList[0]?.mobileBgColor?.mobileCustomBgColor !== undefined ){
				setMobileCustomBgColor(response?.data?.itemList[0]?.mobileBgColor?.mobileCustomBgColor);
			}

			// if(response?.data?.itemList[0]?.pcBgImage !== undefined ){
			// 	setPcBgImage(response?.data?.itemList[0]?.pcBgImage);
			// }

			if(response?.data?.itemList[0]?.pcBgImage?.pcBasicBgImage !== undefined ){
				setPcBasicBgImage(response?.data?.itemList[0]?.pcBgImage?.pcBasicBgImage);
			}

			if(response?.data?.itemList[0]?.pcBgImage?.pcCustomBgImage !== undefined ){
				setPcCustomBgImage(response?.data?.itemList[0]?.pcBgImage?.pcCustomBgImage);
			}

			if(response?.data?.itemList[0]?.pcBgImage?.pcTextBannerProfileImage !== undefined ){
				setPcTextBannerProfileImage(response?.data?.itemList[0]?.pcBgImage?.pcTextBannerProfileImage);
			}


			if(response?.data?.itemList[0]?.pcBgColor !== undefined ){
				setPcBgColor(response?.data?.itemList[0]?.pcBgColor);
			}

			if(response?.data?.itemList[0]?.outLink !== undefined ){
				setOutLink(response?.data?.itemList[0]?.outLink);
			}
			if(response?.data?.itemList[0]?.videoFeed !== undefined ){
				setVideoFeed(response?.data?.itemList[0]?.videoFeed);
			}

			if(response?.data?.itemList[0]?.shoppingEvent !== undefined ){
				setShoppingEvent(response?.data?.itemList[0]?.shoppingEvent);
			}

			if(response?.data?.itemList[0]?.eventBanner !== undefined ){
				setEventBanner(response?.data?.itemList[0]?.eventBanner);
			}

		}
		
	};

	//자료 입력하기
	const putLandingPageInfo = async() =>{
		console.log("PUT called");
		let body = {
			"headerType" :headerType,
			// "designType" :designType, // TODO : 메뉴 페이지 디자인 옵션이 삭제되어 page 형 한 가지만 적용
			"menuOrder"  :menuOrder,
			//"mobileBgImage" :mobileBgImage,
			"mobileBgImage" :{
				mobileBannerImage : mobileBannerImage,
				mobileCustomBgImage : mobileCustomBgImage
			},
			//"mobileBgColor" :mobileBgColor,
			"mobileBgColor":{
				mobileTextBannerBgColor:mobileTextBannerBgColor,
				mobileCustomBgColor:mobileCustomBgColor
			},
			//"pcBgImage" : pcBgImage,
			"pcBgImage":{
				pcBasicBgImage:pcBasicBgImage,
				pcCustomBgImage:pcCustomBgImage,
				pcTextBannerProfileImage:pcTextBannerProfileImage,
			},
			"pcBgColor" : pcBgColor,
			"outLink" : outLink,
			"videoFeed" : videoFeed,
			"shoppingEvent" : shoppingEvent,
			"eventBanner" : eventBanner,
		};
		console.log(body)
		// console.log("body:::", body);
		const res = await authService.getUserId()
		if(res.status===201) {
			const response = await landingPageService.putLandingPageInfo(
				res.data.user_id, body
				// localStorage.getItem('user_id'), body	
			);
			setIsChange(false)
		}
	};
	// 스페이스홈(랜딩페이지) html 파일 생성을 위한 트리거
	const createStaticHtmlPage = async (e) => {
		e.preventDefault()
		// const userId = localStorage.getItem('user_id')
		if(window.confirm('원하는 주소를 입력하고 디자인 설정을 완료했습니까?')) {
			try {
				const response = await authService.getUserId()
				const requestCreateLandingPage = await landingPageService.newCreateLandingPage(response.data.user_id).then(r => {
					if(r.status!==201) throw r
					return r
				})
				console.log("requestCreateLandingPage::::", requestCreateLandingPage)
				if(requestCreateLandingPage.status === 201) {
					toast.success('스페이스 홈을 생성했습니다.');
					setIsReleased(true);
				} else {
					if(requestCreateLandingPage.response?.data?.place_holder) {
						toast.error(`${requestCreateLandingPage.response?.data.place_holder}`)	
					} else {
						toast.error('다시 한번 시도해 주세요')
					}
				}
			} catch(err) {
				// console.log(err.response?.data?.place_holder)
				console.error(err)
				if (err.response?.data?.place_holder) {
					toast.error(`${err.response.data.place_holder}`)
				} else {
					toast.error('다시 한번 시도해 주세요')
				}
			}
		}
	}
    useEffect(()=>{
	},[]);

	useEffect(() => {
		const settingUserId = async () => {
			try {
				const res = await authService.getUserId()
				if(res.status===201) {
					setUserId(()=>{return res.data.user_id})
					getLandingPageInfo(res.data.user_id).then(r => {
						console.log('getLandingPageInfo', r);
					});
					getUserImg(res.data.user_id).then(r => r);
					DistrictLists().then(r => r);
					KeyWords().then(r => r);
					getTime(res.data.user_id).then(r => r);
					getInfo(res.data.user_id).then(r => r);
					authService.getUserprofile(res.data.user_id).then(res => {
						if (res?.status === 200) {
							const isLandingpageCreated = res?.data?.itemList[0]?.isLandingpageCreated;
							setIsReleased(isLandingpageCreated === 'Y');
						}
					})
				}
			} catch(err){}
		}
		settingUserId().then(r=>r)

		if (sessionStorage.getItem('user_data')) {
			const obj = JSON.parse(sessionStorage.getItem('user_data'));
			// const obj = JSON.parse(localStorage.getItem('user_data'));
			setNickname(obj.nickname);
		}
	}, []);

	// useEffect(()=>{
	// 	putLandingPageInfo().then(r => r)
	// },[headerType, designType, menuOrder, mobileBannerImage, mobileCustomBgImage,mobileTextBannerBgColor,mobileCustomBgColor,pcBasicBgImage,pcCustomBgImage,pcTextBannerProfileImage,pcBgColor,outLink,videoFeed]);
	useEffect(()=>{
		isChange === true && putLandingPageInfo().then(r => r)
		console.log("isChange:::", isChange)
	},[isChange]);

	// useMemo()
	const compositeInfo = useMemo(() => {
		return Object.assign({}, {...info}, {
			outLink,
			videoFeed,
			shoppingEvent,
			eventBanner,
		})
	}, [info, outLink, videoFeed, shoppingEvent, eventBanner])


	document.title = 'Flowing';
	return (
		<React.Fragment>
			<ToastContainer />
			{isChange && (
				<div
				className="d-flex align-items-center justify-content-center"
				style={{
					position: 'fixed',
					inset:0,
					backgroundColor: 'rgba(0,0,0,0.5)',
					zIndex: '999'
				}}>
					<div style={{
						backgroundColor: '#fff',
						borderRadius: '16px',
						padding: '20px',
						minWidth: '150px',
						textAlign: 'center'
					}}>
						<Spinner color="primary" />
					</div>		
				</div>
			)}
			<div className="section-body landingPage">
				<div className="container-fluid">
					<div className="row align-items-center mb-2">
						<div className="col text-left">
							<h1 className="page-title">스페이스 홈 관리</h1>
						</div>
						<div className="col text-right">
							<button className="btn btn btn-success" type="button" onClick={createStaticHtmlPage} style={{marginRight:"5px", marginBottom:"5px"}}>
								{isReleased ? '스페이스홈 업데이트' : '스페이스홈 생성하기'}
							</button>
						</div>
					</div>
					<div className="row clearfix">
						<div className="col-lg-12">
							<div className="naccs">
								<div className="grid">
									<div className="gc gc--1-of-2">
										<div className="menu">
											<Nav pills className="flex-column">
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('1');
														}}
														className={classnames({
															active: verticalActiveTab === '1',
														})}
													>
														{/*<span>답변톡 URL 설정</span>*/}
														<span>스페이스 주소 만들기</span>
													</NavLink>
												</NavItem>

												<p className="landingTit">
													디자인 설정
												</p>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('2');
														}}
														className={classnames({
															active: verticalActiveTab === '2',
														})}
													>
														<span>1. 상단 디자인</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('5');
														}}
														className={classnames({
															active: verticalActiveTab === '5',
														})}
													>
														<span>2. 모바일 배경 설정</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('6');
														}}
														className={classnames({
															active: verticalActiveTab === '6',
														})}
													>
														<span>3. PC 배경 설정</span>
													</NavLink>
												</NavItem>


												<p className="landingTit">
													메뉴 설정
												</p>
												{/*<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('3');
														}}
														className={classnames({
															active: verticalActiveTab === '3',
														})}
													>
														<span>4. 메뉴 페이지 디자인</span>
													</NavLink>
												</NavItem>*/}
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('4');
														}}
														className={classnames({
															active: verticalActiveTab === '4',
														})}
													>
														{/*<span>5. 메뉴 순서 편집</span>*/}
														<span>4. 메뉴 순서 편집</span>
													</NavLink>
												</NavItem>

												<p className="landingTit">
													외부링크 설정
												</p>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('7');
														}}
														className={classnames({
															active: verticalActiveTab === '7',
														})}
													>
														{/*<span>외부링크 정보등록</span>*/}
														<span>5. 블록형 링크 등록</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('13');
														}}
														className={classnames({
															active: verticalActiveTab === '13',
														})}
													>
														<span>6. 동영상형 링크 등록</span>
													</NavLink>
												</NavItem>

												{/*<p className="landingTit">
													공구이벤트 설정
												</p>*/}
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('11');
														}}
														className={classnames({
															active: verticalActiveTab === '11',
														})}
													>
														{/*<span>공구이벤트 등록</span>*/}
														<span>7. 스케줄형 링크 등록</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('12');
														}}
														className={classnames({
															active: verticalActiveTab === '12',
														})}
													>
														{/*<span>공구배너 등록</span>*/}
														<span>8. 이미지형 링크 등록</span>
													</NavLink>
												</NavItem>
												{/*<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('8');
														}}
														className={classnames({
															active: verticalActiveTab === '8',
														})}
													>
														<span>원페이지(임시)</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('9');
														}}
														className={classnames({
															active: verticalActiveTab === '9',
														})}
													>
														<span>커스텀페이지(임시)</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														onClick={() => {
															toggleVertical('10');
														}}
														className={classnames({
															active: verticalActiveTab === '10',
														})}
													>
														<span>커스텀페이지(페이지타입 임시)</span>
													</NavLink>
												</NavItem>*/}
											</Nav>
										</div>
									</div>
									<div className="gc gc--2-of-4 p-0">
										<ul className="nacc">
											<TabContent
												activeTab={verticalActiveTab}
												className="text-muted mt-4 mt-md-0"
											>
												<TabPane tabId="1">
													<ProfileUrl/>
												</TabPane>
												<TabPane tabId="2">
													<HeaderDesignSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={2}
														moblieBgColorList={moblieBgColorList}
														tabArea={tabArea} setTabArea={setTabArea}
														headerType = {headerType} setHeaderType={setHeaderType}
														menuOrder={menuOrder} setMenuOrder={setMenuOrder}
														designType={designType} setDesignType={setDesignType}
														putLandingPageInfo={putLandingPageInfo}
														//mobileBgColor={mobileBgColor} setMobileBgColor={setMobileBgColor}
														mobileTextBannerBgColor={mobileTextBannerBgColor} setMobileTextBannerBgColor={setMobileTextBannerBgColor}
														mobileCustomBgColor={mobileCustomBgColor} setMobileCustomBgColor={setMobileCustomBgColor}
														mobileBannerImage={mobileBannerImage} setMobileBannerImage={setMobileBannerImage}
														mobileCustomBgImage={mobileCustomBgImage} setMobileCustomBgImage={setMobileCustomBgImage}
													/>
												</TabPane>
												<TabPane tabId="3">
													<HeaderDesignSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={3}
														moblieBgColorList={moblieBgColorList}
														tabArea={tabArea} setTabArea={setTabArea}
														headerType = {headerType} setHeaderType={setHeaderType}
														menuOrder={menuOrder} setMenuOrder={setMenuOrder}
														designType={designType} setDesignType={setDesignType}
														putLandingPageInfo={putLandingPageInfo}
														//mobileBgColor={mobileBgColor} setMobileBgColor={setMobileBgColor}
														mobileTextBannerBgColor={mobileTextBannerBgColor} setMobileTextBannerBgColor={setMobileTextBannerBgColor}
														mobileCustomBgColor={mobileCustomBgColor} setMobileCustomBgColor={setMobileCustomBgColor}
														mobileBannerImage={mobileBannerImage} setMobileBannerImage={setMobileBannerImage}
														mobileCustomBgImage={mobileCustomBgImage} setMobileCustomBgImage={setMobileCustomBgImage}
													/>
												</TabPane>
												<TabPane tabId="4">
													<HeaderDesignSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={4}
														moblieBgColorList={moblieBgColorList}
														tabArea={tabArea} setTabArea={setTabArea}
														headerType = {headerType} setHeaderType={setHeaderType}
														menuOrder={menuOrder} setMenuOrder={setMenuOrder}
														designType={designType} setDesignType={setDesignType}
														putLandingPageInfo={putLandingPageInfo}
														//mobileBgColor={mobileBgColor} setMobileBgColor={setMobileBgColor}
														mobileTextBannerBgColor={mobileTextBannerBgColor} setMobileTextBannerBgColor={setMobileTextBannerBgColor}
														mobileCustomBgColor={mobileCustomBgColor} setMobileCustomBgColor={setMobileCustomBgColor}
														mobileBannerImage={mobileBannerImage} setMobileBannerImage={setMobileBannerImage}
														mobileCustomBgImage={mobileCustomBgImage} setMobileCustomBgImage={setMobileCustomBgImage}
													/>
												</TabPane>
												<TabPane tabId="5">
													<HeaderDesignSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={5}
														moblieBgColorList={moblieBgColorList}
														tabArea={tabArea} setTabArea={setTabArea}
														headerType = {headerType} setHeaderType={setHeaderType}
														menuOrder={menuOrder} setMenuOrder={setMenuOrder}
														designType={designType} setDesignType={setDesignType}
														putLandingPageInfo={putLandingPageInfo}
														//mobileBgColor={mobileBgColor} setMobileBgColor={setMobileBgColor}
														mobileTextBannerBgColor={mobileTextBannerBgColor} setMobileTextBannerBgColor={setMobileTextBannerBgColor}
														mobileCustomBgColor={mobileCustomBgColor} setMobileCustomBgColor={setMobileCustomBgColor}
														mobileBannerImage={mobileBannerImage} setMobileBannerImage={setMobileBannerImage}
														mobileCustomBgImage={mobileCustomBgImage} setMobileCustomBgImage={setMobileCustomBgImage}
													/>
												</TabPane>
												<TabPane tabId="6">
													<HeaderDesignSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={6}
														moblieBgColorList={moblieBgColorList}
														tabArea={tabArea} setTabArea={setTabArea}
														headerType = {headerType} setHeaderType={setHeaderType}
														menuOrder={menuOrder} setMenuOrder={setMenuOrder}
														designType={designType} setDesignType={setDesignType}
														putLandingPageInfo={putLandingPageInfo}
														//mobileBgColor={mobileBgColor} setMobileBgColor={setMobileBgColor}
														mobileTextBannerBgColor={mobileTextBannerBgColor} setMobileTextBannerBgColor={setMobileTextBannerBgColor}
														mobileCustomBgColor={mobileCustomBgColor} setMobileCustomBgColor={setMobileCustomBgColor}
														mobileBannerImage={mobileBannerImage} setMobileBannerImage={setMobileBannerImage}
														mobileCustomBgImage={mobileCustomBgImage} setMobileCustomBgImage={setMobileCustomBgImage}
														//pcBgImage={pcBgImage} setPcBgImage={setPcBgImage}
														pcBasicBgImage={pcBasicBgImage} setPcBasicBgImage={setPcBasicBgImage}
														pcCustomBgImage={pcCustomBgImage} setPcCustomBgImage={setPcCustomBgImage}
														pcTextBannerProfileImage={pcTextBannerProfileImage} setPcTextBannerProfileImage={setPcTextBannerProfileImage}

														pcBgColor={pcBgColor} setPcBgColor={setPcBgColor}
														pcBgImageList={pcBgImageList}
													/>
												</TabPane>
												<TabPane tabId="7">
													<OutLinkSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={7}
														outLink={outLink} setOutLink ={setOutLink}
														// videoFeed ={videoFeed}  setVideoFeed={setVideoFeed}
													/>
												</TabPane>
												<TabPane tabId="13">
													<VideoFeedSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={7}
														// outLink={outLink} setOutLink ={setOutLink}
														videoFeed ={videoFeed}  setVideoFeed={setVideoFeed}
													/>
												</TabPane>
												<TabPane tabId="8">
													<MenuPageDesign_onepage
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={8}
													/>
												</TabPane>
												<TabPane tabId="9">
													<MenuPageDesign_custom
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={9}
													/>
												</TabPane>
												<TabPane tabId="10">
													<MenuPageDesign_custom2
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={10}
													/>
												</TabPane>
												<TabPane tabId="11">
													<ShoppingEventSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={7}
														shoppingEvent={shoppingEvent} setShoppingEvent ={setShoppingEvent}
													/>
												</TabPane>
												<TabPane tabId="12">
													<ShoppingBannerSetting
														setIsChange={setIsChange}
														body={body}
														info={compositeInfo}
														loading={loading}
														tabId={7}
														eventBanner={eventBanner} setEventBanner ={setEventBanner}
													/>
												</TabPane>

											</TabContent>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(LandingPage));

LandingPage.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
