import React, { useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import ReactPlayer from 'react-player';

const VideoPlayer = (props) => {
	useEffect(() =>{props.scrollActivateControl()}, [props.message])
	return (
		<React.Fragment>
			<ReactPlayer
				width={props.width}
				height={props.height}
				url={props.fileOrigin}
				playing={false}
				controls={true}
				config={{
					file: {
						attributes: {
							controlsList: !props.isDownload && 'nodownload',
						},
					},
				}}
			/>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(VideoPlayer));
