import React, {useEffect, useState} from 'react';

import SplineArea from './SplineArea';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import {Container, Card, CardBody, Row, Col} from 'reactstrap';

import {getBalanceAccountReport, getCalculateBoardData, getMonths} from '../../services/user.deposit.service';
import axios from 'axios';
import config from '../../config/configuration';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import { authService } from '../../services/auth.service';

const CalculateBoard = (props) => {

	// const userId = localStorage.getItem('user_id');

	const [salesAmount, setSalesAmount] = useState()
	const [cancelAmount, setCancelAmount] = useState()
	const [settlementAmount, setSettlementAmount] = useState()
	const [depositAmount, setDepositAmount] = useState()
	const [salesMoM, setSalesMoM] = useState()
	const [cancelMoM, setCancelMoM] = useState()
	const [settlementMoM, setSettlementMoM] = useState()
	const [depositMoM, setDepositMoM] = useState()

	const [salesAmountGraphData, setSalesAmountGraphData] = useState()
	const [settlementAmountGraphData, setSettlementAmountGraphData] = useState()

	const [valuableMonths, setValuableMonths] = useState([])
	const [changeMonths, setChangeMonths] = useState([])
	const [selectMonths, setSelectMonths] = useState('')


	const getData = async (value) => {
		const searchInitDate = value ? value : moment(new Date()).format("YYYYMM").toString();
		const res = await authService.getUserId()
		if(res.status===201) {
			// const item = await getBalanceAccountReport(userId, searchInitDate);
			const item = await getBalanceAccountReport(res.data.user_id, searchInitDate);
			console.log('result', item);
			if(item) {
				setSalesAmount(item.salesAmount || 0);
				setCancelAmount(item.cancelAmount || 0);
				setSettlementAmount(item.settlementAmount || 0);
				setDepositAmount(depositAmount || 0);
				setSalesMoM(item.salesMoM || 0.00);
				setCancelMoM(item.cancelMoM || 0.00);
				setSettlementMoM(item.settlementMoM || 0.00);
				setDepositMoM(item.depositMoM || 0.00);
				setSalesAmountGraphData(item.salesAmountGraphData || []);
				setSettlementAmountGraphData(item.settlementAmountGraphData || []);
				// setValuableMonths(["202210","202211","202212","202301",'202302'])
				// setValuableMonths(item.userActivityPeriodist || [])
				// changeData(["202210","202211","202212","202301",'202302'])
				// ["202210","202211","202212","202301",'202302']
			}
		}
	}

	const getValidMonths = async () => {
		try {
			const res = await authService.getUserId()
			if(res.status===201){
				const response = await getMonths(res.data.user_id)
				console.log(response)
				setValuableMonths(response.data.itemList || [])
			}
		} catch(err) {return err}
	}


	useEffect(() => {
		getData().then(r => r);
		getValidMonths().then(r=>r)
	}, []);

	const getFile = async (value) => {
		const searchDate = value ? value : valuableMonths[0]

		try {
			const response = await authService.getUserId()
			if(response.status===201) {
				const {user_id} = response.data
				const getRes = await getCalculateBoardData({user_id, searchDate})
				// const getRes = await axios.post(`${config.api.payment_api}/payment/settlement/download/report`, {user_id, searchDate})
				// const getRes = await axios.post(`${config.api.payment_api}/payment/settlement/download/report`, {userId, searchDate})
				console.log(getRes)
				if(getRes.status===204) {
					toast.info('데이터가 존재하지 않습니다.');
					return;
				}
				if(getRes.status===201) {
					toast.info('파일 다운로드를 시작합니다.')
					window.location.download = getRes.data.fileName // TODO: 파일 이름 설정
					window.location.href = getRes.data.fileUrl
				}
			}
		} catch(err) {
			if (err.response.status===400) {
				toast.error('다운로드 하고자 하시는 월을 선택해 주세요')
			} else {
				toast.error('다시 한번 시도해 주세요')
			}
		}
	}

	// const changeData = (value) => {
	// 	const result = value.map((x) => {
	// 		const year = [...x].slice(0,4)
	// 		const months = [...x].slice(4,6)
	// 		const result2 = year.concat('-').concat(months)
	// 		const result3 = result2.join().replace(/,/g, '')
	// 		return result3
	// 	})
	// 	setChangeMonths(()=>{return result})
	// }

	document.title = '플로잉 정산 보고서 조회';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
			<ToastContainer />
				<div className="section-body">
					<Container fluid>
						<div className="row">
							<div className="col-12">
								<div className="page-title-box d-sm-flex align-items-center justify-content-between">
									<h4 className="mb-0 font-size-18">정산 보고서 조회</h4>
								</div>
							</div>
						</div>
						<Row className="clearfix">
							<Col lg={12} className="text-center">
								<div className="d-sm-flex justify-content-between">
									<div>
										<div className="form-group">
											<select
												style={{marginRight:"20px"}}
												className="form-control custom-select"
												onChange={(e)=>{
													setSelectMonths(e.target.value)
													getData(e.target.value)}
												}
												// defaultValue={valuableMonths[valuableMonths.length - 1]}
												// TODO: select tag에 defaultValue값을 설정하라는데 안먹음 추후 보완 필요
											>
												{valuableMonths.length > 0 ? (
													valuableMonths.map((x, idx) => {
														const year = [...x].slice(0,4)
														const months = [...x].slice(4,6)
														const result2 = year.concat('-').concat(months)
														const result3 = result2.join().replace(/,/g, '')
														const defaultValue = x === valuableMonths[valuableMonths.length - 1]
														return (
														<option selected={defaultValue} key={idx} value={x}>{new Date(result3).getFullYear()}년 {new Date(result3).getMonth()+1}월 정산 보고서</option>
													)})
												) : (<option>{new Date().getFullYear()}년 {new Date().getMonth()+1}월 정산 보고서</option>)}
											</select>
										</div>
									</div>
									<div className="text-right">
										{/* <Link className="btn btn-outline-secondary" to="/">상세 자료 파일 다운로드</Link> */}
										<button onClick={()=>{getFile(selectMonths)}} className='btn btn-outline-secondary'>상세 자료 파일 다운로드</button>
									</div>
								</div>
								<div className="section-white pl-0 pr-0">
									<SplineArea salesAmountGraphData={salesAmountGraphData} settlementAmountGraphData={settlementAmountGraphData} />
								</div>
								<div className="section-body py-4 CounterArea" style={{marginTop:0}}>
									<Container fluid>
										<Row className="clearfix">
											<Col lg={3} md={6} sm={12} className="text-center">
												<Card>
													<CardBody className="text-left">
														<h6>입금 금액</h6>
														<h3 className="pt-1">
															<span className="counter"> {new Intl.NumberFormat('ko-KR').format(depositAmount)}</span>원
														</h3>
														지난 달 대비
														<span className={Math.abs(Number(depositMoM)) > 30 ? 'text-danger mr-2' : 'text-success mr-2'}>
															<i className={Number(depositMoM) > 0 ? 'fa fa-long-arrow-up' : (Number(depositMoM) < 0 ? 'fa fa-long-arrow-down' : '')}></i> {depositMoM}%
														</span>
													</CardBody>
												</Card>
											</Col>
											<Col lg={3} md={6} sm={12} className="text-center">
												<Card>
													<CardBody className="text-left">
														<h6>매출 금액</h6>
														<h3 className="pt-1">
															<span className="counter"> {new Intl.NumberFormat('ko-KR').format(salesAmount)}</span>원
														</h3>
														지난 달 대비
														<span className={Math.abs(Number(salesMoM)) > 30 ? 'text-danger mr-2' : 'text-success mr-2'}>
															<i className={Number(salesMoM) > 0 ? 'fa fa-long-arrow-up' : (Number(salesMoM) < 0 ? 'fa fa-long-arrow-down' : '')}></i> {salesMoM}%
														</span>
													</CardBody>
												</Card>
											</Col>
											<Col lg={3} md={6} sm={12} className="text-center">
												<Card>
													<CardBody className="text-left">
														<h6>정산 금액</h6>
														<h3 className="pt-1">
															<span className="counter"> {new Intl.NumberFormat('ko-KR').format(settlementAmount)}</span>원
														</h3>
														지난 달 대비
														<span className={Math.abs(Number(settlementMoM)) > 30 ? 'text-danger mr-2' : 'text-success mr-2'}>
															<i className={Number(settlementMoM) > 0 ? 'fa fa-long-arrow-up' : (Number(settlementMoM) < 0 ? 'fa fa-long-arrow-down' : '')}></i> {settlementMoM}%
														</span>
													</CardBody>
												</Card>
											</Col>
											<Col lg={3} md={6} sm={12} className="text-center">
												<Card>
													<CardBody className="text-left">
														<h6>취소 금액</h6>
														<h3 className="pt-1">
															<span className="counter"> {new Intl.NumberFormat('ko-KR').format(cancelAmount)}</span>원
														</h3>
														지난 달 대비
														<span className={Math.abs(Number(cancelMoM)) > 30 ? 'text-danger mr-2' : 'text-success mr-2'}>
															<i className={Number(cancelMoM) > 0 ? 'fa fa-long-arrow-up' : (Number(cancelMoM) < 0 ? 'fa fa-long-arrow-down' : '')}></i> {cancelMoM}%
														</span>
													</CardBody>
												</Card>
											</Col>
										</Row>
									</Container>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(CalculateBoard));

CalculateBoard.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
