import React from 'react';
import {Redirect} from 'react-router-dom';

// Profile
import UserProfile from '../pages/Authentication/user-profile';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import RegisterRenew from '../pages/Authentication/RegisterRenew';
import ForgetPwd from '../pages/Authentication/ForgetPassword';

// Dashboard
import Dashboard from '../pages/Dashboard/index';

import FindQuestion from '../pages/FindQuestion/index';
//Answer Profile
import ProfileManagement from '../pages/Answer/ProfileManagement';
import ParticipantManagement from '../pages/Answer/ParticipantManagement';
import WirteProfile from '../pages/Answer/WriteProfile';

//Setings Introduction

import ChatbotSettings from '../pages/Settings/ChatbotSettings';

//Answer Chatroom
import AnswerChatRoom from '../pages/AnswerChatRoom/index';

//Product Management
import AnswerTalkProduct from '../pages/ProductManagement/AnswerTalkProduct';
//Qna
import QnaView from '../pages/Qna/QnaView';
import QnaWrite from '../pages/Qna/QnaWrite';
import QnaDetail from '../pages/Qna/QnaDetail';
import NoticeView from '../pages/Qna/NoticeView';
import NoticeDetail from '../pages/Qna/NoticeDetail';
//Settlement Management
import CalculateInfo from '../pages/SettlementManagement/CalculateInfo';
import CalculateBoard from '../pages/SettlementManagement/CalculateBoard';
import CalculateList from '../pages/SettlementManagement/CalculateList';

//Question Chatroom
import QuestionChatroom from '../pages/QuestionChatRoom/index';
//Landing page
import LandingPage from '../pages/Settings/LandingPage/index';

const authProtectedRoutes = [
	{path: '/dashboard', component: Dashboard},

	{path: '/findQuestion', component: FindQuestion},

	//Answer Profile
	// {path: '/answer/ProfileManagement', component: ProfileManagement},
	// {path: '/answer/ParticipantManagement', component: ParticipantManagement},
	// {path: '/answer/WriteProfile', component: WirteProfile},
	// //Setings Introduction
	// {path: '/settings/ChatbotSettings', component: ChatbotSettings},

	//QNA
	{path: '/qna/QnaView', component: QnaView},
	{path: '/qna/QnaDetail', component: QnaDetail},
	{path: '/qna/QnaWrite', component: QnaWrite},
	{path: '/qna/NoticeView', component: NoticeView},
	{path: '/qna/NoticeDetail', component: NoticeDetail},

	//Product Management
	{
		path: '/productManagement/AnswerTalkProduct',
		component: AnswerTalkProduct,
	},

	//Settlement management
	{path: '/settlementManagement/CalculateInfo', component: CalculateInfo},
	{path: '/settlementManagement/CalculateBoard', component: CalculateBoard},
	{path: '/settlementManagement/CalculateList', component: CalculateList},

	//Question Chat
	{path: '/question/chatroom', component: QuestionChatroom},
	// //profile
	{path: '/profile', component: UserProfile},

	//Answer Chat
	{path: '/answer/chatroom', component: AnswerChatRoom},

	//Landing page
	{path: '/settings/landing-page', component: LandingPage},

	// this route should be at the end of all other routes
	// eslint-disable-next-line react/display-name
	{path: '/', exact: true, component: () => <Redirect to="/dashboard"/>},
];

const publicRoutes = [
	{path: '/logout', component: Logout},
	{path: '/login', component: Login},
	{path: '/forgot-password', component: ForgetPwd},
	{path: '/register', component: Register},
	{path: '/registerRenew', component: RegisterRenew},
];

export {publicRoutes, authProtectedRoutes};
