import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';
import {daysInKr} from '../../../constants/options';

const MenuPageDesign = (props) => {
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];

	return (
		<li className="active">
			<div className="row">
                <div className="col-lg-12">
                    <p className="stepTit">1. 페이지의 디자인 형태를 정해 주세요.</p>
                </div>
                <div className="col-lg-6">

                    <div className="custom-controls ">
                        <div className="sbox">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input id="checkFoot01" type="radio" className="custom-control-input" name="topDesign02" value="option1" checked="" readOnly />
                                <span className="custom-control-label">구분형 </span>
                            </label>

                            <img src={msample03} className="topup" />
                        </div>
                        <div className="sbox">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input id="checkFoot02" type="radio" className="custom-control-input" name="topDesign02" value="option2" />
                                <span className="custom-control-label">원페이지형 </span>
                            </label>
                            <img src={msample04} className="topup" />
                        </div>
                        
                        
                    </div>
                </div>
                <div className="col-lg-6 text-center">
                    <div className="phone02 drop-shadow hauto w320 min600">
                        <div className="phone_over hauto">
                            <div className="PhoneArea hauto">
                                <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || imgAvatarNew} alt="" /></div>
                                    
                                <div className="PhoneTop designCase01">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-lg mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-lg mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div className="d-block w-100 ">
                                            <div className="d-flex">
                                            <small className="d-block">{props.info && props.info?.userName} </small>
                                                <a href="#" className="icon d-none d-md-inline-block ml-auto"><i className="icon-home"></i></a>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small><small className="d-block ml-2"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="ChArea"><img src={char} /></div>  
                                </div>

                                <div className="PhoneTop designCase02 d-none">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew} alt="" />
                                        <div>
                                            <small className="d-block">{props.info && props.info?.userName}</small>
                                        </div>
                                        <div className="ml-auto">
                                            <a href="#" className="icon d-none d-md-inline-block ml-3"><i className="icon-home"></i></a>
                                        </div>
                                    </div>
                                    <div className="MainImg mt-2">
                                        <div className="ChArea"><img src={char} /></div>
                                        <img src={landingSampleImg} />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                                    </div>
                                </div>
                                <div className="PhoneBody hauto">
                                    <div className="conArea pb-100px">
                                                                                
                                        <div className="txtArea2 mt-2 noView partOn">
                                            <div className="mb-2 sectorTit">탑변톡</div>
                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-clock"></i> <b>1답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                                            </div>

                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                                            </div>
                                            {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                                                <div className="d-block w-100 mt-2">
                                                    <div className="d-block">공휴일에는 답변이 불가능합니다.
                                                        답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                                        수 있습니다. 하지만 답변자의 답변은 운영시간을
                                                        기다려주세요. 감사합니다.
                                                    </div>
                                                </div>
                                            }
                                        </div>
            

                                        <div className="txtArea2 mt-2 noView partOn">
                                            {props.info && props.info?.introduction || ''}
                                        </div>
                                        <div className="mBox boxbig noView partOn">
                                            <div className="titname d-block mb-2 mt-3"><b>답변키워드</b></div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>*/}
                                                        {keywordList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                                                        ))}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="titname d-block mb-2 mt-3"><b>답변지역</b></div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                                        {regionList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="linkInfo noView mt-3 partOn">
                                            {/*<div className="mb-2 sectorTit">외부링크</div>*/}
                                        {outLinkList.map((item, index) => (
                                            item.iconLink ?
                                                <div className="adressArea noView partOn" key={index}>
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                                        <div>
                                                            <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                        ))}
                                            {/*<div className="adressArea noView partOn">
                                                <div className="d-flex align-items-center mt-auto">
                                                    <img className="avatar avatar-md mr-2" src={instar} alt="" />
                                                    <div>
                                                        <div className="d-block">Instar.com</div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className="mt-4 sectorTit subTit">피드</div>*/}
                                        {videoFeedList.map((item, index) => (
                                            <div className="row align-items-center mt-2" key={index}>
                                                <div className="col-5 text-center">
                                                    <div className="listImg">
                                                        <img src={item.videoThumbnail} alt={item.videoTitle}/>
                                                    </div>
                                                </div>
                                                <div className="col-7 text-left">
                                                    <div className="listTxt">
                                                        {item.videoTitle}
                                                    </div>
                                                    <div className="listday">
                                                        <div>{item.ownerChannelName}</div>
                                                        <div className="bar"></div>
                                                        <div>{item.publishDate}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                            {/*<div className="row align-items-center mt-2">
                                                <div className="col-5 text-center">
                                                    <div className="listImg">
                                                        <img src={landingSampleImg} />
                                                    </div>
                                                </div>
                                                <div className="col-7 text-left">
                                                    <div className="listTxt">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <a href="#" className="moreBtnSm">더보기</a>
                                                </div>
                                            </div>*/}
                                        </div>
                                        
                                        <div className="giftInfo noView mt-3 partOn">
                                            <div className="mb-2 sectorTit">상품</div>
                                            <div className="align-items-center mt-auto">
                                                <div className="d-block w-100 ">
                                                    <div className="giftTit"><img src="assets/img/img_gift.png" alt="" /> 플로잉 답변톡 상품1</div>
                                                    <p>
                                                        {props.info && props.info?.goodsUserProduct?.description || ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                                <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                                                <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                                                <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                                            </div>
                                        </div>
                                        <div className="footlogo"><img src={logo} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 text-right">
                    <hr />
                    <button className="btn btn btn-dark " type="button">저장하기</button>
                </div>
            </div>
        </li>

	);
};
export default withRouter(withTranslation()(MenuPageDesign));

MenuPageDesign.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
