import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import flowingLogo from '../../../assets/images/flowing_logo.jpg';
import logo from '../../../assets/images/logo_landingFoot.png';
import logoW from '../../../assets/images/logo_landingFoot_w.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg3 from '../../../assets/images/img_landingSample3.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample5.jpg';
import pcBg01 from '../../../assets/images/pcBg01.png';
import pcBg02 from '../../../assets/images/pcBg02.png';
import pcBg03 from '../../../assets/images/pcBg03.png';
import pcBg04 from '../../../assets/images/pcBg04.png';
import pcBg05 from '../../../assets/images/pcBg05.png';
import pcBg06 from '../../../assets/images/pcBg06.png';
import pcBg07 from '../../../assets/images/pcBg07.png';
import pcBg08 from '../../../assets/images/pcBg08.png';
import imgGift from '../../../assets/images/img_gift.png';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
import {daysInKr} from '../../../constants/options';
import BannerItem from '../../../components/previews/spacehome/BannerItem';
import ScheduleList from '../../../components/previews/spacehome/ScheduleList';

const PcTypeCustom = (props) => {
	
    const [allArea, setAllArea] = useState(true);

    const handleImgError = (e) => {
        e.target.src = landingSampleImg3
    }

	return (

       <>

        <div className="col-lg-12">

            <div className={props.pcBgColor === 'white'?"premiummodal_w phone02 drop-shadow hauto min600 w-100 mt-2":"premiummodal phone02 drop-shadow hauto min600 w-100 mt-2"}>
                <div className="phone_over hauto">
                    <div className="PhoneArea hauto">
                        <div className="TackBtnArea" style={{right: "20px"}}>
                            <span>톡하기</span>
                            <div className="imgboard">
                                <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || imgAvatarNew} alt="" />
                            </div>
                        </div>

                        <div className="topArea">
                            <div className="titArea">
                                <a href="#"><img src={flowingLogo} alt="" /></a>
                                <span className="namebar"></span> {props.info && props.info?.userName} {props.pcBgColor}
                            </div>
                                {/* <a href="#" className="homeBtn"><i className="icon-home"></i></a> */}

                            <div className="topImgBg pcmode">

                                {/* <span><img src={landingSampleImg} /> </span> */}
                                {/* <span><img src={props.pcBgImage[1] || landingSampleImg3} onError={handleImgError} alt="" /> </span> */}
                                <span><img src={props.pcCustomBgImage || landingSampleImg3} onError={handleImgError} alt="" /> </span>

                                <div className="gridBg"></div>
                            </div>
                        </div>

                    <div className="PhoneBody hauto">
                        <div className="socialArea">
                            <div className="row">
                                {/*// TODO : PC버전에서 사용할 프로필 이미지 -- 이후 적용 방법에 대해서 근본적인 재검토 필요
                                <div className="col-12 text-left pb-2">
                                    <img className="avatar avatar-lg mr-2" src={props.pcTextBannerProfileImage || props.info && props.info?.userImg} alt=""
                                         style={{width:'120px', height: '120px'}} />
                                </div>*/}
                                <div className="col-12 text-left pb-2"><p>{props.info && props.info?.introduction || ''}</p></div>
                                <div className="col-6 text-left">
                                    <div className="d-flex">
                                            {/* <span className="slideleft"><i className="fe fe-chevron-left"></i></span> */}
                                            <div className="iconsocial">
                                                <i className="icon-social-youtube"></i>
                                            </div>
                                            <div className="iconsocial">
                                                <i className="fe fe-instagram"></i>
                                            </div>
                                            <div className="iconsocial">
                                                <i className="fe fe-facebook"></i>
                                            </div>
                                            <div className="iconsocial">
                                                <i className="fe fe-twitter"></i>
                                            </div>
                                        {/* <span className="slideright"><i className="fe fe-chevron-right"></i></span> */}
                                    </div>
                                </div>
                                <div className="col-6 text-right">
                                    <p className="d-md-inline-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</p>
                                    <p className="d-md-inline-block ml-5"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</p>
                                </div>
                            </div>
                        </div>
                        {/* <!-- 모바일화면 탭버튼 --> */}
                        {props.designType === 'tab'?
                            <div className="tabArea pagetab mt-4">
                                <span
                                    className={allArea === true?"viewTabb04  tag  active":"viewTabb04  tag "}
                                    onClick={()=>{setAllArea(true)}}
                                >전체보기
                                </span>
                                <span
                                    className={allArea === false && props.tabArea===0?"viewTabb04  tag  ml-2 active":"viewTabb04  tag  ml-2 "}
                                    onClick={()=>{props.setTabArea(0); setAllArea(false);}}
                                >답변톡</span>
                                <span

                                    className={allArea === false && props.tabArea===1?"viewTabb05 tag  ml-2 active":"viewTabb05 tag  ml-2 "}
                                    onClick={()=>{props.setTabArea(1); setAllArea(false);}}
                                >외부링크</span>
                                <span
                                    className={allArea === false && props.tabArea===2?"viewTabb06 tag  ml-2 active":"viewTabb06 tag  ml-2 "}
                                    onClick={()=>{props.setTabArea(2); setAllArea(false);}}
                                >상품</span>
                            </div>
                        :''}
                            {/* <!-- 모바일 화면 탭버튼--> */}
                            {
                                allArea === true || props.designType ==='page'?
                                    <>
                                        {menuOrder(props.menuOrder[0], props)}
                                        {menuOrder(props.menuOrder[1], props)}
                                        {menuOrder(props.menuOrder[2], props)}
                                        {menuOrder(props.menuOrder[3], props)}
                                        {menuOrder(props.menuOrder[4], props)}
                                    </>
                                :
                                    props.tabArea === 0?
                                        menuOrder(props.menuOrder[0], props)
                                    :props.tabArea ===1?
                                        menuOrder(props.menuOrder[1], props)
                                    :props.tabArea ===2?
                                        menuOrder(props.menuOrder[2], props)
                                    :props.tabArea ===3?
                                        menuOrder(props.menuOrder[3], props)
                                    :props.tabArea ===4?
                                        menuOrder(props.menuOrder[4], props)
                                    :''

                            }

                            <div className="footlogo"><img src={props.pcBgColor === 'white'?logo:logoW} /></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
	);
};


function menuOrder(menuName, props){
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];

    const shoppingEvent = props?.info?.shoppingEvent || [];
    const eventBanner = props?.info?.eventBanner || [];

    if(menuName ==='답변톡'){
        return(
            <>
            <div className="conArea pb-100px">
                <div  className="txtArea2 mt-2 noView partOn">
                    <div className="mb-2 sectorTit d-none">답변톡</div>
                    <div className="d-block w-100 ">
                        <div className="d-block"><i className="fe fe-clock"></i> <b>19답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                    </div>

                    <div className="d-block w-100 ">
                        <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                    </div>
                    {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                        <div className="d-block w-100 mt-2">
                            <div className="d-block">공휴일에는 답변이 불가능합니다.
                                답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                수 있습니다. 하지만 답변자의 답변은 운영시간을
                                기다려주세요. 감사합니다.
                            </div>
                        </div>
                    }
                    <div className="mb-2 mt-4 sectorTit subTit">답변키워드</div>
                    <div className="answerInfo">
                        <div className=" align-items-center mt-auto">
                            <div className="d-block w-100 ">
                                {/*<span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>*/}
                                {keywordList.map((item, index) => (
                                    <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                                ))}
                            </div>
                            
                        </div>
                    </div>
                    <div className="mb-2 mt-4 sectorTit subTit">답변지역</div>
                    <div className="answerInfo">
                        <div className=" align-items-center mt-auto">
                            <div className="d-block w-100 ">
                                {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                {regionList.map((item, index) => (
                                    <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }else if(menuName === '외부링크 블록형'){
        return(
            <div className="conArea pb-100px">
                <div className="giftInfo mt-3 noView partOn">
                    {/*<div className="mb-2 sectorTit d-none">외부링크</div>*/}
                    {outLinkList.map((item, index) => (
                        item.iconLink ?
                        <div className="adressArea position-relative" key={index}>
                            <div className="d-flex align-items-center mt-auto">
                                <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                                <div className="arrowBtn">
                                    <i className="fe fe-chevron-right"></i>
                                </div>
                            </div>
                        </div> : ""
                    ))}
                    {/*<div className="adressArea position-relative">
                        <div className="d-flex align-items-center mt-auto">
                            <img className="avatar avatar-md mr-2" src={instar} alt="" />
                            <div className="d-block">Instar.com</div>
                                <div className="arrowBtn">
                                <i className="fe fe-chevron-right"></i>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div className="mt-4 sectorTit subTit">피드</div>*/}
                    <div className="row align-items-center mt-2">
                    {videoFeedList.map((item, index) => (
                        <div className="col-3" key={index}>
                            <div className="listImg" style={{height: "180px"}}>
                                <img src={item.videoThumbnail} alt={item.videoTitle}/>
                            </div>
                            <div className="listTxt mt-2">
                                {item.videoTitle}
                            </div>
                            <div className="listday">
                                <div>{item.ownerChannelName}</div>
                                <div className="bar"></div>
                                <div>{item.publishDate}</div>
                            </div>
                        </div>
                    ))}
                        {/*<div className="col-3">
                            <div className="listImg" style={{height: "180px"}}>
                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                            </div>
                        
                            <div className="listTxt mt-2">
                                {item.videoTitle}
                            </div>
                            <div className="listday">
                                <div>{item.ownerChannelName}</div>
                                <div className="bar"></div>
                                <div>2022.7.19</div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="listImg" style={{height: "180px"}}>
                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                            </div>
                        
                            <div className="listTxt mt-2">
                                {item.videoTitle}
                            </div>
                            <div className="listday">
                                <div>{item.ownerChannelName}</div>
                                <div className="bar"></div>
                                <div>2022.7.19</div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="listImg" style={{height: "180px"}}>
                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                            </div>
                        
                            <div className="listTxt mt-2">
                                {item.videoTitle}
                            </div>
                            <div className="listday">
                                <div>{item.ownerChannelName}</div>
                                <div className="bar"></div>
                                <div>2022.7.19</div>
                            </div>
                        </div>*/}
                        {/*<div className="col-12 text-center">
                            <a href="#" className="moreBtn">더보기</a>
                        </div>*/}
                    </div>
                </div>
            </div>/* 외부링크 end */
        )
    }else if(menuName === '상품'){
        return(
            <div className="giftInfo mt-3 noView partOn">
                <div className="mb-2 sectorTit d-none">상품</div>
                <div className="align-items-center mt-auto">
                    <div className="d-block w-100 ">
                        <div className="giftTit"><img src={imgGift} alt="" /> 플로잉 답변톡 상품17</div>
                        <p>
                            {props.info && props.info?.goodsUserProduct?.description || ''}
                        </p>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                    <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                    <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                    <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                </div>
            </div>
        )
    }else if(menuName === '외부링크 이미지형'){
        return (
            <div className="giftInfo noView mt-3 partOn" style={{display:'flex'}}>
                {eventBanner?.length ? eventBanner.map((item, index) => {
                    return <BannerItem key={index} {...item} />
                }) : <div>{menuName}</div>}
            </div>
        )
    }else if(menuName === '외부링크 스케줄형'){
        return (
            <div className="giftInfo noView mt-3 partOn">
                {shoppingEvent?.length ?
                    <ScheduleList list={shoppingEvent} />
                    : <div>{menuName}</div>}
            </div>
        )
    }

}

export default withRouter(withTranslation()(PcTypeCustom));

PcTypeCustom.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
