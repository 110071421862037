import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import React from 'react';

const Authmiddleware = ({
							component: Component,
							layout: Layout,
							isAuthProtected,
							...rest
						}) => (
	<Route
		{...rest}
		render={(props) => {
			// TODO : token 값은 WebStorage에 저장하지 않는다.
			if (isAuthProtected && !sessionStorage.getItem('access_token')) {
				return (
					<Redirect
						to={{pathname: '/login', state: {from: props.location}}}
					/>
				);
			}

			return (
				<Layout>
					<Component {...props} />
				</Layout>
			);
		}}
	/>
);

Authmiddleware.propTypes = {
	isAuthProtected: PropTypes.bool,
	component: PropTypes.any,
	location: PropTypes.object,
	layout: PropTypes.any,
};

export default Authmiddleware;
