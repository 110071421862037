import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';

import HeaderTypeText from './HeaderTypeText';
import HeaderTypeCustom from './HeaderTypeCustom';

//post forbidden word API's
const SelectMenuOrderMenu = (props) => {
    
    const inputRef = useRef(null);

    const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					toast.error('This file is larger than 200mb');
					return;
				}
			} else {
				if (originFileSize > 20) {
					toast.error('This file is larger than 20mb');
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                //props.setMobileBgImage(response.url[0]);
                props.setMobileBannerImage(response.url[0]);
                props.setIsChange(true)
            }
            
		}
	};
    useEffect(()=>{console.log(props)},[])


	return (
        <>
            <div className="col-lg-12">
                <p className="stepTit">
                    {/* <span>1. 구분형 </span><span className="stepArrow">&gt;</span>
                    <span>2. 노출순서 </span><span className="stepArrow">&gt;</span>
                    <span>3. 텍스트형 </span><span className="stepArrow">&gt;</span>   */}
                    2. 모바일 배경을 설정해 주세요.
                </p>
            </div>
            <div className="col-lg-6">

                <div className="colorArea mt-3" style={{maxWidth:'600px'}}>
                    <div className="row m-0 justify-content-left">
                        <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                            <label className="imagecheck mr-2 mb-2">
                                <input 
                                    name="imagecheck" 
                                    type="radio" 
                                    value="1" 
                                    className="imagecheck-input" 
                                    // checked={props.mobileBgColor[0] === 'ffffff'}
                                    checked={props.mobileTextBannerBgColor === 'ffffff'}
                                    // onClick={()=>props.setMobileBgColor(["ffffff", props.mobileBgColor[1]])}
                                    onClick={()=>{
                                        props.setMobileTextBannerBgColor("ffffff")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <figure className="imagecheck-figure">
                                    <div className="imagecheck-image CheckColor01"></div>
                                </figure>
                            </label>
                            {/* <div className="textColor"> 화이트</div> */}
                        </div>

                        <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                            <label className="imagecheck mr-2 mb-2">
                                <input 
                                    name="imagecheck" 
                                    type="radio" 
                                    value="1" 
                                    className="imagecheck-input" 
                                    // checked={props.mobileBgColor[0] === '4eac6d'}
                                    checked={props.mobileTextBannerBgColor === '4eac6d'}
                                    // onClick={()=>props.setMobileBgColor(["4eac6d", props.mobileBgColor[1]])}
                                    onClick={()=>{
                                        props.setMobileTextBannerBgColor("4eac6d")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <figure className="imagecheck-figure">
                                    <div className="imagecheck-image CheckColor02"></div>
                                </figure>
                            </label>
                            {/* <div className="textColor"> 그린</div> */}
                        </div>
                    
                    
                        <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                            <label className="imagecheck mr-2 mb-2">
                                <input 
                                    name="imagecheck" 
                                    type="radio" 
                                    value="1" 
                                    className="imagecheck-input" 
                                    // checked={props.mobileBgColor[0] === '50a5f1'}
                                    // onClick={()=>props.setMobileBgColor(["50a5f1", props.mobileBgColor[1]])}
                                    checked={props.mobileTextBannerBgColor === '50a5f1'}
                                    onClick={()=>{
                                        props.setMobileTextBannerBgColor("50a5f1")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <figure className="imagecheck-figure">
                                    <div className="imagecheck-image CheckColor03"></div>
                                </figure>
                            </label>
                            {/* <div className="textColor"> 블루</div> */}
                        </div>
                    
                    
                        <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                            <label className="imagecheck mr-2 mb-2">
                                <input 
                                    name="imagecheck" 
                                    type="radio" 
                                    value="1" 
                                    className="imagecheck-input" 
                                    // checked={props.mobileBgColor[0] === '6153cc'}
                                    // onClick={()=>props.setMobileBgColor(["6153cc", props.mobileBgColor[1]])}
                                    checked={props.mobileTextBannerBgColor === '6153cc'}
                                    onClick={()=>{
                                        props.setMobileTextBannerBgColor("6153cc")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <figure className="imagecheck-figure">
                                    <div className="imagecheck-image CheckColor04"></div>
                                </figure>
                            </label>
                            {/* <div className="textColor"> 보라</div> */}
                        </div>
                    
                    
                        <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                            <label className="imagecheck mr-2 mb-2">
                                <input 
                                    name="imagecheck" 
                                    type="radio" 
                                    value="1" 
                                    className="imagecheck-input" 
                                    // checked={props.mobileBgColor[0] === 'e83e8c'}
                                    // onClick={()=>props.setMobileBgColor(["e83e8c", props.mobileBgColor[1]])}
                                    checked={props.mobileTextBannerBgColor === 'e83e8c'}
                                    onClick={()=>{
                                        props.setMobileTextBannerBgColor("e83e8c")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <figure className="imagecheck-figure">
                                    <div className="imagecheck-image CheckColor05"></div>
                                </figure>
                            </label>
                            {/* <div className="textColor"> 핑크</div> */}
                        </div>
                    
                        <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                            <label className="imagecheck mr-2 mb-2">
                                <input 
                                    name="imagecheck" 
                                    type="radio" 
                                    value="1" 
                                    className="imagecheck-input" 
                                    // checked={props.mobileBgColor[0] === '797c8c'}
                                    // onClick={()=>props.setMobileBgColor(["797c8c", props.mobileBgColor[1]])}
                                    checked={props.mobileTextBannerBgColor === '797c8c'}
                                    onClick={()=>{
                                        props.setMobileTextBannerBgColor("797c8c")
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <figure className="imagecheck-figure">
                                    <div className="imagecheck-image CheckColor06"></div>
                                </figure>
                            </label>
                            {/* <div className="textColor"> 그래이</div> */}
                        </div>

                    </div>

                    {props.headerType ==='banner'?
                        <div className="selectImg">
                            <div className="form-group mt-3">
                                <label className="form-label">배너 이미지 찾기</label>
                                <div className="row gutters-xs">
                                    <div className="col">
                                        <form>
                                            <input 
                                                type="file" 
                                                id="input-file"
                                                className="form-control " 
                                                placeholder="업로드할 이미지를 찾아주세요."
                                                ref={inputRef}
                                                onChange={selectFileFunction}
                                            />
                                        </form>
                                    </div>
                                    <span className="col-auto">
                                        <button 
                                            className="btn btn-secondary" 
                                            type="button"
                                            onClick={() => {
                                                inputRef.current.click();
                                            }}
                                        >찾기</button>
                                    </span>
                                </div>
                                <p className="mt-2 imgGuidetxt" >258px * 109px의 최적화된 이미지를 올려주셔야 합니다.</p>
                            </div>
                        </div>
                    :""}
                    
                        
                    
                </div>

                

            </div>
            
        </>

	);
};
export default withRouter(withTranslation()(SelectMenuOrderMenu));

SelectMenuOrderMenu.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
