// import {get} from '../helpers/api_helper_landing';
import config from "../config/configuration"
import {get} from '../helpers/api_helper';

export async function getQna(id) {
	try {
		// console.log('id : ', id);
		const result = await get(id && `/notification/${id}/console` || `/notification`, {baseURL: config.api.common_api});
		return id && result || result && result?.notificationList || [];

	} catch (err) {
		throw err;
	}
}
