import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Fade, Input, Row, Col, Button, Spinner} from 'reactstrap';
import {toast} from 'react-toastify';
import { authService } from '../../../services/auth.service';
import { landingPageService } from '../../../services/landing-page.service';
import {generatePageUrl} from '../../../services/recruit.service';
import welcomeImg from '../../../assets/images/character/ddabong-dog.gif';
import config from '../../../config/configuration'

const ProfileUrl = (props) => {
	// const userId = localStorage.getItem('user_id')
	const [userId, setUserId] = useState("")
	const [path, setPath] = useState('');
	const [isPathChecked, setIsPathChecked] = useState(false)
	const [status, setStatus] = useState("")
	const [isValue, setIsValue] = useState(false)
	const [isGeneratingRecruit, setIsGeneratingRecruit] = useState(false);
	const [recruitUrl, setRecruitUrl] = useState('');
	// TODO: isValue 값이 true 일때 => 설정한 URL있으며, input readonly, 각 버튼은 숨김처리
	//meta title
	const checkPath = async () => {
		// const a = å∫ç∂´ƒ©˙ˆ∆˚¬µ˜øπœ®ß†¨√∑≈¥
		if(!path) {
			toast.warning('원하시는 주소를 입력해 주세요')
		} else {
			if(path.trim() !== "") {
				// const checkExp = /^[a-zA-z0-9-/_]{4,20}$/g; // 이전 체크 로직
				// const checkExp = /^[a-z-/_]{4,20}$/g; // 새로운 체크 로직
				const checkExp = /^[a-z0-9-/_]{4,20}$/g; // 새로운 체크 로직
				const check = checkExp.test(path)
				if (!check) {
					setIsPathChecked(false)
					toast.warning("URL은 영문, 숫자 및 특수문자(-, _) 4~20자 내 입력가능")
					return;
				} else {
					try {
						const response = await landingPageService.checkLandingPageURL({path:path})
						if(response.status === 201) {
							setIsPathChecked(false)
							toast.error('이미 사용중인 주소 입니다.')
						} else {
							setIsPathChecked(true)
							toast.success('사용 가능한 주소 입니다.')
						}
					} catch(err) {
						setIsPathChecked(false)
						toast.error('사용이 불가한 주소입니다.')
					}
				}
			} else {
				toast.warning('주소를 입력해 주세요')
			}
		}
	};

	const getProfileUrl = async (valueId) => {
		try {
			const getUrl = await landingPageService.getLandingPageURL(valueId)
			// console.log(getUrl)
			// if(getUrl.status === 200) {
			// 	setIsValue(true)
			// 	setPath(() => {
			// 		return getUrl.data?.itemList?.path
			// 	})
			// }
			if(getUrl.status===200) {
				setPath(() => {
					return getUrl.data?.itemList?.path
				})
				if(getUrl.data?.itemList.length !== 0) {
					// console.log(getUrl.data?.itemList)
					setIsValue(true)
				}
			}
		} catch(err) {throw new Error(err)}
		// console.log(isValue)
	}

	const postProfileUrl = async () => {
		if(isPathChecked) {
			if(window.confirm('한번 등록한 URL은 변경할 수 없습니다. 저장 하시겠습니까?')) {
				try {
					const response = await authService.getUserId()
					if(response.status===201){
						// console.log(response)
						const valueId = response.data.user_id
						const postUrl = await landingPageService.postLandingPageURL({path:path}, valueId)
						if(postUrl.status === 201) {
							toast.success('저장되었습니다.')
							await getProfileUrl(valueId)
						} else if(postUrl.status === 409) {
							toast.error('이미 사용 중인 주소입니다.')
						} else {
							toast.error('다시 한번 시도해 주세요')
						}
					}
				} catch(err) {
					toast.error('다시 한번 시도해 주세요')
					throw new Error(err)
				}
			}
		} else {
			toast.warning('중복 체크를 먼저 진행해 주세요')
		}
	}

	const generateRecruitPageUrl = useCallback(async (userId, type) => {
		setIsGeneratingRecruit(true);
		try {
			if (isGeneratingRecruit) return;
			const url = await generatePageUrl(userId, type);

			console.log('generatedUrl', url);
			setRecruitUrl(url);
		} catch (e) {
			const placeHolderMessage = e?.response?.data?.place_holder || e?.data?.place_holder || e?.place_holder;
			if (placeHolderMessage) {
				toast.error(placeHolderMessage);
				return;
			} else {
				toast.error('모집 페이지 URL 생성에 실패했습니다. 다시 시도해주세요.');
			}

			console.log('%c generateRecruitPageUrl-error','background-color:rgba(255,0,0,0.5);color:red;' , e);
		} finally {
			setIsGeneratingRecruit(false);
		}
	}, [])

	useEffect(() => {

		const settingUserId = async () => {
			try {
				const res = await authService.getUserId()
				if(res.status===201) {
					setUserId(()=>{return res.data.user_id})
					getProfileUrl(res.data.user_id).then(r => r)
					setStatus(()=>{return res.status})
				} else {
					return await authService.logout()
				}
			} catch(err){}
		}
		settingUserId().then(r=>r)
	}, [status])

	document.title = 'Flowing';
	return (
		<li className={'active'} style={{
			minHeight: 600,
		}}>
			{/* <ToastContainer/> */}
			<div className="row">
				<div className="col-lg-12">
					<Row className="gutters-xs">
						<Col lg={12}>
							{isValue === false && (
								<>
									<p className="mb-2">
										원하는 주소를 입력하고 중복 검사를 진행하세요.
									</p>
									<p className="mb-2">
										URL은 영문, 숫자 및 특수문자(-, _) 4~20자 내 입력가능
									</p>
									<p className="mb-2">
										답변톡 주소는 변경이 불가능합니다. 신중하게 선택하신 후 등록해 주세요.
									</p>
								</>
							)}
							{isValue===true && (<p className="mb-2">
								한번 등록한 URL은 변경이 불가능합니다
							</p>)}
						</Col>

						<Col cols={12} className="d-flex align-items-center mobileArea">
							<span className="BasicAddress">
								{!!config?.family_address?.spacehome ? config?.family_address?.spacehome : 'https://answering.chat'}/
							</span>
							<div className="mx-1" style={{
								minWidth: 350,
							}}>
								<Input
									type="text"
									className="form-control"
									placeholder="원하는 주소를 입력해 주세요."
									value={path}
									readOnly={isValue===true}
									onChange={(e) => {
										setIsPathChecked(false)
										setPath(e.target.value)
									}}
								/>
							</div>
							{isValue === false && (<span className="col-auto">
							<button
								className="btn btn-secondary"
								type="button"
								onClick={checkPath}
							>
							중복 체크
							</button>
						</span>)}
						</Col>
					</Row>
				</div>
				{isValue === false && (<div className="col-lg-12 text-right mt-4">
					<button className="btn btn btn-dark" type="button" onClick={postProfileUrl}>
						저장하기
					</button>
				</div>)}
			</div>

			<hr />

			{isValue && (
				<div style={{
					marginTop: 100,
				}}>
					<Row className={'justify-content-center'}>
						<Col lg={8} className="text-center">
							<div>
								<h3>자신만의 스페이스 홈 URL을 등록하셨군요!</h3>
								<p>주변에 다른 전문가 혹은 인플루언서가 있다면 스페이스 홈을 개설할 수 있도록 추천해주세요.</p>
								<p>
									추천 페이지를 통해 계정 생성을 요청한 분들에게는 등록된 정보를 바탕으로 스페이스홈 계정을 생성하고 커스텀 디자인을 제공합니다.
								</p>
							</div>
							<div className="text-center">
								<img src={welcomeImg} alt="" style={{
									maxWidth: 350,
								}}/>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center">
						<Col lg={8}>
							<div className="text-center">
								<p>본인만의 추천 페이지 URL을 만드세요. 본인의 추천 URL로 스페이스 홈 생성요청이 들어오면 이메일을 통해 알려드립니다.</p>
								<p>추천하고자 하는 분은 어떤 분인가요? 분야에 맞는 추천 페이지를 제공합니다.</p>
							</div>
						</Col>
					</Row>
					<Row className="justify-content-center my-4">
						<Col className={'mb-1'} lg={2}>
							<Button onClick={async () => {await generateRecruitPageUrl(userId, 'common')}} disabled={isGeneratingRecruit} block style={{minHeight:'100%'}}>
								{!isGeneratingRecruit ? '일반 신청폼' : (
									<Spinner size={'sm'} />
								)}
							</Button>
						</Col>
						<Col className={'mb-1'} lg={2}>
							<Button onClick={async () => {await generateRecruitPageUrl(userId, 'expert')}} disabled={isGeneratingRecruit} block style={{minHeight:'100%'}}>
								{!isGeneratingRecruit ? '특정분야의 전문가' : (
									<Spinner size={'sm'} />
								)}
							</Button>
						</Col>
						<Col className={'mb-1'} lg={2}>
							<Button onClick={async () => {await generateRecruitPageUrl(userId, 'group-buyer')}} disabled={isGeneratingRecruit} block style={{minHeight:'100%'}}>
								{!isGeneratingRecruit ? '공동구매 진행자' : (
									<Spinner size={'sm'} />
								)}
							</Button>
						</Col>
					</Row>
					<Fade in={!!recruitUrl && !isGeneratingRecruit}>
						<Row className="justify-content-center">
							<Col lg={8} className="d-flex align-items-center justify-content-center">
								<h5 className={'my-0'}>{recruitUrl || '당신의 추천 URL은?'}</h5>
								<Button size={'sm'} className={'ml-2'} style={{
									backgroundColor: 'rgba(0,0,0,0.1)',
									width: 40,
									height: 40,
									borderRadius: '100%',
									border: 'none',
								}} onClick={async () => {
									await navigator.clipboard.writeText(recruitUrl);
									toast.success('클립보드에 주소가 복사되었습니다.')
								}}>
									<i className="fa fa-copy" style={{color: 'var(--bs-primary, #01a0fc)'}} />
								</Button>
							</Col>
						</Row>
					</Fade>
				</div>
			)}
		</li>
	);
};
export default withRouter(withTranslation()(ProfileUrl));

ProfileUrl.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
