// import {get, post} from '../helpers/api_helper_chat';
import {get, post} from '../helpers/api_helper';

export async function enterRoom(body, roomId) {
	try {
		return await post(`talk2you/rooms/${roomId}/enter`, body);
	} catch (err) {
		throw err;
	}
}

export async function retriveRoomList(userId, roomId) {
	try {
		return await get(`talk2you/rooms/${roomId}/fetch/roomId/lastMessagedAt/activeUsers?userId=${userId}`);
	} catch (err) {
		throw err;
	}
}

export async function retriveRoomListUsers(body, roomId) {
	try {
		return await get(`talk2you/rooms/${roomId}/info/users`, body);
	} catch (err) {
		throw err;
	}
}

export async function retriveMyRoomList(userId, type) {
	try {
		 // 답변방 관리 > 답변방 리스트 조회
		return await get(`/talk2you/rooms/${userId}/question?type=${type}`);
	} catch (err) {
		throw err;
	}
}

export async function retriveUnreadMessageCount(userId, body) {
	try {
		// const result = await post(`common/users/${userId}/unread`, body); // 그리고... 조회 시 POST 를 사용하지 않는다.
		 // DDB 응답값 용량 문제로 API 변경됨
		return await get(`/talk2you/messages/${userId}/unread`);
	} catch (err) {
		throw err;
	}
}

export async function retriveQuestionList(userId) {
	try {
		return await get(`talk2you/rooms/feed?userId=${userId}=&lat=&lon=&offset=&limit=`);
	} catch (err) {
		throw err;
	}
}

/**
 * 답변자 프로필을 가져온다.
 * @template {{config: object, verbose: boolean}} Option
 * @param userId {string}
 * @param options {Option}
 * */
export async function getMyProfile(userId, options) {
	const config = options?.config || {};
	const verbose = options?.verbose || false;
	try {
		return await get(`/talk2you/users/profile/${userId}`, config, verbose);
	} catch (err) {
		throw err;
	}
}
