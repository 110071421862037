import React from 'react';
import {toast} from 'react-toastify';
import _ from 'lodash';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import {deleteOneMessageService} from '../../../services/talk2meMessage.service';

const DeleteMessageModal = (props) => {
	const {messages, targetMessageId, isOnlyOneMesageDeleteModal} = props;

	// Delete message action
	const deleteTextAction = async () => {
		let body = {
			messageId: targetMessageId,
			userId: props.user.id,
			roomId: props.roomId,
		};

		const response = await deleteOneMessageService(body);
		
		if (response.message === 'Deleted') {

			// toast.success('Message deleted');
			toast.success('메시지를 삭제했습니다.');

			//kms 
			// _.remove(messages, function (n) {
			// 	return n.messageId === targetMessageId;
			// });

			const messageIdx = _.findIndex(messages,function (n) {
				return n.messageId === targetMessageId;
			});
			
			messages[messageIdx].msgType ="TEXT";
			messages[messageIdx].message ="삭제된 메세지 입니다.";

			props.refreshMessage(messages);
		} else {
			// toast.error('Cannot this message deleted');
			toast.error('메시지를 삭제하지 못했습니다.');
		}

		props.exitModal();
	};

	return (
		<Modal
			returnFocusAfterClose={true}
			isOpen={isOnlyOneMesageDeleteModal}
			centered={true}
		>
			<ModalBody className="text-center">이 내용을 삭제 하겠습니까 ?</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => props.exitModal()}>
					취소
				</Button>
				<Button color="primary" onClick={() => deleteTextAction()}>
					삭제
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default withRouter(withTranslation()(DeleteMessageModal));
