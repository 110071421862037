import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import flowingLogo from '../../../assets/images/flowing_logo.jpg';
import logo from '../../../assets/images/logo_landingFoot.png';
import logoW from '../../../assets/images/logo_landingFoot_w.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg3 from '../../../assets/images/img_landingSample3.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import imgGift from '../../../assets/images/img_gift.png';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
import {daysInKr} from '../../../constants/options';
//post forbidden word API's
const CustomTab = (props) => {
	
    const handleImgError = (e) => {
        e.target.src = landingSampleImg3
    }

	return (
        <>
        
            <div className="col-lg-6">
                <div className={props.customBgColor=='_w'?"premiummodal_w phone02 drop-shadow hauto w320 min600 mt-4":"premiummodal phone02 drop-shadow hauto w320 min600 mt-4"}>
                    <div className="phone_over hauto">
                        <div className="PhoneArea hauto">
                            {/* <div className="TackBtnArea"><span>톡하기</span><div className="imgboard"><img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /></div></div> */}
                            <div className="TackBtnArea"><span>톡하기</span><div className="imgboard"><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew} alt="" /></div></div>

                            <div className="topArea">
                                <div className="titArea"><a href="#"><img src={flowingLogo} /></a> <span className="namebar"></span> {props.info && props.info?.userName}</div>
                                
                            
                                <div className="topImgBg">

                                {/* mobileBgImage is here */}
                                {/* <img src={landingSampleImg3} /> */}
                                {/* <img src={ props.mobileBgImage.mobileCustomBgImage|| landingSampleImg3 } onError={handleImgError}/> */}
                                <img src={ props.mobileCustomBgImage|| landingSampleImg3 } onError={handleImgError}/>
                                
                                <div className="gridBg"></div>
                                </div>

                                <div className="row infoTopTxt">
                                    <div className="col-12 text-center pt-5 pb-2"><p className="maxtxt">{props.info && props.info?.introduction || ''}</p></div>
                                    <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                    <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                                </div>
                            </div>    
                            <div className="PhoneBody hauto"> 
                                <div className="socialArea">
                                    {/* <span className="slideleft"><i className="fe fe-chevron-left"></i></span>  */}
                                    <div className="iconsocial">
                                        <i className="icon-social-youtube"></i>
                                    </div>
                                    <div className="iconsocial">
                                        <i className="fe fe-instagram"></i> 
                                    </div>                                                
                                    <div className="iconsocial">
                                        <i className="fe fe-facebook"></i>
                                    </div>
                                    <div className="iconsocial">
                                        <i className="fe fe-twitter"></i>
                                    </div>
                                {/* <span className="slideright"><i className="fe fe-chevron-right"></i></span> */}
                                </div>
                                
                                <div className="tabArea pagetab mt-4">
                                    <span 
                                        className={props.tabArea===0?"viewTab01 tag  active ":"viewTab01 tag "}
                                        onClick={()=>{props.setTabArea(0)}}
                                        >{props.menuOrder[0]}
                                    </span>
                                    <span 
                                        className={props.tabArea===1?"viewTab02 tag ml-1 active ":"viewTab02 tag ml-1"}
                                        onClick={()=>{props.setTabArea(1)}}
                                        >{props.menuOrder[1]}
                                    </span>
                                    <span 
                                        className={props.tabArea===2?"viewTab03 tag ml-2 active":"viewTab03 tag ml-2 "}
                                        onClick={()=>{props.setTabArea(2)}}
                                        >{props.menuOrder[2]}
                                    </span>
                                </div>

                                {props.tabArea === 0?(
                                    menuOrder(props.menuOrder[0], props)
                                ):props.tabArea === 1?(
                                    menuOrder(props.menuOrder[1], props)
                                ):(    
                                    menuOrder(props.menuOrder[2], props)
                                )}  
                                            
                                <div className="footlogo"><img src={props.customBgColor==="_w"?logo:logoW} /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

	);
};


function menuOrder(menuOrder, props){
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];

    if(menuOrder ==='답변톡'){
        return(
            <div className="conArea pb-100px mt-4">
                <div id="txtArea01" className="txtArea2 noView">
                    <div className="d-block w-100">
                        <div className="d-block"><i className="fe fe-clock"></i> <b>3답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                    </div>
                    <div className="d-block w-100 ">
                        <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                    </div>
                    {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                        <div className="d-block w-100 mt-2">
                            <div className="d-block">공휴일에는 답변이 불가능합니다.
                                답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                수 있습니다. 하지만 답변자의 답변은 운영시간을
                                기다려주세요. 감사합니다.
                            </div>
                        </div>
                    }
                    <div className="mb-2 mt-4 sectorTit subTit">답변 키워드</div>
                    <div className="answerInfo">
                        <div className=" align-items-center mt-auto">
                            <div className="d-block w-100 ">
                                {/*<span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>*/}
                                {keywordList.map((item, index) => (
                                    <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="mb-2 mt-4 sectorTit subTit">답변 지역</div>
                    
                    <div className="answerInfo">
                        <div className=" align-items-center mt-auto">
                            <div className="d-block w-100 ">
                                {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                {regionList.map((item, index) => (
                                    <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>/* 답변톡 end */
        )   
    }else if(menuOrder ==='외부링크 블록형'){
        return(
            <div className="conArea pb-100px">
                <div id="link01" className="giftInfo mt-3 noView partOn">
                {outLinkList.map((item, index) => (
                    item.iconLink ?
                    <div className="adressArea" key={index}>
                        <div className="d-flex align-items-center mt-auto">
                            <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                            <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                            <div className="arrowBtn">
                                <i className="fe fe-chevron-right"></i>
                            </div>
                        </div>
                    </div> : ""
                ))}
                    {/*<div className="adressArea">
                        <div className="d-flex align-items-center mt-auto">
                            <img className="avatar avatar-md mr-2" src={instar} alt="" />
                            <div className="d-block">Instar.com</div>
                            <div className="arrowBtn">
                                <i className="fe fe-chevron-right"></i>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div className=" mt-4 sectorTit subTit">피드</div>*/}
                    {videoFeedList.map((item, index) => (
                        <div className="row align-items-center mt-2" key={index}>
                            <div className="col-5 text-center">
                                <div className="listImg">
                                    <img src={item.videoThumbnail} alt={item.videoTitle}/>
                                </div>
                            </div>
                            <div className="col-7 text-left">
                                <div className="listTxt">
                                    {item.videoTitle}
                                </div>
                                <div className="listday">
                                    <div>{item.ownerChannelName}</div>
                                    <div className="bar"></div>
                                    <div>{item.publishDate}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/*<div className="row align-items-center mt-2">
                        <div className="col-5 text-center">
                            <div className="listImg">
                                <img src={landingSampleImg} />
                            </div>
                        </div>
                        <div className="col-7 text-left">
                            <div className="listTxt">
                                [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                            </div>
                            <div className="listday">
                                <div>youtube</div>
                                <div className="bar"></div>
                                <div>2022.7.19</div>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div className="row">
                        <div className="col-12 text-center">
                            <a href="#" className="moreBtnSm">더보기</a>
                        </div>
                    </div>*/}
                </div>
                </div>/* 외부링크 end */
        )   
    }else if(menuOrder ==='상품'){
        return(
            <div className="conArea pb-100px">    
                <div id="gift01" className="giftInfo mt-3 noView partOn">
                    
                    <div className="align-items-center mt-auto">
                        <div className="d-block w-100 ">
                            <div className="giftTit"><img src={imgGift} alt="" /> 플로잉 답변톡 상품3</div>
                            <p>
                                {props.info && props.info?.goodsUserProduct?.description || ''}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                        <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                    <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                        <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                    </div>
                </div>
            </div>/* 상품 end */
        )   
    }
}

export default withRouter(withTranslation()(CustomTab));

CustomTab.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
