// import {delWithHeader, get, getWithHeader, put, putWithHeader} from '../helpers/api_helper_chat';
// import {post as postApi} from '../helpers/api_helper_image';
import {post, get, put, del} from '../helpers/api_helper';

import config from "../config/configuration"

export async function getService(id) {
	try {
		if(!id) return null;
		return await get(`/talk2you/users/profile/${id}/room-introduction`);
	} catch (err) {
		throw err;
	}
}

export async function putService(data, id) {
	try {
		return await put(`/talk2you/users/profile/${id}/room-introduction`, data);
	} catch (err) {
		throw err;
	}
}

//KMS add
const getUserProfileInfo = async function(valueId) {
	try {
		let userId = valueId
		// let userId = valueId ? valueId : localStorage.getItem('user_id');
		// return await getWithHeader(`/talk2you/users/web/profile/${userId}`);
		return await get(`/talk2you/users/web/profile/${userId}`, {}, true);
	} catch (err) {
		console.log("ERRORLOG", err);
		return err.response;
	}
}


const getChatbotSettingsInfo = async function(userId){
	try {
		// return await getWithHeader(`/talk2you/users/profile/${userId}/template`);
		return await get(`/talk2you/users/profile/${userId}/template`, {}, true);
	} catch (err) {
		console.log("ERRORLOG", err);
		return err.response;
	}
}

const putChatbotSettingsInfo = async function(body, valueId){
	try {
		let userId = valueId
		// let userId = valueId ? valueId: localStorage.getItem('user_id');
		// return await putWithHeader(`/talk2you/users/profile/${userId}/template`, body);
		return await put(`/talk2you/users/profile/${userId}/template`, body, {}, true);
	} catch (err) {
		console.log("ERRORLOG", err);
		return err.response;
	}
}

const delChatbotSettingsInfo = async function(templateId, valueId){
	try {
		let userId = valueId
		// let userId = valueId ? valueId: localStorage.getItem('user_id');
		// return await delWithHeader(`/talk2you/users/profile/${userId}/template/${templateId}`);
		return await del(`/talk2you/users/profile/${userId}/template/${templateId}`, {}, {}, true);
	} catch (err) {
		console.log("ERRORLOG", err);
		return err.response;
	}
}

const uploadVAFService = async function(formData, bucketName, uploadType){
	try {
		// return await postApi(`/common/files/upload/${bucketName}/${uploadType}`,formData);
		return await post(`/common/files/upload/${bucketName}/${uploadType}?env=${process.env.REACT_APP_SET_ENV}`,formData, {baseURL: config.api.proxy_api});
	} catch (err) {
		return err;
	}
}

const delChatbotImage = async function(templateId, imageId, valueId){
	try {
		let userId = valueId
		// let userId = valueId? valueId: localStorage.getItem('user_id');
		// return await delWithHeader(`/talk2you/users/profile/${userId}/template/${templateId}/${imageId}`);
		return await del(`/talk2you/users/profile/${userId}/template/${templateId}/${imageId}`, {}, {}, true);
	} catch (err) {
		console.log("ERRORLOG", err);
		return err.response;
	}
}

const checkForbiddenWord = async (value) => {
	try {
		return await get(`/talk2you/users/profile/permission/forbidden-words?word=${value}`, {}, true)
	} catch(err){return err}
}

const postForbiddenWord = async (value) => {
	try {
		return await post(`/talk2you/users/profile/assistant/forbidden-words`,value, {}, true)
	} catch(err) {return err}
}

const uploadGreetingMedia = async (type, formData) => {
	try {
		return await post(`/common/files/upload/flowing-web-resources/${type}?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api}, true)
	} catch(err) {return err}
}

const getVideoThumbnail = async (formData) => {
	console.log(formData)
	try {
		return await post(`/common/files/generate/thumbnail?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL:config.api.proxy_api}, true)
	} catch(err) {return err}
}

const deleteGreetingMessage = async (userId, body) => {
	try {
		return await del(`/talk2you/users/profile/${userId}/room-introduction`, body, {}, true)
	} catch(err) {return err}
}

export const chatbotSettingsService = {
	getUserProfileInfo,
	getChatbotSettingsInfo,
	putChatbotSettingsInfo,
	delChatbotSettingsInfo,
    uploadVAFService,
    delChatbotImage,
	checkForbiddenWord,
	postForbiddenWord,
	uploadGreetingMedia,
	deleteGreetingMessage,
	getVideoThumbnail
};