// import {del, get, post, put} from '../helpers/api_helper_chat';

import {del, get, post, put} from '../helpers/api_helper';
import config from '../config/configuration';

export async function sendMessagesService(body, roomId) {
	try {
		return await post(`talk2me/messages/send`, body);
	} catch (err) {
		throw err;
	}
}

export async function getMessagesService(roomId, userId, since, until, limit, verbose) {
	try {
		return await get(
			`talk2me/messages/${roomId}?userId=${userId}&since=${since}&until=${until}&limit=${limit}`, {}, verbose,
		);
	} catch (err) {
		throw err;
	}
}

export async function getBlockedUsersService(roomId, userId) {
	try {
		return await get(`talk2me/rooms/${roomId}/users/blocked`);
	} catch (err) {
		throw err;
	}
}

export async function blockUserFromChatRoomService(roomId, body) {
	try {
		return await del(`/talk2me/rooms/${roomId}/send-off`, body);
	} catch (err) {
		throw err;
	}
}

export async function unblockUserFromChatRoomService(roomId, body) {
	try {
		return await put(`/talk2me/rooms/${roomId}/unblock`, body);
	} catch (err) {
		throw err;
	}
}

export async function sendReportUserService(body) {
	try {
		return await post(`/common/users/report/abuse`, body);
	} catch (err) {
		throw err;
	}
}

export async function leaveChatRoomService(roomId, body) {
	try {
		return await del(`/talk2me/rooms/${roomId}/leave`, body);
	} catch (err) {
		throw err;
	}
}

export async function maskMessageChatRoomService(roomId, body) {
	try {
		return await put(
			`/talk2me/rooms/${roomId}/info/users/mask-message`,
			body,
		);
	} catch (err) {
		throw err;
	}
}

export async function userProfileChatRoomService(roomId, body) {
	try {
		return await put(
			`/talk2me/rooms/${roomId}/info/users/open-profile`,
			body,
		);
	} catch (err) {
		throw err;
	}
}

export async function checkBlockUserService(body) {
	try {
		return await post(`/common/users/block/user/check`, body);
	} catch (err) {
		throw err;
	}
}

export async function previewMessageService(body) {
	try {
		return await post(`/talk2me/messages/preview`, body);
	} catch (err) {
		throw err;
	}
}

export async function uploadVAFService(formData, bucketName, uploadType) {
	try {
		// const result = await postApi(`/common/files/upload/${bucketName}/${uploadType}`, formData)
		return await post(`/common/files/upload/${bucketName}/${uploadType}?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api})
	} catch (err) {
		return err;
	}
}

export async function getThumbnailUrl(formData, bucketName, uploadType) {
	try {
		// const result = await postApiImage(`common/files/generate/thumbnail`, formData)
		return await post(`common/files/generate/thumbnail?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api})
	} catch (err) {
		throw err;
	}
}

export async function uploadImageService(formData) {
	try {
		// const result = await postApi(`common/files/upload/opentalk`, formData)
		// TODO : 여러 곳에서 사용 중으로 당장 삭제하지는 않지만, 잘못된 S3 버킷으로 접근하기 때문에 버킷명을 변경하여 유지함
		return await post(`common/files/upload/flowing-web-resources/image?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api});
	} catch (err) {
		throw err;
	}
}

export async function ownerMessageReadService(roomId, body) {
	try {
		return await put(`/talk2me/messages/${roomId}/read`, body);
	} catch (err) {
		throw err;
	}
}

export async function isSeenService(roomId, messageId) {
	try {
		const encodedMessageId = encodeURIComponent(messageId);
		const result = await get(
			`/talk2me/messages/${roomId}/${encodedMessageId}/read`,
		);
		return result;
	} catch (err) {
		throw err;
	}
}

export async function checkMediaConvertProgressService(mediaUrl) {
	try {
		// return await getApiProxy(`${mediaUrl}`, {}, true)
		return await get(`${mediaUrl}`, {baseURL: config.api.proxy_api}, true)
	} catch (err) {
		console.error(err)
		throw err
	}
}

export async function checkVideoUploadingService(jobId) {
	try {
		// return await getApiProxy(`/common/files/mediaconvert/fetch/state?jobId=${jobId}`,);
		return await get(`/common/files/mediaconvert/fetch/state?jobId=${jobId}`,{baseURL: config.api.proxy_api});
	} catch (err) {
		throw err;
	}
}

export async function deleteOneMessageService(body) {
	try {
		return await del(`/talk2me/messages`, body);
	} catch (err) {
		throw err;
	}
}
