import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
import {daysInKr} from '../../../constants/options';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';

const DesignTypeTab = (props) => {

	return (

		<div className="PhoneBody hauto">

            {/* <!-- 모바일화면 탭버튼 --> */}
            <div className="tabArea pagetab">
                <span 
                    className={props.tabArea===0?"viewTab01 tag tag-rounded active":"viewTab01 tag tag-rounded "}
                    onClick={()=>{props.setTabArea(0)}}
                    >{props.menuOrder[0]}
                </span>
                <span 
                    className={props.tabArea===1?"viewTab02 tag tag-rounded ml-2 active":"viewTab01 tag tag-rounded ml-2"}
                    onClick={()=>{props.setTabArea(1)}}
                    >{props.menuOrder[1]}
                </span>
                <span 
                    className={props.tabArea===2?"viewTab03 tag tag-rounded ml-2 active":"viewTab01 tag tag-rounded ml-2"}
                    onClick={()=>{props.setTabArea(2)}}
                    >{props.menuOrder[2]}
                </span>
            </div>
            {/* <!-- 모바일 화면 탭버튼--> */}
            {props.tabArea === 0?(
                menuOrder(props.menuOrder[0], props)
            ):props.tabArea === 1?(
                menuOrder(props.menuOrder[1], props)
            ):(    
                menuOrder(props.menuOrder[2], props)
            )}

            <div className="footlogo"><img src={logo} /></div>
        </div>	
		
	);
};

function menuOrder(menuOrder, props){
    // console.log('props', props);
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];

    if(menuOrder ==='답변톡'){
        return(
            <div className="conArea pb-100px">
                        
                <div className="txtArea2 mt-2 noView partOn">
                    <div className="mb-2 sectorTit d-none">탑변톡</div>
                    <div className="d-block w-100 ">
                        <div className="d-block"><i className="fe fe-clock"></i> <b>답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                    </div>
    
                    <div className="d-block w-100 ">
                        <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                    </div>
                {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                    <div className="d-block w-100 mt-2">
                        <div className="d-block">공휴일에는 답변이 불가능합니다.
                            답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                            수 있습니다. 하지만 답변자의 답변은 운영시간을
                            기다려주세요. 감사합니다.</div>
                    </div>
                }
                </div>

                <div className="txtArea2 mt-2 noView partOn">
                    {/*현재 영국 유학 중입 유학생입니다.
                    영국 유학 생활에 대한 자세히 안내해 드릴게요.*/}
                    {props.info && props.info?.introduction || ''}
                </div>
                <div className="mBox noView boxbig partOn">
                    <div className="titname d-block mb-2 mt-3"><b>답변키워드</b></div>
                    <div className="answerInfo">
                        <div className=" align-items-center mt-auto">
                            <div className="d-block w-100 ">
                                {/*<span className="tag tag-rounded mr-1">#영어</span>*/}
                            {keywordList.map((item, index) => (
                                <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                            ))}
                            </div>
                        </div>
                    </div>
                    <div className="titname d-block mb-2 mt-3"><b>답변지역</b></div>
                    <div className="answerInfo">
                        <div className=" align-items-center mt-auto">
                            <div className="d-block w-100 ">
                                {/*<span className="tag tag-rounded mr-1">길동</span>*/}
                            {regionList.map((item, index) => (
                                <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        } else if(menuOrder === '외부링크 블록형') {
            // console.log('outLinkList', outLinkList)
            return(
                <div className="conArea pb-100px">
                <div className="linkInfo noView mt-3">
                    <div className="mb-2 sectorTit d-none">외부링크6</div>
                {outLinkList.map((item, index) => (
                    item.iconLink ?
                            <div className="adressArea noView" key={index}>
                                <div className="d-flex align-items-center mt-auto">
                                    {/*<img className="avatar avatar-md mr-2" src={youtube} alt="" />*/}
                                    <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                    <div>
                                        <div className="d-block">
                                            <a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""
                ))}
                    {/*<div className="adressArea noView">
                        <div className="d-flex align-items-center mt-auto">
                            <img className="avatar avatar-md mr-2" src={instar} alt="" />
                            <div>
                                <div className="d-block">Instar.com</div>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div className="mt-4 sectorTit subTit d-none">피드</div>*/}
                {videoFeedList.map((item, index) => (
                    <div className="row align-items-center mt-2" key={index}>
                        <div className="col-5 text-center">
                            <div className="listImg">
                                {/*<img src={landingSampleImg} />*/}
                                <img src={item.videoThumbnail} alt={item.videoTitle}/>
                            </div>
                        </div>
                        <div className="col-7 text-left">
                            <div className="listTxt">
                                {/*[플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...*/}
                                {item.videoTitle}
                            </div>
                            <div className="listday">
                                <div>{item.ownerChannelName}</div>
                                <div className="bar"></div>
                                <div>{item.publishDate}</div>
                                {/*<div>youtube</div>
                                <div className="bar"></div>
                                <div>2022.7.19</div>*/}
                            </div>
                        </div>
                    </div>
                ))}
                    {/*<div className="row align-items-center mt-2">
                        <div className="col-5 text-center">
                            <div className="listImg">
                                <img src={landingSampleImg} />
                            </div>
                        </div>
                        <div className="col-7 text-left">
                            <div className="listTxt">
                                [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                            </div>
                            <div className="listday">
                                <div>youtube</div>
                                <div className="bar"></div>
                                <div>2022.7.19</div>
                            </div>
                        </div>
                    </div>*/}
                    {/*{videoFeedList.length > 2 &&
                        <div className="row">
                            <div className="col-12 text-center">
                                <a href="#" className="moreBtnSm">더보기</a>
                            </div>
                        </div>
                    }*/}
                </div>
            </div>
            )
        }else if(menuOrder === '상품'){
            return(
                <div className="conArea pb-100px">
                <div className="giftInfo noView mt-3">
                    <div className="mb-2 sectorTit d-none">상품</div>
                    <div className="align-items-center mt-auto">
                        <div className="d-block w-100 ">
                            <div className="giftTit"><img src="assets/img/img_gift.png" alt="" /> 플로잉 답변톡 상품6</div>
                            <p>
                                {props.info && props.info?.goodsUserProduct?.description || ''}
                            </p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                        <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                        <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                        <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                    </div>
                </div>
            </div>
            )

        }
    
}

export default withRouter(withTranslation()(DesignTypeTab));

DesignTypeTab.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
