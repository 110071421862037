import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import _ from "lodash";
import axios from "axios";
import chatBag from "../../assets/images/imgChatBag.png";
import helper from "../../assets/images/img_helper.jpg";
import { chatbotSettingsService, getService } from "../../services/chatbotSettings.service.js";
import { putService } from "../../services/chatbotSettings.service.js";
import { Row, Col, Input, Button } from "reactstrap";
import { toast } from "react-toastify";
import config from "../../config/configuration";
import { authService } from "../../services/auth.service";

const GreetingSettings = (props) => {
    // const userId = localStorage.getItem("user_id");
    document.title = "Flowing";

    // Greeting settings
    const inputRef = useRef(null);
    const textRef = useRef();
    const [previewChatMessage, setPreviewChatMessage] = useState("");
    const [previewFile, setPreviewFile] = useState();
    const [dataArr, setDataArr] = useState([]);
    const userData = JSON.parse(sessionStorage.getItem("user_data"));
    // const userData = JSON.parse(localStorage.getItem("user_data"));
    const { nickname } = userData;
    const [uploadLoading, setUploadLoading] = useState(false);

    const getChatbot = async (id) => {
        try {
            const response = await authService.getUserId()
            if(response.status===201) {
                // const profileRes = await axios.get(
                //     `${config.api.chat_api}/talk2you/users/web/profile/${response.data.user_id}`
                //     // `${config.api.chat_api}/talk2you/users/web/profile/${userId}`
                // );
                const profileRes = await authService.getUserprofile(response.data.user_id)
                const result = await getService(id);
                const { itemList } = result;
                if (profileRes.status === 204 || profileRes.status === 201) {
                    setDataArr(() => {
                        return [
                            {
                                message: `안녕하세요? ${nickname}의 답변톡방을 찾아주셔서 감사합니다.`,
                                msgType: "TEXT",
                            },
                        ];
                    });
                } else {
                    if (itemList?.length === 0) {
                        setDataArr(() => {
                            return [
                                {
                                    message: `안녕하세요? ${nickname}의 답변톡방을 찾아주셔서 감사합니다.`,
                                    msgType: "TEXT",
                                },
                            ];
                        });
                    } else {
                        setDataArr(() => {
                            return itemList;
                        });
                    }
                }
            }
        } catch (error) {
            // throw new Error(error);
        }
    };
    const handleChange = async (e) => {
        setUploadLoading(true);
        const fileUploaded = e.target;
        setPreviewFile(fileUploaded.files[0]);
        const fileType = fileUploaded.files[0].type.slice(0, 5);
        try {
            const response = await authService.getUserId()
            if(response.status===201) {
                // const profileRes = await axios.get(
                //     `${config.api.chat_api}/talk2you/users/web/profile/${response.data.user_id}`
                //     // `${config.api.chat_api}/talk2you/users/web/profile/${userId}`
                // );
                const profileRes = await authService.getUserprofile(response.data.user_id)
                if (profileRes.status === 204 || profileRes.status === 201) {
                    toast.error("답변 프로필 먼저 작성해 주세요");
                } else {
                    if (fileType === "image") {
                        try {
                            const originFormData = new FormData();
                            originFormData.append("upload", fileUploaded.files[0]);
                            // const originImgRes = await axios.post(
                            //     `${config.api.proxy_api}/common/files/upload/flowing-web-resources/image?env=DEV`,
                            //     originFormData
                            // );
                            const originImgRes = await chatbotSettingsService.uploadGreetingMedia("image", originFormData)
                            const thumbFormData = new FormData();
                            thumbFormData.append("upload", fileUploaded.files[0]);
                            thumbFormData.append("limitedImageSize", 500);
                            // const thumbImgRes = await axios.post(
                            //     `${config.api.proxy_api}/common/files/upload/flowing-web-resources/image?env=DEV`,
                            //     thumbFormData
                            // );
                            const thumbImgRes = await chatbotSettingsService.uploadGreetingMedia("image", thumbFormData)
                            if (originImgRes.data?.msg === "OK") {
                                const postBody = {
                                    msgType: "PHOTO",
                                    imgRatio: originImgRes.data?.imgRatio[0],
                                    fileOrigin: originImgRes.data?.url[0],
                                    message: thumbImgRes.data?.url[0],
                                };
                                try {
                                    const saveGreetingRes = await putService(
                                        postBody,
                                        response.data.user_id
                                        // userId
                                    );
                                    if (saveGreetingRes.message === "Updated") {
                                        inputRef.current.value = null;
                                        getChatbot(response.data.user_id);
                                        // getChatbot(userId);
                                    }
                                } catch (error) {
                                    // throw new Error(error);
                                }
                            } else {
                                toast.error("다시 한번 시도해 주세요");
                            }
                        } catch (error) {
                            // throw new Error(error);
                        }
                    } else if (fileType === "video") {
                        let durationResult;
                        const vid = document.createElement("video");
                        const fileURL = URL.createObjectURL(fileUploaded.files[0]);
                        vid.src = fileURL;
                        vid.ondurationchange = function () {
                            durationResult = this.duration;
                        };
                        try {
                            const originFormData = new FormData();
                            originFormData.append("upload", fileUploaded.files[0]);
                            originFormData.append("needToConvert", "Y");
                            // const originVideoRes = await axios.post(
                            //     `${config.api.proxy_api}/common/files/upload/flowing-web-resources/video?env=DEV`,
                            //     originFormData
                            // );
                            const originVideoRes = await chatbotSettingsService.uploadGreetingMedia("video", originFormData)
                            const thumbFormData = new FormData();
                            thumbFormData.append(
                                "video_url",
                                originVideoRes.data?.src_url[0]
                            );
                            thumbFormData.append(
                                "bucket_name",
                                "flowing-web-resources"
                            );
                            // const thumbVideoRes = await axios.post(
                            //     `${config.api.proxy_api}/common/files/generate/thumbnail?env=DEV`,
                            //     thumbFormData
                            // );
                            const thumbVideoRes = await chatbotSettingsService.getVideoThumbnail(thumbFormData)
    
                            const postBody = {
                                msgType: "VIDEO",
                                imgRatio: thumbVideoRes.data.imgRatio,
                                duration: durationResult,
                                message: thumbVideoRes.data?.thumbnailUrl,
                                fileOrigin: originVideoRes.data?.url[0],
                            };
                            try {
                                const saveGreetingRes = await putService(
                                    postBody,
                                    response.data.user_id
                                    // userId
                                );
                                if (saveGreetingRes.message === "Updated") {
                                    inputRef.current.value = null;
                                    getChatbot(response.data.user_id);
                                    // getChatbot(userId);
                                } else if (saveGreetingRes === "") {
                                    toast.error("다시 한번 시도해 주세요");
                                }
                            } catch (err) {
                                throw new Error(err);
                            }
                            setUploadLoading(false);
                        } catch (error) {
                            setUploadLoading(false);
                            // throw new Error(error);
                        }
                    } else {
                        setUploadLoading(false);
                        return;
                    }
                }
            }
        } catch (err) {
            throw new Error(err);
        }
        setUploadLoading(false);
    };

    async function deleteMessage(valueId) {
        setUploadLoading(true);
        try {
            const response = await authService.getUserId()
            if(response.status===201) {
                // const res = await axios.delete(
                //     `${config.api.chat_api}/talk2you/users/profile/${response.data.user_id}/room-introduction`,
                //     // `${config.api.chat_api}/talk2you/users/profile/${userId}/room-introduction`,
                //     { data: { id: valueId } }
                // );
                const res = await chatbotSettingsService.deleteGreetingMessage(response.data.user_id, {id: valueId})
                if (res.status === 201) {
                    await getChatbot(response.data.user_id);
                    // await getChatbot(userId);
                } else {
                    toast.error("다시 한번 시도해 주세요");
                }
                setUploadLoading(false);
            }
        } catch (error) {
            setUploadLoading(false);
            if (error.response.status) {
                toast.error(
                    "존재하지 않는 컨텐츠입니다. 다시 한번 시도해 주세요"
                );
            }
            // throw new Error(error);
        }
    }

    const postGreetingMessage = async (e) => {
        e.preventDefault();
        document.getElementById("textInput").value = "";
        textRef.current.value = "";
        setPreviewChatMessage(() => "");
        const postBody = {
            msgType: "TEXT",
            message: previewChatMessage,
        };
        if (previewChatMessage.trim() === "") {
            toast.error("내용을 입력해 주세요");
        } else {
            try {
                const response = await authService.getUserId()
                if(response.status===201) {
                    // const profileRes = await axios.get(
                    //     `${config.api.chat_api}/talk2you/users/web/profile/${response.data.user_id}`
                    //     // `${config.api.chat_api}/talk2you/users/web/profile/${userId}`
                    // );
                    const profileRes = await authService.getUserprofile(response.data.user_id)
                    console.log(profileRes)
                    if (profileRes.status === 204 || profileRes.status === 201) {
                        toast.error("답변 프로필 먼저 등록해주세요");
                    } else {
                        try {
                            const res = await putService(postBody, response.data.user_id);
                            // const res = await putService(postBody, userId);
                            if (res.message === "Updated") {
                                getChatbot(response.data.user_id);
                                // getChatbot(userId);
                            } else if (res === "") {
                                toast.error("다시 한번 시도해 주세요");
                            }
                        } catch (err) {
                            throw new Error(err);
                        }
                    }
                }
            } catch (err) {
                throw new Error(err);
            }
        }
    };

    useEffect(() => {
        const callChatbot = async () => {
            const res = await authService.getUserId()
            getChatbot(res.data.user_id);
            // getChatbot(userId);
        }
        callChatbot().then(r=>r)
    }, []);
    //meta title

    document.title = "Flowing";
    return (
        <React.Fragment>
            <li className="active">
                <Row>
                    <Col xl={7} lg={6} className="PcRightBoard">
                        <div className="chat_room whiteBox">
                            {dataArr?.length > 0 &&
                                dataArr.map((x, idx) => {
                                    if (x.msgType === "TEXT") {
                                        return (
                                            <div
                                                className="chat_windows"
                                                style={{
                                                    marginTop: "10px",
                                                    position: "relative",
                                                }}
                                                key={idx}
                                            >
                                                <div className="mb-0 other-message message">
                                                    <p className="bg-light-blue">
                                                        {x.message}
                                                    </p>
                                                </div>
                                                {x.id ? (
                                                    <i
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            cursor: "pointer",
                                                            marginLeft: "5px",
                                                        }}
                                                        onClick={() => {
                                                            deleteMessage(x.id);
                                                        }}
                                                        className="icon-close"
                                                    ></i>
                                                ) : null}
                                            </div>
                                        );
                                    }
                                    // if (x.msgType === "VIDEO") {
                                    //     return (
                                    //         <div
                                    //             className="chat_windows"
                                    //             style={{
                                    //                 marginTop: "10px",
                                    //             }}
                                    //             key={idx}
                                    //         >
                                    //             <div
                                    //                 style={{
                                    //                     position: "relative",
                                    //                 }}
                                    //                 className="mb-0 other-message message"
                                    //             >
                                    //                 <video
                                    //                     controls
                                    //                     width="300"
                                    //                     style={{
                                    //                         borderRadius:
                                    //                             "10px",
                                    //                     }}
                                    //                 >
                                    //                     <source
                                    //                         src={x.fileOrigin}
                                    //                     ></source>
                                    //                 </video>
                                    //                 <i
                                    //                     style={{
                                    //                         position:
                                    //                             "absolute",
                                    //                         cursor: "pointer",
                                    //                         marginLeft: "5px",
                                    //                     }}
                                    //                     onClick={() => {
                                    //                         deleteMessage(x.id);
                                    //                     }}
                                    //                     className="icon-close"
                                    //                 ></i>
                                    //             </div>
                                    //         </div>
                                    //     );
                                    // }
                                    if (x.msgType === "PHOTO" || "VIDEO") {
                                        return (
                                            <div
                                                className="chat_windows"
                                                style={{
                                                    marginTop: "10px",
                                                }}
                                                key={idx}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                    }}
                                                    className="mb-0 other-message message"
                                                >
                                                    <img
                                                        src={x.message}
                                                        alt="인사말 이미지"
                                                        style={{
                                                            width: "300px",
                                                            borderRadius:
                                                                "10px",
                                                        }}
                                                    />
                                                    <i
                                                        style={{
                                                            position:
                                                                "absolute",
                                                            cursor: "pointer",
                                                            marginLeft: "5px",
                                                        }}
                                                        onClick={() => {
                                                            deleteMessage(x.id);
                                                        }}
                                                        className="icon-close"
                                                    ></i>
                                                </div>
                                            </div>
                                        );
                                    }
                                    return (
                                        <div className="chat_windows" key={idx}>
                                            <div className="mb-0">
                                                <div className="other-message">
                                                    <div className="message">
                                                        <p className="bg-light-blue">
                                                            {}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="chat_room">
                            <div
                                className="chat-message clearfix bg-white"
                                style={{
                                    border: "1px solid #E8E9E9",
                                    borderRadius: "5px",
                                    padding: "20px 10px",
                                    marginTop: "30px",
                                }}
                            >
                                <input
                                    className="d-none"
                                    type="file"
                                    accept="image/*, video/*"
                                    id="selected-file"
                                    name="attachments"
                                    ref={inputRef}
                                    disabled={uploadLoading}
                                    onChange={handleChange}
                                />

                                <label
                                    htmlFor="selected-file"
                                    className="b-none bg-none "
                                >
                                    <i
                                        className="fa fa-paperclip"
                                        style={{
                                            color: "#01a0fc",
                                            cursor: "pointer",
                                        }}
                                    ></i>
                                </label>
                                <Row className="gutters-xs mb-0 mt-2">
                                    <form onSubmit={postGreetingMessage}>
                                        <div style={{ display: "flex" }}>
                                            <div className="col">
                                                <Input
                                                    disabled={uploadLoading}
                                                    ref={textRef}
                                                    id="textInput"
                                                    className="form-control bgChatText mt-0"
                                                    type="text"
                                                    maxLength="200"
                                                    placeholder="인사말을 입력해 주세요"
                                                    onChange={(e) => {
                                                        setPreviewChatMessage(
                                                            e.target.value
                                                        );
                                                    }}
                                                    onKeyPress={(e) => {
                                                        const { key, target } =
                                                            e;
                                                        if (key === "Enter")
                                                            target.value = "";
                                                    }}
                                                />
                                            </div>
                                            <span className="col-auto">
                                                <Button
                                                    disabled={uploadLoading}
                                                    className="btn btn-dark"
                                                    type="button"
                                                    onClick={
                                                        postGreetingMessage
                                                    }
                                                >
                                                    보내기
                                                </Button>
                                            </span>
                                        </div>
                                    </form>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col xl={5} lg={6}>
                        <div className="phone02 drop-shadow">
                            <div className="phone_over">
                                <div className="PhoneArea">
                                    <div className="ChatTop d-flex align-items-center mt-auto">
                                        <div>
                                            <small className="d-block">
                                                <i className="fe fe-arrow-left"></i>
                                                {nickname}
                                            </small>
                                        </div>
                                        <div className="ml-auto">
                                            <Link
                                                to="/#"
                                                className="icon d-none d-md-inline-block"
                                            >
                                                <i className="fe fe-user"></i>
                                            </Link>
                                            <Link
                                                to="/#"
                                                className="icon d-none d-md-inline-block ml-2"
                                            >
                                                <i className="fe fe-align-justify"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="PhoneBody p-2">
                                        <div className="ChatOption d-flex align-items-center">
                                            <div>
                                                <small className="d-block">
                                                    <img
                                                        src={chatBag}
                                                        alt="img"
                                                    ></img>
                                                    <span className="wordNum">
                                                        0/100
                                                    </span>
                                                </small>
                                            </div>
                                            <div className="ml-auto">
                                                <small className="d-block">
                                                    답변톡쿠폰 : 0
                                                </small>
                                            </div>
                                        </div>
                                        <div className="conArea mt-2">
                                            <div className="d-flex align-items-top mt-2">
                                                <img
                                                    className="avatar avatar-md mr-2"
                                                    src={helper}
                                                    alt="img"
                                                ></img>
                                                <div>
                                                    <small className="d-block">
                                                        경험 도우미
                                                    </small>
                                                    {/* <div className="bubble"> */}
                                                    {dataArr?.length > 0 &&
                                                        dataArr.map(
                                                            (x, idx) => {
                                                                if (
                                                                    x.msgType ===
                                                                    "PHOTO"
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                idx
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                                width: "max-content",
                                                                            }}
                                                                        >
                                                                            <div className="bubble">
                                                                                <img
                                                                                    src={
                                                                                        x.message
                                                                                    }
                                                                                    alt="이미지"
                                                                                    style={{
                                                                                        width: "150px",
                                                                                        paddingLeft:
                                                                                            "10px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                } else if (
                                                                    x.msgType ===
                                                                    "VIDEO"
                                                                ) {
                                                                    return (
                                                                        <div
                                                                            key={
                                                                                idx
                                                                            }
                                                                            style={{
                                                                                display:
                                                                                    "flex",
                                                                                flexDirection:
                                                                                    "column",
                                                                                width: "max-content",
                                                                            }}
                                                                        >
                                                                            <div className="bubble">
                                                                                <img
                                                                                    src={
                                                                                        x.message
                                                                                    }
                                                                                    alt="썸네일"
                                                                                    style={{
                                                                                        width: "150px",
                                                                                        paddingLeft:
                                                                                            "10px",
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                                return (
                                                                    <div
                                                                        key={
                                                                            idx
                                                                        }
                                                                        style={{
                                                                            display:
                                                                                "flex",
                                                                            flexDirection:
                                                                                "column",
                                                                            width: "max-content",
                                                                            maxWidth:
                                                                                "150px",
                                                                            zIndex: "1500",
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="bubble"
                                                                            style={{
                                                                                wordBreak:
                                                                                    "break-all",
                                                                                paddingLeft:
                                                                                    "10px",
                                                                            }}
                                                                        >
                                                                            <p>
                                                                                {
                                                                                    x.message
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    {/* {previewChatMessage && (
															<p
																style={{
																	'word-wrap': 'break-word',
																	'max-width': 168,
																}}
															>
																{previewChatMessage}
															</p>
														)}
														{previewChatEmoji && (
															<span className={previewChatEmoji}></span>
														)}
														{previewFile && (
															<img
																src={URL.createObjectURL(previewFile)}
																alt="img"
															/>
														)}
														{previewChatImage && !previewFile && (
															<img src={chatBotImage} alt="img"/>
														)}
														{previewChatVideo && (
															<video
																style={{
																	width: 120,
																	height: '100%',
																}}
																src={previewChatVideo}
															/>
														)} */}
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </li>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(GreetingSettings));

GreetingSettings.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
