import React, { useEffect, useState } from "react";
import _, { map } from "lodash";
import "../../assets/css/myStyle.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
    enterRoom,
    retriveMyRoomList,
} from "../../services/talk2youRooms.service";
import { Amplify } from "aws-amplify";
// defualt image
import profile from "../../assets/images/users/defualt-image.png";
//import chatbox
import classnames from "classnames";
import ChatBox from "./chatbox";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
    Container,
    Row,
    Col,
    Card,
    InputGroup,
    Input,
    Button,
    Nav,
    NavItem,
    NavLink,
    Spinner,
} from "reactstrap";
import {useLocation} from 'react-router';

const Chat = () => {
    // aa2d1b93-80e5-4fb4-8d4c-03d333afc5a2 batmaa
    // const user = {
    //   id: "1u6bm6ghx92yo915",
    //   image_url:
    //     "https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg",
    //   nickname: "나르시스",
    // };

    const [currentRoomId, setCurrentRoomId] = useState("");
    const [currentRoomData, setCurrentRoomData] = useState("");

    const [newMessage, setNewMessage] = useState({});
    const [myRooms, setMyRooms] = useState([]);
    const [myInactiveRooms, setInactiveMyRooms] = useState([]);
    const [myActiveRooms, setActiveMyRooms] = useState([]);
    const [loading, setLoading] = useState();
    const [refreshWindow, setRefreshWindow] = useState();
    const [userInformation, setUserInformation] = useState();
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const [relatedRoomId, setRelatedRoomId] = useState("");
    const [relatedRoomData, setRelatedRoomData] = useState("");

    const location = useLocation();

    useEffect(() => {
        const getArgData = async () => {
            // if(location.search) {
            //     const params = new URLSearchParams(location.search);
            //     const roomId = params.get("roomId");
            //     if(roomId) setRelatedRoomId(roomId);
            if(location.data) {
                const room = location.data;
                setRelatedRoomId(room?.roomId);
                room.unreadCount = 0;
                setRelatedRoomData(room);

                return room;
            }
        }
        getArgData().then();
    }, []);

    useEffect(() => {
        const getUserInfo = async () => {
            // let userInfo = localStorage.getItem("user_data");
            let userInfo = sessionStorage.getItem("user_data");
            setUserInformation(JSON.parse(userInfo));
            //setUserInformation(user);
        };

        getUserInfo().then(r => r);
        // console.log('1111111')
    }, []);

    // get rooms service
    async function fetchActiveRoomList(type, isEmpty, activeRoom) {
        setLoading(true);
        /*const resultRoomList = await retriveMyRoomList(
            userInformation.id,
            type
        );*/
        let resultRoomList;
        if(userInformation && userInformation.id) {
            resultRoomList = await retriveMyRoomList(
                userInformation.id,
                type
            );
        }

        if (resultRoomList && resultRoomList.itemList?.length > 0) {
            let rooms = resultRoomList.itemList;
            console.log('rooms',resultRoomList);

            // Rooms id subscribe
            map(rooms, (room) => {
                Amplify.PubSub.subscribe(room.roomId).subscribe({
                    next: (data) => {
                        setNewMessage(data.value);
                    },
                    error: (error) => console.error(error),
                });
            });

            if(relatedRoomId) {
                setCurrentRoomId(relatedRoomId);
                setCurrentRoomData(relatedRoomData);
            } else {
                if (isEmpty) {
                    setCurrentRoomId(rooms[0]?.roomId);
                    setCurrentRoomData(rooms[0]);
                }
            }

			// console.log('activeRoom', activeRoom)
            // setMyRooms(isEmpty ? rooms : [...activeRoom, ...rooms]);
            setMyRooms(rooms);

            if (type === "active") {
                setActiveMyRooms(rooms);
            } else if (type === "inactive") {
                setInactiveMyRooms(rooms);
            } else if (type === "") {
                setMyRooms(rooms);
            }

            setLoading(false);
            // if (type === "active")
            //     fetchActiveRoomList("active", false, rooms);
        } else {
            console.log('resultRoomList is empty....')
            if (type === "active") await fetchActiveRoomList("inactive", true);
            // toast.info("There are no " + type + " rooms");
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchActiveRoomList().then(r => r);
        if (userInformation) {
            fetchActiveRoomList("", true).then(r => r);
        }
        // console.log('2222222')

    }, [refreshWindow, userInformation]);

    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
            if (tab === "1") {
                fetchActiveRoomList("", true).then(r => r);
            } else if (tab === "2") {
                fetchActiveRoomList("active", true).then(r => r);
            } else if (tab === "3") {
                fetchActiveRoomList("inactive", true).then(r => r);
            }
        }
    };

    useEffect(() => {
        if (newMessage.senderId !== "SYSTEM") {
            if (newMessage.action === "read") return;
            if (newMessage.roomId) {
                let roomIndex = _.findIndex(myRooms, {
                    roomId: newMessage.roomId,
                });
                let roomData = _.find(myRooms, function (o) {
                    return o.roomId === newMessage.roomId;
                });
                if (
                    userInformation.id !== newMessage.senderId &&
                    newMessage.senderId !== "SYSTEM" &&
                    newMessage.roomId !== currentRoomId
                ) {
                    roomData.unreadCount = roomData.unreadCount + 1;
                }
                roomData.lastMessage = {
                    createdAt: newMessage.createdAt,
                    message: newMessage.message,
                    msgType: newMessage.msgType,
                    senderId: newMessage.senderId,
                };
                roomData.lastMessagedAt = newMessage.createdAt; // IoT 로 수신하는 추가 메시지는 lastMessagedAt 값이 없다. 때문에 수동으로 추가
                let newArr = [...myRooms];
                newArr[roomIndex] = roomData;
                // newArr.sort((a, b) => a.lastMessagedAt < b.lastMessagedAt ? 1 : a.lastMessagedAt > b.lastMessagedAt ? -1 : 0); // 최근 메시지 정렬 갱신(시간 역순)
                newArr.sort((a, b) => !a.lastMessagedAt || a.lastMessagedAt < b.lastMessagedAt ? 1 : !b.lastMessagedAt || a.lastMessagedAt > b.lastMessagedAt ? -1 : 0); // 값이 없는 경우 뒤로 보내고 최근 메시지 순
                setMyRooms(newArr);
            }
		}
        // console.log('3333333')
    }, [newMessage]);

    const handleChange = () => {
        setRefreshWindow(!refreshWindow);
    };

    //Use For Chat room open
    const userChatOpen = async (room) => {
        // console.log('room', room); return;
        let userId, userName, userImg;
        if(userInformation) {
            const { id, nickname, image_url } = userInformation;
            userId = id;
            userName = nickname;
            userImg = image_url;
        } else {
            userId = room.userId || '';
            userName = room.userName || '';
            userImg = room.userImg || '';
        }
        const postbody = {
            userId: userId,
            userName: userName,
            userImg: userImg,
        };
        await enterRoom(postbody, room.roomId);
        if (room.roomId) {
            console.log('myRooms', myRooms);
            let roomIndex = _.findIndex(myRooms, { roomId: room.roomId });
            let roomData = _.find(myRooms, function (o) {
                return o.roomId === room.roomId;
            });

            roomData.unreadCount = 0;
            let newArr = [...myRooms];
            newArr[roomIndex] = roomData;
            setMyRooms(newArr);
            setCurrentRoomId(room.roomId);
            setCurrentRoomData(roomData);
        }
    };

    //search recent user
    const searchUsers = () => {
        let input, filter, ul, li, a, i, txtValue;
        input = document.getElementById("search-user");
        filter = input.value.toUpperCase();
        ul = document.getElementById("recent-list");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("span")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    };

    return (
        <React.Fragment>
            <div>
                <ToastContainer />
                <div className="section-body" id="GGGG">
                    <div className="container-fluid chat-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">
                                        답변 방 관리
                                    </h4>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <Spinner type="grow" color="warning" />
                        ) : (
                            <div className="row clearfix chat-bg">
                                <div className="col-lg-12">
                                    <div className="section-light py-3 chat_app">
                                        <div className="chat_list" id="users">
                                            <h5 className="font-weight-bolder page-title">
                                                답변방
                                            </h5>
                                            <Container fluid>
                                                <Nav
                                                    tabs
                                                    className="nav nav-tabs b-none"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        customActiveTab ===
                                                                        "1",
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                toggleCustom(
                                                                    "1"
                                                                );
                                                            }}
                                                        >
                                                            전체
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        customActiveTab ===
                                                                        "2",
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                toggleCustom(
                                                                    "2"
                                                                );
                                                            }}
                                                        >
                                                            답변중
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        customActiveTab ===
                                                                        "3",
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                toggleCustom(
                                                                    "3"
                                                                );
                                                            }}
                                                        >
                                                            미답변
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                            </Container>
                                            <div className="input-group mt-3 mb-2">
                                                <InputGroup>
                                                    <Input
                                                        id="search-user"
                                                        type="text"
                                                        onChange={searchUsers}
                                                        className="form-control"
                                                        placeholder="답변방 검색하기"
                                                    />
                                                    <span className="input-group-append">
                                                        <Button
                                                            className="btn btn-secondary"
                                                            onClick={() => {
                                                                searchUsers();
                                                            }}
                                                        >
                                                            <i className="icon-magnifier"></i>
                                                        </Button>
                                                    </span>
                                                </InputGroup>
                                            </div>

                                            <ul
                                                id="recent-list"
                                                className="right_chat list-unstyled list"
                                            >
                                                {/* {customActiveTab === "1" &&
                                                    myRooms.length >
                                                        0 && <li>전체</li>} */}
                                                {customActiveTab === "1" &&
                                                    map(
                                                        myRooms,
                                                        (room, index) => (
                                                            <li
                                                                className={
                                                                    currentRoomId ===
                                                                    room.roomId
                                                                        ? "online active"
                                                                        : "online"
                                                                }
                                                                key={index}
                                                                style={{
                                                                    backgroundColor:
                                                                        currentRoomId ===
                                                                            room.roomId &&
                                                                        "#e8eaf6",
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => {
                                                                        userChatOpen(
                                                                            room
                                                                        ).then(r => r);
                                                                    }}
                                                                    className="media"
                                                                >
                                                                    <img
                                                                        className="media-object"
                                                                        src={
                                                                            room.userImg ||
                                                                            profile
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <div className="media-body">
                                                                        <span
                                                                            id="ownerName"
                                                                            className="name"
                                                                        >
                                                                            {
                                                                                room.userName
                                                                            }
                                                                            <span className="badge badge-primary badge-pill float-right">
                                                                                {room.unreadCount > 0 &&
                                                                                    room.unreadCount}
                                                                                {/*{room.lastMessagedAt}*/}
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="message chat-side-menu"
                                                                            style={{
                                                                                color:
                                                                                    room.unreadCount >
                                                                                        0 &&
                                                                                    "#283593",
                                                                            }}
                                                                        >
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "PREVIEW" &&
                                                                                room
                                                                                    .lastMessage
                                                                                    ?.message}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "TEXT" &&
                                                                                room
                                                                                    .lastMessage
                                                                                    ?.message}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "PHOTO" &&
                                                                                "sent photo"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "VIDEO" &&
                                                                                "sent video"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "VOICE" &&
                                                                                "sent voice"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "FILE" &&
                                                                                "sent file"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "NOTICE" &&
                                                                                "NOTICE"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "INFO" &&
                                                                                "INFO"}
                                                                        </span>
                                                                        <span className="badge badge-outline status"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    )}
                                                {/* {customActiveTab === "1" &&
                                                    myRooms.length > 0 && (
                                                        <li>답변 중</li>
                                                    )} */}
                                                {customActiveTab === "2" &&
                                                    map(
                                                        myActiveRooms,
                                                        (room, index) => (
                                                            <li
                                                                className={
                                                                    currentRoomId ===
                                                                    room.roomId
                                                                        ? "online active"
                                                                        : "online"
                                                                }
                                                                key={index}
                                                                style={{
                                                                    backgroundColor:
                                                                        currentRoomId ===
                                                                            room.roomId &&
                                                                        "#e8eaf6",
                                                                }}
                                                            >
                                                                <div
                                                                    onClick={() => {
                                                                        userChatOpen(
                                                                            room
                                                                        ).then(r => r);
                                                                    }}
                                                                    className="media"
                                                                >
                                                                    <img
                                                                        className="media-object"
                                                                        src={
                                                                            room.userImg ||
                                                                            profile
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <div className="media-body">
                                                                        <span
                                                                            id="ownerName"
                                                                            className="name"
                                                                        >
                                                                            {
                                                                                room.userName
                                                                            }
                                                                            <span className="badge badge-primary badge-pill float-right">
                                                                                {room.unreadCount >
                                                                                    0 &&
                                                                                    room.unreadCount}
                                                                            </span>
                                                                        </span>
                                                                        <span
                                                                            className="message chat-side-menu"
                                                                            style={{
                                                                                color:
                                                                                    room.unreadCount >
                                                                                        0 &&
                                                                                    "#283593",
                                                                            }}
                                                                        >
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "PREVIEW" &&
                                                                                room
                                                                                    .lastMessage
                                                                                    ?.message}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "TEXT" &&
                                                                                room
                                                                                    .lastMessage
                                                                                    ?.message}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "PHOTO" &&
                                                                                "sent photo"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "VIDEO" &&
                                                                                "sent video"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "VOICE" &&
                                                                                "sent voice"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "FILE" &&
                                                                                "sent file"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "NOTICE" &&
                                                                                "NOTICE"}
                                                                            {room
                                                                                .lastMessage
                                                                                ?.msgType ===
                                                                                "INFO" &&
                                                                                "INFO"}
                                                                        </span>
                                                                        <span className="badge badge-outline status"></span>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    )}
                                                {/* {customActiveTab === "1" &&
                                                    myInactiveRooms.length >
                                                        0 && <li>미답변</li>} */}
                                                {customActiveTab === "3" &&
                                                    map(
                                                        myInactiveRooms,
                                                        (room, index) => {
                                                            return (
                                                                <li
                                                                    className={
                                                                        currentRoomId ===
                                                                        room.roomId
                                                                            ? "online active"
                                                                            : "online"
                                                                    }
                                                                    key={index}
                                                                    style={{
                                                                        backgroundColor:
                                                                            currentRoomId ===
                                                                                room.roomId &&
                                                                            "#e8eaf6",
                                                                    }}
                                                                >
                                                                    <div
                                                                        onClick={() => {
                                                                            userChatOpen(
                                                                                room
                                                                            ).then(r => r);
                                                                        }}
                                                                        className="media"
                                                                    >
                                                                        <img
                                                                            className="media-object"
                                                                            src={
                                                                                room.userImg ||
                                                                                profile
                                                                            }
                                                                            alt=""
                                                                        />
                                                                        <div className="media-body">
                                                                            <span
                                                                                id="ownerName"
                                                                                className="name"
                                                                            >
                                                                                {
                                                                                    room.userName
                                                                                }{" "}
                                                                                {/*inactive room*/}
                                                                                <span className="badge badge-primary badge-pill float-right">
                                                                                    {room.unreadCount >
                                                                                        0 &&
                                                                                        room.unreadCount}
                                                                                </span>
                                                                            </span>
                                                                            <span
                                                                                className="message chat-side-menu"
                                                                                style={{
                                                                                    color:
                                                                                        room.unreadCount >
                                                                                            0 &&
                                                                                        "#283593",
                                                                                }}
                                                                            >
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "PREVIEW" &&
                                                                                    room
                                                                                        .lastMessage
                                                                                        ?.message}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "TEXT" &&
                                                                                    room
                                                                                        .lastMessage
                                                                                        ?.message}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "PHOTO" &&
                                                                                    "sent photo"}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "VIDEO" &&
                                                                                    "sent video"}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "VOICE" &&
                                                                                    "sent voice"}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "FILE" &&
                                                                                    "sent file"}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "NOTICE" &&
                                                                                    "NOTICE"}
                                                                                {room
                                                                                    .lastMessage
                                                                                    ?.msgType ===
                                                                                    "INFO" &&
                                                                                    "INFO"}
                                                                            </span>
                                                                            <span className="badge badge-outline status"></span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                            </ul>
                                        </div>
                                        {currentRoomId && (
                                            <ChatBox
                                                key={currentRoomId}
                                                roomId={currentRoomId}
                                                room={currentRoomData}
                                                user={userInformation}
                                                onChange={() => handleChange()}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(withTranslation()(Chat));
