import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import {getQna as getData} from '../../services/noticeView.service';

import {Container, Row, Table} from 'reactstrap';

const NoticeDetail = (props) => {
	//meta title

	const [noticeList, setNoticeList] = useState([]);
	const getNotice = async () => {
		console.log('props.location.id : ', props.location.id); //return;
		const result = await getData(props.location.id); //return;
		setNoticeList(result);
	};
	useEffect(() => {
		getNotice();
	}, []);

	document.title = '플로잉 공지사항';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<Container fluid>
					<Row className="align-items-center">
						<div className="col text-left">
							<h1 className="page-title">플로잉 공지사항</h1>
						</div>
					</Row>
					<div className="table-responsive">
						<Table className="table table-vcenter text-nowrap tableView">
							{/*{noticeList.map((item, index) => {*/}
								{/*return (*/}
									{/*<tbody key={index}>*/}
									<tbody>
									<tr>
										<th scope="row" className="text-left BoardTitle">
											{noticeList.title}
										</th>
										<td className="text-right BoardInfo">
											{/*<span className="mr-4">작성자 : {noticeList.}</span>*/}
											<span> 등록일 : {noticeList.displayedDate}</span>
										</td>
									</tr>

									<tr>
										<td colSpan={2}>
											{/*{noticeList.content}*/}
											<div dangerouslySetInnerHTML={{ __html: noticeList.content }} />
										</td>
									</tr>
									</tbody>
								{/*);*/}
							{/*})}*/}
						</Table>
					</div>
					<Row className="justify-content-center">
						{/*<Link to="/#" className="btn btn-outline-secondary m-1 w-auto">
							before
						</Link>*/}
						<Link
							className="btn btn-outline-secondary m-1 w-auto"
							to={'/qna/NoticeView'}
						>
							목록
						</Link>
						{/*<Link className="btn btn-outline-secondary m-1 w-auto" to="/#">
							next
						</Link>*/}
					</Row>
				</Container>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(NoticeDetail));

NoticeDetail.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
	id: PropTypes.string,
	state: PropTypes.object,
};
