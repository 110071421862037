import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import '../../../assets/css/myStyleChat.css';

import {
	setRoomTransferFunction,
	getTransferDataFunction, getRoomTransferLimitFunction,
} from '../helper/SupportFunction';
import {isEmptyObject} from '../../../common/utils/functions';

export default function SetChatLimit(props) {
	const {user, roomId} = props;

	const [freeTransfer, setFreeTransfer] = useState();
	const [purchaseNeedsCount, setPurchaseNeedsCount] = useState();
	const [transferLimit, setTransferLimit] = useState({});

	// set limit
	const saveTransfer = async () => {
		let condition = /^[0-9]+$/;
		if (freeTransfer === undefined || freeTransfer === null || !condition.test(freeTransfer)) {
			toast.warning(`숫자로 된 적절한 무료 전송 글자수를 입력해 주세요.`);
			setFreeTransfer(0);
			return;
		}
		if (purchaseNeedsCount === undefined || purchaseNeedsCount === null || !condition.test(purchaseNeedsCount)) {
			toast.warning(`숫자로 된 적절한 답변톡 상품 구매가를 입력해 주세요.`);
			setPurchaseNeedsCount(0);
			return;
		}
		if(transferLimit && !isEmptyObject(transferLimit) && transferLimit.hasOwnProperty('freeTransferCount')) {
			if(parseInt(freeTransfer) < 0 || parseInt(freeTransfer) > parseInt(transferLimit.freeTransferCount)) {
				toast.warning(`무료 전송 글자수는 0 ~ ${transferLimit.freeTransferCount} 사이에서 설정해 주세요.`);
				setFreeTransfer(transferLimit.freeTransferCount);
				return;
			}
		}
		if(transferLimit && !isEmptyObject(transferLimit) && transferLimit.hasOwnProperty('productPurchaseNeedsCount')) {
			if(parseInt(purchaseNeedsCount) < 0 || parseInt(purchaseNeedsCount) > parseInt(transferLimit.productPurchaseNeedsCount)) {
				toast.warning(`답변톡 상품 구매가는 0 ~ ${transferLimit.productPurchaseNeedsCount} 사이에서 설정해 주세요.`);
				setPurchaseNeedsCount(transferLimit.productPurchaseNeedsCount);
				return;
			}
		}
		let data = {
			userId: user.id,
			freeTransferCount: parseInt(freeTransfer),
			productPurchaseNeedsCount: parseInt(purchaseNeedsCount),
			productDescription: '상품설명',
		};
		const result = await setRoomTransferFunction(roomId, data);
		if (result.message === 'Updated') {
			// toast.success('Save transfer limit');
			toast.success('전송제한 값을 설정했습니다.');
		} else {
			toast.warning(result?.response?.data?.message);
		}
	};

	// Main useEffect
	useEffect(() => {
		getTransferLimit().then(r => r);

		async function getTransferLimit() {
			/* TODO : 엉뚱한 API 를 호출함 => 잔여 전송 글자수 표시에 필요한 값 호출
			let result = await getTransferDataFunction(roomId, user.id);
			setFreeTransfer(result.limit.transferMaxCount);
			setPurchaseNeedsCount(result.limit.purchaseCount);*/
			let result = await getRoomTransferLimitFunction(roomId, user.id);
			setFreeTransfer(result && result?.itemList[0]?.freeTransferCount || 0);
			setPurchaseNeedsCount(result && result?.itemList[0]?.productPurchaseNeedsCount || 0);
			setTransferLimit(result && result?.transferLimit || {});
		}
	}, []);

	return (
		<div className="room-list">
			<div className="room-item-header d-flex align-items-center">
				<div className="room-item-close-button">
          <span
			  className="la la-close"
			  onClick={(e) => {
				  props.onChangeMenu('chatContainer');
			  }}
		  ></span>
				</div>
				<div className="room-item-header-title font-weight-bold">
					답변방 유료 설정
				</div>
			</div>
			{/*<div className="chat-trans-limit-body p-10">
				<div className="trans-limit-row d-flex align-items-center justify-content-between">
					<div className="">- 전송 제한 적용</div>
					<div className="ml-auto text-muted">
						<div className="form-group">
							<div className="custom-switches-stacked">
								<label className="custom-switch form-label">
									<input
										type="checkbox"
										className="custom-switch-input form-check-input"
										defaultChecked="checked"
									/>
									<span className="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>*/}
			<div className="chat-trans-limit-body p-10">
				<div className="trans-limit-row d-flex align-items-center justify-content-between">
					<div className="">- 무료 전송 글자수</div>
				</div>

				<input
					className="pt-2px pb-2px text-right input-tans-limit form-control mt-2"
					placeholder=""
					style={{width: '100%', height: '30px'}}
					value={freeTransfer}
					onChange={(e) => {
						setFreeTransfer(e.target.value);
					}}
				/>
			</div>
			<div className="chat-trans-limit-body p-10">
				<div className="trans-limit-row d-flex align-items-center justify-content-between">
					<div className="">- 500 답변톡 상품 구매가(보석)</div>
				</div>
				<input
					className="pt-2px pb-2px text-right input-tans-limit input-tans-limit form-control mt-2"
					placeholder=""
					value={purchaseNeedsCount}
					onChange={(e) => {
						setPurchaseNeedsCount(e.target.value);
					}}
					style={{width: '100%', height: '30px'}}
				/>
			</div>
			<div className="chat-trans-limit-body p-10 ">
				<div className="p-10">
					변경된 설정 내용은 현재 답변방에만 적용됩니다. 이미지, 영상, 음성,
					파일 등의 전송은 50글자로 인식하여 전송됩니다.
				</div>
			</div>
			<div
				className="text-center w-100 position-absolute "
				style={{bottom: '150px'}}
			>
				<button
					className="btn btn-secondary btn-submit-limits w-100"
					style={{padding: 'pt-4px pb-4px'}}
					onClick={() => saveTransfer()}
				>
					저장
				</button>
			</div>
		</div>
	);
}
