import PropTypes from 'prop-types';

import {Switch, BrowserRouter as Router, useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {authService} from './services/auth.service';
import analytics from './utils/analytics';

// Import Routes all
import {authProtectedRoutes, publicRoutes} from './routes';

// Import all middleware
import Authmiddleware from './routes/route';

import {ToastContainer} from 'react-toastify';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import HorizontalLayout from './components/HorizontalLayout/';
import NonAuthLayout from './components/NonAuthLayout';
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import config from './config/configuration';
import axios from 'axios';

const App = (props) => {
    const [checkedSession, setCheckedSession] = useState(false);
	const location = useLocation();

	// 배포환경에 따른 로그 출력 Override
	const env = process.env.REACT_APP_SET_ENV || 'DEV'
	if(env === 'PRD'){
		if(!window.console) window.console = {};
		const methods = ["log", "debug", "warn", "info"]
		for(let i=0; i<methods.length; i++){
			console[methods[i]] = function(){};
		}
	}

	useEffect(() => {
		async function callFunction() {
			try {
				console.log(checkedSession)
				if(checkedSession) return;
				const accessToken = await authService.getACToken().then(res => {
					if(res?.status !== 201) throw new Error('session-out')
					return res?.data?.access_token
				})
				sessionStorage.setItem("access_token", accessToken)
				const userId = await authService.getUserId().then(res => {
					if(!res?.data?.user_id) throw res
					return res?.data?.user_id
				})
				await authService.getUserInfo(userId)
			} catch(err) {
				setCheckedSession(()=>true);
				if(err.response.status===401) {
					const logout = await authService.logout()
					if(logout.status===201) {
						localStorage.clear()
						sessionStorage.clear()
						if(window.location.pathname !== "/login") {
							window.location.href = "/login"
						}
					}
				}
			}
		}
		callFunction().then(r=>setCheckedSession(()=>true))
	}, [checkedSession])

	useEffect(() => {
		analytics.pageView(location);
	}, [location])


    useEffect(() => {
        // Respond to the `storage` event
        function storageEventHandler(event) {
            if (event.key === 'session-trigger') {
                if (event.newValue === 'changed') {
                    setCheckedSession(()=>false);
                    localStorage.removeItem('session-trigger');
                }
            }
        }
        // Hook up the event handler
        window.addEventListener('storage', storageEventHandler);
        return () => {
            // Remove the handler when the component unmounts
            window.removeEventListener('storage', storageEventHandler);
        };
    }, [])

	// const timeout = 1800000;
	// const [remaining, setRemaining] = useState(timeout);
	//
	// const handleOnIdle = () => {};
	//
	// const {getRemainingTime} = useIdleTimer({
	// 	timeout,
	// 	onIdle: handleOnIdle,
	// });
	//
	// useEffect(() => {
	// 	setRemaining(getRemainingTime());
	//
	// 	setInterval(() => {
	// 		setRemaining(getRemainingTime());
	// 	}, 1);
	// }, []);

	const Layout = VerticalLayout;
	if(checkedSession) {
		return (
			<React.Fragment>
				<ToastContainer />
				<Router>
					<Switch>
						{publicRoutes.map((route, idx) => (
							<Authmiddleware
								path={route.path}
								layout={NonAuthLayout}
								component={route.component}
								key={idx}
								isAuthProtected={false}
								exact
							/>
						))}
	
						{authProtectedRoutes.map((route, idx) => (
							<Authmiddleware
								path={route.path}
								layout={Layout}
								component={route.component}
								key={idx}
								isAuthProtected={true}
								exact
							/>
						))}
					</Switch>
				</Router>
			</React.Fragment>
		);
	} else {
		return (
			<div />
		)
	}
};

App.propTypes = {
	layout: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		layout: state.Layout,
	};
};

export default connect(mapStateToProps, null)(App);
