/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {isEmpty, map} from 'lodash';
import _ from 'lodash';
import moment from 'moment';
import {Button, Fade, Spinner} from 'reactstrap';
import Picker from 'emoji-picker-react';
// IOT aws
import {Amplify} from 'aws-amplify';
//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Input} from 'reactstrap';
import {toast} from 'react-toastify';
//import room service
import {
	getMessagesService,
	ownerMessageReadService,
	checkVideoUploadingService, checkMediaConvertProgressService,
} from '../../services/talk2meMessage.service';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
// defualt image
import giphy from '../../assets/images/giphy.gif';
import plane from '../../assets/images/plane.png';
import profile from '../../assets/images/users/defualt-image.png';
import so_select from '../../assets/images/chatroom/so_select.png';

import badSmall from  "../../assets/images/badSmall.png"
import goodSmall from "../../assets/images/goodSmall.png"
import sosoSmall from "../../assets/images/sosoSmall.png"

import '../../assets/scss/AnswertalkChat.scss'

import ReactPlayer from 'react-player';
// Import child components
import File from './components/File';
import Preview from './components/Preview';
import ChatHeader from './components/ChatHeader';
import RightMenuContent from '../../components/RightLayout/RightMenuContent';

// Helper chat functions
import {
	isSeenFunction,
	addMessageFunction,
	selectFileFunction,
} from './helper/chatSupportFunctions';
// Import helper function
import {toggleRight} from '../../helpers/Functions/check';
import {findMessage} from '../../helpers/Functions/findMessage';
import {
	checkDeadlineTimer,
	startDeadlineTimer,
} from '../../helpers/Functions/deadlineTimer';

import DeleteMessageModal from './helper/DeleteMessageModal';
import SettingsSideBar from './components/SettingsSideBar';
import WinnerModal from './components/WinnerModal';
import BlockModal from './helper/BlockModal';
import KickRoomModal from './helper/KickRoomModal';
import {getRoomData} from '../../services/talk2meRooms.service';
import { authService } from '../../services/auth.service';
import { useInView } from '../../utils/customHooks';

const ChatBox = (props) => {
	document.title = 'Chatroom';

	// message
	const [messageBox, setMessageBox] = useState();
	const [newMessage, setNewMessage] = useState();
	const [curMessage, setcurMessage] = useState('');
	const [since, setSince] = useState('');
	const [until, setUntil] = useState('');
	const [limit, setLimit] = useState(20);
	const textAreaRef = useRef(null);
	const [messages, setMessages] = useState([]);

	// Owner information
	const [isOwner, setIsOwner] = useState(false);
	const [ownerData, setRoomOwnerData] = useState({});
	const [ownerSeen, setOwnerSeen] = useState(false);
	const [unReadOwnerMsgCount, setUnReadOwnerMsgCount] = useState(false);

	// loading
	const [loadimg, setLoading] = useState();
	const [loadingFile, setLoadingFile] = useState();
	const [loadingVideo, setLoadingVideo] = useState();
	const [selectedVideoId, setSelectedVideoId] = useState();

	// sub menu screens and modals
	const [kickRoomModal, setKickRoomModal] = useState(false);
	const [isBlockModal, setIsBlockModal] = useState(false);
	const [settingsMenu, setSettingsMenu] = useState(false);
	const [reportScreen, setReportScreen] = useState(false);

	// target user information ( block, leave rooom ... etc )
	const [winnerTargetUserId, setWinnerTargetUserId] = useState();
	const [targetUserId, setTargetUserId] = useState();
	const [targetMessageId, setTargetMessageId] = useState();
	const [targetUserName, setTargetUserName] = useState();

	// blocked users
	const [targetVideoAndImage, setTargetVideoAndImage] = useState({});
	const [senderHashCode, setSenderHashCode] = useState();
	const [isShowEmoji, setIsShowEmoji] = useState(false);
	// Deadline
	const [deadline, setDeadline] = useState();
	const [timer, setTimer] = useState('00:00:00');
	const inputRef = useRef(null);

	const [winnerModal, setWinnerModal] = useState();
	const [winnerMessage, setWinnerMessage] = useState();

	const [messageClickAction, setMessageClickAction] = useState(false);
	const [copyText, setCopyText] = useState(false);
	const [isOnlyOneMesageDeleteModal, setIsOnlyOneMesageDeleteModal] =
		useState(false);
	const [clickAvatar, setClickAvatar] = useState();
	const [selectedAvatarMsgId, setSelectedAvatarMsgId] = useState();
	let deadlineTimeId;

	const [lastReadMsgTime, setLastReadMsgTime] = useState('');
	const [roomData, setRoomData] = useState({})
	const [userId, setUserId] = useState(props.user.id)
	const [isMessageSend, setIsMessageSend] = useState(false)


    const messagesEndRef = useRef()
    const messagesStartRef = useRef()
    const lastShowingMsg = useRef('')
    const messageRefs = useRef({});
    const setMessageRefs = (key, el) => {
        messageRefs[key] = el;
    }
	const [isDataInit, setIsDataInit] = useState(false)

	const [isTotal, setIsTotal] = useState(false)


    // Tool tip
    const [isTopShow, setIsTopShow] = useState(false);
    const [isBottomShow, setIsBottomShow] = useState(true);
    const [isBeforeLoading, setIsBeforeLoading] = useState(false);


    const [observeTop, unobserveTop] = useInView((isShow) => {
        console.log('%c top-view','color:red;font-weight:bold;' , isShow)
        setIsTopShow(()=>{return isShow});
    });

    const [observeBottom, unobserveBottom] = useInView((isShow) => {
        console.log('%c bottom-view','color:blue;font-weight:bold;' , isShow);
        setIsBottomShow(()=>{return isShow});
    });


	async function getMoreMessages () {
		if (!messages || messages?.length === 0) return false;
		setLoading(true)
		try {
			// until 파라미터를 message의 가장 최신 시간으로 입력
			const response = await getMessagesService(
					props.roomId,
					props.user.id,
					since,
					messages[0].createdAt, // until
					limit,
					true
				).then(result => {
					console.log(result)
					return result
			})
			if(response.status===204) {
				setIsTotal(()=>{return true})
			}
	
			const currentMessages = [...messages];
			const nMessages = response.data?.itemList || [];
			const concatMessage = nMessages.concat(currentMessages)
			setMessages(()=>{return concatMessage});
		} catch(err) {
			throw err
		} finally {
			setLoading(false)
		}
	}


	const getMoreBefore = async () => {
        setIsBeforeLoading(true);
        if (isBeforeLoading) return;
        try {
			const anchorId = messages[0]?.messageId
            const anchor = messageRefs[anchorId];
			await getMoreMessages().then(r => r);
			// anchor.scrollIntoView({behavior:'auto'});
            if (anchor) {
				anchor.scrollIntoView(true);
				// anchor.scrollIntoView({behavior:'auto'});
			}
        } catch (e) {
            console.error(e);
        } finally {
            setIsBeforeLoading(false);
        }
    }

		// Chat box scroll to bottom
		useEffect(() => {
			if (isDataInit) {
				observeTop(messagesStartRef.current);
				observeBottom(messagesEndRef.current);
			}
	
			if (!isBottomShow && isDataInit) return;
			messagesEndRef.current.scrollIntoView({ behavior: "auto" });
			checkLastShow();
		}, [messages])
	
	
		useEffect(() => {
			if (isTopShow === true && isBottomShow === false) {
				// before data loading event
				// Todo: 현대 불러와 있는 데이터 이전의 데이터를 현재 데이터의 앞단에 가져와 뿌려준다. 스크롤 관련 스코프가 미지수
				isTotal === false && getMoreBefore().then(r=>r)
			}
	
			checkLastShow();
		}, [isTopShow, isBottomShow])
	
	
		function checkLastShow () {
			if (isBottomShow && messages?.length > 0) {
				// console.log(lastShowingMsg)
				// console.log(messages[messages.length - 1]?.messageId)
				lastShowingMsg.current = messages[messages.length - 1]?.messageId;
			}
		}
	
		useEffect(()=>{
			if(isTotal) unobserveTop(messagesStartRef.current)
		}, [isTotal])

		const scrollActivateControl = (isForced, behavior) => {
			console.log(isForced, behavior)
			if (!isBottomShow && isForced !== true) return;
			messagesEndRef.current?.scrollIntoView({ behavior: isForced === true && !behavior ? 'auto' : behavior || 'smooth', block: 'end' });
		}


		const unShowMessageCount = useMemo(() => {
			const lastShowId = lastShowingMsg.current;
			if (!lastShowId) return false;
	
			const idx =  _.findLastIndex(messages, ['messageId', lastShowId]);
			const len = messages?.length || 0;
	
			return len - idx - 1;
		}, [messages, lastShowingMsg.current]);

		useEffect(()=>{
			if(isMessageSend===true) {
				scrollActivateControl(true)
				setIsMessageSend(()=>{return false})
			}
		},[isMessageSend])





















	useEffect(() => {
		const getId = async () => {
			const result = await authService.getUserId()
			if(result.status===201) {
				setUserId(()=>{return result.data.user_id})
			}
		}
		getId().then(r=>r)
	}, [])

	// const userId = props.user.id || localStorage.getItem("user_id")

	//KMS modify
	function getUserName(userId){
		let userName = _.find(props.room.activeUsers, function (o) {
			return o.userId === userId;
		});
	}

	let timeCheck="";
	function checkTime(createdAt){
		if(createdAt == null || createdAt ==="") return;
		createdAt = createdAt.substr(0,10);
		//console.log("createdAt:::", createdAt);
		if(timeCheck !== createdAt){
			timeCheck = createdAt;

			return (
				
				<li className="system-style">
					<div className="message">
						<div>
							<div className="bg-light-blue msg-tag ln-msg">
								<div>
									{createdAt}
				 				</div>
				 			</div>
				 		</div>
				 	</div>
				</li>
				
			)
		}else{
			timeCheck = createdAt;
		}
	}
	

	// Set time start
	const clearDeadlineTimer = (duration) => {
		const interval = 1000;
		deadlineTimeId = setInterval(() => {
			duration = moment.duration(duration - interval, 'milliseconds');
			let result = startDeadlineTimer(duration._data);
			// Timer finished condition
			if (!result) {
				setTimer('00:00:00');
				setDeadline(false);
				clearInterval(deadlineTimeId);
				return;
			} else {
				if(result !== '00:00:00') {
					setTimer(result);
				} else {
					setTimer('00:00:00');
					setDeadline(false);
					clearInterval(deadlineTimeId);
					return;
				}
			}
		}, interval);
	};

	//KMS add
	const rateCheck = index => {
		if (index === 1) return goodSmall
		if (index === 2) return sosoSmall
		if (index === 3) return badSmall
	  }

	// Room time calculation
	useEffect(() => {
		if (props.room && props.room.deadline) {
			let duration = checkDeadlineTimer(props.room.deadline, deadlineTimeId);
			console.log('duration',duration)
			if (!duration) return;

			setDeadline(true);
			clearDeadlineTimer(duration);
		}
		return () => {
			clearInterval(deadlineTimeId);
		};
	}, [props.room]);

	// Main useEffect
	useEffect(() => {
		// Owner is seen function
		async function checkIsSeenFunction(messageList) {
			let unReadCount = await isSeenFunction(
				messageList,
				props.room.ownerId,
				props.roomId,
			);
			setUnReadOwnerMsgCount(unReadCount);
		}

		// Owner msg read function
		async function ownerMessageReadFunction(messageList) {
			if (messageList.length <= 0) return;
			let body = {
				userId: props.room.ownerId,
				since: messageList[0].createdAt,
				until: messageList[messageList.length - 1].createdAt,
			};
			await ownerMessageReadService(props.roomId, body);
		}

		async function getRoomDataFunction() {
			const response = await getRoomData(userId, props.roomId)
			if (response && response?.itemList.length < 1) {
				return {
					status: false,
					message: "Room data not found"
				}
			}

			let roomData = response?.itemList[0]

			// Is the current user the room owner?
			let isOwner = userId === roomData.ownerId

			// Serach owner data
			let ownerData = _.find(roomData.activeUsers, function(o) {
				return o.userId === roomData.ownerId
			})

			if (!ownerData) {
				ownerData = _.find(roomData.invitedUsers, function(o) {
					return o.userId === roomData.ownerId
				})
			}

			// Active users array empty
			if (!ownerData && userId === roomData.ownerId) {
				ownerData = {
					userId: roomData.ownerId,
					userImg: roomData.myInfo.userImg,
					userName: roomData.myInfo.userName
				}
			}
			setRoomData({
				isOwner,
				roomData,
				ownerData
			})

		}

		getRoomDataFunction().then(r => r)

		async function getMessagesFunction() {
			setLoading(true);
			const response = await getMessagesService(
				props.roomId,
				props.user.id,
				since,
				until,
				limit,
			);
			const originalMessages = response?.itemList ? response?.itemList : []
			setMessages(originalMessages);
			console.log('here is data init')
			setIsDataInit(()=>{return true})
			if(originalMessages.length < 10) setIsTotal(()=>{return true})
			console.log('response.itemList', originalMessages);

			// IOT subscribe message
			Amplify.PubSub.subscribe(props.roomId).subscribe({
				next: (data) => setNewMessage(data),
				error: (error) => toast.warning('IOT not subscribe' + error),
			});

			// is owner read message
			if (props.user.id === props.room.ownerId) {
				await ownerMessageReadFunction(originalMessages);
			} else {
				// This user no owner
				await checkIsSeenFunction(originalMessages);
			}
			setLoading(false);
		}

		getMessagesFunction().then(r => r);
		setRoomOwnerData(props.currentRoomOwnerData);
		setIsOwner(props.user.id === props.room.ownerId);
	}, [
		props.room,
		props.roomId,
		props.user.id,
		props.currentRoomOwnerData,
		since,
		until,
		limit,
	]);

	// IOT new message
	useEffect(() => {
		if (
			newMessage &&
			newMessage.value &&
			senderHashCode !== newMessage?.value?.hashCode && // 이 채팅방의 다른 사람이 보낸 메시지
			newMessage.value.roomId === props.roomId &&
			newMessage?.value?.senderId !=="BOT"   //modify KMS : Not print BOT message
		) {
			if (
				newMessage.value.msgType === 'INFO' &&
				newMessage.value.action === 'sendoff' &&
				newMessage.value.targetId === props.user.id
			) {
				// Block user -> Room leave
				setKickRoomModal(true);
				return;
			}
			if (newMessage.value.action === 'read') {
				// Owner seen
				setOwnerSeen(true);

				// 방장이 메시지를 읽은 경우 내 비행기를 날려버리기 위해 마지막 읽은 시각 기록
				console.log('lastReadMsgTime', moment(newMessage.value?.targetData?.until || new Date().toISOString()).format('HH:mm:sss') || '')
				// setLastReadMsgTime(newMessage.value?.targetData?.until || newMessage.value?.createdAt) // 마지막으로 메시지 읽은 시각 기록 ==> IoT 수신 후 createdAt 데이터가 update 되면서 모든 값이 동일하게 되는 문제 발생
				setLastReadMsgTime(newMessage.value?.targetData?.until || new Date().toISOString()) // 마지막으로 메시지 읽은 시각 기록
				return;
			}
			if (newMessage.value.action === 'pick') {
				// Winner action
				winnerFunction();
			}

			//KMS Modify
			if(newMessage.value.msgType ==='DELETED'){
				const messageIdx = _.findIndex(messages,function (n) {
					return n.messageId === newMessage.value.messageId;
				});
				messages[messageIdx].msgType ="TEXT";
				messages[messageIdx].message ="삭제된 메세지 입니다.";
				setMessages(messages);

			/*}else{
				// Set new message
				setMessages([...messages, newMessage.value]);*/
			}

			setMessages([...messages, newMessage.value]);

			// TODO : 방장이 메시지를 읽었으면 읽음 정보를 업데이트하는 API를 추가로 호출해 주어야 한다.
			//  그렇지 않으면 다른 디바이스에서 상태 업데이트가 되지 않는다.
			console.log('ownerId', roomData?.ownerData?.userId);
			console.log('userId', userId);

			// TODO : 로그인한 현재 유저가 방장인 경우 '방장이 메시지를 읽었다' 는 정보를 업데이트 함.
			//  하지만 콘솔에서 공개톡 질문방에 방장이 입장할 일이 없기 때문에 아래 코드는 실행되지 않는다. 이후 삭제처리 바람.
			if(roomData && roomData?.ownerData?.userId === userId) {
				console.log('read......')
				const body = {
					userId,
					since:newMessage?.value?.createdAt,
					until:newMessage?.value?.createdAt,
				}
				ownerMessageReadService(newMessage?.value?.roomId, body).then(r => {
					console.log('rs', r)
				})
			}

		}

		// Preview link
		if (
			newMessage &&
			newMessage.value &&
			props.user.id === newMessage?.value?.senderId &&
			newMessage.value.roomId === props.roomId &&
			newMessage.value.msgType === 'PREVIEW'
		) {
			// Preview text
			setMessages([...messages, newMessage.value]);
		}

		// Set message id => my message
		if (
			senderHashCode === newMessage?.value?.hashCode && // 이 채팅방에서 내가 보낸 메시지
			newMessage?.value?.roomId === props.roomId &&
			newMessage.value.msgType !== 'PREVIEW'
		) {
			let newArr = findMessage(messages, newMessage?.value);
			if (newArr) setMessages(newArr);
		}

		if (newMessage?.value?.senderId === props.room.ownerId) {
			// Owner seen
			setOwnerSeen(true);
		}

		function winnerFunction() {
			setWinnerMessage(newMessage.value.message);
			setWinnerModal(true);
			setWinnerTargetUserId(newMessage.value.targetId);

			const timeId = setInterval(() => {
				setWinnerModal(false);
				clearTimeout(timeId);
			}, 3000);
		}
	}, [newMessage]);

	// Room reset data function
	useEffect(() => {
		resetRoomDataFunction();
	}, [props.roomId]);

	// Owner seen action and flying plane
	/*useEffect(() => {
		if (ownerSeen && unReadOwnerMsgCount > 0)
			setTimeout(function () {
				//setOwnerSeen(false);
				//setUnReadOwnerMsgCount(0);
			}, 3000);
	}, [ownerSeen, unReadOwnerMsgCount]);*/

	// Chat box scrool to bottom
	useEffect(() => {
		const scrollToBottom = () => {
			if (messageBox) {
				messageBox.scrollTop = messageBox.scrollHeight + 1000;
				
				setTimeout(() => {
					const container = document.querySelector('.chatScroll');
					if(container) {
						container.scrollTop = container.scrollHeight + 1000;
					}
				}, 100);
			}
			
		};
		if (!isEmpty(messageBox)) scrollToBottom();
	}, [messages, messageBox]);

	const resetRoomDataFunction = () => {
		setReportScreen(false);
		setIsBlockModal(false);
		setMessageClickAction();
	};

	// Send new message
	const addMessage = async (messsage) => {
		let meHashCode = Date.now().toString();
		setSenderHashCode(meHashCode);
		let datas = await addMessageFunction(meHashCode, props, 'TEXT', messsage);
		if (!datas.status) {
			toast.error(datas.message);
			return;
		}
		setcurMessage('');
		setMessages([...messages, datas.message]);
		// scrollActivateControl(true)
		setIsMessageSend(()=>{return true})
	};

	// File upload
	const selectFileAction = async (event) => {
		setLoadingFile(true);
		let meHashCode = Date.now().toString();
		setSenderHashCode(meHashCode);
		let datas = await selectFileFunction(meHashCode, props, event);
		setLoadingFile(false);
		if (!datas.status) {
			toast.error(datas.message);
			return;
		}
		setMessages([...messages, datas.message]);
		// scrollActivateControl(true)
		setIsMessageSend(()=>{return true})
	};

	// Enter keyboard action
	const onKeyPress = (e) => {
		const {key} = e;
		if (e.shiftKey) {
		} else if (key === 'Enter') {
			if (e.target.value && e.target.value !== '' && e.target.value !== '\n') {
				addMessage(curMessage);
			} else {
				setcurMessage('');
				e.preventDefault();
			}
		}
	};

	// check shift enter chat message
	const checkText = (text) => {
		if(!text || text.indexOf('\n') === -1) return text;
		return text.split('\n').map((item, i) => (
			<div style={{textAlign: 'left', clear:'both'}} className="" key={i}>
				{item}
			</div>
		));
	};

	const checkVideoUploading = async (fileOrigin, jobId) => {
		setLoadingVideo(true);
		setSelectedVideoId(jobId);
		console.warn(fileOrigin)
		// const response = await checkVideoUploadingService(jobId);
		// if (response === 0) {
		// TODO : message obj 안에 jobId가 없기 때문에 jobId를 이용한 Media Convert status 를 체크할 수 없다.
		//  API 를 변경하여 jobId 가 전달되는 경우 해당 message 의 hashCode 를 jobId 값으로 대체하여 저장하는 것은 어떤지 검토 필요.

		// TODO: 다시 jobId를 이용한 로직으로 변경
		try {
			const response = !jobId || await checkVideoUploadingService(jobId).then(res => {
				const {status} = res
				switch(status) {
					case 'COMPLETE' : return true;
					case 'SUBMITTED': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
					case 'PROGRESSING': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
					case 'CANCELED': throw new Error('비디오 업로드가 취소되었습니다.')
					case 'ERROR': throw new Error('비디오 업로드에 실패하였습니다.')
					default: throw new Error('다시 한번 시도해 주세요.')
				}

			})
			if(response===true) {
				setTargetVideoAndImage({
					name: 'VIDEO',
					value: fileOrigin,
					jobId: jobId,
				});
				toggleRight(true);
			}
			// console.warn('response', response); //return;
			// if (response.status === 200) {
			// 	setTargetVideoAndImage({
			// 		name: 'VIDEO',
			// 		value: fileOrigin,
			// 		jobId: jobId,
			// 	});
			// 	toggleRight(true);
			// // } else if (response === 1) {
			// } else if (response.status === 403) {
			// 	// toast.info('This video uploading, please wait for a moment');
			// 	toast.info("원활한 영상 재생을 위한 준비 중입니다. 잠시 후 다시 시도해 주세요.")
			// } else {
			// 	// toast.error('Video cannot be played');
			// 	toast.error("요청하신 영상은 재생 가능한 상태가 아닙니다. 잠시 후 다시 시도해 주세요.")
			// }
		} catch (err) {
			toast.error(`${err}`)
		} finally {
			setLoadingVideo(false);
		}
	};

	const onEmojiClick = (event, emojiObject) => {
		let newEmoji = curMessage + emojiObject.emoji;
		setcurMessage(newEmoji);
	};

	// Emoji action
	const showEmojeContent = (condition, self) => {
		setIsShowEmoji(self ? !isShowEmoji : condition);
	};

	// Message text Delete and copy action
	const getModalCopyAndDelete = (condition, messageId, copy) => {
		if (copy) {
			setMessageClickAction({
				copyAction: true,
				action: false,
				messageId: messageId,
				myMessage: true,
			});
		} else if (condition) {
			setMessageClickAction({
				action: true,
				messageId: messageId,
				myMessage: true,
			});
		} else {
			setMessageClickAction({
				action: true,
				messageId: messageId,
				myMessage: false,
			});
		}
	};

	// Copy text action
	const copyTextAction = (message) => {
		resetRoomDataFunction();
		setCopyText(message);
	};

	const propsBody = {
		getModalCopyAndDelete,
		messageClickAction,
		setTargetMessageId,
		setIsOnlyOneMesageDeleteModal,
		scrollActivateControl,
		checkVideoUploading,
		loadingVideo,
		selectedVideoId,
		setTargetVideoAndImage,
		checkText,
		rateCheck,
		messages
	}

	return (
		<React.Fragment>
			<div className="chat_room">
				{/* {loadimg ? (
					<Spinner type="grow" color="warning"/>
				) : ( */}
				{loadimg && <Spinner type="grow" color="warning"/> }
					<div className="row">
						<div
							className={
								settingsMenu ? 'col-lg-9 chat_box' : 'col-lg-12 chat_box'
							}
						>
							{/* Start header buttons  -> setting button & open profile & room time */}
							<ChatHeader
								{...props}
								timer={timer}
								isOwner={isOwner}
								ownerData={ownerData}
								setOwnerData={(newData) => setRoomOwnerData(newData)}
								settingsMenu={() => {
									setSettingsMenu(!settingsMenu);
									showEmojeContent(false);
								}}
							/>
							{/* End header buttons  -> setting button & open profile & room time */}
							<div className="chat_windows">
								{winnerModal && <WinnerModal winnerMessage={winnerMessage}/>}
								<ul
									className="mb-0"
									style={{
										height: deadline
											? 'calc(100vh - 320px)'
											: 'calc(100vh - 220px)',
									}}
									onClick={() => {
										setMessageClickAction();
										showEmojeContent(false);
									}}
									onContextMenu={() => {
										setClickAvatar(false);
										showEmojeContent(false);
									}}
								>
									{/* start chat list */}
									{/* <PerfectScrollbar className="chatScroll" containerRef={(ref) => setMessageBox(ref)}> */}
									<div ref={messagesStartRef} id="hereistop" />
										{messages &&
											map(messages, (message, index) => (
												<div style={{margin:'30px 0'}} key={message.messageId} ref={(el) => {
														setMessageRefs(message?.messageId, el);
													}}>
												{checkTime(message.createdAt)}
												<div key={message.messageId}>
												<li
													// key={index}
													key={message.messageId}
													className={
														message.senderId === props.user.id &&
														message.msgType !== 'INFO'
															? 'my-message'
															: message.msgType === 'INFO'
																? 'system-style'
																: isOwner && message.senderId === 'BOT'
																	? 'my-message'
																	: 'other-message'
													}
												>
													{/* Chat profile image */}
													{message.senderId !== 'BOT' &&
														message.senderId !== props.user.id &&
														message.senderId !== 'SYSTEM' &&
														message.msgType !== 'INFO' && (
															<div className="avatar mr-3 mt-2">
																{clickAvatar &&
																	selectedAvatarMsgId === message.messageId && (
																		<div
																			className="tool_tip"
																			style={message.messageId === messages[0].messageId ? {top:'35px'} : {}}
																		>
																			{/* 콘솔 공개톡은 내가 방장인 경우가 없음 */}
																			{/* {isOwner && (
																				<button
																					onClick={() => {
																						setTargetUserId(message.senderId);
																						setTargetUserName(
																							message.senderName,
																						);
																						setIsBlockModal(true);
																						setClickAvatar(false);
																					}}
																				>
																					<i className="fas fa-copy"></i>
																					<p>내보내기 </p>
																				</button>
																			)} */}
																			<button
																				onClick={() => {
																					setTargetUserId(message.senderId);
																					setSettingsMenu(true);
																					setReportScreen(true);
																					setClickAvatar(false);
																				}}
																			>
																				<i className="fas fa-trash-alt"></i>
																				<p>신고하기 </p>
																			</button>
																		</div>
																	)}
																<img
																	// id="TooltipExample"
																	onClick={() => {
																		if(clickAvatar === false) {
																			setSelectedAvatarMsgId(message.messageId);
																			setClickAvatar(true);
																		} else {
																			setSelectedAvatarMsgId('');
																			setClickAvatar(false);
																		}
																	}}
																	className="avatar"
																	rel="noreferrer"
																	src={
																		message.senderImg === '<null>' ||
																		!message.senderImg
																			? profile
																			: message.senderImg
																	}
																	alt="avatar"
																/>
															</div>
														)}
													{/* Start show message image & video & voice */}
													<div className="message">
														{/* Preview component */}
														{message.previewData && (
															<Preview message={message}/>
														)}
														{/* KMS add */}
														{(message.msgType !== 'INFO' && !message.previewData && (props.user.id !== message.senderId))?(
															<div className="sendrName">				
																{message.senderName === undefined || message.senderName ==='' ?getUserName(message.senderId):message.senderName}
															</div>
														):''}
														{message.msgType === 'INFO' && 
														(message.action !== 'banText' || 'purchase' || 'transfer') ? (
															<InfoMessage message={message} propsBody={propsBody} {...props} />
														) : !message.previewData &&
														message.msgType === 'TEXT' ? (
															<TextMessage message={message} propsBody={propsBody} {...props} />
														) : message.msgType === 'PHOTO' ? (
															<PhotoMessage message={message} propsBody={propsBody} {...props} />
														) : message.msgType === 'VIDEO' ? (
															<VideoMessage message={message} propsBody={propsBody} {...props} />
														) : message.msgType === 'VOICE' ? (
															<VoiceMessage message={message} propsBody={propsBody} {...props} />
														) : (
															// FILE component
															message.msgType === 'FILE' && (
																<div>
																	<div
																		onContextMenu={(e) => {
																			e.preventDefault();
																			getModalCopyAndDelete(
																				message.senderId === props.user.id,
																				message.messageId,
																			);
																		}}
																	>
																		<File scrollActivateControl={scrollActivateControl} message={message}/>
																	</div>
																	{messageClickAction?.action &&
																	messageClickAction?.messageId ===
																	message.messageId && (
																		<div
																			className="tool_tip"
																			style={message.messageId === messages[0].messageId ? {top:'35px'} : {}}
																		>
																			{/* <button
																				onClick={() => {
																					copyTextAction(message.message);
																				}}
																			>
																				<i className="fas fa-share-square"></i>
																				<p>copy </p>
																			</button> */}
																			
																			{messageClickAction?.myMessage && (
																				<button
																					onClick={() => {
																						setTargetMessageId(
																							message.messageId,
																						);
																						setIsOnlyOneMesageDeleteModal(true);
																					}}
																				>
																					<i className="fas fa-copy"></i>
																					<p>delete </p>
																				</button>
																			)}
																		</div>
																	)}
																</div>
															)
														)}

														{/* Start message time */}
														{message.senderId !== 'SYSTEM' &&
															message.msgType !== 'INFO' && (
																<span className="time">
																	{Number(moment(message.createdAt).format('HH')) < 12 ? "오전 " : "오후 "}
																	{moment(message.createdAt).format('hh:mm')}
																	{!(message?.createdAt && lastReadMsgTime &&
																			new Date(message?.createdAt).getTime() <= new Date(lastReadMsgTime).getTime()) &&
																		ownerData?.userId !== props.user?.id && // 방장인 경우 표시 안함
																		ownerData?.userId !== message.senderId && // 방장이 아닌 모든 이의 메시지에 표시함
																		!(message.read && message.read >= 1) && (
																			<img
																				src={plane}
																				className="img-thumbnail"
																				style={{
																					height: '14px',
																					weight: '14px',
																					backgroundColor: 'white',
																					border: '0px',
																				}}
																				alt=""
																			/>
																	)}
                               								 </span>
															)}
														{/* End message time */}
													</div>
													{/* End show message image & video & voice */}
												</li>
												</div>
												</div>
											))}
										<LoadingComponent scrollActivateControl={scrollActivateControl} loadingFile={loadingFile} />
										<div ref={messagesEndRef} id="hereisbottom" />
										<Fade in={!isBottomShow}>
											{isBottomShow === false && (
												<Button
													className={`scroll-bottom-button ${unShowMessageCount > 0 ? 'display-count' : ''}`}
													onClick={() => {scrollActivateControl(true, 'smooth')}}
												>
													<div className="unseen-count">{unShowMessageCount}</div>
													<i className="fa fa-chevron-down" />
												</Button>
											)}
										</Fade>
									{/* </PerfectScrollbar> */}
									{/* end chat list */}
								</ul>
								{/* start message sent input and attachments file & emoji  */}
								{deadline && (
									<div className="chat-message clearfix bg-white">
										{/* attachments file  */}
										<button
											onClick={() => {
												inputRef.current.click();
												showEmojeContent(false);
											}}
											className="b-none bg-none "
										>
											<form>
												<input
													className="d-none"
													type="file"
													id="selected-file"
													name="attachments"
													ref={inputRef}
													onChange={selectFileAction}
												/>
												<i className="icon-paper-clip icon-color"></i>
											</form>
										</button>
										{/* emoji icon */}
										<button
											onClick={() => showEmojeContent(true, true)}
											className="b-none bg-none "
										>
											<i className="icon-emoticon-smile icon-color"></i>
										</button>
										{isShowEmoji && (
											<Picker
												id="emojiPicker"
												preload={true}
												groupVisibility={{
													flags: false,
												}}
												onEmojiClick={onEmojiClick}
											/>
										)}

										{/* Message input */}
										<div className="input-group mb-0 mt-2">
											{messageClickAction?.copyAction && (
												<div className="tool_tip">
													<button
														onClick={() => {
															resetRoomDataFunction();
															copyText && setcurMessage(copyText);
														}}
													>
														<i className="fas fa-copy"></i>
														<p>paste</p>
													</button>
												</div>
											)}
											<Input
												ref={textAreaRef}
												type="textarea"
												rows={1}
												id="sent-chat-area"
												className="form-control bgChatText mt-0"
												placeholder="내용을 입력해주세요."
												//onContextMenu={() => {
												onContextMenu={(e) => {
													e.preventDefault();
													getModalCopyAndDelete(false, '', true);
												}}
												value={curMessage}
												onKeyPress={onKeyPress}
												onChange={(e) => {
													showEmojeContent(false);
													const {key} = e;
													if (key !== 'Enter') {
														setcurMessage(e.target.value);
													}
												}}
											/>
											<span className="input-group-append">
												<button
													onClick={() => addMessage(curMessage)}
													className="btn btn-secondary"
													type="button"
												>
												보내기
												</button>
											</span>
										</div>
									</div>
								)}
								{/* end chat input and attachments file & emoji  */}
							</div>
						</div>
						{/* Start Setting right menu  */}
						{settingsMenu && (
							<SettingsSideBar
								{...props}
								winnerTargetUserId={winnerTargetUserId}
								ownerData={ownerData}
								isOwner={isOwner}
								targetUserId={targetUserId}
								reportScreen={reportScreen}
								targetUserName={targetUserName}
								hideSettingsBar={() => setSettingsMenu(false)}
								hideReportBar={() => {
									setSettingsMenu(false);
									setReportScreen(false);
								}}
							/>
						)}
						{/* End Setting right menu  */}
						{/* Start Modals */}
						<BlockModal
							{...props}
							isBlockModal={isBlockModal}
							userId={ownerData?.userId}
							userName={ownerData?.userName}
							targetId={targetUserId}
							targetName={targetUserName}
							exitModal={() => {
								setTargetUserId();
								setTargetUserName();
								setIsBlockModal(false);
							}}
						/>

						<KickRoomModal
							{...props}
							kickRoomModal={kickRoomModal}
							kickRoom={() => {
								setKickRoomModal(false);
								props.onChange();
							}}
						/>

						<DeleteMessageModal
							{...props}
							messages={messages}
							targetMessageId={targetMessageId}
							isOnlyOneMesageDeleteModal={isOnlyOneMesageDeleteModal}
							refreshMessage={(data) => setMessages(data)}
							exitModal={() => {
								resetRoomDataFunction();
								setIsOnlyOneMesageDeleteModal(false);
							}}
						/>

						{/* End Modals */}
					</div>
				{/* )} */}
				<RightMenuContent
					targetVideoAndImage={targetVideoAndImage}
					menuValue={'DetailView'}
				/>
			</div>
		</React.Fragment>
	);
};

const LoadingComponent = (props) => {
	const {scrollActivateControl, loadingFile} = props
	useEffect(() => {
		scrollActivateControl()
	} ,[loadingFile])
	return (
		<>
			{loadingFile && <Spinner type="grow" color="warning"/>}
		</>
	)
}

const InfoMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div>
			{message.action === 'pick' && (
				<img
					className="media-object"
					src={so_select}
					alt=""
					style={{width: '100px'}}
				/>
			)}
			<div className="bg-light-blue msg-tag ln-msg">
				{propsBody.checkText(message.message)}
			</div>
		</div>
	)
}
const TextMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div  className="chatWindow"
			onContextMenu={(e) => {
				e.preventDefault();
				propsBody.getModalCopyAndDelete(
					message.senderId === props.user.id,
					message.messageId,
				);
			}}
			
		>
			{propsBody.messageClickAction?.action &&
				propsBody.messageClickAction?.messageId ===
				message.messageId && (
					<div
						className="tool_tip"
						style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
					>
						{/* <button
							onClick={() => {
								copyTextAction(message.message);
							}}
						>
							<i className="fas fa-share-square"></i>
							<p>copy </p>
						</button> */}
						
						{propsBody.messageClickAction?.myMessage && (
							<button
								onClick={() => {
									propsBody.setTargetMessageId(
										message.messageId,
									);
									propsBody.setIsOnlyOneMesageDeleteModal(true);
								}}
							>
								<i className="fas fa-copy"></i>
								<p>delete </p>
							</button>
						)}
					</div>
				)}
			<div className="bg-light-blue msg-tag ln-msg">
				{propsBody.checkText(message.message)}
			</div>
			{/* 따봉표시 */}
			{message.rate && (
				<div>
					<img src={propsBody.rateCheck(message.rate)} alt="" />
				</div>
			)}
		</div>
	)
}
const VideoMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div 
		onContextMenu={(e) => {
			e.preventDefault();
			propsBody.getModalCopyAndDelete(
				message.senderId === props.user.id,
				message.messageId,
			);
		}}
		>
			{propsBody.messageClickAction?.action &&
					propsBody.messageClickAction?.messageId ===
					message.messageId && (
						<div
							className="tool_tip"
							style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
						>
							{/* <button
								onClick={() => {
									copyTextAction(message.message);
								}}
							>
								<i className="fas fa-share-square"></i>
								<p>copy </p>
							</button> */}
							
							{propsBody.messageClickAction?.myMessage && (
								<button
									onClick={() => {
										propsBody.setTargetMessageId(
											message.messageId,
										);
										propsBody.setIsOnlyOneMesageDeleteModal(true);
									}}
								>
									<i className="fas fa-copy"></i>
									<p>delete </p>
								</button>
							)}
						</div>
					)}
			<div
				className="media_img media-message"
				onClick={() => {
					propsBody.checkVideoUploading(
						message.fileOrigin,
						message.jobId,
					);
				}}
				style={{boxSizing:"content-box", aspectRatio: 1 / (message.imgRatio || 1)}}
			>

				<div className="video_container" >
					<img
						src={message.message}
						className="img-thumbnails"
						alt=""
						onLoad={()=>{propsBody.scrollActivateControl()}}
						style={{objectFit:'cover', backgroundColor: '#ebebeb'}}
					/>
					<i className="fas fa-play-circle video">
						{propsBody.loadingVideo &&
							propsBody.selectedVideoId === message.jobId && (
								<Spinner
									animation="border"
									variant="primary"
								/>
							)}
					</i>
				</div>
			</div>
		</div>
	)
}
const PhotoMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div className="media_img media-message" style={{boxSizing:"content-box", aspectRatio: 1 / (message.imgRatio || 1)}}>
			<img
				onClick={() => {
					propsBody.setTargetVideoAndImage({
						name: 'PHOTO',
						value: message.message,
					});
					toggleRight(true);
				}}
				onLoad={()=>{propsBody.scrollActivateControl()}}
				onContextMenu={(e) => {
					e.preventDefault();
					propsBody.getModalCopyAndDelete(
						message.senderId === props.user.id,
						message.messageId,
					);
				}}
				style={{objectFit:'cover', backgroundColor: '#ebebeb'}}
				src={message.message}
				className="img-thumbnails"
				alt=""
			/>
			{propsBody.messageClickAction?.action &&
				propsBody.messageClickAction?.messageId ===
				message.messageId && (
					<div
						className="tool_tip"
						style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
					>
						{/* <button
							onClick={() => {
								copyTextAction(message.message);
							}}
						>
							<i className="fas fa-share-square"></i>
							<p>copy </p>
						</button> */}
						
						{propsBody.messageClickAction?.myMessage && (
							<button
								onClick={() => {
									propsBody.setTargetMessageId(
										message.messageId,
									);
									propsBody.setIsOnlyOneMesageDeleteModal(true);
								}}
							>
								<i className="fas fa-copy"></i>
								<p>delete </p>
							</button>
						)}
					</div>
				)}
		</div>
	)
}
const VoiceMessage = (props) => {
	const {
		message,
		propsBody,
	} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div>
			<div 
				onContextMenu={(e) => {
				e.preventDefault();
				propsBody.getModalCopyAndDelete(
					message.senderId === props.user.id,
					message.messageId,
				);
			}}
			>
				{propsBody.messageClickAction?.action &&
				propsBody.messageClickAction?.messageId ===
				message.messageId && (
					<div
						className="tool_tip"
						style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
					>
						{/* <button
							onClick={() => {
								copyTextAction(message.message);
							}}
						>
							<i className="fas fa-share-square"></i>
							<p>copy </p>
						</button> */}
						
						{propsBody.messageClickAction?.myMessage && (
							<button
								onClick={() => {
									propsBody.setTargetMessageId(
										message.messageId,
									);
									propsBody.setIsOnlyOneMesageDeleteModal(true);
								}}
							>
								<i className="fas fa-copy"></i>
								<p>delete </p>
							</button>
						)}
					</div>
				)}
				<ReactPlayer
					width={300}
					height={60}
					url={message.fileOrigin}
					playing={false}
					controls={true}
					config={{
						file: {
							attributes: {
								controlsList: 'nodownload',
							},
						},
					}}
				/>
			</div>
		</div>
	)
}

export default withRouter(withTranslation()(ChatBox));
