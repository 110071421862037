import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import {
	Container,
	Card,
	CardBody,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	Button,
	TabContent,
	TabPane,
	Table,
} from 'reactstrap';
import {getUserDepositProductHistory as getData} from '../../services/user.deposit.service';
import moment from 'moment';
import { authService } from '../../services/auth.service';

const CalculateList = (props) => {
	//meta title
	const [customActiveTab, setcustomActiveTab] = useState('1');
	const [incomeList, setIncomeList] = useState([]);
	const [outgoingList, setOutgoingList] = useState([]);
	const [incomeOffset, setIncomeOffset] = useState(0);
	const [outgoingOffset, setOutgoingOffset] = useState(0);
    const [isRetoken, setIsRetoken] = useState("")

	// const userId = '04e9860a-14a5-4cab-bfaa-f94b82c19673' // TODO : 테스트용 임시 아이디 적용
	// const userId = localStorage.getItem("user_id")
	const getIncomeList = async () => {
		try {
			const response  = await authService.getUserId()
			if(response.status===201) {
				const userId = response.data.user_id
				const result = await getData(userId, 'purchase', 0, 10);
				console.log('result', result);
				setIncomeList(result);
			}
		} catch(err) {
		}
	};
	const getOutgoingList = async () => {
		try {
			const response  = await authService.getUserId()
			if(response.status===201){
				const userId = response.data.user_id
				const result = await getData(userId, 'sale', 0, 10);
				console.log('result', result);
				setOutgoingList(result);
			}
		} catch(err) {
		}
	};
	useEffect(() => {
		const getId = async () => {
			const response = await authService.getACToken()
			if(response.status===201){
				setIsRetoken(()=>{return response.status})
			} else {
				return await authService.logout()
			}
		}
		getId().then(r=>r)
		getIncomeList().then(r => r);
		getOutgoingList().then(r => r);
	}, [isRetoken]);

	const toggleCustom = (tab) => {
		if (customActiveTab !== tab) {
			setcustomActiveTab(tab);
		}
	};

	document.title = 'Flowing';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<div className="section-body">
					<Container fluid>
						<div className="row">
							<div className="col-12">
								<div className="page-title-box d-sm-flex align-items-center justify-content-between">
									<h4 className="mb-0 font-size-18">정산 내역 조회</h4>
								</div>
							</div>
						</div>
						<Row className="clearfix">
							<Col lg={12} className="text-center">
								<Card>
									<CardBody>
										<div className="d-md-flex justify-content-between">
											<Nav tabs className="nav nav-tabs b-none">
												<NavItem>
													<NavLink
														style={{cursor: 'pointer'}}
														className={classnames({
															active: customActiveTab === '1',
														})}
														onClick={() => {
															toggleCustom('1');
														}}
													>
														수익
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														style={{cursor: 'pointer'}}
														className={classnames({
															active: customActiveTab === '2',
														})}
														onClick={() => {
															toggleCustom('2');
														}}
													>
														사용
													</NavLink>
												</NavItem>
											</Nav>
										</div>
										{/*<div className="mt-4 input-group">
											<input
												type={'text'}
												className="form-control"
												// placeholder="Please enter your nickname or payment item"
												placeholder="닉네임 또는 결제 항목을 입력하세요"
											/>
											<span className="input-group-append">
												<Button className="btn btn-primary">
													<i className="icon-magnifier"></i>
												</Button>
											</span>
										</div>*/}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="section-body">
					<Container fluid>
						<TabContent activeTab={customActiveTab} className="text-muted">
							<TabPane tabId="1">
								<Row>
									<Col sm="12">
										<div className="table-responsive">
											<Table
												className="table table-hover table-vcenter text-nowrap table_custom list">
												<tbody>
												<tr>
													<th>번호</th>
													{/* <th>nickname</th>
													<th className="hidden-xs">time of occurrence</th>
													<th>Payment items</th>
													<th>payment goods</th>
													<th>price</th> */}
													{/*<th>닉네임</th>*/}
													<th className="hidden-xs">발생시간</th>
													<th>결제항목</th>
													<th>결제재화</th>
													<th>가격</th>
													<th>환불여부</th>
												</tr>
												</tbody>
												<tbody>
												{outgoingList.map((item, index) => {
													return (
														<tr
															// key={item.id}
															key={item.created_at}
															id={item.id}
															/*onClick={handleClick}*/
														>
														<td className="text-center width40 hidden-xs">
															<div className="text-muted">{incomeOffset + 1 + index}</div>
														</td>
														{/*<td>
															<div className="text-muted">닉네임즈</div>
														</td>*/}
														<td className="hidden-xs">
															<div className="text-muted">
																{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
															</div>
														</td>
														<td>
															<div className="text-muted">
																{/* answer talk product */}
																{item.description}
															</div>
														</td>
														<td>
															<div className="text-muted">{item.deposit_type === 'ruby' ? '루비' : '다이아'}</div>
														</td>
														<td>
															{/*<div className="text-muted">{new Intl.NumberFormat('ko-KR').format(item.deposit_amount * 100)}</div>*/}
															<div className="text-muted">{new Intl.NumberFormat('ko-KR').format(item.deposit_amount * 100)} 원</div>
														</td>
														<td>
															<div className="text-muted">{item.is_canceled === 'Y' ? '환불' : ''}</div>
														</td>
													</tr>
													/*<tr>
														<td colspan="6">
															<p className="noResult">
																{props.t('No matching questions found')}
															</p>
														</td>
													</tr>*/
													);
												})}
												</tbody>
											</Table>
											{/* <nav aria-label="Page navigation example">
												<ul className="pagination justify-content-center">
													<li className="page-item disabled"><a className="page-link" href="#">First</a></li>
													<li className="page-item disabled">
														<a className="page-link" href="#" aria-label="Previous">
															<span aria-hidden="true">«</span>
															<span className="sr-only">Previous</span>
														</a>
													</li>
													<li className="page-item active"><a className="page-link" href="#">1</a></li>
													<li className="page-item"><a className="page-link" href="#">2</a></li>
													<li className="page-item"><a className="page-link" href="#">3</a></li>
													<li className="page-item"><a className="page-link" href="#">4</a></li>
													<li className="page-item"><a className="page-link" href="#">5</a></li>
													<li className="page-item">
														<a className="page-link" href="#" aria-label="Next">
															<span aria-hidden="true">»</span>
															<span className="sr-only">Next</span>
														</a>
													</li>
													<li className="page-item"><a className="page-link" href="#">Last</a></li>
												</ul>
											</nav> */}
										</div>
									</Col>
								</Row>
							</TabPane>
							<TabPane tabId="2">
								<Row>
									<Col sm="12">
										<div className="table-responsive">
											<Table
												className="table table-hover table-vcenter text-nowrap table_custom list">
												<tbody>
												<tr>
													<th>번호</th>
													{/*<th>{props.t('seller nickname')}</th>*/}
													<th className="hidden-xs">발생시간</th>
													<th>결제항목</th>
													<th>결제재화</th>
													<th>가격</th>
													<th>환불여부</th>
												</tr>
												</tbody>
												<tbody>
												{incomeList.map((item, index) => {
													return (
														<tr
															// key={item.id}
															key={item.created_at}
															id={item.id}
															/*onClick={handleClick}*/
														>
															<td className="text-center width40 hidden-xs">
																<div className="text-muted">{outgoingOffset + 1 + index}</div>
															</td>
															{/*<td>
																<div className="text-muted">Nicknames</div>
															</td>*/}
															<td className="hidden-xs">
																<div className="text-muted">
																	{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}
																</div>
															</td>
															<td>
																<div className="text-muted">
																	{item.description}
																</div>
															</td>
															<td>
																<div className="text-muted">{item.deposit_type === 'ruby' ? '루비' : '다이아'}</div>
															</td>
															<td>
																<div className="text-muted">{new Intl.NumberFormat('ko-KR').format(item.deposit_amount * 100)} 원</div>
															</td>
															<td>
																<div className="text-muted">{item.is_canceled === 'Y' ? '환불' : ''}</div>
															</td>
														</tr>
														/*<tr>
															<td colspan="6">
																<p className="noResult">
																	{props.t('No matching questions found')}
																</p>
															</td>
														</tr>*/
													);
												})}
												</tbody>
											</Table>
										</div>
									</Col>
								</Row>
							</TabPane>
						</TabContent>
					</Container>
				</div>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(CalculateList));

CalculateList.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
