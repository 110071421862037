import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';

import HeaderTypeText from './HeaderTypeText';
import HeaderTypeCustom from './HeaderTypeCustom';
import {menuLabel} from '../../../constants/options';

//post forbidden word API's
const SelectMenuOrderMenu = (props) => {
    const menuOrder = props.menuOrder.filter(item => item !== '답변톡' && item !== '상품');
    function menuUp(index){
        if(index <= 0) return;
        let tmpMenuArray = [...menuOrder];
        let tmp = tmpMenuArray[index];
        tmpMenuArray[index] = tmpMenuArray[index -1];
        tmpMenuArray[index-1] = tmp;
        props.setMenuOrder(tmpMenuArray);
    }

    function menuDown(index){
        if(index >= 2) return;
        let tmpMenuArray = [...menuOrder];
        let tmp = tmpMenuArray[index];
        tmpMenuArray[index] = tmpMenuArray[index +1];
        tmpMenuArray[index+1] = tmp;
        props.setMenuOrder(tmpMenuArray);
    }

	return (
        <>
            <div className="col-lg-12">
                <p className="stepTit">5. 노출 순서를 변경하실 수 있습니다.</p>
            </div>
            <div className="col-lg-6">
                <div className="custom-controls">
                    <div className="casePage">
                        {menuOrder.map((menu,index) => (
                            <div className="sbox2"
                                 key={menu}
                            >
                                {menuLabel[menu]}
                                <div className="controlBox">
                                    <span className="btnUp" onClick={()=>{
                                        menuUp(index);
                                        props.setIsChange(true)
                                    }}><i className="fe fe-chevron-up"></i></span>
                                    <span className="btnDown" onClick={()=>{
                                        menuDown(index);
                                        props.setIsChange(true)
                                    }}><i className="fe fe-chevron-down"></i></span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
	);
};
export default withRouter(withTranslation()(SelectMenuOrderMenu));

SelectMenuOrderMenu.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
