import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import CustomTab from './CustomTab';
import CustomPage from './CustomPage';

//post forbidden word API's
const HeaderTypeCustom = (props) => {

	return (
        <>
            {props.tabId !=='5' && props.designType === 'tab'?
                (
                <>
                    <div className="col-lg-6 text-center">
                    </div>
                    <CustomTab {...props} />
                    <CustomTab {...props} customBgColor={"_w"} />
                </>
                )
            :props.tabId !=='5' && props.designType === 'page'?
                (
                <>
                    <div className="col-lg-6 text-center">
                    </div>
                    <CustomPage {...props} />
                    <CustomPage {...props} customBgColor={"_w"} />
                </>
                )
            :props.tabId ==='5' && props.designType === 'tab'?
                // <CustomTab {...props} customBgColor={props.mobileBgColor[1] ==='white'?"_w":""} />
                <CustomTab {...props} customBgColor={props.mobileCustomBgColor ==='white'?"_w":""} />
            :props.tabId ==='5' && props.designType === 'page'?
                // <CustomPage {...props} customBgColor={props.mobileBgColor[1] ==='white'?"_w":""}/>
                <CustomPage {...props} customBgColor={props.mobileCustomBgColor ==='white'?"_w":""}/>
            :''
            }

        </>
				
	);
};
export default withRouter(withTranslation()(HeaderTypeCustom));

HeaderTypeCustom.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
