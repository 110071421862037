import React, {useEffect, useState, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {
	CardBody,
	Card,
	Alert,
	Input,
	Form,
	InputGroup,
	FormGroup,
	CardFooter,
	Button,
} from 'reactstrap';
import {uploadImageService} from '../../services/talk2meMessage.service';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {withRouter, Link} from 'react-router-dom';
import {toast} from 'react-toastify';

import logoFoot from '../../assets/images/logo_landingFoot.png';
import logoImg from '../../assets/images/userbg.jpg';

import i18n from '../../i18n';

//import auth services
import {authService} from '../../services/auth.service';

const Register = (props) => {
	// const [phoneNumber, setPhoneNumber] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [authCode, setAuthCode] = useState('');
	const [authCodeTime, setAuthCodeTime] = useState('')
	const [authCodeTimeIn, setAuthCodeTimeIn] = useState(false)
	const [ref_url, setRefUrl] = useState('');
	const [resultAuthCode, setResultAuthCode] = useState(false);
	const [loadingFile, setLoadingFile] = useState();
	const [userImg, setUserImg] = useState("");
	const inputRef = useRef(null);

	const [isAlert, setIsAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [isSignedUp, setIsSignedUp] = useState(false);

	const [password, setPassword] = useState('')
	const [passwordCheck, setPasswordCheck] = useState('')


    const secToMMSS = (sec) => {
        if (sec > 0) return `0${~~(sec / 60)}:${sec % 60<10 ? '0':''}${sec % 60}`
        else return '00:00'
    }
    useInterval(() => {
        setAuthCodeTime(authCodeTime - 1)
    }, authCodeTime > 0 ? 1000 : null)


	useEffect(()=>{
		if(authCodeTime < 1) setAuthCodeTimeIn(()=>false)
	}, [authCodeTime])
	//meta title
	document.title = 'Register | Flowing';

	//profile image upload
	const imageUpload = async (formData, fileSize, fileName) => {
		let msgType = 'PHOTO';
		formData.append('limitedImageSize', 1280);
		const imageResponse = await uploadImageService(formData);
		console.log('imageResponse', imageResponse)
		if (imageResponse.msg === 'OK') {
			setUserImg(imageResponse.url[0]);
		} else {
			toast.error(imageResponse.msg);
		}

		setLoadingFile(false);
	};

	const requestCodeFunction = async () => {
		// if (phoneNumber === null || phoneNumber === "") {
		// 	setAlertMessage("휴대폰 번호를 입력해 주세요");
		// 	setIsAlert(true);
		// }else{
		// 	setIsAlert(false);
		// 	const result = await authService.requestCode(phoneNumber);
		// 	console.log(result);
		// 	setAlertMessage(result);
		// 	setIsAlert(true);
		// }
		if(email.trim()==='') {
			setAlertMessage("이메일을 입력해 주세요");
			setIsAlert(true);
			return;
		}
		setIsAlert(()=>false)
		try {
			await authService.requestEmailCode({email}).then(r => r)
			setAlertMessage(() => '인증번호가 전송되었습니다.')
			setIsAlert(true);
            setAuthCodeTimeIn(()=>true)
            setAuthCodeTime(()=>180)
		} catch(error) {
            console.log(error)
			setAuthCodeTimeIn(()=>false)
            toast.error(error?.data?.place_holder)
            throw error
		}
		
	};
	const checkRequestedCodeFunction = async () => {
		setIsAlert(false);
		// const result = await authService.checkRequestedCode(
		// 	phoneNumber,
		// 	authCode,
		// 	ref_url,
		// );
		// if (result === 'sucess') {
		// 	setResultAuthCode(true);
			
		// 	setAlertMessage(i18n.t('Code submitted successfully'));
		// 	setIsAlert(true);
		// }else{
		// 	setAlertMessage(result);
		// 	setIsAlert(true);	
		// }
		if(authCode.trim()==='') {
			setAlertMessage(()=>'인증번호를 입력해 주세요')
			setIsAlert(true);
			return;
		}
        if (!authCodeTimeIn) {
            toast.error("인증시간이 지났습니다. 다시 인증을 받아주세요.")
            return
        }
		try {
			await authService.certificateEmailCode({email, auth_code: authCode}).then(r => r)
			setAlertMessage('인증되었습니다.')
			setIsAlert(()=>true)
			setAuthCodeTime(()=>0)
			setResultAuthCode(()=>true)
		} catch(error) {
            toast.error(error?.data?.place_holder || '다시 한번 시도해 주세요')
            throw error
		}
	};
	const signUpFunction = async () => {
		if (!resultAuthCode) {
			setAlertMessage("이메일 인증을 진행하세요.");
			setIsAlert(true);
			return;
		}
		if(name.trim()==='') {
			setAlertMessage("닉네임을 입력하세요");
			setIsAlert(true);
			return;
		}
		try {
			await authService.checkNameDuplicate(name).then(r => {
				if(r.status !== 204) throw r
				return r
			})
			await authService.newSignUp(
				{nickname: name, email, password, image_url: userImg | ''}
			).then(r => {
				if(r.status!==201) throw r
				return r
			})
			// props.history.push('/login');
			toast.success('회원가입 되었습니다. 로그인을 진행해 주세요')
		} catch(error) {
			setAlertMessage(error?.data?.place_holder||'다시 한번 시도해 주세요');
			setIsAlert(true);
		}
		// const checkedEmailResponse = await authService.checkEmailDuplicate(email);
		
		// if(checkedEmailResponse.status != "204" ){  //201 or 400
		// 	console.log("email", checkedNameResponse);
		// 	setAlertMessage(checkedEmailResponse.data.place_holder);
		// 	setIsAlert(true);
		// 	return;
		// }
		// setIsAlert(false);

		
		// //console.log(checkedNameResponse, 'checked name');
		// //console.log(checkedEmailResponse, 'checked email');
	
		// try {
		// 	//if (authCode == true) {
		// 	if (resultAuthCode == true) {
		// 		let result = await authService.signUp(
		// 			// phoneNumber,
		// 			name,
		// 			email,
		// 			userImg,
		// 			password,
		// 		);
		// 		if (result) {
		// 			// props.history.push('/login');
		// 			setIsSignedUp(true);
		// 		}
		// 	}
		// } catch (err) {
		// }
	};
	const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {
			setLoadingFile(true);

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					// toast.error('This file is larger than 200mb');
					toast.error('선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다.');
					setLoadingFile(false);
					return;
				}
			} else {
				if (originFileSize > 20) {
					// toast.error('This file is larger than 20mb');
					toast.error('선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다.');
					setLoadingFile(false);
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
			let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;
			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

			await imageUpload(formData, fileSize, fileName);
		}
	};

	return (
		<React.Fragment>
			<ToastContainer/>

			<div className="auth">
				<div className="logo">
					<Link to="/#">
						<img src={logoFoot} alt="logo"/>
					</Link>
				</div>
				<div className="auth_left">
					<Card>
						<div className="card-top d-flex">
							<div className="card-title">{props.t('Create your account')}</div>

							{/* {!isSignedUp && ( */}
								<div className="logo_profile ml-auto">
									<a
										onClick={() => {
											inputRef.current.click();
										}}
										className="b-none bg-none"
									>
										<form>
											<input
												type="file"
												id="input-file"
												accept='image/png, image/jpeg, image/jpg'
												className="d-none"
												ref={inputRef}
												onChange={selectFileFunction}
											/>

											<label className="Phonebutton">
												<i className="fe fe-camera"></i>
											</label>
										</form>
									</a>

									<img
										src={userImg || logoImg}
										alt=""
										className="img-fluid"
									/>
								</div>
							{/* )} */}
						</div>
						{/* {!isSignedUp ? ( */}
							<>
								<CardBody className="">
									{isAlert === true && (
										<Alert
											color="success"
											className="alert alert-icon alert-success fs12"
											role={alert}
										>
											<i className="fe fe-alert-triangle"></i>
											{props.t(alertMessage)}
										</Alert>
									)}
									{/* <Form> */}
									<FormGroup>
										<InputGroup>
											<Input
												id="email"
												name="email"
												className="form-control blackBox"
												placeholder='이메일을 입력하세요'
												// placeholder={props.t('Enter your mobile')}
												type="email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
											/>

											<span className="input-group-append">
										<button
											className="btn btn-primary widthAuto "
											type="button"
											onClick={requestCodeFunction}
										>
										  {props.t('Send code')}
										</button>
								  </span>
										</InputGroup>
									</FormGroup>
									<FormGroup>
										<InputGroup>
											<Input
												id="InputNumberCheck"
												name="InputNumberCheck"
												className="form-control blackBox"
												placeholder={props.t('Enter your verification code')}
												type="text"
												value={authCode}
												onChange={(e) => setAuthCode(e.target.value)}
											/>
											<span className="CountNum">{secToMMSS(authCodeTime)}</span>
											<span className="input-group-append">
												<button
													className="btn btn-primary widthAuto "
													type="button"
													onClick={checkRequestedCodeFunction}
												>
												{props.t('Confirm')}
												</button>
											</span>
										</InputGroup>
									</FormGroup>

									<FormGroup>
										<Input
											id="nickname"
											name="nickname"
											className="form-control blackBox"
											// placeholder={props.t(
											// 	'Please enter your nickname. (10 characters or less)',
											// )}
											placeholder='닉네임을 입력하세요(3~10자로 한글, 영어, 숫자만 사용 가능합니다.)'
											type="text"
											//maxLength={10}
											value={name}
											onChange={(e) => setName(e.target.value)}
										/>
									</FormGroup>

									<FormGroup>
										<Input
											name="password"
											type="password"
											// placeholder={props.t('Enter your email account')}
											placeholder='패스워드를 입력하세요'
											className="form-control blackBox"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										{password && !password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) && (
											<p style={{color:'red'}}>영문 대소문자, 숫자, 특수문자(!@#$%^&amp;*?) 4~20글자로 입력하세요.</p>
										)}
									</FormGroup>
									<FormGroup>
										<Input
											name="password"
											type="password"
											// placeholder={props.t('Enter your email account')}
											placeholder='패스워드를 다시 한번 입력하세요'
											className="form-control blackBox"
											value={passwordCheck}
											onChange={(e) => setPasswordCheck(e.target.value)}
										/>
										{passwordCheck && passwordCheck && passwordCheck !== password && (
											<p style={{color:'red'}}>비밀번호가 일치하지 않습니다.</p>
										)}
									</FormGroup>

									<div className="mt-3">
										<button
											className="btn btn-primary btn-block w-100 "
											disabled={
												resultAuthCode === true ? false :
												password.trim() === '' ? true :
												!password.match(/^[A-Za-z0-9!@#$%^&*?]{4,20}$/) ? true :
												passwordCheck.trim() === '' ? true :
												passwordCheck !== password ? true : false
											}
											type="submit"
											onClick={signUpFunction}
										>
											{props.t('Join')}
										</button>
									</div>
									{/* </Form> */}
								</CardBody>
								<CardFooter className="text-muted text-center">
									<p className="registerGuide">
										{props.t(
											'Set the password from the setup URL sent to the account email you entered.',
										)}
									</p>
									<p className="">
										{/*{props.t('I agree to the Flowing Terms.')}*/}
										<Link
											target="_blank"
											to={{pathname: 'https://terms.flowing.tel'}}
										>
											{props.t('Check the Terms and Conditions')}
										</Link>
									</p>
									<div className=" text-center mt-5">
										{props.t('Already have a flowing account?')}
										<Link to="/login" className="ml-1">
											{props.t('Login')}
										</Link>
									</div>
								</CardFooter>
							</>
						{/* ) : ( */}
							{/* <CardBody>
								<Alert color={'primary'} className={'d-flex'}>
									<div className="flex-grow-0 mr-4">
										<i className="fas fa-paper-plane" />
									</div>
									<div className="flex-grow-1">
										등록하신 이메일로 비밀번호 설정 메일이 전송되었습니다.<br/>
										전송된 이메일을 통해 비밀번호를 설정하시면 이메일 주소와 비밀번호를 통한 계정 로그인이 가능합니다.
									</div>
								</Alert>
								<div>
									<Button color={'primary'} block onClick={() => {props.history.push('/login')}}>
										로그인 하러가기
									</Button>
								</div>
							</CardBody>
						)} */}
					</Card>

					<p className="mt-5 Copyright">
						© {new Date().getFullYear()} Copyright Tpkorea Co.,Ltd.
					</p>
				</div>
				<div className="auth_right"></div>
			</div>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(Register));

Register.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};

function useInterval(callback, delay) {
    const savedCallback = useRef(callback)
  
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])
  
    useEffect(() => {
        if (!delay && delay !== 0) {
            return
        }
  
        const id = setInterval(() => savedCallback.current(), delay)
        return () => clearInterval(id)
    }, [delay])
}