/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';

const File = (props) => {
	useEffect(()=>{props.scrollActivateControl()}, [props.message])
	return (
		<React.Fragment>
			<a
				className="file-container"
				download
				target="_blank"
				href={props.message.fileOrigin}
				rel="noreferrer"
			>
				<div className="w50">
					<i className="fas fa-file-download"></i>
				</div>

				<span>
				<span>{props.message.message}</span>
				<span>Size: {props.message.fileSize}</span>
				</span>
			</a>
			
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(File));
