// import {del, get, post, put} from '../helpers/api_helper_chat';
// import {post as postApi} from '../helpers/api_helper_default';
// import {post as postApiImage} from '../helpers/api_helper_image';
// import {get as getApiProxy} from '../helpers/api_helper_3';

import {del, get, post, put} from '../helpers/api_helper';
import config from '../config/configuration';

export async function sendMessagesService(body, roomId) {
	try {
		return await post(`talk2you/messages/send`, body);
	} catch (err) {
		throw err;
	}
}

export async function getMessagesService(roomId, userId, since, until, limit, verbose) {
	try {
		// console.log('result', result);
		return await get(
			`talk2you/messages/${roomId}?userId=${userId}&since=${since}&until=${until}&limit=${limit}`, {}, verbose
		);
	} catch (err) {
		throw err;
	}
}

export async function getBlockedUsersService(roomId, userId) {
	try {
		return await get(`talk2you/rooms/${roomId}/users/blocked`);
	} catch (err) {
		throw err;
	}
}

export async function blockUserFromChatRoomService(roomId, body) {
	try {
		return await del(`/talk2you/rooms/${roomId}/send-off`, body);
	} catch (err) {
		throw err;
	}
}

export async function unblockUserFromChatRoomService(roomId, body) {
	try {
		return await put(`/talk2you/rooms/${roomId}/unblock`, body);
	} catch (err) {
		throw err;
	}
}

export async function sendReportUserService(body) {
	try {
		return await post(`/common/users/report/abuse`, body);
	} catch (err) {
		throw err;
	}
}

export async function leaveChatRoomService(roomId, body) {
	try {
		return await del(`/talk2you/rooms/${roomId}/leave`, body);
	} catch (err) {
		throw err;
	}
}

export async function maskMessageChatRoomService(roomId, body) {
	try {
		return await put(
			`/talk2you/rooms/${roomId}/info/users/mask-message`,
			body,
		);
	} catch (err) {
		throw err;
	}
}

export async function getUnreadMessagesCount(valueId) {
	try {
		let userId = valueId
		// let userId = valueId?valueId:localStorage.getItem('user_id');
		// const result = await get(`/common/users/${userId}/unread`);
		 // DDB 응답값 용량 문제로 API 변경됨
		return await get(`/talk2you/messages/${userId}/unread`);
	} catch (err) {
		throw err;
	}
}

export async function userProfileChatRoomService(roomId, body) {
	try {
		return await put(`/talk2you/rooms/${roomId}/info/users/push`, body);
	} catch (err) {
		throw err;
	}
}

export async function checkBlockUserService(body) {
	try {
		return await post(`/common/users/block/user/check`, body);
	} catch (err) {
		throw err;
	}
}

export async function previewMessageService(body) {
	try {
		return await post(`/talk2you/messages/preview`, body);
	} catch (err) {
		throw err;
	}
}

export async function uploadVAFService(formData, bucketName, uploadType) {
	try {
		// return await postApi(`/common/files/upload/${bucketName}/${uploadType}`,formData,);
		return await post(`/common/files/upload/${bucketName}/${uploadType}?env=${process.env.REACT_APP_SET_ENV}`,formData,{baseURL: config.api.proxy_api});
	} catch (err) {
		return err;
	}
}

export async function getThumbnailUrl(formData, bucketName, uploadType) {
	try {
		// return await postApiImage(`common/files/generate/thumbnail`,formData,);
		return await post(`common/files/generate/thumbnail?env=${process.env.REACT_APP_SET_ENV}`,formData,{baseURL: config.api.proxy_api});
	} catch (err) {
		throw err;
	}
}

export async function uploadImageService(formData) {
	try {
		// return await postApi(`common/files/upload/opentalk`, formData);
		// return await post(`common/files/upload/opentalk`, formData, {baseURL: config.api.proxy_api});
		// TODO : 여러 곳에서 사용 중으로 당장 삭제하지는 않지만, 잘못된 S3 버킷으로 접근하기 때문에 버킷명을 변경하여 유지함
		return await post(`/common/files/upload/flowing-web-resources/image?env=${process.env.REACT_APP_SET_ENV}`, formData, {baseURL: config.api.proxy_api});
	} catch (err) {
		throw err;
	}
}

export async function ownerMessageReadService(roomId, body) {
	try {
		// console.log('body', body)
		return await put(`/talk2you/messages/${roomId}/read`, body);
	} catch (err) {
		throw err;
	}
}

export async function isSeenService(roomId, messageId) {
	try {
		const encodedMessageId = encodeURIComponent(messageId);
		return await get(
			`/talk2you/messages/${roomId}/${encodedMessageId}/read`,
		);
	} catch (err) {
		throw err;
	}
}

export async function checkMediaConvertProgressService(mediaUrl) {
	try {
		// return await getApiProxy(`${mediaUrl}`, {}, true)
		return await get(`${mediaUrl}`, {baseURL: config.api.proxy_api}, true)
	} catch (err) {
		console.error(err)
		throw err
	}
}

export async function checkVideoUploadingService(jobId) {
	try {
		// return await getApiProxy(`/common/files/mediaconvert/fetch/state?jobId=${jobId}`,);
		return await get(`/common/files/mediaconvert/fetch/state?jobId=${jobId}`,{baseURL: config.api.proxy_api});
	} catch (err) {
		throw err;
	}
}

export async function deleteOneMessageService(body) {
	try {
		return await del(`/talk2you/messages`, body);
	} catch (err) {
		throw err;
	}
}

export async function getRoomData(roomId, userId) {
	try {
		return await get(`talk2you/rooms/${roomId}/fetch?userId=${userId}`);
	} catch (err) {
		throw err;
	}
}

export async function getRoomTransferService(roomId, userId) {
	try {
		 // 채팅 전송 데이터 조회
		return await get(`/talk2you/rooms/${roomId}/transfer?userId=${userId}`);
	} catch (err) {
		throw err;
	}
}

export async function getRoomTransferLimitService(roomId, userId) {
	try {
		 // 답변톡방 전송 제한 설정 조회
		return await get(`/talk2you/rooms/${roomId}/transfer-limit?userId=${userId}`);
	} catch (err) {
		throw err;
	}
}

export async function setRoomTransferLimitService(roomId, body) {
	try {
		return await put(`/talk2you/rooms/${roomId}/transfer-limit`, body);
	} catch (err) {
		console.error(err);
		return err;
	}
}
