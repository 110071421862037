import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
    createProfile,
    updateProfile,
} from "../../services/answerProfile.service";
import videoImg from "../../assets/images/chatroom/video.png";
import RightMenuProfile from "../../components/RightLayout/RightMenuProfile";
import RightMenuContent from "../../components/RightLayout/RightMenuContent";
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/myStyle2.css";
import {
    getThumbnailUrl,
    uploadVAFService,
    uploadImageService,
} from "../../services/talk2meMessage.service";

import { getMyProfile } from "../../services/talk2youRooms.service";
import {getImageSize} from 'react-image-size';
import { authService } from "../../services/auth.service";

const WriteProfile = (props) => {
    const [introduction, setIntroduction] = useState("");
    const [menuValue, setMenuValue] = useState("");
    const [selCategories, setSelcategories] = useState([]);
    const [selDisCategories, setSelDisCategories] = useState([]);
    const [homepage, setHomepage] = useState("");
    const [categoryIds, setCategoryIds] = useState([]);
    const [distCategoryIds, setDistCategoryIds] = useState([]);
    const [keyWordList, setKeyWordList] = useState([]);
    const [keyWord, setKeyWord] = useState("");
    const inputRef = useRef(null);
    const [previewFileArray, setPreviewFileArray] = useState([]);
    const [putImageUrl, setPutImageUrl] = useState([]);
    const [fileUiList, setFileUiList] = useState(["1", "2", "3", "4", "5"]);
    const [loading, setLoading] = useState();
    const [myProfileData, setMyProfileData] = useState();
    const [isProfile, setIsProfile] = useState();
    const [active, setActive] = useState("");
    let isOpenSectionRight = false;

    const [introductionAlert, setIntroductionAlert] = useState("");
    const [keyWordAlert, setKeyWordAlert] = useState("");

    document.title = "Profile";

    // modify kms 2022-11-08
    // adding right menus on page
    // const toggleRight = (action) => {
    // 	console.log("action:::", action);
    // 	if (action) {

    // 		console.log("menuValue:::", menuValue);
    // 		document.getElementById(menuValue).classList.add('viewCon');
    // 		document.getElementById('mainContents').classList.add('sectionRight');
    // 		document.getElementById('right-sidebar').classList.add('rightView');

    // 	}

    // };

    // modify kms 2022-11-08
    useEffect(() => {
        if (menuValue !== "") {
            document.getElementById(menuValue).classList.add("viewCon");
            document
                .getElementById("mainContents")
                .classList.add("sectionRight");
            document.getElementById("right-sidebar").classList.add("rightView");
        }
    }, [menuValue]);
    //passing category datas to parent
    const onChangeFunction = (value) => {
        setSelcategories(value);
        setCategoryIds(value.map((item) => item.id));
    };
    //passing district category datas to parent
    const onChangeDistrictFunction = (value) => {
        setSelDisCategories(value);
        setDistCategoryIds(value.map((item) => item.id));
    };
    //add keywords to array
    const addKeyWords = (e) => {
        if (keyWordList.length < 10) {
            keyWordList.push(e);
            // } else toast.error('Please enter maximium 10 keywords');
        } else toast.error("키워드를 최대 10개 까지 입력할 수 있습니다.");
    };
    const removeItem = (data) => {
        let newList = keyWordList.filter((item) => {
            return item !== data;
        });
        setKeyWordList(newList);
    };

    const removeCategoryItem = (idx) => {
        let newList = selCategories.slice();
        let newIdList = categoryIds.slice();
        newList.splice(idx, 1);
        newIdList.splice(idx, 1);

        setSelcategories(newList);
        setCategoryIds(newIdList);
    }

    const removeLocationCategory = (idx) => {
        console.log('지역 지우기');
        // console.log('distCategoryIds', distCategoryIds);
        // console.log('selDisCategories', selDisCategories);

        let newList = selDisCategories.slice();
        let newIdList = distCategoryIds.slice();
        newList.splice(idx, 1);
        newIdList.splice(idx, 1);

        setSelDisCategories(newList);
        setDistCategoryIds(newIdList);
    }

    const generateVideoThumbnail = (file) => {
        return new Promise((resolve) => {
            const canvas = document.createElement("canvas");
            const video = document.createElement("video");

            // this is important
            video.autoplay = true;
            video.muted = true;
            video.src = URL.createObjectURL(file);

            video.onloadeddata = () => {
                let ctx = canvas.getContext("2d");

                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;

                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                video.pause();
                return resolve(canvas.toDataURL("image/png"));
            };
        });
    };

    // Video upload function
    const videoUpload = async (formData, data, b) => {
        let uploadType = "video";
        let bucketName = "flowing-web-resources";
        formData.append("needToConvert", "Y");
        formData.append("upload", data.file);

        const videoResponse = await uploadVAFService(
            formData,
            bucketName,
            uploadType
        );
        if (videoResponse.msg === "OK") {
            let body = {
                video_url: videoResponse.src_url[0],
                bucket_name: bucketName,
            };

            const thumbnailUrlResponse = await getThumbnailUrl(body);
            if (thumbnailUrlResponse.thumbnailUrl) {
                return {
                    mediaUrl: videoResponse.url[0],
                    resourceType: "VIDEO",
                    thumbnailUrl: thumbnailUrlResponse.thumbnailUrl,
                };
            } else {
                // toast.error('Error sent video upload get thumbnailUrl');
                toast.error(
                    "비디오 파일에서 썸네일이미지를 추출하지 못했습니다."
                );
            }
        } else {
            // toast.error('Error sent video upload');
            toast.error("비디오 파일을 업로드하지 못했습니다.");
        }

        setLoading(false);
    };

    // Image upload function
    const imageUpload = async (formData, data, b) => {
        formData.append("upload", data.file);
        formData.append("limitedImageSize", 1280);
        const rsOrigin = await uploadImageService(formData);

        if (rsOrigin.msg === "OK") {
            const { width, height } = await getImageSize(rsOrigin?.url[0])
            console.log("width", width)
            console.log("height", height)
            let thumbnailUrl = rsOrigin?.url[0]
            if (Number(width) > 170) { // 정해진(default 170, 이후 변경 가능) 사이즈보다 클 경우 썸네일 추가 생성
                formData.set("limitedImageSize", 170)
                const thumbnail = await uploadImageService(formData)
                if (thumbnail && thumbnail?.msg === "OK") {
                    thumbnailUrl = thumbnail?.url[0]
                }
            }
            return {
                mediaUrl: rsOrigin.url[0],
                resourceType: "PHOTO",
                // thumbnailUrl: rsOrigin.url[0],
                thumbnailUrl: thumbnailUrl,
            };
        } else {
            // toast.error('Error upload image');
            toast.error("이미지 파일을 업로드하지 못했습니다.");
        }

        setLoading(false);
    };

    const Register = async () => {
        // if (!introduction) return toast.warning('introduction is required');
        if (!introduction) {
            setIntroductionAlert("profile_alert");
            toast.warning("자기소개를 입력해 주세요.");
            return;
        }
        // if (keyWordList.length === 0) return toast.warning('keyword is required');
        if (keyWordList.length === 0){
            setKeyWordAlert("profile_alert");
            toast.warning("키워드를 입력해 주세요.");
            return 
        }
        setPutImageUrl([]);
        setLoading(!loading);
        if (previewFileArray) {
            for (let i = 0; i < previewFileArray.length; i++) {
                if (!previewFileArray[i].mediaUrl) {
                    const formData = new FormData();

                    if (previewFileArray[i].type === "PHOTO") {
                        let imgResponse = await imageUpload(
                            formData,
                            previewFileArray[i],
                            i
                        );
                        putImageUrl.push(imgResponse);
                    }
                    if (previewFileArray[i].type === "VIDEO") {
                        let videoResponse = await videoUpload(
                            formData,
                            previewFileArray[i],
                            i
                        );
                        // console.log('videoResponse', videoResponse);
                        putImageUrl.push(videoResponse);
                    }
                } else {
                    putImageUrl.push({
                        mediaUrl: previewFileArray[i].mediaUrl,
                        resourceType: previewFileArray[i].type,
                        thumbnailUrl: previewFileArray[i].thumbnailUrl,
                    });
                }
            }
        }

        const body = {
            introduction: introduction,
            homepage: homepage,
            keywordList: keyWordList,
            categoryList: categoryIds,
            regionList: distCategoryIds,
            resourceList: putImageUrl,
            transfer: active,
        };

        const response = await authService.getUserId()
        if(response.status === 201) {
            if(isProfile) await updateProfile(body, response.data.user_id);
            else await createProfile(body, response.data.user_id);
            // if(isProfile) await updateProfile(body, localStorage.getItem("user_id"));
            // else await createProfile(body, localStorage.getItem("user_id"));
    
            // toast.success('Success update');
            const isLandingPage = await authService.getUserprofile(response.data.user_id)
            if(isLandingPage.data.itemList[0].isLandingpageCreated==="Y"){
                toast.success("업데이트가 완료되었습니다. 내용을 반영하기 위해서는 스페이스 홈 관리 > 업데이트 버튼을 클릭하셔야 반영됩니다.");
            }else if(isLandingPage.data.itemList[0].isLandingpageCreated==="N"){
                toast.success("업데이트 완료");
            }
            setLoading(false);
        }

    };

    const handleChange = async (event, index) => {
        if (event?.target?.files[0]) {
            const fileUploaded = event.target;
            let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
            // let type = fileUploaded.files[0].type.substring(0, 5);
            let type = fileUploaded.files[0]?.type.split("/")[0];

            if (type === "video") {
                if (originFileSize > 200) {
                    // toast.error('This file is larger than 200mb');
                    toast.error(
                        "선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다."
                    );
                    return;
                }
            } else {
                if (originFileSize > 20) {
                    // toast.error('This file is larger than 20mb');
                    toast.error(
                        "선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다."
                    );
                    return;
                }
            }

            /*if (type === "audio") {
                // return toast.error('This is audio');
                return toast.error("오디오파일 입니다.");
            } else if (type === "video") {*/
            if (type === "video") {
                if(previewFileArray.length >= 5){
                    previewFileArray[index].mediaUrl = null;
                    previewFileArray[index].type = "VIDEO";
                    previewFileArray[index].file = fileUploaded.files[0];
                    previewFileArray[index].showFile = URL.createObjectURL(fileUploaded.files[0]);
                    previewFileArray[index].name = fileUploaded.files[0].name;
                }else{
                    const thumbnail =  await generateVideoThumbnail(fileUploaded.files[0]);
                    // console.log('thumb', thumbnail);

                    previewFileArray.push({
                        index: index,
                        type: "VIDEO",
                        file: fileUploaded.files[0],
                        showFile: URL.createObjectURL(fileUploaded.files[0]),
                        name: fileUploaded.files[0].name,
                        thumbnail
                    });
                }
                
            } else if (type === "image") {
                if(previewFileArray.length >= 5){
                    previewFileArray[index].mediaUrl = null;
                    previewFileArray[index].type = "PHOTO";
                    previewFileArray[index].file = fileUploaded.files[0];
                    previewFileArray[index].showFile = URL.createObjectURL(fileUploaded.files[0]);
                    previewFileArray[index].name = fileUploaded.files[0].name;
                }else{
                    previewFileArray.push({
                        index: index,
                        type: "PHOTO",
                        file: fileUploaded.files[0],
                        showFile: URL.createObjectURL(fileUploaded.files[0]),
                        name: fileUploaded.files[0].name,
                    });
                }
                
            } else {
                // return toast.error('This is file');
                /*return toast.error("일반파일 입니다.");*/
                return toast.error("사진파일 또는 영상파일을 업로드 해주세요.");
            }
            console.log("previewFileArray:::",previewFileArray);
        }
    };

    const removeClasses = (count) => {
        _.remove(previewFileArray, function (n, index) {
            return index === count;
        });
    };

    useEffect(() => {
        getProfile().then(r => r);

        async function getProfile() {
            const res = await authService.getUserId()
            if(res.status===201) {
                let response = await getMyProfile(res.data.user_id);
                // let response = await getMyProfile(localStorage.getItem("user_id"));
                //console.log(response, 'WITRTER');
                if (response?.itemList[0]) {
                    let data = response.itemList[0];
                    setActive(data.active);
                    setIsProfile(data.introduction || false);
                    setMyProfileData(data);
                    setHomepage(data.homepage);
                    setIntroduction(data.introduction);
                    setKeyWordList(data.keywordList);
                    setSelDisCategories(data.regionList);
                    setDistCategoryIds(data.regionList.map((item) => item.id));
                    setSelcategories(data.categoryList);
                    setCategoryIds(data.categoryList.map((item) => item.id));
                    for (let i = 0; i < data.resourceList.length; i++) {
                        previewFileArray.push({
                            index: i,
                            type: data.resourceList[i].resourceType,
                            mediaUrl: data.resourceList[i].mediaUrl,
                            file: data.resourceList[i].mediaUrl,
                            name: data.resourceList[i].resourceId,
                            thumbnailUrl: data.resourceList[i].thumbnailUrl,
                        });
                    }
                    //console.log('asdasd', response?.itemList[0]);
                }
            }
        }
    }, []);

    const divRef = useRef();
    const [showOption, setShowOption] = useState(false);
    const handleToggleOption = () => setShowOption((prev) => !prev);

    const handleClickOutSide = (e) => {
        //console.log(divRef.current.contains(e.target))
        if (showOption && !divRef.current.contains(e.target)) {
            setShowOption(false);
        }
    };
    useEffect(() => {
        if (showOption) document.addEventListener("click", handleClickOutSide);
        return () => {
            document.removeEventListener("clisk", handleClickOutSide);
        };
    });

    return (
        <React.Fragment>
            {/* <div className="page-content write-profile-content"> */}
            <div className="write-profile-content">
                <ToastContainer />
                {loading && (
                    <div className="loadingActive">
                        <Spinner type="grow" color="warning" />
                    </div>
                )}

                {menuValue === "contentArea" ? (
                    <RightMenuContent
                        menuValue={menuValue}
                        previewFileArray={previewFileArray}
                        setMenuValue={setMenuValue}
                    />
                ) : (
                    <RightMenuProfile
                        menuValue={menuValue}
                        selCategories={selCategories}
                        selDisCategories={selDisCategories}
                        onChange={(value) => onChangeFunction(value)}
                        onDistChange={(value) =>
                            onChangeDistrictFunction(value)
                        }
                        setMenuValue={setMenuValue}
                    />
                )}
                {/* <RightMenuContent
						menuValue={menuValue}
						previewFileArray={previewFileArray}
					/>
				<RightMenuProfile
					menuValue={menuValue}
					selCategories={selCategories}
					selDisCategories={selDisCategories}
					onChange={(value) => onChangeFunction(value)}
					onDistChange={(value) =>
						onChangeDistrictFunction(value)
					}
					previewFileArray={previewFileArray}
				/> */}
                {/* <div className="section-body" onClick={(ref)=>{toggleRight(false)}}> */}
                <div className="section-body">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col text-left">
                                <h1 className="page-title">
                                    {props.t("Manage profile")}
                                </h1>
                            </div>
                            <div className="col text-right"></div>
                        </div>
                        <div className="row clearfix ">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label className="form-label">
                                                {props.t("Introduce your self (Required)")}
                                                <span className="form-label-small">{`${
                                                    introduction?.length || 0
                                                }/200`}</span>
                                            </label>
                                            <textarea
                                                className={`form-control form_profile ${introductionAlert}`}
                                                name="example-textarea-input"
                                                rows="4"
                                                required
                                                maxLength={200}
                                                value={introduction}
                                                onChange={(e) => {
                                                    setIntroduction(
                                                        e.target.value
                                                    );
                                                }}
                                                placeholder="자기소개를 입력해 주세요"
                                            >
                                                {props.t("Breifly introduce yourself (under 200 letters)")}
                                            </textarea>
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">
                                                {props.t("Keywords for your answer (Required)")}
                                            </label>
                                            {keyWordList &&
                                                keyWordList.map(
                                                    (item, index) => {
                                                        return (
                                                            <span
                                                                onClick={() =>
                                                                    removeItem(item)
                                                                }
                                                                key={index}
                                                                className="tag tag-gray btn"
                                                                style={{marginLeft:".5rem",}}
                                                            >
                                                                {item}
                                                                <i className="fe fe-x del_i"></i>
                                                            </span>
                                                        );
                                                    }
                                                )}
                                            <input
                                                type="text"
                                                className={`form-control mt-2 form_profile ${keyWordAlert}`}
                                                name="example-input"
                                                required
                                                // placeholder={props.t("Enter keywords that you want to answer (maximum 10)")}
                                                placeholder="등록 방법 키워드를 하나씩 입력하시면 엔터를 누르세요 (최대 10개까지 가능) ex) 어학연수 > 입력 후 > ENTER"
                                                value={keyWord}
                                                onChange={(e) =>
                                                    setKeyWord(e.target.value)
                                                }
                                                onKeyPress={(e) => {
													// onKeyDown 이벤트에서 onKeyPress 이벤트로 변경, 또한 Enter 키만 입력되도록 구현하였습니다.
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        if (e.target.value.length <= 10) {
                                                            addKeyWords(e.target.value);
                                                            setKeyWord("");
                                                        } else {
                                                            toast.error("키워드는 최대 10자까지 입력할 수 있습니다.");
                                                        }
                                                    }
                                                    //e.key === 'Enter' &&
                                                    // (addKeyWords(e.target.value), setKeyWord(''))
                                                }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="form-label">
                                                {props.t("Homepage")}
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="example-input"
                                                value={homepage}
                                                placeholder={props.t("Please enter your website address.")}
                                                onChange={(e) =>
                                                    setHomepage(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">
                                                {props.t("Answering category")}
                                            </label>
                                            <div className="row gutters-xs">
                                                <div className="col">
                                                    <div
                                                        className="form-control"
                                                        style={{
                                                            height: "100%",
                                                        }}
                                                        onClick={(ev) => {
                                                            ev.stopPropagation();
                                                            setMenuValue("cateArea");
                                                            //toggleRight(true);
                                                        }}
                                                    >
                                                        {selCategories &&
                                                            selCategories.map((item, index) => {
                                                                    return (
                                                                        <span
                                                                            key={index}
                                                                            className="tag tag-gray"
                                                                            style={{marginLeft:".5rem",}}
                                                                        >
                                                                            {item.name}
                                                                            <i className="fe fe-x del_i" onClick={(ev) => {
                                                                                ev.stopPropagation();
                                                                                removeCategoryItem(index)
                                                                            }} />
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                                <span className="col-auto">
                                                    <button
                                                        className="btn btn-secondary viewCate"
                                                        type="button"
                                                        onClick={() => {
                                                            setMenuValue("cateArea");
                                                            //toggleRight(true);
                                                        }}
                                                    >
                                                        <i className="fe fe-search"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">
                                                {props.t("Area you want to answer")}
                                            </label>
                                            <div className="row gutters-xs">
                                                <div className="col">
                                                    <div
                                                        className="form-control"
                                                        style={{height: "100%",}}
                                                        onClick={() => {
                                                            setMenuValue("AreaArea");
                                                            //toggleRight(true);
                                                        }}
                                                    >
                                                        {selDisCategories &&
                                                            selDisCategories.map(
                                                                (item,index) => {
                                                                    return (
                                                                        <span
                                                                            key={index}
                                                                            className="tag tag-gray"
                                                                            style={{marginLeft:".5rem",}}
                                                                        >
                                                                            {item.upmyeondong_name}
                                                                            <i className="fe fe-x del_i" onClick={(ev) => {
                                                                                ev.stopPropagation();
                                                                                removeLocationCategory(index);
                                                                            }}/>
                                                                        </span>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                                <span className="col-auto">
                                                    <button
                                                        className="btn btn-secondary viewArea"
                                                        type="button"
                                                        onClick={() => {
                                                            setMenuValue("AreaArea");
                                                            //toggleRight(true);
                                                        }}
                                                    >
                                                        <i className="fe fe-search"></i>
                                                    </button>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button
                                                className="btn btn-secondary viewContent"
                                                type="button"
                                                onClick={() => {
                                                    setMenuValue("contentArea");
                                                    //toggleRight(true);
                                                }}
                                            >
                                                {props.t("View photo details")}
                                            </button>
                                        </div>
                                        {/* {menuValue === 'contentArea' ? (
											<RightMenuContent
												menuValue={menuValue}
												previewFileArray={previewFileArray}
											/>
										) : ( */}
                                        {/* <RightMenuProfile
												menuValue={menuValue}
												selCategories={selCategories}
												selDisCategories={selDisCategories}
												onChange={(value) => onChangeFunction(value)}
												onDistChange={(value) =>
													onChangeDistrictFunction(value)
												}
											/> */}
                                        {/* )} */}

                                        <div className="row ">
                                            <div className="col-lg-12">
                                                <p className="form-label">
                                                    {props.t("Image/Video")}
                                                </p>
                                            </div>
                                            {fileUiList &&
                                                fileUiList.map(
                                                    (item, index) => {
                                                        return (
                                                            <div
                                                                key={index}
                                                                className="col-lg-2 col-sm-6"
                                                            >
                                                                <div className="card border">
                                                                    <div className="card-body">
                                                                        <div
                                                                            className={previewFileArray[index]
                                                                                    ? "has-view dropify-wrapper"
                                                                                    : "dropify-wrapper"
                                                                            }
                                                                        >
                                                                            <div className="dropify-message">
                                                                                <span className="file-icon"></span>{" "}
                                                                                <p>
                                                                                    {props.t("Drag or press the button to upload image or video")}
                                                                                </p>
                                                                                <p className="dropify-error">
                                                                                    일시적인 오류로 처리를 완료하지 못했습니다. 다시 시도해 주세요.{" "}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                className={previewFileArray[index]
                                                                                        ? "dropify-loader d-none"
                                                                                        : "dropify-loader"
                                                                                }
                                                                            ></div>
                                                                            <div className="dropify-errors-container">
                                                                                <ul></ul>
                                                                            </div>
                                                                            <input
                                                                                key={index}
                                                                                type="file"
                                                                                className="dropify"
                                                                                id="selected-file"
                                                                                accept="image/gif, image/jpeg, image/jpg, image/png, video/mp4, video/quicktime, video/x-ms-asf, video/avi"
                                                                                onChange={(e) =>
                                                                                    handleChange(e, index)
                                                                                }
                                                                                ref={inputRef}
                                                                            />
                                                                            <button
                                                                                type="button"
                                                                                className={previewFileArray[index]
                                                                                        ? "dropify-clear d-block"
                                                                                        : "dropify-clear"
                                                                                }
                                                                                onClick={() =>
                                                                                    removeClasses(index)
                                                                                }
                                                                            >
                                                                                {/* Remove */}
                                                                                삭제
                                                                            </button>
                                                                            <div
                                                                                className={previewFileArray[index]
                                                                                        ? "dropify-preview d-block"
                                                                                        : "dropify-preview "
                                                                                }
                                                                            >
                                                                                <span className="dropify-render">
                                                                                    {previewFileArray[index]?.file &&
                                                                                        previewFileArray[index]?.type === "PHOTO" && (
                                                                                            <img src={previewFileArray[index].mediaUrl || previewFileArray[index]?.showFile} alt="img" />
                                                                                        )}
                                                                                    {previewFileArray[index]?.file &&
                                                                                        previewFileArray[index]?.type === "VIDEO" && (
                                                                                            <img src={previewFileArray[index].thumbnail ||
                                                                                                previewFileArray[index].thumbnailUrl || videoImg} alt="img" />
                                                                                            /*<img src={videoImg} alt="img" />*/
                                                                                        )}
                                                                                </span>
                                                                                <div className="dropify-infos">
                                                                                    <div className="dropify-infos-inner">
                                                                                        {/* <p className="dropify-filename">
                                                                                            <span className="file-icon"></span>{" "}
                                                                                            <span className="dropify-filename-inner">
                                                                                                {previewFileArray[index]?.name}
                                                                                            </span>
                                                                                        </p> */}
                                                                                        <p className="">
                                                                                            {/* Drag and drop or click to replace */}
                                                                                            새로운 파일을 <br/> 첨부하시면 <br/> 새로운 파일로 <br/> 대체됩니다.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                        {/*TODO : 아래 코드는 현재상황에서 필요없어짐에 따라 주석 처리*/}
                                        {/*<div className="form-group">
											<label className="custom-control custom-checkbox">
												{active && (
													<input
														type="checkbox"
														defaultChecked={active === 'on' && true}
														className="custom-control-input"
														onClick={() =>
															setActive(active === 'on' ? 'off' : 'on')
														}
													/>
												)}
												<span className="custom-control-label">
												  {props.t(
													  'Set up maximum letters of question (default 100)',
												  )}
												</span>
											</label>
										</div>*/}

                                        <button
                                            className="btn btn-secondary btn-block mt-2"
                                            title=""
                                            onClick={() => Register()}
                                        >
                                            {isProfile
                                                ? props.t("Update")
                                                : props.t("Register")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(WriteProfile));

WriteProfile.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
