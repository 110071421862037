import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {withRouter, Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import ReactPlayer from 'react-player';
import gallery from '../../assets/images/gallery/6.jpg';
import avatar from '../../assets/images/xs/avatar4.jpg';
import moment from 'moment';
import {enterRoom} from '../../services/talk2meRooms.service';
import _, {map} from 'lodash';
import {lastValueFrom} from 'rxjs';
import {categoryService} from '../../services/category.service';

const RightAnswerMenu = (props) => {
	const [ownerInformation, setOwnerInformation] = useState();
	const [districtCategories, setDistrictCategories] = useState(
		props.selDisCategories,
	);
	const [offset, setOffset] = useState('0');
	const [limit, setLimit] = useState('100');
	const [keyword, setKeyword] = useState('');
	const [searchedCate, setSearchedCate] = useState([]);

	const searchCategories = async () => {
		let response = await categoryService.searchDistrictCategories(
			keyword,
			offset,
			limit,
		);
		setSearchedCate(response.district_category_list);

		return response;
	};
	const addItem = (item) => {
		districtCategories.push(item);
		props.onDistChange(districtCategories);
	};
	const removeItem = (item) => {
		const newCategories = districtCategories.filter(
			(cate) => cate.id !== item.id,
		);
		setDistrictCategories(newCategories);
		props.onDistChange(newCategories);
	};
	useEffect(() => {
		searchCategories();
	}, []);
	useEffect(() => {
		setDistrictCategories(props.selDisCategories);
	}, [props.selDisCategories])
	return (
		<React.Fragment>
			<div id="AreaArea" className="viewContents viewCon">
				<h5>{props.t('Choose area you want to answer (maximum 5)')}</h5>
				<p className="textArea">{props.t('Enter area')}</p>

				<div>
					<form className="input-group" onSubmit={async ev => {
						ev.preventDefault();
						await searchCategories().then(res => {
							console.log('search-res', res);
						});
					}}>
						<input
							type="text"
							className="form-control"
							placeholder={props.t('Search area you want to answer')}
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<span className="input-group-append">
						  <button
							  className="btn btn-secondary"
							  type="button"
							  onClick={searchCategories}
						  >
							{props.t('Search')}
						  </button>
						</span>
					</form>
				</div>
				<div className="ReaultArea">
					<ul>
						{searchedCate &&
							searchedCate.map((item, index) => (
								<li key={index}>
									<div className="d-flex align-items-center mt-auto">
										<div>{`${item.sido_name},${item.sigungu_name},${item.upmyeondong_name}`}</div>
										<div className="ml-auto text-muted">
											{districtCategories.find(({id}) => id == item.id) ==
											null ? (
												<button
													className="btn btn-outline-secondary btn-sm"
													onClick={() => addItem(item)}
												>
													{props.t('Add')}
												</button>
											) : (
												<button
													className="btn btn-outline-secondary btn-sm"
													onClick={() => removeItem(item)}
												>
													{props.t('Cancel')}
												</button>
											)}
										</div>
									</div>
								</li>
							))}
							{searchedCate && searchedCate.length === 0 && (
								<li>
									<p className="noResult">{props.t('No result')}</p>
								</li>
							)}

					</ul>
				</div>
			</div>
		</React.Fragment>
	);
};

RightAnswerMenu.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(RightAnswerMenu));
