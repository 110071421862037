import React, { useEffect, useRef, useState } from "react";
import PropTypes, { number } from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col, Input, Button, Table } from "reactstrap";
import imgHelper from "../../assets/images/img_helper.jpg";
import imgChatBag from "../../assets/images/imgChatBag.png";
import SecondAnwerSetting from "./AnswerGuideComponents/SecondAnwerSetting";
import "./AnswerGuideComponents/AnswerGuide.scss";
import axios from "axios";
import ThirdAnswerSetting from "./AnswerGuideComponents/ThirdAnswerSetting";
import AddTabInput from "./AnswerGuideComponents/AddTabInput";
import {chatbotSettingsService} from '../../services/chatbotSettings.service';
import { toast } from "react-toastify";

import { Pagination, FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Picker from 'emoji-picker-react';
import { authService } from "../../services/auth.service";
import DialogModal from '../../components/DialogModal/DialogModal';
import ScenarioGuideContent from './ScenarioGuideContent';


const AnswerGuideSettings2 = (props) => {
    //meta title
    document.title = "Flowing";

    

    const [template, setTemplate] = useState({});
    const [firstTemplate,setFirstTemplate] = useState({message:"", title:"", subTemplate:[]});
    const [firstTemplateSaveButtonTitle, setFirstTemplateSaveButtonTitle] = useState("저장");

    const [secondTemplateTitle, setSecondTemplateTitle] = useState(""); //수정을 위한 List와의 분리
    const [secondTemplateList, setSecondTemplateList] = useState([{message:"", title:"", subTemplate:[]}]);
    const [selectedSecondListIndex, setSelectedSecondListIndex] = useState(0);

    const [selectedThirdListIndex, setSelectedThirdListIndex] = useState(0);
    const [isShowEmoji, setIsShowEmoji] = useState();
    let isSaved = false;
    const [isSavedArr,setSavedArr] = useState([]);
    
    const [thirdTemplateTitle, setThirdTemplateTitle] = useState(""); //수정을 위한 List와의 분리

    const [userId, setUserId] = useState("")


    

    /** 템플릿 GET API 호출 */
    const getTemplateData = async (valueId) => {
        const targetId = valueId ? valueId : userId
            let profileResponse = await chatbotSettingsService.getUserProfileInfo(targetId);
            console.log("profileResponse:::",profileResponse);
            // if(profileResponse.status === 204){
            //     window.alert("유저프로필 정보가 없습니다. 답변 프로필을 먼저 등록해주세요");
            //     return;
            // } else if(profileResponse.status === 201){
            //     window.alert("앱에서 답변 프로필 등록을 모두 완료해 주세요");
            //     return;
            // } else
            if(profileResponse.status === 200){
                let response = await chatbotSettingsService.getChatbotSettingsInfo(
                    // localStorage.getItem('user_id'),
                    // userId,
                    targetId
                );
                console.log("response::::", response);
                // if(response.status === 204){
                //     window.alert("유저프로필 정보가 없습니다. 답변 프로필을 먼저 등록해주세요");
                //     return;
                // }
                // if(response.status !==200){
                //     window.alert("유저프로필 정보가 없습니다. 답변 프로필을 먼저 등록해주세요");
                //     return;
                // }else if(response.status === 200){
                    setFirstTemplate(
                        {
                            message:response?.data?.template?.message,
                            templateId : response?.data?.template?.templateId,
                            subTemplate : response?.data?.template?.subTemplate || [],
                        }
                    );
        
                // }else{
                //     window.alert("유저프로필 정보가 없습니다. 답변 프로필을 먼저 등록해주세요");
                //     return;
                // }
        
                setTemplate(response?.data?.template);
            
                setSecondTemplateList(response?.data?.template?.subTemplate);
        
                if(response?.data?.template?.subTemplate?.subTemplate?.length > 0  || response?.data?.template?.subTemplate[0]?.message?.length > 1 || isSavedArr[0] === true){
                    isSavedArr[0] = true;
                }else{
                    isSavedArr[0] = false;
                }
                console.log("isSavedArr:::",isSavedArr);
        
                console.log("response.data.template:::", response?.data?.template);
            }
    }

    //답변분야 안내 1단계 저장
    const putFirstTemplate = async() =>{
            let profileResponse = await chatbotSettingsService.getUserProfileInfo(userId);
            console.log("profileResponse:::",profileResponse);
            if(profileResponse.status === 204){
                window.alert("유저프로필 정보가 없습니다. 답변 프로필을 먼저 등록해주세요");
                return;
            } else if(profileResponse.status === 201){
                window.alert("앱에서 답변 프로필 등록을 모두 완료해 주세요");
                return;
            } else if(profileResponse.status === 200) {
                if(firstTemplate.message.replace(/ /g, '').length < 2){
                    window.alert("답변분야 안내를 최소 2글자 이상 입력해주세요");
                    return;
                }
                
                let body;
                if(firstTemplate.templateId){
                    body ={
                        template: {
                            message: firstTemplate.message,
                            templateId : firstTemplate.templateId,
                            subTemplate : []
                        }
                    }
                }else{
                    body ={
                        template: {
                            message: firstTemplate.message,
                            subTemplate : []
                        }
                    }
                }
                
                ;
                const response = await chatbotSettingsService.putChatbotSettingsInfo(
                    body,
                    userId
                )
                getTemplateData();
                window.alert("저장되었습니다.");
            } else {
                window.alert("다시한번 시도해 주세요")
                return;
            }

    }

    //2단계 답변분야 명칭 추가
    const putSecondTemplate = async() =>{
            if(secondTemplateTitle.replace(/ /g, '').length < 2){
                window.alert("답변분야 명칭은 최소 두글자 이상 입력해주세요");
                return;
            }
            let body ={
                template: {
                    parentTemplateId: firstTemplate.templateId,
                    title : secondTemplateTitle,
                    message :"",
                    contents : [],
                    subTemplate :[]
                }
            }
    
            const response = await chatbotSettingsService.putChatbotSettingsInfo(
                body,
                userId
            )
            
            if(response.status === 201){
                getTemplateData();
                
            }else{
                window.alert("문제가 발생하였습니다. : response.status");
                return;
            }
            setSecondTemplateTitle("");
    
    
            //setSelectedSecondListIndex(secondTemplateList.length );
            window.alert("답변분야가 추가되었습니다. ");

    }

    //3단계 답변세부분야 추가
    const putThirdTemplate = async() =>{
        if(thirdTemplateTitle.replace(/ /g, '').length < 2){
            window.alert("답변세부분야 명칭은 최소 두글자 이상 입력해주세요");
            return;
        }
        let body;
        body ={
            template: {
                parentTemplateId: secondTemplateList[selectedSecondListIndex].templateId,
                title : thirdTemplateTitle,
                message :"",
                contents : []
            }
        }
            const response = await chatbotSettingsService.putChatbotSettingsInfo(
                body,
                userId
            )
            
            if(response.status === 201){
                getTemplateData();
                //selectedSecondListIndex(secondTemplateList.length -1 );
            }else{
                window.alert("문제가 발생하였습니다. : response.status");
            }
    
            setThirdTemplateTitle("");
            window.alert("답변세부 분야가 추가되었습니다.");

    }

    //2,3단계 답변분야안내내용 수정
    async function updateSecondTemplate(targetTemplate, isPhoto){

        
        //if(secondTemplateList[selectedSecondListIndex].message.length < 2){
        if(isPhoto !== "photo"){
            if(targetTemplate?.message?.replace(/ /g, '').length <2){
                window.alert("답변분야 안내는 최소 두글자 이상 입력해주세요");
                return;
            }
        }

        //const targetTemplate = secondTemplateList[selectedSecondListIndex];
        console.log("targetTemplate:::",targetTemplate)
            const response = await chatbotSettingsService.putChatbotSettingsInfo(
                {template : targetTemplate},
                userId
            );
            console.log("response:::",response);
            
            if(response.status === 201){
                getTemplateData();
                //setSelectedSecondListIndex(secondTemplateList.length -1 );
            }else{
                window.alert("문제가 발생하였습니다. : response.status");
                return;
            }
    
            isSavedArr[selectedSecondListIndex] = true;
            console.log(isSavedArr);
    
            if(isPhoto !== "photo"){
                window.alert("저장되었습니다.");
            }
        

    }


    //2,3 단계 답변분야 삭제
    const deleteTemplate = async(templateId,isThird) =>{
        let alertMessage = isThird?"답변 상세분야를 삭제하시겠습니까?":"답변분야를 삭제하시겠습니까?";
        if(window.confirm(alertMessage)){
                const response = await chatbotSettingsService.delChatbotSettingsInfo(
                    templateId,
                    userId
                );
                
                if(response.status === 201){
                    getTemplateData();
                }else{
                    window.alert("문제가 발생하였습니다. : response.status");
                }
    
                if(!isThird)setSelectedSecondListIndex(0);
            }
        
    }

    const modifySecondTemplateTitle = async() =>{
        console.log("modifySecondTemplateTitle clicked");
    }


    // 1단계 내용 수정 한글깨짐 대응
    const firstTemplateValueHandle = (target) => {
        const { name, value } = target;
        setFirstTemplate(() => {
            return {
                ...firstTemplate,
                [name]: value,
            };
        });
        //console.log(firstTemplate);
    };

    // 2단계 타이틀 수정
    const secondTemplateTitleValueHandle = (target) => {
        const { name, value } = target;
        setSecondTemplateTitle(value);
        
    };

    // 2단계 메세지 수정
    const secondTemplateMessageHandle = (target) => {
        const { name, value } = target;
        const newTemplateList = [...secondTemplateList];
        newTemplateList[selectedSecondListIndex].message = value;
        setSecondTemplateList(newTemplateList);
    };

    // 3단계 메세지 수정
    const thirdTemplateMessageHandle = (target, index) => {
        const { name, value } = target;
        const newTemplateList = [...secondTemplateList];
        newTemplateList[selectedSecondListIndex].subTemplate[index].message = value;
        setSecondTemplateList(newTemplateList);
    };

    // 3단계 타이틀 수정
    const thirdTemplateTitleValueHandle = (target) => {
        const { name, value } = target;
        setThirdTemplateTitle(value);
    };


    
    const selectFileFunction = async (event, thisTemplate, isThird,originalMessage) => {
        console.log("thisTemplate:::",thisTemplate);
        if(thisTemplate?.contents?.length >=5){
            window.alert("첨부는 5개까지 가능합니다.");
            return;
        }
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					toast.error('This file is larger than 200mb');
					return;
				}
			} else {
				if (originFileSize > 20) {
					toast.error('This file is larger than 20mb');
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
            let uploadType = 'image';
            let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

            const response = await chatbotSettingsService.uploadVAFService(formData,bucketName,uploadType);
            if(response.msg === 'OK'){
                console.log(response.url[0]);

                console.log("imageresponse:::",response);
                console.log("isThird:::", isThird);
                // if(!isThird){
                //     secondTemplateList[selectedSecondListIndex].contents = [
                //         ...secondTemplateList[selectedSecondListIndex].contents ,{
                //             msgType: "PHOTO",
                //             imgRatio: 2.164102554321289,
                //             message: response.url[0],
                //             fileOrigin: response.url[0]
    
                //         }
                //     ];
                // }else{
                    if(thisTemplate.contents){
                        thisTemplate.contents = [
                            ...thisTemplate.contents ,{
                                msgType: "PHOTO",
                                imgRatio: 2.164102554321289,
                                message: response.url[0],
                                fileOrigin: response.url[0]
        
                            }
                        ];
                    }else{
                        thisTemplate.contents = [
                            {
                                msgType: "PHOTO",
                                imgRatio: 2.164102554321289,
                                message: response.url[0],
                                fileOrigin: response.url[0]
        
                            }
                        ];
                    }
                    
                // }
                
                console.log("메세지비교:::",thisTemplate.message, "   ", originalMessage)

                updateSecondTemplate(thisTemplate, "photo");

                console.log(thisTemplate);
                
            }
            
		}
	};

    
    
    //이미지삭제
    const deleteImage = async(templateId,imageId) =>{

        console.log(templateId,imageId);
        if(window.confirm("첨부이미지를 삭제하시겠습니까?")){
            const response = await chatbotSettingsService.delChatbotImage(
                templateId, imageId, userId
            );
            
            if(response.status === 201){
                getTemplateData();
            }else{
                window.alert("문제가 발생하였습니다. : response.status");
            }
        }
        
    }

    //처음 로딩시 작업
    useEffect(() => {
        const setId = async () => {
            const res = await authService.getUserId()
            if(res.status===201) {
                console.log("init get data.....");
                getTemplateData(res.data.user_id);
                setUserId(res.data.user_id)
                //setSecondTemplateTitle(secondTemplateList[selectedSecondListIndex]?.title);
                console.log("secondTemplateList:::", secondTemplateList);
            }
        }
        setId().then(r=>r)
        
    }, []);


    // useEffect(() => {
    //     const setId = async () => {
    //         const res = await authService.getUserId()
    //         if(res.status===201) {
    //             setUserId(res.data.user_id)
    //         }
    //     }
    //     setId().then(r=>r)
    // }, [])

    // console.log("template:::", template);
    // console.log("firstTemplate:::", firstTemplate);
    // console.log("second:::",   secondTemplateList);


    
    return (
        <li className="active">
             <div className="row d-flex">
                {/* 1단계 답변분야 */}
                <div className="col-lg-6 col-xl-7 chatbot-PcRightBoard-group">
                    <div className="PcRightBoard">
                        <div className="chatbot-setting">
                            <p className="chatbot-setting-title">
                                <strong>1단계</strong> 답변 분야 안내 만들기
                            </p>
                            <div className="first-answer-wrap">
                                <p className="message-length">{firstTemplate?.message?.length || 0}/200</p>
                                <textarea
                                    className="form-control"
                                    required
                                    placeholder={firstTemplate.message || '답변자님의 답변 분야에 대한 간단한 안내 내용을 입력하세요'}
                                    type="text"
                                    name="message"
                                    rows={4}
                                    maxLength="200"
                                    onChange={(e) => {
                                        //firstTemplate.message = e.target.value;
                                        firstTemplateValueHandle(e.target);
                                    }}
                                    value={firstTemplate.message}
                                    
                                />
                                <button
                                    onClick={putFirstTemplate}
                                    className="btn btn-primary post-btn btn-1st-custom"
                                >
                                    {firstTemplateSaveButtonTitle}
                                </button>
                            </div>
                        </div>
                    </div> {/*PcRightBoard */}

                    {/* ########2단계 타이틀 생성 ######### */}
                    {firstTemplate.templateId && 

                        <div className="PcRightBoard">
                            <div className="chatbot-setting">
                                <p className="chatbot-setting-title">
                                    <strong>2단계</strong> 답변 분야 만들기
                                </p>
                                <div className="first-answer-wrap">
                                    <p className="message-length">{secondTemplateTitle.length || 0}/20</p>
                                    <input
                                        className="form-control"
                                        required
                                        placeholder={'답변분야 명칭을 입력하고 추가하기를 누르세요'}
                                        type="text"
                                        name="title"
                                        maxLength="20"
                                        value={secondTemplateTitle}
                                        onChange={(e) => {
                                            secondTemplateTitleValueHandle(e.target);
                                        }}
                                        
                                    />
                                    {/* <button
                                        onClick={modifySecondTemplateTitle }
                                        className="btn btn-primary post-btn btn-1st-custom"
                                    >
                                        타이틀 수정
                                    </button> */}
                                    <div className="clear">
                                        <button
                                            onClick={putSecondTemplate}
                                            className="btn btn-primary post-btn btn-1st-custom"
                                        >
                                            답변분야 추가
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                    }

                    {secondTemplateList && 
                    <div className="chatbot-setting">
                        <div className="first-answer-wrap">
                            <div className="PcRightBoard">
                                <div className="swiper-setting">
                                    <Swiper
                                        slidesPerView='auto'
                                        navigation={true}
                                        modules={[Pagination, FreeMode, Navigation]}
                                        breakpoints={{
                                            "1300": {
                                            slidesPerView: 'auto',
                                            spaceBetween:5,
                                            },
                                            "1350": {
                                            slidesPerView: 'auto',
                                            spaceBetween:5,
                                            },
                                            "1635": {
                                                slidesPerView: 'auto',
                                                spaceBetween:5,
                                            },
                                            "1800": {
                                                slidesPerView: 'auto',
                                                spaceBetween:5,
                                            },
                                        }}
                                        className="chatbotSwiper text-center"
                                        >
                                        {firstTemplate?.templateId && secondTemplateList.map((item, index) => {
                                            
                                            
                                            return (
                                            <SwiperSlide key={index}>
                                                <div className="carouselItemArea" key={index}>
                                                    <div>
                                                        <span
                                                            className={`carousel-tab-title ${selectedSecondListIndex === index?"btn-primary":"btn-secondary"}`}
                                                            onClick={() => {

                                                                if(!isSaved){
                                                                    //console.log("call");
                                                                    if(item.subTemplate?.length > 0 || template?.subTemplate[index]?.message?.length>1 ||isSavedArr[index] === true){

                                                                        isSavedArr[index] = true;
                                                                    }else{
                                                                        isSavedArr[index] = false;
                                                                    }
                                                                }

                                                                console.log("isSavedArr:::", isSavedArr);
                                                                setSelectedSecondListIndex(index);
                                                                //setSecondTemplateTitle(item.title);
                                                                setSelectedThirdListIndex(0);
                                                              
                                                                
                                                                
                                                                if (index == secondTemplateList?.length -1 ) {
                                                                    isSaved = true;
                                                                    //console.log(index , secondTemplateList?.length -1 == index, isSaved, isSavedArr);
                                                                }
                    

                                                                console.log("selectedSecondlist:::", secondTemplateList[index]);
                                                                

                                                            }}
                                                        >
                                                            {item.title}
                                                            <i className="icon-close"
                                                                onClick={() => {
                                                                    deleteTemplate(
                                                                        item.templateId
                                                                    );
                                                                }}
                                                            ></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            );
                                        })}
                                    </Swiper>
                                </div>
                            </div>
                        
                            {firstTemplate?.templateId && secondTemplateList?.length > 0 &&
                                <ViewCategory
                                    template={template}
                                    selectedTemplate = {secondTemplateList[selectedSecondListIndex]} 
                                    selectedListIndex = {selectedSecondListIndex}
                                    selectedTemplateMessageHandle ={secondTemplateMessageHandle}
                                    updateTemplate ={updateSecondTemplate}
                                    selectFileFunction ={selectFileFunction}
                                    deleteImage ={deleteImage}
                                    isSaved={isSaved}
                                />
                                
                            }
                            



                            {/* ########3단계 타이틀 생성 ######### */}
                            {
                                (template && 
                                template?.subTemplate )&& 
                                //template?.subTemplate[selectedSecondListIndex]?.message) && isSavedArr[selectedSecondListIndex]  || 
                                isSavedArr[selectedSecondListIndex]  || 
                                (template && 
                                template?.subTemplate &&
                                template?.subTemplate[selectedSecondListIndex]?.subTemplate?.length > 0) ? 

                                <div className="PcRightBoard">
                                    <div className="chatbot-setting">
                                        <p className="chatbot-setting-title">
                                            <strong>3단계</strong> 답변 세부 분야 만들기 
                                        </p>
                                        <div className="first-answer-wrap">
                                            <p className="message-length">{thirdTemplateTitle?.length || 0}/20</p>
                                            <input
                                                className="form-control"
                                                required
                                                placeholder={'답변세부 분야 명칭을 입력하고 추가하기를 누르세요'}
                                                type="text"
                                                name="title"
                                                maxLength="20"
                                                value={thirdTemplateTitle}
                                                onChange={(e) => {
                                                    thirdTemplateTitleValueHandle(e.target);
                                                }}
                                                
                                            />
                                            {/* <button
                                                onClick={modifySecondTemplateTitle }
                                                className="btn btn-primary post-btn btn-1st-custom"
                                            >
                                                타이틀 수정
                                            </button> */}
                                            <div className="clear">
                                                <button
                                                    onClick={putThirdTemplate}
                                                    className="btn btn-primary post-btn btn-1st-custom"
                                                >
                                                    답변세부 분야 추가
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :""
                            }

                            {
                                secondTemplateList[selectedSecondListIndex]?.subTemplate &&
                                secondTemplateList[selectedSecondListIndex]?.subTemplate.map((item, index)=>{
                                    return(
                                        <ViewCategory
                                            isThird = {true}
                                            selectedTemplate = {item}
                                            index = {index}
                                            key = {index}
                                            deleteTemplate ={deleteTemplate}
                                            selectedListIndex = {selectedSecondListIndex}
                                            selectedTemplateMessageHandle ={thirdTemplateMessageHandle}
                                            updateTemplate ={updateSecondTemplate}
                                            selectFileFunction ={selectFileFunction}
                                            deleteImage ={deleteImage}
                                            setSelectedThirdListIndex={setSelectedThirdListIndex}
                                        />
                                    );
                                })
                            }


                        </div>


                        

                        


                    </div>
                    }


                </div>{/* 왼쪽끝 */}

                
                    <PreviewPanel 
                    template = {template}
                    firstTemplate ={firstTemplate}
                    selectedSecondListIndex={selectedSecondListIndex}
                    secondTemplateList={secondTemplateList}
                    selectedThirdListIndex={selectedThirdListIndex}
                    />
                
                
                
                

                
             </div> 
        </li>
    );       
};


function ViewCategory(props){
    const {
        template,
        isThird,
        selectedTemplate ,
        index,
        deleteTemplate,
        selectedListIndex,
        selectedTemplateMessageHandle,
        updateTemplate,
        selectFileFunction,
        deleteImage,
        showEmojeContent,
        onEmojiClick,
        isShowEmoji,
        setSelectedThirdListIndex,
        isSaved

    } = props;
    const inputRef = useRef(null);
    const originalMessage = selectedTemplate?.message;
    return(
        <div key={index} className="PcRightBoard">
            <div className="second-answer-setting">
                {
                    isThird?
                        <div className="first-answer-wrap-title">
                            <div className="clear">
                                <span className="btn-primary mb-5"
                                    onClick={()=>{console.log("index:::", index); if(isThird) {setSelectedThirdListIndex(index)};}}
                                >
                                    {selectedTemplate.title}
                                    
                                        <i
                                            className="icon-close"
                                            onClick={() => {
                                                deleteTemplate(
                                                    selectedTemplate.templateId,
                                                    isThird
                                                );
                                            }}
                                        ></i>
                                    
                                </span>
                            </div>
                        </div>
                    :''
                }
                <p className="message-length">{selectedTemplate?.message?.length || 0}/200</p>
                
                <div className="second-answer-setting-contents">
                    <textarea
                        required
                        name="message"
                        placeholder="해당 답변 분야에 대한 간단한 안내 내용을 입력하세요"
                        type="text"
                        maxLength="200"
                        value={selectedTemplate?.message}
                        onChange={(e) => {
                            if(isThird) {selectedTemplateMessageHandle(e.target, index)}
                            else{selectedTemplateMessageHandle(e.target)}
                        }}
                        onClick={()=>{console.log("index:::", index); if(isThird) {setSelectedThirdListIndex(index)};}}
                    />
                    <div className="second-answer-setting-contents-files">
                        <div className="second-answer-setting-contents-imgs">
                            {
                                selectedTemplate?.contents &&
                                selectedTemplate?.contents?.map((item, index) => {
                                    return(
                                        <div key={index}>
                                            <img
                                                src={item.fileOrigin}
                                                alt="Second Template Img Data"
                                                height="30px"
                                                width="30px"
                                            />
                                            <i
                                                className="icon-close"
                                                onClick={() => {
                                                    deleteImage(
                                                        selectedTemplate?.templateId,
                                                        item.id
                                                        
                                                        )
                                                }}
                                            ></i>
                                        </div>
                                    )
                                })
                            }
                                        
                            
                        </div>
                        <div className="second-answer-setting-contents-add-wrap">
                            <div className="second-answer-setting-contents-add">
                                <div>
                                    {/* <i className="icon-emoticon-smile"
                                        //onClick={() => showEmojeContent(true, true)}
                                    ></i> */}
                                </div>
                                <div>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="input-file"
                                        style={{ display: "none" }}
                                        ref={inputRef}
                                        onChange={(event)=>{
                                                if(selectedTemplate?.message ==="" ){
                                                    window.alert("사진 첨부 전 내용을 입력해주세요. ");
                                                }
                                                selectFileFunction(event, selectedTemplate, isThird, originalMessage);
                                                inputRef.current.value="";

                                            }
                                        }

                                    />

                                    <i className="icon-paper-clip"
                                        type="button"
                                        onClick={() => {
                                            if(selectedTemplate?.message ===""){
                                                window.alert("사진 첨부 전 내용을 입력해주세요. ");
                                            }else{
                                                inputRef.current.click();
                                            }
                                            
                                        }}
                                    >
                                    </i>
                                </div>
                                {/* <div>
                                    <i className="icon-emoticon-smile"
                                        //onClick={() => showEmojeContent(true, true)}
                                    ></i>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="clear">
                        <button
                            onClick={()=>{updateTemplate(selectedTemplate)}}
                            className="btn btn-primary post-btn btn-1st-custom"
                        >
                            저장
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

function PreviewPanel(props){

    const {
        template,
        firstTemplate,
        selectedSecondListIndex,
        secondTemplateList,
        selectedThirdListIndex
    } = props;

    const [isShowSample, setIsShowSample] = useState(false);
    const toggleSample = () => {
        setIsShowSample(!isShowSample);
    }

    return(
        <div className="col-lg-6 col-xl-5 chatbot-phone">
            <DialogModal isOpen={isShowSample} toggle={toggleSample} centered={true} isClosable={true} size={'xl'} content={{
                title: '안내 시나리오',
                message: (<ScenarioGuideContent/>),
                actions: [],
            }} />

            {/* 우측화면 */}
            <div className="rightTemplate">
                <div className="rightBox">
                    <button className="btn btn-primary" onClick={toggleSample}>
                        템플릿 답변 시나리오 샘플
                    </button>
                </div>
                <div
                    className="drop-shadow"
                    style={{
                        minHeight: "500px",
                        width: "260px",
                        borderRadius: "28px",
                        margin: "0 auto",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "20px",
                            background: "#fff",
                            border: "1px solid #eee",
                            overflow: "hidden",
                        }}
                    >
                        <div
                            style={{
                                minHeight: "500px",
                                background: "#787878",
                                position: "relative",
                            }}
                        >
                            <div className="ChatTop d-flex align-items-center mt-auto">
                                <div>
                                    <small className="d-block">
                                        <i className="fe fe-arrow-left"></i>{" "}
                                        답변방 안내
                                    </small>
                                </div>
                                <div className="ml-auto">
                                    <a
                                        href="/" // TODO: href 링크 추후 수정
                                        className="icon d-none d-md-inline-block "
                                    >
                                        <i className="fe fe-user"></i>
                                    </a>
                                    <a
                                        href="/" // TODO: href 링크 추후 수정
                                        className="icon d-none d-md-inline-block ml-2"
                                    >
                                        <i className="fe fe-align-justify"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="PhoneBody p-2">
                                <div className="ChatOption d-flex align-items-center">
                                    <div>
                                        <small className="d-block">
                                            <img src={imgChatBag} />
                                            <span className="wordNum">
                                                {" "}
                                                0/100
                                            </span>
                                        </small>
                                    </div>
                                    <div className="ml-auto">
                                        <small className="d-block">
                                            답변톡쿠폰 : 0개
                                        </small>
                                    </div>
                                </div>
                                <div className="conArea mt-2">
                                    <div className="d-flex align-items-top mt-2">
                                        <img
                                            className="avatar avatar-md mr-2"
                                            src={imgHelper}
                                            alt=""
                                        />
                                        <div className="clear">
                                            <small className="d-block">
                                                답변 도우미
                                            </small>

                                            {template && firstTemplate?.message?.length > 2 &&                    
                                                <div className="bubble">
                                                    <p>{template && firstTemplate?.message}</p>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                    
                                                    </div>
                                                </div>
                                            }

                                            {
                                                template && secondTemplateList?.length >0 &&
                                                <div className="bubble">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                        }}
                                                    >
                                                        {
                                                            template && secondTemplateList?.map((item,index)=>{
                                                                return(
                                                                    <button key={index} className="btn tag btn-primary">
                                                                        {item.title}
                                                                    </button>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            }
                                            


                                            
                                            {
                                                template?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.title && (
                                                <div>
                                                    <div className="bubble noArrow">
                                                        <p>{secondTemplateList[selectedSecondListIndex]?.title}<br/></p>
                                                    </div>
                                                </div>
                                                )
                                                
                                            }
                                            
                                            {/* 2단계 메세지 */}
                                            
                                            {
                                                template?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.message && (
                                                <div>
                                                    <div className="bubble">
                                                        <p>{secondTemplateList[selectedSecondListIndex]?.message || "2단계 메세지를 입력해 주세요."}<br/></p>
                                                    </div>
                                                </div>
                                                )
                                                
                                            }
                                            
                                            {/* 2단계 이미지 */}
                                            {
                                                template?.subTemplate&&
                                                secondTemplateList[selectedSecondListIndex]?.contents &&
                                                secondTemplateList[selectedSecondListIndex]?.contents?.length >0 &&
                                                <div className="noArrow-img"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            width: "100%",
                                                        }}
                                                 >
                                                {
                                                    secondTemplateList[selectedSecondListIndex]?.contents?.map((item, index)=>{
                                                        return(
                                                            <img
                                                                key={index}
                                                                src={
                                                                    item.fileOrigin
                                                                }
                                                                style={{
                                                                    width: "100%",
                                                                    height: "auto",
                                                                    paddingBottom :"10px",
                                                                }}
                                                                alt="2단계이미지"
                                                            />
                                                        )
                                                    })
                                                }
                                                </div>
                                                
                                            }


                                            
                                            

                                            {/* 3단계 타이틀 리스트*/}
                                            {
                                                template?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.message && 
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate[0]?.title && (
                                                <div>
                                                    <div className="bubble">
                                                        <div
                                                            style={{
                                                                display:
                                                                    "flex",
                                                                flexDirection:
                                                                    "column",
                                                            }}
                                                        >
                                                           {
                                                            template && 
                                                            template?.subTemplate &&
                                                            secondTemplateList[selectedSecondListIndex]?.subTemplate &&
                                                            secondTemplateList[selectedSecondListIndex]?.subTemplate?.map((item,index)=>{
                                                                return(
                                                                    <button key={index} className="btn tag btn-primary">
                                                                        {item.title}
                                                                    </button>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                                
                                            }

                                            
                                            {/* 3단계 타이틀 사용자 선택 */}
                                            {
                                                template?.subTemplate&&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.title&&
                                                <div className="bubble noArrow"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection:
                                                            "column",
                                                    }}
                                                >
                                                    <p>
                                                        {template && secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.title}<br />
                                                        
                                                    </p>
                                                </div>
                                                
                                            }

                                            
                                            {/* 3단계 메세지 */}
                                            {
                                                template?.subTemplate&&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.message&&
                                                <div className="bubble"
                                                    style={{
                                                        display: "flex",
                                                        flexDirection:
                                                            "column",
                                                    }}
                                                >
                                                    <p>
                                                        {template && secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.message}<br />
                                                        
                                                    </p>
                                                </div>
                                                
                                            }

                                            {/* 3단계 이미지 */}
                                            {
                                                template?.subTemplate&&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.message && 

                                                <div  className="noArrow-img-area">
                                                    {/* <p> 
                                                        {template && secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.message}<br />
                                                    </p> */}
                                                    <div className="noArrow-img"
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            width: "100%",
                                                            // flexWrap:
                                                            //     "wrap",
                                                        }}
                                                    >
                                                        {
                                                            template && 
                                                            secondTemplateList[selectedSecondListIndex]?.subTemplate[selectedThirdListIndex]?.contents?.map((item, index)=>{
                                                                return(
                                                                    <img
                                                                        key={index}
                                                                        style={{
                                                                            width: "100%",
                                                                            height: "auto",
                                                                        }}
                                                                        src={
                                                                            item.fileOrigin
                                                                        }
                                                                        alt="세번 째 템플릿 이미지"
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    
                                                    </div>
                                                </div>
                                                
                                            }


                                            


                                            {
                                                template?.subTemplate &&
                                                secondTemplateList[selectedSecondListIndex]?.message && (
                                               
                                                    <div className="bubble">
                                                        {/* <p>{secondTemplateList[selectedSecondListIndex]?.message || "2단계 메세지를 입력해 주세요."}<br/></p> */}
                                                        <div
                                                            style={{
                                                                display:
                                                                    "flex",
                                                                flexDirection:
                                                                    "column",
                                                            }}
                                                        >
                                                            <button className="btn tag">
                                                                돌아가기
                                                            </button>
                                                            <button
                                                                className="btn btn-primary tag"
                                                            >
                                                                최종선택
                                                            </button>
                                                            
                                                        </div>
                                                    </div>
                                               
                                                )
                                                
                                            }

                                            
                                             
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}


export default withRouter(withTranslation()(AnswerGuideSettings2));

AnswerGuideSettings2.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
