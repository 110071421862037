import React, { useEffect, useRef } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Row, Col, Input, Button } from "reactstrap";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import config from "../../config/configuration";
import { authService } from "../../services/auth.service";
import { chatbotSettingsService } from "../../services/chatbotSettings.service";

const ForbiddenWords = () => {
    // const userId = localStorage.getItem("user_id");
    const briefREf = useRef();

    const [wordArr, setWordArr] = useState([]);
    const [briefReason, setBriefReason] = useState(null);

    const makeWords = async (value) => {
        if (value !== "") {
            if (wordArr.length <= 9) {
                if ([...wordArr].indexOf(value) !== -1) {
                    toast.error("요청 목록에 추가하신 단어입니다.");
                } else {
                    try {
                        const checkRes = await chatbotSettingsService.checkForbiddenWord(value)
                        const { status } = checkRes;
                        if (status === 204) {
                            setWordArr((prev) => [...prev, value]);
                        } else if (status === 201) {
                            toast.error("이미 등록된 단어입니다.");
                        } else {
                            return;
                        }
                    } catch (error) {
                        throw new Error(error);
                    }
                }
            } else {
                toast.error("한번에 10개까지 요청할 수 있습니다.");
            }
        }
    };

    const deleteWord = (value) => {
        setWordArr((prev) => {
            return prev.filter((x) => x !== value);
        });
    };

    async function handleSubmit() {
        const checkBrief = briefReason?.trim().length;
        if (wordArr.length > 0) {
            try {
                const response = await authService.getUserId()
                if(response.status===201) {
                    const forbiddenData = checkBrief
                        ? { userId: response.data.user_id, words: wordArr, briefReason: briefReason }
                        : { userId: response.data.user_id, words: wordArr };
                    const res = await chatbotSettingsService.postForbiddenWord(forbiddenData)
                    if (res.status === 201) {
                        setWordArr(() => {
                            return [];
                        });
                        briefREf.current.value = null;
                        setBriefReason(() => {
                            return null;
                        });
                        toast.success(
                            "입력 하신 단어가 금지어로 요청이 되었습니다. 확인 후 문제가 없으면 금지어로 적용 됩니다"
                        );
                    } else {
                        toast.error("요청에 실패했습니다.");
                    }
                }
            } catch (err) {
                throw new Error(err);
            }
        } else {
            toast.error("단어를 입력해 주세요");
        }
    }

    //meta title
    document.title = "Flowing";

    return (
        <React.Fragment>
            <li className="active">
                <Row>
                    <Col>
                        <div className="mb-2">
                            {wordArr.length !== 0 &&
                                wordArr.map((x, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="tag tag-gray mr-1"
                                        >
                                            <p>{x}</p>
                                            <i
                                                className="fe fe-x del_i"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    deleteWord(x);
                                                }}
                                            ></i>
                                        </div>
                                    );
                                })}
                        </div>
                    </Col>
                    <Col lg="12">
                        <Input
                            type="text"
                            className="form-control"
                            // placeholder="Please enter the prohibited words you want to request (up to 10)."
                            placeholder="요청할 단어 입력 후 엔터를 눌러주세요."
                            onKeyPress={(e) => {
                                const { key, target } = e;
                                if (key === "Enter") {
                                    e.preventDefault();
                                    makeWords(target.value);
                                    target.value = "";
                                }
                            }}
                        />
                    </Col>
                    <Col lg="12 mt-2">
                        <textarea
                            className="form-control "
                            rows="6"
                            ref={briefREf}
                            // placeholder="Please provide a brief reason for your request (optional/100 characters or less)."
                            placeholder="요청 사유를 간단하게 입력해 주세요(선택사항/100자 이내)."
                            onChange={(e) => setBriefReason(e.target.value)}
                            maxLength="100"
                        ></textarea>
                    </Col>
                    <Col lg="12 mt-2 text-right">
                        <Button className="btn btn-dark" onClick={handleSubmit}>
                            요청하기
                        </Button>
                    </Col>
                </Row>
            </li>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(ForbiddenWords));

ForbiddenWords.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
