export function isValidUrl(urlString) {
	var urlPattern = new RegExp(
		'^(https?:\\/\\/)?' + // validate protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
		'(\\#[-a-z\\d_]*)?$',
		'i',
	); // validate fragment locator
	return !!urlPattern.test(urlString);
}

export function getTimeRemaining(e) {
	const total = Date.parse(e) - Date.parse(new Date());
	const seconds = Math.floor((total / 1000) % 60);
	const minutes = Math.floor((total / 1000 / 60) % 60);
	const hours = Math.floor((total / 1000 / 60 / 60) % 24);
	const days = Math.floor((total / 60 / 60) % 24);
	return {
		total,
		days,
		hours,
		minutes,
		seconds,
	};
}

export function toggleRight(action) {
	if (action) {
		document.getElementById('mainContents').classList.add('sectionRight');
		document.getElementById('DetailView').classList.add('viewCon');
		document.getElementById('right-sidebar').classList.add('rightView');
	} else {
		document.getElementById('mainContents').classList.remove('sectionRight');
		document.getElementById('DetailView').classList.remove('viewCon');
		document.getElementById('right-sidebar').classList.remove('rightView');
	}
}
