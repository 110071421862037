import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {useState} from 'react';
//i18n
import {withTranslation} from 'react-i18next';
import MetisMenu from 'react-metismenu';
import {Link} from 'react-router-dom';
//toggling sidebar
import CustomLink from './CustomLink';
import '../../assets/css/myStyle.css';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
// import user1 from '../../assets/images/users/avatar-1.jpg';
import user1 from '../../assets/images/profile_blank.png';
import {userService} from '../../services/user.service';
import { authService } from '../../services/auth.service';

const Sidebar = (props) => {
	const [hideMenu, setHideMenu] = useState(true);
	const [menuIndex, setMenuIndex] = useState(0);
	const [userImage, setUserImage] = useState('');

	const getUserImg = async () => {
		try {
			const res = await authService.getUserId()
			console.log(res)
			if(res.status===201){
				const response = await userService.getUserImg(
					// localStorage.getItem('user_id'),
					res.data.user_id
				);
				// setUserImage(response?.image_list[0]?.image_url); // Error Occurred
				setUserImage(response && response.hasOwnProperty('image_url') && response.image_url || '');
			}
		} catch(err) {}
	};
	const subMenuTitle = [
		// props.t('Flowing answer management'),
		// props.t('Guide setting'),
		// props.t('Answer'),
		// props.t('Find a question'),
		// props.t('Manage product'),
		// props.t('Manage settlement'),
		"플로잉 답변관리",
		"스페이스 홈 설정",
		"답변톡 관리",
		"공개톡 관리",
		"상품 관리",
		"정산 관리",
	];

	const subMenu = [
		[
			{
				id: 1,
				label: props.t('Dashboard'),
				to: '/dashboard',
			},
			{
				id: 2,
				label: props.t('Manage profile'),
				to: '/answer/ProfileManagement',
			},
		],
		// [
			// {
			// 	id: 3,
			// 	label: props.t('스페이스 홈 관리'),
			// 	to: '/settings/landing-page',
			// },
			// {
			// 	id: 4,
			// 	label: props.t('Chat bot setting'),
			// 	to: '/settings/ChatbotSettings',
			// },
		// ],
		[
			{
				id: 5,
				label: props.t('Manage answering chat'),
				to: '/answer/chatroom',
			},
			{
				id: 6,
				label: props.t('Manage participant'),
				to: '/answer/ParticipantManagement',
			},
		],
		[
			{
				id: 7,
				label: props.t('Search question'),
				to: '/FindQuestion',
			},
			{
				id: 8,
				label: props.t('Manage chat'),
				to: '/question/chatroom',
			},
		],
		[
			{
				id: 9,
				label: props.t('Product setting'),
				to: '/productManagement/AnswerTalkProduct',
			},
		],
		[
			{
				id: 10,
				label: props.t('Enter settlement information'),
				to: '/settlementManagement/CalculateInfo',
			},
			{
				id: 11,
				label: props.t('View settlement report'),
				to: '/settlementManagement/CalculateList',
			},
			{
				id: 12,
				label: props.t('Settlement report'),
				to: '/settlementManagement/CalculateBoard',
			},
		],
	];
	useEffect(() => {
		getUserImg().then(r => r);
		if (sessionStorage.getItem("user_data")) {
			const obj = JSON.parse(sessionStorage.getItem("user_data"));
			setUserImage(obj.image_url);
		}
		checkSubMenuId();
		setHideMenu(true);
	}, []);

	function checkSubMenuId() {
		let currentPathId;
		for (let menu of subMenu) {
			for (let item of menu) {
				if (item.to === window.location.pathname) {
					currentPathId = item.id;
					localStorage.setItem('selectedSubMenuId', item.id);
					break;
				}
			}
		}
		if (window.location.pathname === '/answer/WriteProfile') {
			localStorage.setItem('selectedSubMenuId', 2);
			currentPathId = 2;
		}

		if (!currentPathId) {
			localStorage.setItem('selectedSubMenuId', 1);
			currentPathId = 1;
		}
		findMenuIndex(currentPathId);
	}

	function toggleMenu(e) {
		const body = document.body;
		setHideMenu(!hideMenu);
		if (window.screen.width <= 998) {
			body.classList.toggle('sidebar-enable');
		} else {
			body.classList.toggle('vertical-collpsed');
			body.classList.toggle('sidebar-enable');
		}
		document.getElementById('left-sidebar') &&
		document.getElementById('left-sidebar').classList.remove('d-none');
	}

	const toggleRight = (action) => {
		if (action) {
			document.getElementById('user_div').classList.add('sectionLeft');
		}
	};

	function changeSubMenu(menuIndex) {
		if (menuIndex >= 0) {
			setMenuIndex(menuIndex);
			localStorage.setItem('selectedSubMenuId', subMenu[menuIndex][0].id);
			props.history.push(subMenu[menuIndex][0].to);
		}
	}

	function findMenuIndex(id) {
		if (id === 1 || id === 2) setMenuIndex(0);
		// else if (id === 3 || id === 4) setMenuIndex(1);
		//else if (id >= 3 && id <= 4) setMenuIndex(1);
		else if (id === 5 || id === 6) setMenuIndex(1);
		else if (id === 7 || id === 8) setMenuIndex(2);
		else if (id === 9) setMenuIndex(3);
		else if (id >= 10) setMenuIndex(4);
	}

	return (
		<React.Fragment>
			<div className="">
				<div id="header_top" className="header_top">
					<div className="container">
						<div className="hleft">
							<div className="dropdown">
								<div className="nav-link user_btn">
									<img
										className="avatar"
										onClick={() => {
											toggleRight(true);
										}}
										src={userImage ? userImage : user1}
										alt=""
									/>
								</div>
								<ProfileMenu/>

								<div
									onClick={() => {
										changeSubMenu(0);
									}}
									className="nav-link icon"
								>
									<i
										className="fa fa-home"
										title="대시보드"
										style={{color: menuIndex === 0 && '#01a0fc'}}
									></i>
								</div>
								{/* <div
									onClick={() => {
										changeSubMenu(1);
									}}
									className="nav-link icon"
								>
									<i
										className="fa fa-cog"
										title="스페이스 홈 설정"
										style={{color: menuIndex === 1 && '#01a0fc'}}
									></i>
								</div> */}
								<div
									onClick={() => {
										changeSubMenu(1);
									}}
									className="nav-link icon"
								>
									<i
										className="fa fa-commenting"
										title="답변톡 관리"
										style={{color: menuIndex === 1 && '#01a0fc'}}
									></i>
								</div>
								<div
									onClick={() => {
										changeSubMenu(2);
									}}
									className="nav-link icon"
								>
									<i
										className="fa fa-wechat"
										title="공개톡 관리"
										style={{color: menuIndex === 2 && '#01a0fc'}}
									></i>
								</div>
								<div
									onClick={() => {
										changeSubMenu(3);
									}}
									className="nav-link icon"
								>
									<i
										className="fa fa-cubes"
										title="상품관리"
										style={{color: menuIndex === 3 && '#01a0fc'}}
									></i>
								</div>
								<div
									onClick={() => {
										changeSubMenu(4);
									}}
									className="nav-link icon"
								>
									<i
										className="fa fa-area-chart"
										title="정산관리"
										style={{color: menuIndex === 4 && '#01a0fc'}}
									></i>
								</div>
							</div>
						</div>
						<div className="hright">
							<div className="dropdown">
								<Link
									to="#"
									onClick={() => {
										toggleMenu();
									}}
									className="nav-link icon menu_toggle"
								>
									<i className="fa fa-navicon"></i>
								</Link>
							</div>
						</div>
					</div>
				</div>
				{hideMenu ? (
					<div id="left-sidebar">
						<h5 className="mt-5 d-flex justify-content-between brand-name">
							{subMenuTitle[menuIndex]}
						</h5>
						<nav id="left-sidebar-nav" className="mt-4">
							<MetisMenu
								className="metismenu ci-effect-1 view"
								content={subMenu[menuIndex]}
								noBuiltInClassNames={true}
								LinkComponent={CustomLink}
								activeLinkId={6}
								activeLinkLabel={'질문방 관리'}
								active={true}
								onSelected={(result) => {
									localStorage.setItem('selectedSubMenuId', result.id);
									if (result.newLocation)
										props.history.push(result.newLocation);
										// window.location.href = `${result.newLocation}`
								}}
							/>
						</nav>
					</div>
				) : (
					<div/>
				)}
			</div>
		</React.Fragment>
	);
};

Sidebar.propTypes = {
	type: PropTypes.string,
};

const mapStatetoProps = (state) => {
	return {
		layout: state.Layout,
	};
};
export default connect(mapStatetoProps)(withRouter(withTranslation()(Sidebar)));
