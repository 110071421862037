import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import DialogModal from '../../components/DialogModal/DialogModal';

import {
	Alert,
	CardBody,
	Card,
	CardFooter,
	Input,
	Label,
	CardTitle,
	FormGroup,
	Button,
	Form,
} from 'reactstrap';

//redux

import { withRouter, Link } from 'react-router-dom';

// import images
import logoFoot from '../../assets/images/logo_landingFoot.png';
//import service
import { authService } from '../../services/auth.service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'aws-amplify';

const Login = (props) => {
	//meta title
	document.title = 'Login | Flowing';
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [isAlert, setIsAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [isShowGuide, setIsShowGuide] = useState(false);


	const login = async () => {
		// if (email === null || email === "") {
		// if (!email) {
		// 	setAlertMessage("로그인 계정(이메일)을 입력해 주세요.");
		// 	setIsAlert(true);
		// // } else if (password === null || password === "") {
		// } else if (!password) {
		// 	setAlertMessage("비밀번호를 입력해 주세요.");
		// 	setIsAlert(true);
		// } else {
		// 	setIsAlert(false);
		// 	const result = await authService.login(email, password);
		// 	if (result.response.status === 400) {
		// 		setAlertMessage(result.response.data.place_holder);
		// 		setIsAlert(true);
		// 	}else if(result.response.status === 302){
		// 		// sessionStorage.setItem('renewUserId', JSON.stringify(result.response.data.user_id));
		// 		// TODO : 위와 같이 입력하면 userId 값을 쌍따옴표로 둘러싼 값이 입력된다. "..userId.."
		// 		sessionStorage.setItem('renewUserId', result.response.data.user_id);
		// 		sessionStorage.setItem('renewUserEmail', email);
		// 		window.alert("번호재인증이 필요하여 재인증 화면으로 이동합니다.");
		// 		props.history.push("/registerRenew");
				
		// 	}
		// }
		// if (result.status == 200) {
		//   props.history.push("/dashboard");
		//   window.location.href = "/dashboard";
		// }
		setIsAlert(()=>false);
		if(email.trim()===''){
			setAlertMessage("로그인 계정(이메일)을 입력해 주세요.");
			setIsAlert(()=>true);
			return;
		}
		if(password.trim()===''){
			setAlertMessage("비밀번호를 입력해 주세요.");
			setIsAlert(()=>true);
			return;
		}
		try {
			await authService.newSignIn({email, password}).then(r => r)
		} catch(error) {
			if(error?.status === 302) {
				sessionStorage.setItem("renewUserId", error?.data?.user_id)
				sessionStorage.setItem('renewUserEmail', email);
				window.alert("번호재인증이 필요하여 재인증 화면으로 이동합니다.");
				props.history.push("/registerRenew");
			}
			setAlertMessage(() => error?.data?.place_holder)
			setIsAlert(() => true)
			throw error
		}
	};

	return (
		<React.Fragment>
			<ToastContainer />

			<div className="auth">
				<div className="logo">
					<Link to="/">
						<img src={logoFoot} alt="logo" />
					</Link>
				</div>
				<div className="auth_left">
					<Form>
						<Card>
							<div className="card-top">
								<CardTitle>{/*Login Flowing*/}답변톡 콘솔 로그인</CardTitle>
							</div>
							<CardBody>
								{isAlert === true && (
									<Alert
										color="success"
										className="alert alert-icon alert-success fs12"
										role={alert}
									>
										<i className="fe fe-alert-triangle"></i>
										{props.t(alertMessage)}
									</Alert>
								)}
								<FormGroup>
									<Input
										className="form-control blackBox"
										type="email"
										placeholder={props.t('Please enter your account (email).')}
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										onKeyDown={(e) => e.key === 'Enter' && login()}
									/>
								</FormGroup>
								<FormGroup>
									<Input
										className="form-control blackBox"
										type="password"
										placeholder={props.t('Please enter a password.')}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										onKeyDown={(e) => e.key === 'Enter' && login()}
									/>
								</FormGroup>
								{/*<FormGroup>*/}
								{/*	<Label className="custom-control custom-checkbox">*/}
								{/*		<Input type="checkbox" className="custom-control-input" />*/}
								{/*		<span className="custom-control-label">*/}
								{/*			/!*Saving your login information*!/로그인 정보 저장*/}
								{/*		</span>*/}
								{/*	</Label>*/}
								{/*</FormGroup>*/}
								<div className="form-footer">
									<Button
										className="btn btn-primary btn-block btn-lg"
										onClick={login}
									>
										{/*Log in*/}로그인
									</Button>
								</div>
							</CardBody>
							<CardFooter className="text-center text-muted">
								<Link to="/forgot-password">
									<i className="fa fa-lock mr-1"/>
									<span className="blackColor">{/*Forgot your password?*/}비밀번호 재설정</span>
								</Link>
							</CardFooter>
							<div className="text-center mt-0 pb-2">
								{/*Not a Flowing member?*/}아직 플로잉 회원이 아니세요?
								<Link to="/register"> {/*Sign up now.*/}신규회원가입</Link>
							</div>
							<div className="text-center">
								<a href="#" onClick={(ev) => {
									ev.preventDefault();
									setIsShowGuide(true);
								}}>이메일 계정과 비밀번호를 설정하지 않으셨나요?</a>
							</div>
							<div className="mt-5">
								<p className="Copyright text-center">
									© 2022 Copyright Tpkorea Co.,Ltd.{' '}
								</p>
							</div>
						</Card>
					</Form>
				</div>

				<DialogModal isOpen={isShowGuide} toggle={() => {setIsShowGuide(!isShowGuide)}}  centered={true} isClosable={true} content={{
					title: '패스 워드 등록 방법',
					message: (<div>
						앱으로 계정 회원 가입을 하신 경우, 이메일 로그인을 위한 패스워드 설정이 등록하신 이메일로 전송되어 있습니다. (유효 기간은 7일)
						<br/>
						7일이 경과 한 경우는 app  마이페이지 > 정보 설정 > 계정 이메일 > 비밀 번호 초기화를 클릭 하시면 다시 이메일로 비밀 번호 설정 경로가 전송됩니다.
					</div>),
					actions: [],
				}} />

				<div className="auth_right" />
			</div>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(Login));

Login.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
