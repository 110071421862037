/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import '../../assets/css/myStyle.css';

const CustomLink = (props) => {
	function onClick() {
		props.activateMe({
			newLocation: props.to,
			selectedMenuLabel: props.label,
			id: props.id,
		});
	}

	return (
		<a
			onClick={() => {
				onClick();
			}}
			className="metisMenuA"
			style={{fontSize: 15}}
		>
      <span
		  id={props.id}
		  data-hover={props.children[1]}
		  style={{
			  color:
				  props.id === parseInt(localStorage.getItem('selectedSubMenuId'))
					  ? '#2A2C35'
					  : '#202121',
			  fontWeight:
				  props.id === parseInt(localStorage.getItem('selectedSubMenuId')) &&
				  700
		  }}
	  >
        {props.children[1]}
      </span>
		</a>
	);
};

export default withRouter(withTranslation()(CustomLink));

CustomLink.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
