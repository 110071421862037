import React from 'react';
import {Container} from 'reactstrap';
import SearchQuestion from './SearchQuestion.js';

const FindQuestion = (props) => {
	//meta title
	document.title = 'Flowing';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<div className="section-body">
					<Container fluid>
						<SearchQuestion/>
					</Container>
				</div>
			{/* </div> */}
		</React.Fragment>
	);
};

export default FindQuestion;
