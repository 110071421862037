import React from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {toast} from 'react-toastify';

import {userProfileChatRoomService} from '../../../services/talk2meMessage.service';

const ChatHeader = (props) => {
	const {timer, isOwner, ownerData} = props;

	// user profile ON / OFF
	const userProfileChatRoomFunction = async () => {
		let data = {
			userId: props.user.id,
			openProfile: ownerData.openProfile === 'on' ? 'off' : 'on',
		};
		const response = await userProfileChatRoomService(props.roomId, data);

		if (response.message === 'Updated') {
			ownerData.openProfile = ownerData.openProfile === 'on' ? 'off' : 'on';
			props.setOwnerData(ownerData);
			// toast.success('change user profile');
			toast.success('사용자 프로필을 변경했습니다.');
		}
	};
	// console.log('room', props.room)

	return (
		<div className="card-header pt-0 pl-0 pr-0">
			<h3 className="card-title font-weight-bolder">{props.room.name}</h3>
			<div className="card-options">
				<div className=" b-none bg-none p-1 time">
					<i className="fa far fa-clock"></i>
					<span style={{marginLeft: '10px', marginRight: '10px'}}>
            			{timer}
					</span>
					{/*채택포인트 : 100*/}채택 보상 : {props.room?.rewardType === 'point' ? '포인트 ' : (props.room?.rewardType === 'ruby' ? '루비' : '다이아')} {props.room?.rewardCount}
				</div>
				{isOwner && (
					<button
						onClick={() => userProfileChatRoomFunction()}
						className="b-none bg-none p-1"
					>
						{ownerData.openProfile === 'on' ? (
							<i className="fas fa-bell-slash"></i>
						) : (
							<i className="fa fa-bell"></i>
						)}
					</button>
				)}
				<button
					onClick={() => {
						props.settingsMenu();
					}}
					className="b-none bg-none p-1"
				>
					<i className="fa fa-cog"></i>
				</button>
			</div>
		</div>
	);
};

export default withRouter(withTranslation()(ChatHeader));
