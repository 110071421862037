import moment from 'moment/moment';
import {isNum} from '../common/utils/functions';

export function calculateDateDiff(startDate, finishDate) {
    try {
        const now = new Date()
        // const today = moment() // moment time인 경우 diff 계산이 -1 만큼 줄어들기 때문에 아래처럼 날짜값을 생성해서 사용해야 함
        const year = now.getFullYear()
        const month = (now.getMonth() + 1).toString()
        const day = now.getDate()
        const today = moment(year + '/' + month + '/' + day)

        if(today.isAfter(finishDate)) return 'CLOSED';
        // if(today.isBetween(startDate, finishDate)) return 'OPEN'; // 시작일 또는 종료일이 동일 날짜인 경우 moment 기준으로는 between 이 false. 따라서 아래 코드 사용해야 함
        if(today.isSameOrAfter(startDate) && today.isSameOrBefore(finishDate)) return 'OPEN';
        if(today.isBefore(startDate) && Math.abs(today.diff(startDate, 'days')) <= 7) return 'SOON';
        if(today.isBefore(startDate) && Math.abs(today.diff(startDate, 'days')) > 7) return 'D-' + String(moment(startDate).diff(today, 'days'));
        return '';
    } catch (e) {
        console.error(e);
        return '';
    }
}

export function displayTimeByKST(str) {
    if(!str || !isNum(str)) return '';
    if(str > 12) {
        return `오후 ${(str - 12)+''}시`;
    } else {
        return `오전 ${str}시`;
    }
}

const displayDateTime = {
    calculateDateDiff,
    displayTimeByKST,
}

export default displayDateTime;