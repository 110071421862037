import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {getQna as getData} from '../../services/noticeView.service';

import {Container, Row, Table} from 'reactstrap';
import moment from 'moment/moment';

const NoticeView = (props) => {
	//meta title
	const [noticeList, setNoticeList] = useState([]);
	const getNotice2 = async () => {
		const result = await getData();
		console.log('result', result);
		setNoticeList(result);
	};
	useEffect(() => {
		getNotice2().then(r => r);
	}, []);
	const handleClick = (event) => {
		const id = event.currentTarget.id;
		console.log('event.currentTarget.id', id); //return ;
		// withRouter(props.history.push(`/qna/NoticeDetail?id=${id}`));
		props.history.push({
			pathname: '/qna/NoticeDetail',
			id: id,
		});
	};
	document.title = '플로잉 공지사항';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<Container fluid>
					<Row className="align-items-center">
						<div className="col text-left">
							<h1 className="page-title">플로잉 공지사항</h1>
						</div>
					</Row>
					<div className="table-responsive">
						<Table className="table m-b-0 table-vcenter text-nowrap tableList">
							<thead className="thead-light">
							<tr>
								<th>번호</th>
								<th>등록일</th>
								<th>제목</th>
								<th>작성자</th>
							</tr>
							</thead>
							<tbody>
							{noticeList.map((item, index) => {
								return (
									<tr
										/*key={item.article_number}*/
										key={item.id}
										/*id={item.article_number}*/
										id={item.id}
										onClick={handleClick}
									>
										<th>{noticeList.length - index}</th>
										<td>{moment(item.createdAt).format("YYYY-MM-DD")}{/*{item.created_date}*/}</td>
										<td>{item.title}</td>
										<td>{item.writer}</td>
									</tr>
								);
							})}
							</tbody>
						</Table>
					</div>
				</Container>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(NoticeView));

NoticeView.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
