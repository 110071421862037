import React, {useEffect, useState} from 'react';
import _, {map} from 'lodash';
import {Button, Container, Input, InputGroup, Nav, NavItem, NavLink, Spinner} from 'reactstrap';
import '../../assets/css/myStyle.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {retriveMyRoomList} from '../../services/talk2meRooms.service';
import {Amplify} from 'aws-amplify';
// defualt image
import profile from '../../assets/images/users/defualt-image.png';
//import chatbox
import classnames from 'classnames';
import ChatBox from './chatbox';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import {useLocation} from 'react-router';

const Chat = () => {
	const [currentRoomId, setCurrentRoomId] = useState('');
	const [currentRoomData, setCurrentRoomData] = useState('');
	const [currentRoomOwnerData, setCurrentRoomOwnerData] = useState({});
	const [newMessage, setNewMessage] = useState({});
	const [myInactiveRooms, setInactiveMyRooms] = useState([]);
	const [myRooms, setMyRooms] = useState([]);
	const [loading, setLoading] = useState();
	const [refreshWindow, setRefreshWindow] = useState();
	const [userInformation, setUserInformation] = useState();
	const [customActiveTab, setcustomActiveTab] = useState('1');

	const [relatedRoomId, setRelatedRoomId] = useState("");
	const [relatedRoomData, setRelatedRoomData] = useState("");
	const [relatedRoomOwnerData, setRelatedRoomOwnerData] = useState("");

	const location = useLocation();

	const toggleCustom = (tab) => {
		if (customActiveTab !== tab) {
			setcustomActiveTab(tab);
		}
	};

	useEffect(() => {
		console.log(location.data)
		const getArgData = async () => {
			if(location.data) {
				const room = location.data;
				setRelatedRoomId(room?.roomId);
				setRelatedRoomData(room);
				let ownerData = _.find(room?.activeUsers, function (o) {
					return o.userId === room?.ownerId;
				});
				setRelatedRoomOwnerData(ownerData)

				return room;
			}
		}
		getArgData().then();
	}, []);

	useEffect(() => {
		async function getUserInfo() {
			let userInfo = sessionStorage.getItem('user_data');
			// let userInfo = localStorage.getItem('user_data');
			setUserInformation(JSON.parse(userInfo));
		}

		getUserInfo().then(r => r);
	}, []);

	useEffect(() => {
		// get rooms service
		async function fetchRoomList() {
			setLoading(true);
			const resultRoomList = await retriveMyRoomList(userInformation.id);

			if (resultRoomList && resultRoomList?.itemList?.length > 0) {
				let rooms = resultRoomList?.itemList;
				let ownerData = _.find(rooms[0]?.activeUsers, function (o) {
					return o.userId === rooms[0]?.ownerId;
				});

				//kms modify 20221029 START
				//1.room ownerid가 로그인 한 사람인 경우 room list에서 제외한다
				//2.deadline property 가 ""인것은 제외
				rooms = rooms.filter(
					(room) => room.ownerId !== userInformation.id && room.deadline !== ""
				);
				if(rooms.length === 0) {
					toast.info('현재 답변 중인 공개톡방이 없습니다.');
					setLoading(false);
					return;
				}
				//kms modify 20221029 END

				// Rooms id subscribe
				map(rooms, (room) => {
					Amplify.PubSub.subscribe(room.roomId).subscribe({
						next: (data) => {
							setNewMessage(data.value);
						},
						error: (error) => console.error(error),
					});
				});

				if(relatedRoomId) {
					const filteredRoom = rooms.filter((room) => room.roomId===location.data.roomId)
					// setCurrentRoomOwnerData(relatedRoomOwnerData);
					// setCurrentRoomId(relatedRoomId);
					// setCurrentRoomData(relatedRoomData);
					userChatOpen(filteredRoom[0], rooms)
				} else {
					setCurrentRoomOwnerData(ownerData);
					setCurrentRoomId(rooms[0]?.roomId);
					setCurrentRoomData(rooms[0]);
				}
				setMyRooms(rooms);
				setLoading(false);

			} else {
				// toast.info('You currently have no rooms');
				toast.info('현재 활동 중인 공개톡방이 없습니다.');
				setLoading(false);
			}
		}

		if (userInformation) fetchRoomList().then(r => r);

	}, [refreshWindow, userInformation]);

	useEffect(() => {
		if (newMessage.senderId !== 'SYSTEM') {
			if (newMessage.action === 'read') return;
			if (newMessage.roomId) {
				let roomIndex = _.findIndex(myRooms, {roomId: newMessage.roomId});
				let roomData = _.find(myRooms, function (o) {
					return o.roomId === newMessage.roomId;
				});
				if (
					userInformation.id !== newMessage.senderId &&
					newMessage.senderId !== 'SYSTEM' &&
					newMessage.roomId !== currentRoomId
				) {
					roomData.unreadCount = roomData.unreadCount + 1;
				}
				roomData.lastMessage = {
					createdAt: newMessage.createdAt,
					message: newMessage.message,
					msgType: newMessage.msgType,
					senderId: newMessage.senderId,
				};
				let newArr = [...myRooms];
				newArr[roomIndex] = roomData;
				setMyRooms(newArr);
			}
		}

		if (newMessage?.roomId && newMessage?.action === 'enter') {
			let roomIndex = _.findIndex(myRooms, {
				roomId: newMessage?.roomId,
			});
			if (roomIndex > -1) {
				myRooms[roomIndex].activeUsers.push(newMessage.targetData);
			}
		}
	}, [newMessage]);

	const handleChange = () => {
		setRefreshWindow(!refreshWindow);
	};

	//Use For Chat room open
	const userChatOpen = (room, rooms) => {
		if (room.roomId) {
			let roomIndex = _.findIndex(myRooms, {roomId: room.roomId});
			let roomData = _.find(rooms ? rooms : myRooms, function (o) {
				return o.roomId === room.roomId;
			});

			//KMS modify 채팅창에서 userNick을 받아오지 못해서 방참가자의 데이타를 가져와서 입력해놓고 
			//이를 이용하여 채팅에서 사용한다.
			sessionStorage.setItem('sessionUsers', JSON.stringify(room.activeUsers));
			//======================================================================

			let ownerData = _.find(room.activeUsers, function (o) {
				return o.userId === room.ownerId;
			});
			roomData.unreadCount = 0;
			let newArr = [...myRooms];
			newArr[roomIndex] = roomData;
			setMyRooms(newArr);
			setCurrentRoomId(room.roomId);
			setCurrentRoomData(roomData);
			setCurrentRoomOwnerData(ownerData);
		}
	};

	//search recent user
	const searchUsers = () => {
		var input, filter, ul, li, a, i, txtValue;
		input = document.getElementById('search-user');
		filter = input.value.toUpperCase();
		ul = document.getElementById('recent-list');
		li = ul.getElementsByTagName('li');
		for (i = 0; i < li.length; i++) {
			a = li[i].getElementsByTagName('span')[0];
			txtValue = a.textContent || a.innerText;
			if (txtValue.toUpperCase().indexOf(filter) > -1) {
				li[i].style.display = '';
			} else {
				li[i].style.display = 'none';
			}
		}
	};

	return (
		<React.Fragment>
			<div>
				<ToastContainer/>
				<div className="section-body" id="GGGG">
					<div className="container-fluid chat-container">
						<div className="row">
							<div className="col-12">
								<div className="page-title-box d-sm-flex align-items-center justify-content-between">
									<h4 className="mb-0 font-size-18">질문 방 관리</h4>
								</div>
							</div>
						</div>
						{loading ? (
							<Spinner type="grow" color="warning"/>
						) : (
							<div className="row clearfix chat-bg">
								<div className="col-lg-12">
									<div className="section-light py-3 chat_app">
										<div className="chat_list" id="users">
											<h5 className="font-weight-bolder page-title">질문방</h5>
											<Container fluid>
												
												<Nav
													tabs
													className="nav nav-tabs b-none"
												>
													<NavItem>
														<NavLink
															style={{cursor: 'pointer'}}
															className={classnames({
																active: customActiveTab === '1',
															})}
															onClick={() => {
																toggleCustom('1');
															}}
														>
															전체
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
															style={{cursor: 'pointer'}}
															className={classnames({
																active: customActiveTab === '2',
															})}
															onClick={() => {
																toggleCustom('2');
															}}
														>
															답변중
														</NavLink>
													</NavItem>
													<NavItem>
														<NavLink
															style={{cursor: 'pointer'}}
															className={classnames({
																active: customActiveTab === '3',
															})}
															onClick={() => {
																toggleCustom('3');
															}}
														>
															미답변
														</NavLink>
													</NavItem>
												</Nav>
											</Container>
											<div className="input-group mt-3 mb-2">
												<InputGroup>
													<Input
														id="search-user"
														type="text"
														onChange={searchUsers}
														className="form-control"
														placeholder="질문방 검색하기"
													/>
													<span className="input-group-append">
														<Button
															className="btn btn-secondary"
															onClick={() => {
																searchUsers();
															}}
														>
														<i className="icon-magnifier"></i>
														</Button>
													</span>
												</InputGroup>
											</div>
											<ul
												id="recent-list"
												className="right_chat list-unstyled list"
											>
												{(customActiveTab === '1' || customActiveTab === '2') &&
													map(myRooms, (room, index) => (
														<li
															className={
																currentRoomId === room.roomId
																	? 'online active'
																	: 'online'
															}
															key={index}
															style={{
																backgroundColor:
																	currentRoomId === room.roomId && '#e8eaf6',
															}}
														>
															<div
																onClick={() => {
																	userChatOpen(room);
																}}
																className="media"
															>
																<img
																	className="media-object"
																	src={
																		(room?.activeUsers[0] &&
																			room?.activeUsers[0]?.userImg !==
																			'(null)' &&
																			room?.activeUsers[0]?.userImg) ||
																		profile
																	}
																	alt=""
																/>
																<div className="media-body">
																	<span id="ownerName" className="name">
																	{room.name}
																		<span className="badge badge-primary badge-pill float-right">
																		{room.unreadCount > 0 && room.unreadCount}
																	</span>
																	</span>
																	<span
																		className="message chat-side-menu"
																		style={{
																			color: room.unreadCount > 0 && '#283593',
																		}}
																	>
																		{room.lastMessage?.msgType === 'PREVIEW' &&
																			room.lastMessage?.message}
																		{room.lastMessage?.msgType === 'TEXT' &&
																			room.lastMessage?.message}
																		{room.lastMessage?.msgType === 'PHOTO' &&
																			// 'sent photo'}
																			'사진 전송'}
																		{room.lastMessage?.msgType === 'VIDEO' &&
																			// 'sent video'}
																			'영상 전송'}
																		{room.lastMessage?.msgType === 'VOICE' &&
																			// 'sent voice'}
																			'음성 전송'}
																		{room.lastMessage?.msgType === 'FILE' &&
																			// 'sent file'}
																			'파일 전송'}
																		{room.lastMessage?.msgType === 'NOTICE' &&
																			// 'NOTICE'}
																			'알림 메시지'}
																		{room.lastMessage?.msgType === 'INFO' &&
																			'INFO 메시지'}
																	</span>
																	<span className="badge badge-outline status"></span>
																</div>
															</div>
														</li>
													))}
													{customActiveTab === '1' &&
													myInactiveRooms.length > 0 && <li>미답변</li>}
												{(customActiveTab === '1' || customActiveTab === '3') &&
													map(myInactiveRooms, (room, index) => (
														<li
															className={
																currentRoomId === room.roomId
																	? 'online active'
																	: 'online'
															}
															key={index}
															style={{
																backgroundColor:
																	currentRoomId === room.roomId && '#e8eaf6',
															}}
														>
															<div
																onClick={() => {
																	userChatOpen(room);
																}}
																className="media"
															>
																<img
																	className="media-object"
																	src={room.userImg || profile}
																	alt=""
																/>
																<div className="media-body">
																	<span id="ownerName" className="name">
																		{room.userName} {/*inactive room*/}
																		<span className="badge badge-primary badge-pill float-right">
																		{room.unreadCount > 0 && room.unreadCount}
																		</span>
																	</span>
																	<span
																		className="message chat-side-menu"
																		style={{
																			color: room.unreadCount > 0 && '#283593',
																		}}
																	>
																		{room.lastMessage?.msgType === 'PREVIEW' &&
																			room.lastMessage?.message}
																		{room.lastMessage?.msgType === 'TEXT' &&
																			room.lastMessage?.message}
																		{room.lastMessage?.msgType === 'PHOTO' &&
																			// 'sent photo'}
																			'사진 전송'}
																		{room.lastMessage?.msgType === 'VIDEO' &&
																			// 'sent video'}
																			'영상 전송'}
																		{room.lastMessage?.msgType === 'VOICE' &&
																			// 'sent voice'}
																			'음성 전송'}
																		{room.lastMessage?.msgType === 'FILE' &&
																			// 'sent file'}
																			'파일 전송'}
																		{room.lastMessage?.msgType === 'NOTICE' &&
																			// 'NOTICE'}
																			'알림 메시지'}
																		{room.lastMessage?.msgType === 'INFO' &&
																			'INFO 메시지'}
                                 									</span>
																	<span className="badge badge-outline status"></span>
																</div>
															</div>
														</li>
													))}
											</ul>
										</div>
										{currentRoomId && (
											<ChatBox
												key={currentRoomId}
												roomId={currentRoomId}
												room={currentRoomData}
												currentRoomOwnerData={currentRoomOwnerData}
												user={userInformation}
												onChange={() => handleChange()}
											/>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(Chat));
