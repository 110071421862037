import { memo } from 'react'
import './TagList.scoped.scss';
import {get} from 'lodash';

const TagList = ({tags=[], prefix='', keyPath=''}) => {
    return (
        <div className="sh-ui--tag-list">
            {tags.map((item, index) => (
                <div className="sh-ui--tag-list__item" key={item?.id || index}>
                    {!!prefix ? (
                        <span>{prefix}</span>
                    ) : ''}
                    {!!keyPath ? get(item, keyPath, `${item}`) : item}
                </div>
            ))}
        </div>
    )
}

export default memo(TagList);