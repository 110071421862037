import React from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';

const BlockModal = (props) => {
	const {kickRoomModal} = props;

	return (
		<Modal returnFocusAfterClose={true} isOpen={kickRoomModal} centered={true}>
			<ModalBody className="text-center">
				입장 불가 방장에 의해서 내보내기를
				<br/> 당한 유저는 입장을 할수 없습니다.
				<br/>
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => props.kickRoom()}>
					확인
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default withRouter(withTranslation()(BlockModal));
