import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import RightAnswerMenu from './RightAnswerMenu';
import RightCategoryMenu from './RightCategoryMenu';
import RightMenuContent from './RightMenuContent';

const RightMenuProfile = (props) => {
	// Right menu toglle
	const toggleRight = (action) => {
		if (!action) {
			document.getElementById(props.menuValue).classList.remove('sectionRight');
			document.getElementById('right-sidebar').classList.remove('rightView');
			document.getElementById('mainContents').classList.remove('sectionRight');
		}
	};

	return (
		<React.Fragment>
			<div id="right-sidebar" onClick={(ref)=>console.log(2)}>
				<div
					className="d-flex justify-content-between brand_name"
					onClick={() => {toggleRight(false);props.setMenuValue("")}}
				>
					<h5 className="brand-name mb-4">
						<i className="icon-close" style={{cursor:'pointer'}}></i>

						<span id="rightMenuTitle">
						{props.menuValue === 'cateArea' && '답변 카테고리 검색'
							? '' : (props.menuValue === 'AreaArea' ? ''
							: ' 상세보기')}
						</span>
					</h5>
				</div>
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-lg-12">
							<div className="BtnDown">
								<a href="#">
									<i className="fe fe-download"></i>
								</a>
							</div>
							{/* {props.menuValue === 'cateArea' ? (
								<RightCategoryMenu {...props} />
							) : props.menuValue === 'AreaArea' ? (
								<RightAnswerMenu {...props} />
							) : props.menuValue === 'contentArea' ? (
								<RightMenuContent {...props} />
							) : (
								''
							)} */}

							{props.menuValue === 'cateArea' ? (
								<RightCategoryMenu {...props} />
							) : props.menuValue === 'AreaArea' ? (
								<RightAnswerMenu {...props} />
							) :""
							}	
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

RightMenuProfile.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(RightMenuProfile));
