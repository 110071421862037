import React from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import plane from '../../../assets/images/plane.png';

const Time = (props) => {
	// console.log('props ', props.message?.createdAt)
	return (
		<React.Fragment>
			<span className="time">
				{Number(moment(props.message.createdAt).format('HH')) < 12 ? '오전 ' : '오후 '}
				{moment(props.message.createdAt).format('hh:mm')}
				{/*{' ' + moment(Number(props.message?.hashCode)).format('HH:mm:sss') || ''}*/}
				{/*{' ' + moment(props.lastReadHashCode).format('HH:mm:sss') || ''}*/}

				{/* Start seen icon */}
				{/*{!props.ownerSeen &&
				  props.unReadOwnerMsgCount > 0 &&
				  props.messages.length - props.unReadOwnerMsgCount <= props.index && (*/}
				{/*{!(props.message?.hashCode && props.lastReadMsgTime
						&& moment(Number(props.message?.hashCode)).isSameOrBefore(moment(props.lastReadMsgTime))) &&
					props.userId === props.message.senderId &&
					!(props.message?.unreadCount === 0) && (*/}
				{props.userId === props.message.senderId &&
					!(props.message?.unreadCount === 0) && (
					  <img src={plane} className="img-thumbnail unread_icon" alt=""/>
				  )}
				{/* End seen icon */}
				</span>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(Time));
