/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';

// defualt image
import preveiwImg from '../../../assets/images/chatroom/site_name.png';

const Preview = (props) => {
	document.title = 'Chatroom';
	const [url, setUrl] = useState();

	const checkUrl = (url) => {
		let array = url.split('//');
		if (array[0] === 'https:' || array[0] === 'http:') {
			setUrl(url);
		} else {
			setUrl('https:' + url);
		}
	};

	// IOT new message
	useEffect(() => {
		checkUrl(props.message.message.split(' ')[0]);
	}, [props.message.message]);

	return (
		<React.Fragment>
			<a className="media_img preview_data_container" href={url} target="popup">
				<div className="preview_content">
					<img
						src={props.message.previewData.image || preveiwImg}
						className="img-thumbnail"
						alt=""
					/>
					<div className="preview_data">
						<div className="title">{props.message.previewData.title}</div>
						<div className="desc">{props.message.previewData.description}</div>
						<div className="link">{props.message.message}</div>
					</div>
				</div>
			</a>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(Preview));
