import ic_social01 from '../assets/images/ic_social01.jpg';
import ic_social02 from '../assets/images/ic_social02.jpg';
import ic_social03 from '../assets/images/ic_social03.jpg';
import ic_social04 from '../assets/images/ic_social04.jpg';
import ic_social05 from '../assets/images/ic_social05.jpg';
import ic_social06 from '../assets/images/ic_social06.jpg';
import ic_social07 from '../assets/images/ic_social07.jpg';
import ic_social08 from '../assets/images/ic_social08.jpg';
import ic_social09 from '../assets/images/ic_social09.jpg';
import ic_social10 from '../assets/images/ic_social10.jpg';
import ic_social11 from '../assets/images/ic_social11.jpg';
import ic_social12 from '../assets/images/ic_social12.jpg';
import ic_social13 from '../assets/images/ic_social13.jpg';
import ic_social14 from '../assets/images/ic_social14.jpg';
import ic_social15 from '../assets/images/ic_social15.jpg';
import ic_social16 from '../assets/images/ic_social16.jpg';
import ic_social17 from '../assets/images/ic_social17.jpg';
import ic_social18 from '../assets/images/ic_social18.jpg';
import ic_social19 from '../assets/images/ic_social19.jpg';
import ic_social20 from '../assets/images/ic_social20.jpg';
import ic_social21 from '../assets/images/ic_social21.jpg';
import ic_social22 from '../assets/images/ic_social22.jpg';
import ic_social23 from '../assets/images/ic_social23.jpg';
import ic_social24 from '../assets/images/ic_social24.jpg';
import ic_social25 from '../assets/images/ic_social25.jpg';
import ic_social31 from '../assets/images/ic_social31.jpg';
import ic_social32 from '../assets/images/ic_social32.jpg';
import ic_social33 from '../assets/images/ic_social33.jpg';
import ic_social41 from '../assets/images/ic_social41.jpg';
import ic_social42 from '../assets/images/ic_social42.jpg';
import ic_social43 from '../assets/images/ic_social43.jpg';
import ic_social44 from '../assets/images/ic_social44.jpg';
import ic_social51 from '../assets/images/ic_social51.jpg';
import ic_social52 from '../assets/images/ic_social52.jpg';
import ic_social53 from '../assets/images/ic_social53.jpg';
import ic_social54 from '../assets/images/ic_social54.jpg';
import ic_social55 from '../assets/images/ic_social55.jpg';
import ic_social91 from '../assets/images/ic_social91.jpg';
import ic_social92 from '../assets/images/ic_social92.jpg';
import ic_social99 from '../assets/images/img_ch01.png';

const selectOptions =[
	{value:"99", label :"직접 업로드", image:ic_social99},
	{value:"1",  label :"유튜브", image:ic_social01},
	{value:"2",  label :"인스타그램", image:ic_social02},
	{value:"3",  label :"페이스북", image:ic_social03},
	{value:"4",  label :"트위터", image:ic_social04},
	{value:"5",  label :"틱톡", image:ic_social05},
	{value:"6",  label :"트위치", image:ic_social06},
	{value:"7",  label :"아프리카", image:ic_social07},
	{value:"8",  label :"펜더TV", image:ic_social08},
	{value:"9",  label :"스마트스토어", image:ic_social09},
	{value:"10", label :"블로그", image:ic_social10},
	{value:"11", label :"밴드", image:ic_social11},
	{value:"12", label :"클래스101", image:ic_social12},
	{value:"13", label :"크몽", image:ic_social13},
	{value:"14", label :"탈잉", image:ic_social14},
	{value:"15", label :"패스트캠퍼스", image:ic_social15},
	{value:"16", label :"브런치", image:ic_social16},
	{value:"17", label :"유데미", image:ic_social17},
	{value:"18", label :"Medium", image:ic_social18},
	{value:"19", label :"마스터클래스", image:ic_social19},
	{value:"20", label :"퍼블리", image:ic_social20},
	{value:"21", label :"숨고", image:ic_social21},
	{value:"22", label :"아이디어스", image:ic_social22},
	{value:"23", label :"네이버라이브쇼핑", image:ic_social23},
	{value:"24", label :"쿠팡", image:ic_social24},
	{value:"25", label :"쿠팡라이브", image:ic_social25},
	{value:"31", label :"카카오톡", image:ic_social31},
	{value:"32", label :"카톡오픈채팅", image:ic_social32},
	{value:"33", label :"카톡채널", image:ic_social33},
	{value:"41", label :"네이버", image:ic_social41},
	{value:"42", label :"네이버카페", image:ic_social42},
	{value:"43", label :"네이버폼", image:ic_social43},
	{value:"44", label :"네이버플레이스", image:ic_social44},
	{value:"51", label :"yes24", image:ic_social51},
	{value:"52", label :"교보문구", image:ic_social52},
	{value:"53", label :"아마존", image:ic_social53},
	{value:"54", label :"영풍문구", image:ic_social54},
	{value:"55", label :"뉴스킨", image:ic_social55},
	{value:"91", label :"플로잉스페이스", image:ic_social91},
	{value:"92", label :"플로잉레이지몰", image:ic_social92},
];

const daysInKr = {
	"MON" : "월",
	"THE" : "화",
	"WED" : "수",
	"THU" : "목",
	"FRI" : "금",
	"SAT" : "토",
	"SUN" : "일"
};

const selectTime =[
	{value:"0",  label :"오전 0시"},
	{value:"1",  label :"오전 1시"},
	{value:"2",  label :"오전 2시"},
	{value:"3",  label :"오전 3시"},
	{value:"4",  label :"오전 4시"},
	{value:"5",  label :"오전 5시"},
	{value:"6",  label :"오전 6시"},
	{value:"7",  label :"오전 7시"},
	{value:"8",  label :"오전 8시"},
	{value:"9",  label :"오전 9시"},
	{value:"10", label :"오전 10시"},
	{value:"11", label :"오전 11시"},
	{value:"12", label :"오후 12시"},
	{value:"13", label :"오후 1시"},
	{value:"14", label :"오후 2시"},
	{value:"15", label :"오후 3시"},
	{value:"16", label :"오후 4시"},
	{value:"17", label :"오후 5시"},
	{value:"18", label :"오후 6시"},
	{value:"19", label :"오후 7시"},
	{value:"20", label :"오후 8시"},
	{value:"21", label :"오후 9시"},
	{value:"22", label :"오후 10시"},
	{value:"23", label :"오후 11시"},
];

const menuLabel = {
	"외부링크 이미지형" : "이미지형 링크",
	"외부링크 스케줄형" : "스케줄형 링크",
	"외부링크 블록형" : "블록형 링크",
	"외부링크 영상형" : "동영상형 링크",
};

export {
	selectOptions,
	selectTime,
	daysInKr,
	menuLabel
}