/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useEffect, useState, useRef, useMemo} from 'react';

// import './AnswertalkChat.scss'
import '../../assets/scss/AnswertalkChat.scss'

//import room service
import {
	getRoomData,
	isSeenService,
	getThumbnailUrl,
	uploadVAFService,
	uploadImageService,
	sendMessagesService,
	getMessagesService,
	previewMessageService,
	getRoomTransferService,
	deleteOneMessageService,
	ownerMessageReadService,
	checkVideoUploadingService,
	blockUserFromChatRoomService,
	checkMediaConvertProgressService,
} from '../../services/talk2youMessage.service';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {Button, Modal, Input, ModalBody, ModalFooter, Fade} from 'reactstrap';
import {Amplify} from 'aws-amplify';
import {Spinner} from 'reactstrap';
import {toast} from 'react-toastify';
import Picker from 'emoji-picker-react';
import _, {isEmpty, map} from 'lodash';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import PerfectScrollbar from 'react-perfect-scrollbar';

// defualt image import
import profile from '../../assets/images/users/defualt-image.png';
import so_select from '../../assets/images/chatroom/so_select.png';
import podding_box from '../../assets/images/chatroom/podding_box.png';

// Import child components
import Time from './components/Time';
import File from './components/File';
import Preview from './components/Preview';
import ChatHeader from './components/ChatHeader';
import SettingsSideBar from './components/SettingsSideBar';
import VideoPlayer from '../../components/Common/VideoPlayer';
import {isValidUrl, toggleRight} from '../../helpers/Functions/check';
import RightMenuContent from '../../components/RightLayout/RightMenuContent';
import moment from 'moment/moment';
import { useInView } from '../../utils/customHooks';

const ChatBox = (props) => {
	document.title = 'Chatroom';

	// message
	const textAreaRef = useRef(null);
	const [messageBox, setMessageBox] = useState();
	const [newMessage, setNewMessage] = useState();
	const [curMessage, setcurMessage] = useState('');
	const [since, setSince] = useState('');
	const [until, setUntil] = useState('');
	const [limit, setLimit] = useState(20);
	const [messages, setMessages] = useState([]);
	const [senderHashCode, setSenderHashCode] = useState();
	const [transferCount, setTransferCount] = useState({});
	//const [transferMaxCount, setTransferMaxCount] = useState();

	// Owner information
	const [isOwner, setIsOwner] = useState(false);
	const [ownerData, setRoomOwnerData] = useState({});
	const [roomData, setRoomData] = useState({});
	const [ownerSeen, setOwnerSeen] = useState(false);
	const [unReadOwnerMsgCount, setUnReadOwnerMsgCount] = useState(false);

	const [lastReadMsgTime, setLastReadMsgTime] = useState('');

	// loading
	const [loading, setLoading] = useState();
	const [loadingFile, setLoadingFile] = useState();
	const [loadingVideo, setLoadingVideo] = useState();

	// sub menu screens and modals
	const [kickRoomModal, setKickRoomModal] = useState(false);
	const [isBlockModal, setIsBlockModal] = useState(false);
	const [settingsMenu, setSettingsMenu] = useState(false);
	const [reportScreen, setReportScreen] = useState(false);

	// target user information ( block, leave rooom ... etc )
	const [targetUserId, setTargetUserId] = useState();
	const [targetMessageId, setTargetMessageId] = useState();
	const [targetUserName, setTargetUserName] = useState();
	const [targetVideoAndImage, setTargetVideoAndImage] = useState({});

	// Actions
	const fileRef = useRef(null);
	const [deadline, setDeadline] = useState(true);
	const [isShowEmoji, setIsShowEmoji] = useState();
	const [copyText, setCopyText] = useState();
	const [clickAvatar, setClickAvatar] = useState();
	const [selectedAvatarMsgId, setSelectedAvatarMsgId] = useState();
	const [messageClickAction, setMessageClickAction] = useState();
	const [isOnlyOneMesageDeleteModal, setIsOnlyOneMesageDeleteModal] = useState(false);

	const [messageBoxSize, setMessageBoxSize] = useState(0);

	const [isMessageSend, setIsMessageSend] = useState(false)

    const messagesEndRef = useRef()
    const messagesStartRef = useRef()
    const lastShowingMsg = useRef('')
    const messageRefs = useRef({});
    const setMessageRefs = (key, el) => {
        messageRefs[key] = el;
    }
	const [isDataInit, setIsDataInit] = useState(false)

	const [isTotal, setIsTotal] = useState(false)


    // Tool tip
    const [isTopShow, setIsTopShow] = useState(false);
    const [isBottomShow, setIsBottomShow] = useState(true);
    const [isBeforeLoading, setIsBeforeLoading] = useState(false);


    const [observeTop, unobserveTop] = useInView((isShow) => {
        console.log('%c top-view','color:red;font-weight:bold;' , isShow)
        setIsTopShow(()=>{return isShow});
    });

    const [observeBottom, unobserveBottom] = useInView((isShow) => {
        console.log('%c bottom-view','color:blue;font-weight:bold;' , isShow);
        setIsBottomShow(()=>{return isShow});
    });

	async function getMoreMessages () {
		if (!messages || messages?.length === 0) return false;
		setLoading(true)
		try {
			// until 파라미터를 message의 가장 최신 시간으로 입력
			const response = await getMessagesService(
					props.roomId,
					props.user.id,
					since,
					messages[0].createdAt, // until
					limit,
					true
				).then(result => {
					console.log(result)
					return result
			})
			if(response.status===204) {
				setIsTotal(()=>{return true})
			}
	
			const currentMessages = [...messages];
			const nMessages = response.data?.itemList || [];
			const concatMessage = nMessages.concat(currentMessages)
			setMessages(()=>{return concatMessage});
		} catch(err) {
			throw err
		} finally {
			setLoading(false)
		}
	}

	const getMoreBefore = async () => {
        setIsBeforeLoading(true);
        if (isBeforeLoading) return;
        try {
			const anchorId = messages[0]?.messageId
            const anchor = messageRefs[anchorId];
			await getMoreMessages().then(r => r);
			// anchor.scrollIntoView({behavior:'auto'});
            if (anchor) {
				anchor.scrollIntoView(true);
				// anchor.scrollIntoView({behavior:'auto'});
			}
        } catch (e) {
            console.error(e);
        } finally {
            setIsBeforeLoading(false);
        }
    }


	// Chat box scroll to bottom
	useEffect(() => {
		if (isDataInit) {
			observeTop(messagesStartRef.current);
			observeBottom(messagesEndRef.current);
		}

		if (!isBottomShow && isDataInit) return;
		// messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
		scrollActivateControl(true)
		checkLastShow();
	}, [messages])


    useEffect(() => {
        if (isTopShow === true && isBottomShow === false) {
            // before data loading event
            // Todo: 현대 불러와 있는 데이터 이전의 데이터를 현재 데이터의 앞단에 가져와 뿌려준다. 스크롤 관련 스코프가 미지수
			isTotal === false && getMoreBefore().then(r=>r)
        }

        checkLastShow();
    }, [isTopShow, isBottomShow])


    function checkLastShow () {
        if (isBottomShow && messages?.length > 0) {
            lastShowingMsg.current = messages[messages.length - 1]?.messageId;
        }
    }

	useEffect(()=>{
		if(isTotal) unobserveTop(messagesStartRef.current)
	}, [isTotal])

	useEffect(() => {
		if(isMessageSend === true) {
			scrollActivateControl(true)
			setIsMessageSend(() => {return false})
		}
	}, [isMessageSend])



	

	
	let timeCheck="";
	function checkTime(createdAt){
		if(createdAt == null || createdAt === "") return;
		createdAt = createdAt.substr(0,10);
		if(timeCheck !== createdAt){
			timeCheck = createdAt;

			return (
				
				<li className="system-style">
					<div className="message">
						<div>
							<div className="bg-light-blue msg-tag ln-msg">
								<div>
									{createdAt}
				 				</div>
				 			</div>
				 		</div>
				 	</div>
				</li>
				
			)
		}else{
			timeCheck = createdAt;
		}
	}

// Main useEffect
	useEffect(() => {
		// Owner is seen function
		async function isSeenFunction(messageList) {
			let newList = [...messageList];
			let reverseMessageList = _.reverse(newList);
			let unReadOwnerMsgCount = 0;
			for (let message of reverseMessageList) {
				if (message.senderId === props.room.ownerId) break;
				let response = await isSeenService(props.roomId, message.messageId);
				if (response && response.itemList.length > 0) {
					if (response.itemList[0].read) break;
					unReadOwnerMsgCount++;
				}
			}
			setUnReadOwnerMsgCount(unReadOwnerMsgCount);
		}

		// Owner msg read function
		async function ownerMessageReadFunction(messageList) {
			let body = {
				userId: props.user.id,
				since: messageList[0].createdAt,
				until: messageList[messageList.length - 1].createdAt,
			};
			await ownerMessageReadService(props.roomId, body);
		}

		async function updateMessageReadStatus(response) {
			const items = response && response?.itemList.map(item => item.createdAt) || [];
			items.sort((a, b) => a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0); // 날짜순 정렬
			// console.log("items", items)
			const since = items.length > 0 && items[0] || new Date().toISOString();
			// console.log("since", since)
			const until = items.length > 0 && items[items.length - 1] || new Date().toISOString();
			// console.log("until", until)
			const body = {
				userId: props.user.id,
				since,
				until,
			};
			ownerMessageReadService(newMessage?.value?.roomId, body).then(r => {
				console.log('rs', r);
			});
		}

		async function getMessages(ownerId) {
			setLoading(true);
			const response = await getMessagesService(
				props.roomId,
				props.user.id,
				since,
				until,
				limit,
			);

			if (response?.limit) {
				setTransferCount({
					transferCount: response?.limit?.transferCount,
					transferMaxCount: response?.limit?.transferMaxCount,
				});
			}

			let originalMessages = response?.itemList ? response?.itemList : [];
			for(let i=0; originalMessages.length > i ; i++){
				if(originalMessages[i].isActivated === 0){
					originalMessages[i].msgType = 'TEXT';
					originalMessages[i].message ="삭제된 메세지 입니다.";
				}
			}
			console.log('originalMessages', originalMessages);
			if(originalMessages.length < 10) setIsTotal(()=>{return true})
			//setMessages(response.itemList);
			setMessages(originalMessages);


			// TODO : 원래는 Chat API에서 메시지 조회 시 자동으로 읽음 IoT를 보내야 함.
			//  하지만 해당 부분이 적용돼 있지 않아 직접 수동으로 읽음 처리 Update
			await updateMessageReadStatus(response);


			// console.log("msglist:::",response.itemList);
			Amplify.PubSub.subscribe(props.roomId).subscribe({
				next: (data) => {
					// IOT subscribe message
					// console.log('data ', data)
					setNewMessage(data);
				},
				error: (error) => console.error(error),
			});

			/* TODO : 위 코드구간에서 처리함 - updateMessageReadStatus
			// is owner read message
			if (props.user.id === ownerId) {
				//ownerMessageReadFunction(response.itemList);
				await ownerMessageReadFunction(originalMessages);
			} else {
				//isSeenFunction(response.itemList);
				await isSeenFunction(originalMessages);
			}*/

			setLoading(false);
			setIsDataInit(true)
		}

		// Get room data
		async function getRoomDataFunction() {
			setLoading(true);
			const response = await getRoomData(props.roomId, props.user.id);
			if (response.itemList?.length < 1) {
				// toast.error('not found room data');
				toast.error('채팅방 데이터를 찾을 수 없습니다.');
				return;
			}

			let roomData = response.itemList[0];
			setIsOwner(props.user.id === roomData.ownerId);

			let ownerData = _.find(roomData.activeUsers, function (o) {
				return o.userId === roomData.ownerId;
			});

			if (ownerData) setRoomOwnerData(ownerData);
			else if (props.user.id === roomData.ownerId) {
				let ownerData = {
					userId: roomData.ownerId,
					userImg: roomData.myInfo.userImg,
					userName: roomData.myInfo.userName,
				};
				setRoomOwnerData(ownerData);
			}

			setRoomData(roomData);
			await getMessages(roomData.ownerId);
		}

		getRoomDataFunction().then(r => r);
		toggleRight(false);
	}, [props.room, props.roomId, props.user.id, since, until, limit]);

	// IOT new message
	useEffect(() => {
		if (
			newMessage &&
			newMessage.value &&
			senderHashCode !== newMessage?.value?.hashCode &&
			newMessage.value.roomId === props.roomId
			&& newMessage?.value?.senderId !=="BOT"   //modify KMS : Not print BOT message
		) {
			console.log('newMessage.value.action ', newMessage.value.action)
			if (
				newMessage.value.action === 'sendoff' &&
				newMessage.value.msgType === 'INFO' &&
				newMessage.value.targetId === props.user.id
			) {
				// Block user -> Room leave
				setKickRoomModal(true);
				return;
			}
			if (newMessage.value.action === 'read') {
				// Owner seen
				setOwnerSeen(true);

				// TODO : 상대방이 메시지를 읽은 경우 내 비행기를 날려버리는 기능을 구현해야 함
				// console.log('lastReadMsgTime', moment(newMessage.value?.createdAt).format('HH:mm:sss') || '')
				setLastReadMsgTime(newMessage.value?.createdAt || '') // 마지막으로 메시지 읽은 시각 기록

				// TODO : 상대방이 메시지를 읽은 구간을 기록해서 Time Component에서 비교할 것이 아니라
				//  시간값을 기준으로 messages Array 의 state 를 변경하는 것이 Reactive 한 방식이다.
				// console.log('newMessage?.value', newMessage?.value)
				if(newMessage?.value?.targetId !== props.user.id // targetId 가 나 자신인 경우에는 제외
					&& newMessage?.value?.targetData?.since && newMessage?.value?.targetData?.until) {
					const temp = [...messages];
					const filtered = [];
					for (let message of temp) {
						if(new Date(message.createdAt).getTime() >= new Date(newMessage?.value?.targetData?.since).getTime() &&
							new Date(message.createdAt).getTime() <= new Date(newMessage?.value?.targetData?.until).getTime()) {
							message.unreadCount = 0;
						}
						filtered.push(message);
					}
					setMessages(filtered);
				}

				// console.log('messages', messages);
				/*if (props.user.id === ownerId) {
					//ownerMessageReadFunction(response.itemList);
					await ownerMessageReadFunction(originalMessages);
				} else {
					//isSeenFunction(response.itemList);
					await isSeenFunction(originalMessages);
				}*/

				return;
			}

			// Enter room new user
			if (newMessage.value.action === 'enter') {
				if (roomData?.activeUsers)
					roomData?.activeUsers.push(newMessage.value.targetData);
			}

			if (newMessage.value.senderId === props.room.ownerId) {
				// Owner seen
				setOwnerSeen(true);
			}

			//KMS Modify
			if(newMessage.value.msgType ==='DELETED') {
				const messageIdx = _.findIndex(messages, function (n) {
					return n.messageId === newMessage.value.messageId;
				});
				// console.log(newMessage);
				// console.log(messages[messageIdx]);
				messages[messageIdx].msgType = "TEXT";
				messages[messageIdx].message = "삭제된 메세지 입니다.";
				setMessages(messages);
			}
			/*}else{*/

			// Set new message
			getRoomTransferFunction().then(r => r);
			setMessages([...messages, newMessage.value]);

			// 신규 메시지 수신 시 메시지 읽음처리(Update DB) 수행
			ownerMessageReadService(props.roomId, {
				userId: props.user.id,
				since: newMessage.value.createdAt,
				until: newMessage.value.createdAt,
			}).then(r => {
				console.log('updated message read status......')
			});
				
			/*}*/
		}
		if (
			newMessage &&
			newMessage.value &&
			props.user.id === newMessage?.value?.senderId &&
			newMessage.value.roomId === props.roomId &&
			newMessage.value.msgType === 'PREVIEW'
		) {
			// Preview text
			setMessages([...messages, newMessage.value]);
		}

		// Set message
		if (
			// 이 채팅방에서 내가 보낸 메시지
			senderHashCode === newMessage?.value?.hashCode &&
			newMessage?.value?.roomId === props.roomId
		) {
			const filterNewArr = [...messages].filter(item => item?.hashCode !== newMessage?.value?.hashCode);
			// TODO: 차단당한 유저에게 메시지를 보냈을 때 usersToRead 값이 내려오지 않음. 이에 대한 적절성 여부 판단 필요
			newMessage.value.unreadCount = newMessage.value?.usersToRead?.length || 0;
			filterNewArr.push(newMessage.value);
			// console.log('newMessage.value', newMessage.value);
			setMessages(filterNewArr)
		}


		async function getRoomTransferFunction() {
			let response = await getRoomTransferService(props.roomId, props.user.id);
			if (response && response?.itemList.length > 0) {
				//setTransferMaxCount(response?.itemList[0]?.transferMaxCount);
				//setTransferCount(response?.itemList[0]?.transferCount);
				setTransferCount({
					transferCount: response?.itemList[0]?.transferCount,
					transferMaxCount: response?.itemList[0]?.transferMaxCount,
				});
			}
		}
	}, [newMessage]);

	// Room reset data function
	useEffect(() => {
		resetRoomDataFunction();
	}, [props.roomId]);

	// Owner seen action and flying plane
	useEffect(() => {
		if (ownerSeen && unReadOwnerMsgCount > 0)
			setTimeout(function () {
				//setOwnerSeen(false);
				//setUnReadOwnerMsgCount(0);
			}, 3000);
	}, [ownerSeen, unReadOwnerMsgCount]);

	// Chat box scrool to bottom
	// useEffect(() => {
	// 	const scrollToBottom = () => {
	// 		if (messageBox) {
	// 			messageBox.scrollTop = messageBox.scrollHeight + 1000;
				
	// 			setTimeout(() => {
	// 				const container = document.querySelector('.chatScroll');
	// 				if(container) {
	// 					container.scrollTop = container.scrollHeight + 1000;
	// 				}
	// 			}, 100);
	// 		}
	// 	};
		
	// 	if (!isEmpty(messageBox)) scrollToBottom();
	// }, [messages, messageBox]);

	

	//KMS modify
	function getUserName(userId){
		let userName = _.find(props.room.activeUsers, function (o) {
			return o.userId === userId;
		});
	}

	const resetRoomDataFunction = () => {
		setReportScreen(false);
		setIsBlockModal(false);
		setMessageClickAction();
	};

	// Send new message
	const addMessage = async (msgType, fileName, fileOrigin, fileSize, jobId, imgRatio, durationResult) => {
		let checkMsg = curMessage;
		console.log(checkMsg.length)
		if (msgType === 'TEXT') {
			if(curMessage.trim().length===0) {
				toast.warning("내용을 입력해 주세요")
				return
			} else {
				if(curMessage.length > 1000) {
					toast.error('한번에 전송 가능한 글자 수는 1,000자입니다.')
					return;
				} else {
					if (curMessage.substring(0, 1) === '\n') checkMsg = checkMsg.substring(1);
				}
			}
		}

		let newHashCode = Date.now().toString();
		setSenderHashCode(newHashCode);

		const body = {
			roomId: props.roomId,
			senderId: props.user.id,
			senderImg: props.user.image_url,
			senderName: props.user.nickname,
			msgType: msgType,
			message: fileName || checkMsg,
			imgRatio: imgRatio || 0,
			duration: durationResult || 0,
			jobId: jobId,
			fileOrigin: fileOrigin || '',
			fileSize: fileSize || 0,
			hashCode: newHashCode,
			isActivated: 1,
		};

		const result = await sendMessagesService(body);
		if (result.limit) {
			setTransferCount({
				transferCount: result.limit.transferCount,
				transferMaxCount: result.limit.transferMaxCount,
			});
			if (messages) {
				setMessages([...messages, body]);
			} else {
				setMessages([body]);
			}
		}
		showEmojeContent(false);
		setcurMessage('');

		if (msgType === 'TEXT') {
			const first = checkMsg.split(' ')[0];
			let urlCheck = isValidUrl(first);

			if (urlCheck) {
				const previewbody = {
					url: first,
					hashCode: newHashCode,
				};
				await previewMessageService(previewbody);
			}
		}
		// scrollActivateControl(true, 'auto')
		setIsMessageSend(()=>{return true})
	};

	// docs and zip etc ... other file upload
	const fileUpload = async (formData, bucketName, fileSize, fileName) => {
		let msgType = 'FILE';
		let uploadType = 'docs';

		const fileResponse = await uploadVAFService(
			formData,
			bucketName,
			uploadType,
		);

		if (fileResponse.msg === 'OK') {
			addMessage(msgType, fileName, fileResponse.url[0], fileSize);
		} else {
			// toast.error('Error upload this file');
			toast.error('파일을 업로드하지 못했습니다.');
		}

		setLoadingFile(false);
	};

	// Image upload function
	const imageUpload = async (formData, fileSize, fileName) => {
		let msgType = 'PHOTO';
		formData.append('limitedImageSize', 1280);
		const imageResponse = await uploadImageService(formData).then(r=>{
			console.log(r)
			return r
		});
		if (imageResponse.msg === 'OK') {
			// const addMessage = async (msgType, fileName, fileOrigin, fileSize, jobId) => {
			addMessage(msgType, imageResponse?.url[0], imageResponse?.url[0], fileSize, '', imageResponse.imgRatio[0]);
		} else {
			// toast.error('Error upload image');
			toast.error('이미지 파일을 업로드하지 못했습니다.');
		}

		setLoadingFile(false);
	};

	// Audio upload function
	const audioUpload = async (formData, bucketName, fileSize, fileName) => {
		let msgType = 'VOICE';
		let uploadType = 'audio';

		const audioResponse = await uploadVAFService(
			formData,
			bucketName,
			uploadType,
		);

		if (audioResponse.msg === 'OK') {
			addMessage(msgType, fileName, audioResponse.url[0], fileSize);
		}

		setLoadingFile(false);
	};

	// Video upload function
	const videoUpload = async (formData, bucketName, fileSize, fileName, durationResult) => {
		formData.append('needToConvert', 'Y');
		const videoResponse = await uploadVAFService(formData, bucketName, 'video');

		if (videoResponse && videoResponse.msg !== 'OK') {
			// toast.error('Error sent video upload');
			toast.error('비디오파일을 전송하지 못했습니다.');
			return;
		}

		let body = {
			video_url: videoResponse.src_url[0],
			bucket_name: bucketName,
		};

		const thumbnailUrlResponse = await getThumbnailUrl(body).then(r=> {
			console.log(r)
			return r
		});
		setLoadingFile(false);

		if (!thumbnailUrlResponse.thumbnailUrl) {
			// toast.error('Error sent video upload get thumbnailUrl');
			toast.error('비디오파일에서 썸네일이미지를 추출하지 못했습니다.');
			return;
		}

		return addMessage('VIDEO',thumbnailUrlResponse.thumbnailUrl,videoResponse.url[0],fileSize,videoResponse.jobIds[0], thumbnailUrlResponse.imgRatio, durationResult);
	};

	// Image and Voice and video upload action
	const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {
			setLoadingFile(true);

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					// toast.error('This file is larger than 200mb');
					toast.error('선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다.');
					setLoadingFile(false);
					return;
				}
			} else {
				if (originFileSize > 20) {
					// toast.error('This file is larger than 20mb');
					toast.error('선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다.');
					setLoadingFile(false);
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
			let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;
			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				// fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
				fileSize = (fileUploaded?.files[0]?.size ) + 'Byte';
			} else {
				fileSize += 'MB';
			}

			if (type === 'audio') {
				audioUpload(formData, bucketName, fileSize, fileName);
			} else if (type === 'video') {
				let durationResult = null;
				const vid = document.createElement("video");
				const fileURL = URL.createObjectURL(fileUploaded.files[0]);
				vid.src = fileURL;
				vid.ondurationchange = async function () {
					durationResult = this.duration
					console.log('test duration:::', durationResult)
					videoUpload(formData, bucketName, fileSize, fileName, durationResult)
				}
			} else if (type === 'image') {
				imageUpload(formData, fileSize, fileName);
			} else {
				fileUpload(formData, bucketName, fileSize, fileName);
			}
		}
	};

	// User block
	const blockUserFunction = async () => {
		let data = {
			userId: ownerData.userId,
			userName: ownerData.userName,
			targetId: targetUserId,
			targetName: targetUserName,
		};
		try {
			await blockUserFromChatRoomService(props.roomId, data).then(r=>{
				console.log(r)
				if(r.message === 'Deleted') toast.success('사용자를 차단했습니다.')
				else throw r
				// return r
			})

		} catch(err) {
			if(err.response.data.errors.message === `targetUser doesn't in the room`) toast.warning('이미 차단된 사용자 입니다.')
			else toast.error('다시 한번 시도해 주세요')
			throw err
		} finally {
			resetRoomDataFunction();
			// props.onChange();
		}
	};

	// Enter keyboard action
	const onKeyPress = (e) => {
		const {key} = e;
		if (e.shiftKey) {
		} else if (key === 'Enter') {
			if (e.target.value && e.target.value !== '' && e.target.value !== '\n') {
				addMessage('TEXT');
			} else {
				setcurMessage('');
				e.preventDefault();
			}
		}
	};

	// check shift enter chat message
	const checkText = (text) => {
		if(!text || text.indexOf('\n') === -1) return text;
		return text.split('\n').map((item, i) => (
			<div style={{textAlign: 'left'}} className="" key={i}>
				{item}
			</div>
		));
	};

	const scrollActivateControl = (isForced, behavior) => {
		console.log(isForced, behavior)
		if (!isBottomShow && isForced !== true) return;
        messagesEndRef.current?.scrollIntoView({ behavior: isForced === true && !behavior ? 'auto' : behavior || 'smooth' });
    }

	const checkVideoUploading = async (fileOrigin, jobId) => {
		setLoadingVideo(true);
		//setTargetVideoId(jobId);
		// const response = await checkVideoUploadingService(jobId);
		// if (response === 0) {
		// TODO : message obj 안에 jobId가 없기 때문에 jobId를 이용한 Media Convert status 를 체크할 수 없다.
		//  API 를 변경하여 jobId 가 전달되는 경우 해당 message 의 hashCode 를 jobId 값으로 대체하여 저장하는 것은 어떤지 검토 필요.

		// TODO: 다시 jobId를 이용한 로직으로 변경
		console.log(jobId)
		try {
			const response = !jobId || await checkVideoUploadingService(jobId).then((res) => {
				const {status} = res
				switch(status) {
					case 'COMPLETE' : return true;
					case 'SUBMITTED': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
					case 'PROGRESSING': throw new Error('비디오 파일을 업로드하고 있습니다. 잠시만 기다려 주세요.')
					case 'CANCELED': throw new Error('비디오 업로드가 취소되었습니다.')
					case 'ERROR': throw new Error('비디오 업로드에 실패하였습니다.')
					default: throw new Error('다시 한번 시도해 주세요.')
				}
			})
			if(response===true) {
				setTargetVideoAndImage({
					name: 'VIDEO',
					value: fileOrigin,
					jobId: jobId,
				});
				toggleRight(true);
			}
			// console.warn('response', response); //return;
			// if (response.status === 200) {
			// 	setTargetVideoAndImage({
			// 		name: 'VIDEO',
			// 		value: fileOrigin,
			// 		jobId: jobId,
			// 	});
			// 	toggleRight(true);
			// // } else if (response === 1) {
			// } else if (response.status === 403) {
			// 	// toast.info('This video uploading, please wait for a moment');
			// 	toast.info("원활한 영상 재생을 위한 준비 중입니다. 잠시 후 다시 시도해 주세요.")
			// } else {
			// 	// toast.error('Video cannot be played');
			// 	toast.error("요청하신 영상은 재생 가능한 상태가 아닙니다. 잠시 후 다시 시도해 주세요.")
			// }
		} catch(err) {
			toast.error(`${err}`)
		} finally {
			setLoadingVideo(false);
		}

	};

	const onEmojiClick = (event, emojiObject) => {
		let newEmoji = curMessage + emojiObject.emoji;
		setcurMessage(newEmoji);
	};

	// Emoji action
	const showEmojeContent = (condition, self) => {
		setIsShowEmoji(self ? !isShowEmoji : condition);
	};

	const getModalCopyAndDelete = (condition, messageId, copy) => {
		if (copy) {
			setMessageClickAction({
				copyAction: true,
				action: false,
				messageId: messageId,
				myMessage: true,
			});
		} else if (condition) {
			setMessageClickAction({
				action: true,
				messageId: messageId,
				myMessage: true,
			});
		} else {
			setMessageClickAction({
				action: true,
				messageId: messageId,
				myMessage: false,
			});
		}
	};

	const copyTextAction = (message) => {
		resetRoomDataFunction();
		setCopyText(message);
	};

	const deleteTextAction = async () => {
		setIsOnlyOneMesageDeleteModal(false);
		resetRoomDataFunction();
		let body = {
			messageId: targetMessageId,
			userId: props.user.id,
			roomId: props.roomId,
		};
		const response = await deleteOneMessageService(body);
		if (response.message !== 'Deleted') {
			toast.error('Cannot this message deleted');
			return;
		}

		//kms modify 2022-11-12
		//toast.success('Message deleted');
		toast.success('메시지를 삭제했습니다.');

		// _.remove(messages, function (n) {
		// 	return n.messageId === targetMessageId;
		// });

		const messageIdx = _.findIndex(messages,function (n) {
			return n.messageId === targetMessageId;
		});
		
		messages[messageIdx].msgType ="TEXT";
		//messages[messageIdx].message ="삭제된 메세지 입니다.";
		messages[messageIdx].isActivated = 0;
		setMessages(messages);
	};

	const headerChangeProfile = () => {
		roomData.myInfo.push = roomData.myInfo.push === 'on' ? 'off' : 'on';
		setRoomData(roomData);
	};

	const headerSettingsAction = () => {
		setSettingsMenu(!settingsMenu);
		showEmojeContent(false);
	};

	const unShowMessageCount = useMemo(() => {
        const lastShowId = lastShowingMsg.current;
        if (!lastShowId) return false;

        const idx =  _.findLastIndex(messages, ['messageId', lastShowId]);
        const len = messages?.length || 0;

        return len - idx - 1;
    }, [messages, lastShowingMsg.current]);

	const propsBody = {
		checkText,
		getModalCopyAndDelete,
		messageClickAction,
		copyTextAction,
		setTargetMessageId,
		setIsOnlyOneMesageDeleteModal,
		scrollActivateControl,
		setTargetVideoAndImage,
		checkVideoUploading,
		loadingVideo,
		targetVideoAndImage,
		messages
	}
	
	return (
		<React.Fragment>
			<div className="chat_room" style={{position:'relative'}}>
				{/* {loading ? (
					<Spinner type="grow" color="warning"/>
				) : ( */}
				{loading && <Spinner type="grow" color="warning"/>}
					<div className="row">
						<div
							className={
								settingsMenu ? 'col-lg-9 chat_box' : 'col-lg-12 chat_box'
							}
						>
							{/* Start header buttons  -> setting button & open profile*/}
							<ChatHeader
								{...props}
								transferCount={transferCount}
								isOwner={isOwner}
								roomData={roomData}
								headerChangeProfile={() => headerChangeProfile()}
								headerSettingsAction={() => headerSettingsAction()}
							/>

							{/* End header buttons  -> setting button & open profile */}
							<div className="chat_windows">
								<ul
									className="mb-0"
									style={{
										height: deadline
											? 'calc(100vh - 320px)'
											: 'calc(100vh - 220px)',
									}}
									onClick={() => {
										setMessageClickAction();
										showEmojeContent(false);
									}}
									onContextMenu={(e) => {
										e.preventDefault();
										setClickAvatar(false);
										showEmojeContent(false);
									}}
								>
									{/* start chat list */}
									{/* <PerfectScrollbar className="chatScroll" containerRef={(ref) => setMessageBox(ref)}> */}
										{/* <div className='text-center'>
											<Fade in={isBeforeLoading} className={'py-3'}
												onEnter={(ev) => {ev.style.display='block'}}
												onExit={(ev) => {ev.style.display='none'}} style={{display: 'none',}}>
												<Spinner size={'sm'} style={{borderWidth:1, color: 'var(--primary, #01a0fc)',}} />
											</Fade>
										</div> */}
										<div ref={messagesStartRef} id="hereistop" />
										{messages &&
											map(
												messages,
												(message, index) =>
														// message.isActivated > 0 && (
													(
													<div style={{margin:'30px 0'}} key={message.messageId} ref={(el) => {
														setMessageRefs(message?.messageId || message?.instanceId, el);
													}}>
														{checkTime(message.createdAt)}

														<div key={message?.messageId || message?.instanceId} >
														<li
															key={index}
															className={
																message.senderId === props.user.id &&
																message.msgType !== 'INFO'
																	? 'my-message'
																	: message.msgType === 'INFO'
																		? 'system-style'
																		: isOwner && message.senderId === 'BOT'
																			? 'my-message'
																			: 'other-message'
															}
														>

															{/* Chat profile image */}
															{message.senderId !== 'BOT' &&
																message.senderId !== props.user.id &&
																message.senderId !== 'SYSTEM' &&
																message.msgType !== 'INFO' && (
																	<div className="avatar mr-3 ">
																		{clickAvatar &&
																			selectedAvatarMsgId ===
																			message.messageId && (
																				<div
																					className="tool_tip"
																					style={message.messageId === messages[0].messageId ? {top:'35px'} : {}}
																				>
																					{isOwner && (
																						<button
																							onClick={() => {
																								setTargetUserId(
																									message.senderId,
																								);
																								setTargetUserName(
																									message.senderName,
																								);
																								setIsBlockModal(true);
																								setClickAvatar(()=>{return false})
																							}}
																						>
																							<i className="fas fa-copy"></i>
																							{/* // todo : 1:1 채팅형식의 답변톡은 내보내기보다
																							개인별 차단하기가 더 맞다고 판단하여 변경됨.
																							<p>내보내기 </p>
																							*/}
																							<p>차단하기 </p>
																						</button>
																					)}
																					<button
																						onClick={() => {
																							setTargetUserId(message.senderId);
																							setSettingsMenu(true);
																							setReportScreen(true);
																							setClickAvatar(()=>{return false})
																						}}
																					>
																						<i className="fas fa-trash-alt"></i>
																						<p>신고하기 </p>
																					</button>
																				</div>
																			)}
																		<img
																			onClick={() => {
																				if(clickAvatar) {
																					setSelectedAvatarMsgId(()=>{return ''})
																					setClickAvatar(()=>{return false})
																				} else {
																					setSelectedAvatarMsgId(
																						message.messageId,
																					);
																					setClickAvatar(true);
																				}
																			}}
																			className="avatar"
																			rel="noreferrer"
																			src={
																				message.senderImg === '<null>' ||
																				!message.senderImg
																					? profile
																					: message.senderImg
																			}
																			alt="avatar"
																		/>
																	</div>
																)}
															{/* Start show message image & video & voice */}
															<div className="message">
																{/* Preview component */}
																{message.previewData && (
																	<Preview message={message}/>
																)}
																{/* KMS add */}
																{(message.msgType !== 'INFO' && !message.previewData && (props.user.id !== message.senderId))?(
																		<div className="sendrName">
																			{message.senderName === undefined || message.senderName ==='' ?getUserName(message.senderId):message.senderName}
																		</div>
																):''}
																{message.msgType === 'INFO' &&
																(message.action !== 'enter' || 'leave' || 'read' || 'sendoff' || 'pick' || 'deadline') ? (
																	<InfoMessage message={message} propsBody={propsBody} {...props} />
																) : !message.previewData &&
																message.msgType === 'TEXT' ? (
																	<TextMessage message={message} propsBody={propsBody} {...props} />
																) : message.msgType === 'PHOTO' ? (
																	<PhotoMessage message={message} propsBody={propsBody} {...props} />
																) : message.msgType === 'VIDEO' ? (
																	<VideoMessage message={message} propsBody={propsBody} {...props} />
																) : message.msgType === 'VOICE' ? (
																	<VideoPlayer
																		width={300}
																		height={60}
																		fileOrigin={message.fileOrigin}
																		isDownload={false}
																		scrollActivateControl={scrollActivateControl}
																	/>
																) : (
																	// FILE component
																	
																	message.msgType === 'FILE' && (
																		<div
																		onContextMenu={(e) => {
																			e.preventDefault();
																			getModalCopyAndDelete(
																				message.senderId === props.user.id,
																				message.messageId,
																			);
																		}}
																	>
																		{messageClickAction?.action &&
																			messageClickAction?.messageId ===
																			message.messageId && (
																				<div
																					className="tool_tip"
																					style={message.messageId === messages[0].messageId ? {top:'35px'} : {}}
																				>	
																					{messageClickAction?.myMessage && (
																						<button
																							onClick={() => {
																								setTargetMessageId(
																									message.messageId,
																								);
																								setIsOnlyOneMesageDeleteModal(
																									true,
																								);
																							}}
																						>
																							<i className="fas fa-copy"></i>
																							<p>delete </p>
																						</button>
																					)}
																				</div>
																			)}
																		<File scrollActivateControl={scrollActivateControl} message={message}/>
																		</div>
																	)
																	
																)}
																{/* Start message time */}
																{message.senderId !== 'SYSTEM' &&
																	message.msgType !== 'INFO' && (
																		<Time
																			message={message}
																			ownerSeen={ownerSeen}
																			unReadOwnerMsgCount={unReadOwnerMsgCount}
																			index={index}
																			userId={props.user.id}
																			lastReadMsgTime={lastReadMsgTime}
																		/>
																	)}
																{/* End message time */}
																

															</div>
															{/* End show message image & video & voice */}
														</li>
														</div>
													</div>
													),
													)}
													<LoadingComponent scrollActivateControl={scrollActivateControl} loadingFile={loadingFile} />
										{/* {loadingFile && <Spinner type="grow" color="warning"/>} */}
											<div ref={messagesEndRef} id="hereisbottom" />
										<Fade in={!isBottomShow}>
											{isBottomShow === false && (
												<Button
													className={`scroll-bottom-button ${unShowMessageCount > 0 ? 'display-count' : ''}`}
													onClick={() => {scrollActivateControl(true, 'smooth')}}
												>
													<div className="unseen-count">{unShowMessageCount}</div>
													<i className="fa fa-chevron-down" />
												</Button>
											)}
										</Fade>
									{/* </PerfectScrollbar> */}
									{/* end chat list */}
								</ul>
								{/* start message sent input and attachments file & emoji  */}
								<div className="chat-message clearfix bg-white">
									{/* attachments file  */}
									<div className='d-flex justify-content-between'>
										<div>
											<button
												onClick={() => {
													fileRef.current.click();
													showEmojeContent(false);
												}}
												className="b-none bg-none "
											>
												<form>
													<input
														className="d-none"
														type="file"
														id="selected-file"
														name="attachments"
														ref={fileRef}
														onChange={selectFileFunction}
													/>
													<i className="icon-paper-clip icon-color"></i>
												</form>
											</button>
											{/* emoji icon */}
											<button
												onClick={() => showEmojeContent(true, true)}
												className="b-none bg-none "
											>
												<i className="icon-emoticon-smile icon-color"></i>
											</button>
											{isShowEmoji && (
												<Picker
													id="emojiPicker"
													preload={true}
													groupVisibility={{
														flags: false,
													}}
													onEmojiClick={onEmojiClick}
												/>
											)}
										</div>
										<span>{`${curMessage.length}/1,000`}</span>
									</div>

									{/* Message input */}
									<div className="input-group mb-0 mt-2">
										{messageClickAction?.copyAction && (
											<div className="tool_tip">
												<button
													onClick={() => {
														resetRoomDataFunction();
														copyText && setcurMessage(copyText);
													}}
												>
													<i className="fas fa-copy"></i>
													<p>paste</p>
												</button>
											</div>
										)}
										<Input
											ref={textAreaRef}
											type="textarea"
											rows={1}
											id="sent-chat-area"
											className="form-control bgChatText mt-0"
											placeholder="내용을 입력해주세요."
											onContextMenu={(e) => {
												e.preventDefault();
												getModalCopyAndDelete(false, '', true);
											}}
											value={curMessage}
											onKeyPress={onKeyPress}
											onChange={(e) => {
												showEmojeContent(false);
												const {key} = e;
												if (key !== 'Enter') {
													if(e.target.value.length <= 1000){
														setcurMessage(e.target.value);
													}else{
														toast.error(`한번에 1000자까지만 보낼수 있습니다. \n 입력하신 글자 수 : (${e.target.value.length}자)`);
														// toast.error(`한번에 1000자까지만 보낼수 있습니다.`);
													}
													
												}
											}}
										/>
										<span className="input-group-append">
											<button
												onClick={() => addMessage('TEXT')}
												className="btn btn-secondary"
												type="button"
											>
											보내기
											</button>
										</span>
									</div>
								</div>
								{/* end chat input and attachments file & emoji  */}
							</div>
						</div>
						{/* Start Setting right menu  */}
						{settingsMenu && (
							<SettingsSideBar
								{...props}
								currentRoomOwnerData={ownerData}
								roomData={roomData}
								isOwner={isOwner}
								targetUserId={targetUserId}
								reportScreen={reportScreen}
								targetUserName={targetUserName}
								hideSettingsBar={() => setSettingsMenu(false)}
								hideReportBar={() => {
									setSettingsMenu(false);
									setReportScreen(false);
								}}
							/>
						)}
						{/* End Setting right menu  */}
						{/* Start Modals */}
						<Modal
							returnFocusAfterClose={true}
							isOpen={isBlockModal}
							centered={true}
						>
							<ModalBody className="text-center">
								{/*해당 유저를 답변톡방에서 <br/> 내보내시겠습니까?
								<br/>
								내보내기를 하면 해당 이용자는 해당 <br/> 답변톡방에 입장할 수
								없습니다.*/}
								해당 유저를 대화방에서 차단하시겠습니까?
								<br/>
								차단하면 해당 이용자는
								<br/>
								이 대화방에서 톡을 전송할 수 없습니다.
							</ModalBody>
							<ModalFooter>
								<Button
									color="primary"
									onClick={() => {
										setTargetUserId();
										setTargetUserName();
										setIsBlockModal(false);
									}}
								>
									취소
								</Button>
								<Button color="primary" onClick={() => blockUserFunction()}>
									{/*내보내기*/}차단하기
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							returnFocusAfterClose={true}
							isOpen={kickRoomModal}
							centered={true}
						>
							<ModalBody className="text-center">
								입장 불가 방장에 의해서 내보내기를
								<br/> 당한 유저는 입장을 할수 없습니다.
								<br/>
							</ModalBody>
							<ModalFooter>
								<Button
									color="primary"
									onClick={() => {
										setKickRoomModal(false);
										props.onChange();
									}}
								>
									확인
								</Button>
							</ModalFooter>
						</Modal>
						<Modal
							returnFocusAfterClose={true}
							isOpen={isOnlyOneMesageDeleteModal}
							centered={true}
						>
							<ModalBody className="text-center">
								이 내용을 삭제 하겠습니까 ?
							</ModalBody>
							<ModalFooter>
								<Button
									color="primary"
									onClick={() => {
										resetRoomDataFunction();
										setIsOnlyOneMesageDeleteModal(false);
									}}
								>
									취소
								</Button>
								<Button color="primary" onClick={() => deleteTextAction()}>
									삭제
								</Button>
							</ModalFooter>
						</Modal>
						{/* End Modals */}
					</div>
				{/* )} */}
				<RightMenuContent
					targetVideoAndImage={targetVideoAndImage}
					menuValue={'DetailView'}
				/>
			</div>
		</React.Fragment>
	);
};

const LoadingComponent = (props) => {
	const {scrollActivateControl, loadingFile} = props;
	useEffect(() => {
		scrollActivateControl()
	}, [loadingFile])
	return (
		<>
			{loadingFile && <Spinner type="grow" color="warning"/>}
		</>
	)
}

const InfoMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div>
			{message.action === 'purchase' && (
				<img
					className="media-object"
					src={podding_box}
					alt=""
					style={{width: '100px'}}
				/>
			)}
			<div className="bg-light-blue msg-tag ln-msg">
				{propsBody.checkText(message.message)}
			</div>
		</div>
	)
}
const TextMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div
			onContextMenu={(e) => {
				e.preventDefault();
				propsBody.getModalCopyAndDelete(
					message.senderId === props.user.id,
					message.messageId,
				);
			}}
		>
			{propsBody.messageClickAction?.action &&
				propsBody.messageClickAction?.messageId ===
				message.messageId && message.isActivated !==0 &&(
					<div
						className="tool_tip"
						style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
					>
						<button
							onClick={() => {
								propsBody.copyTextAction(message.message);
							}}
						>
							<i className="fas fa-share-square"></i>
							<p>copy </p>
						</button>
						{propsBody.messageClickAction?.myMessage && (
							<button
								onClick={() => {
									propsBody.setTargetMessageId(
										message.messageId,
									);
									propsBody.setIsOnlyOneMesageDeleteModal(
										true,
									);
								}}
							>
								<i className="fas fa-copy"></i>
								<p>delete </p>
							</button>
						)}
					</div>
				)}
			<div className="bg-light-blue msg-tag ln-msg">
				{propsBody.checkText(message.message)}
			</div>
		</div>
	)
}
const PhotoMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div className="media_img media-message"
			onContextMenu={(e) => {
				e.preventDefault();
				propsBody.getModalCopyAndDelete(
					message.senderId === props.user.id,
					message.messageId,
				);
			}}
			style={{boxSizing:"content-box", aspectRatio: 1 / (message.imgRatio || 1)}}
		>
			{propsBody.messageClickAction?.action &&
				propsBody.messageClickAction?.messageId ===
				message.messageId && (
					<div
						className="tool_tip"
						style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
					>
						{propsBody.messageClickAction?.myMessage && (
							<button
								onClick={() => {
									propsBody.setTargetMessageId(
										message.messageId,
									);
									propsBody.setIsOnlyOneMesageDeleteModal(
										true,
									);
								}}
							>
								<i className="fas fa-copy"></i>
								<p>delete </p>
							</button>
						)}
					</div>
				)}
			<img
				onClick={() => {
					propsBody.setTargetVideoAndImage({
						name: 'PHOTO',
						value: message.fileOrigin,
					});
					toggleRight(true);
				}}
				src={message.message}
				alt=""
				style={{objectFit:'cover', backgroundColor: '#ebebeb'}}
				onLoad={()=>{propsBody.scrollActivateControl()}}
			/>
		</div>
	)
}
const VideoMessage = (props) => {
	const {message, propsBody} = props
	useEffect(() => {
		propsBody.scrollActivateControl()
	}, [message])
	return (
		<div
			className="media_img media-message"
			onClick={() => {
				propsBody.checkVideoUploading(
					message.fileOrigin,
					message.jobId,
				);
			}}
			style={{boxSizing:"content-box", aspectRatio: 1 / (message.imgRatio || 1)}}
			onContextMenu={(e) => {
				e.preventDefault();
				propsBody.getModalCopyAndDelete(
					message.senderId === props.user.id,
					message.messageId,
				);
			}}
		>
			{propsBody.messageClickAction?.action &&
				propsBody.messageClickAction?.messageId ===
				message.messageId && (
					<div
						className="tool_tip"
						style={message.messageId === propsBody.messages[0].messageId ? {top:'35px'} : {}}
					>
						{propsBody.messageClickAction?.myMessage && (
							<button
								onClick={() => {
									propsBody.setTargetMessageId(
										message.messageId,
									);
									propsBody.setIsOnlyOneMesageDeleteModal(
										true,
									);
								}}
							>
								<i className="fas fa-copy"></i>
								<p>delete </p>
							</button>
						)}
					</div>
				)}
			<div className="video_container">
				<img
					src={message.message}
					alt=""
					onLoad={()=>{propsBody.scrollActivateControl()}}
					style={{objectFit:'cover', backgroundColor: '#ebebeb'}}
				/>
				<i className="fas fa-play-circle video">
					{propsBody.loadingVideo &&
						propsBody.targetVideoAndImage.jobId ===
						message.jobId && (
							<Spinner
								animation="border"
								variant="primary"
							/>
						)}
				</i>
			</div>
		</div>
	)
}

export default withRouter(withTranslation()(ChatBox));
