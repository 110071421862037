// import axios from 'axios';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {patch, post} from '../helpers/api_helper_user';
// import {get as getCommon} from '../helpers/api_helper_common';
import {get, patch, post} from '../helpers/api_helper';
import config from '../config/configuration';

const updateProfile = async function (userId, age, gender) {
	try {
		let body = {
			age_group: age,
			gender: gender,
		};
		return await patch(`/${userId}/profile`, body, {baseURL: config.api.user_api});
	} catch (err) {
		toast.error(err.response.data.message);

		return err;
	}
};

const getKeyWords = async function (userId) {
	try {
		// return await getCommon(`/categories/keyword/subscribe?userId=${userId}`);
		return await get(`/categories/keyword/subscribe?userId=${userId}`, {baseURL: config.api.common_api});
	} catch (err) {
		toast.error(err.response.data.message);

		return err;
	}
};
const getDistrictLists = async function (userId) {
	try {
		// return await getCommon(`/categories/district/subscribe?userId=${userId}`,);
		return await get(`/categories/district/subscribe?userId=${userId}`,{baseURL: config.api.common_api});
	} catch (err) {
		toast.error(err.response.data.message);

		return err;
	}
};
const checkLandingPage = async function (path) {
	try {
		/*let response = await axios({
			url: `${config.api.chat_api}/talk2you/users/profile/landing-page/check`,
			method: 'POST',
			data: {
				path: path,
			},
		});*/
		const body = {
			path: path,
		}
		const response = await post(`/talk2you/users/profile/landing-page/check`, body);
		toast.success(response.data.message);
		return response;

	} catch (err) {
		toast.error(err.response.data.errors.errorMessage[0]);
		return err;
	}
};

const uploadUserImg = async function (userId, image) {
	let body = {
		images: image,
	};
	try {
		return await post(`/${userId}/images`, body, {baseURL: config.api.user_api});

	} catch (err) {
		toast.error(err.response.data.message);
		console.error(err);
		return err;
	}
};
const getUserImg = async function (userId) {
	try {
		// let response = await get(`/${userId}/images`);
		// TODO : 몽골 친구들이 API를 잘못 이해함. 위 API는 USER Profile Image History를 제공하는 API. 따라서 아래 API를 사용해야 함
		let body = {
			user_id: userId,
		};
		return await post(`/profile/find/basic-info`, body, {baseURL: config.api.user_api});

	} catch (err) {
		// toast.error(err.response.data.message);
		console.error(err);
		return err;
	}
};
export const userService = {
	uploadUserImg,
	getUserImg,
	getKeyWords,
	getDistrictLists,
	checkLandingPage,
	updateProfile,
};
