import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Container, Card, CardBody, Row, Col, Input, Label } from "reactstrap";
import {
    getService,
    putService,
} from "../../services/answerTalkProduct.service";

import icTiket from "../../assets/images/ic_tiket.png";
import imgBosuk01 from "../../assets/images/imgbosuk01.png";
import imgBosuk02 from "../../assets/images/imgbosuk02.png";
import { authService } from "../../services/auth.service";

const ProductManagement = () => {
    // const userId = localStorage.getItem("user_id");
    const [editData, setEditData] = useState({
        freeTransferCount: 0,
        productPurchaseNeedsCount: 0,
        productDescription: "",
    });
    const [limit, setLimit] = useState({
        freeTransferCount: 0,
        productPurchaseNeedsCount: 0,
    });
    const [isRetoken, setIsRetoken] = useState("")

    const getTransLimit = async () => {
        try {
            const response = await authService.getUserId()
            if(response.status===201){
                // const result = await getService(userId);
                const result = await getService(response.data.user_id);
                // TODO: 204 처리 필요
                if (result?.itemList[0]) {
                    setEditData(() => result?.itemList[0]);
                    setLimit(() => {
                        return result?.transferLimit;
                    });
                }
            }
        } catch (err) {
            throw new Error(err);
        }
    };
    const handleData = (data) => {
        const { name, value } = data;
        setEditData((prev) => {
            return {
                ...prev,
                [name]: value,
            };
        });
    };
    const handleSubmit = async (value) => {
        const {
            freeTransferCount, // 1
            productPurchaseNeedsCount, // 2
            productDescription,
        } = editData;
        const TimeLimitsData =
            value === 1
                ? {
                      freeTransferCount: parseInt(freeTransferCount),
                  }
                : value === 2
                ? {
                      productPurchaseNeedsCount: parseInt(
                          productPurchaseNeedsCount
                      ),
                  }
                : {
                      freeTransferCount: parseInt(freeTransferCount),
                      productPurchaseNeedsCount: parseInt(
                          productPurchaseNeedsCount
                      ),
                      productDescription,
                  };
        try {
            const checkLength =
                TimeLimitsData.productDescription?.trim().length === 0;
            if (value === 3 && checkLength) {
                return toast.error("답변톡 상세 설정을 입력해 주세요");
            } else {
                if (
                    value !== 2 &&
                    limit.freeTransferCount < freeTransferCount
                ) {
                    return toast.error("기본 무료 전송 글자 수는 20,000자 입니다.");
                } else if (
                    value !== 1 &&
                    limit.productPurchaseNeedsCount < productPurchaseNeedsCount
                ) {
                    return toast.error("답변톡 이용권 가격은 최대 10,000보석입니다.");
                } else {
                    const response = await authService.getUserId()
                    // const result = await putService(TimeLimitsData, userId);
                    const result = await putService(TimeLimitsData, response.data.user_id);
                    if (result.status === 204) toast.error("유저 정보 없음");
                    else return toast.success("제한 설정을 완료했습니다.");
                }
            }
        } catch (error) {
            return toast.error("입력 값을 확인해 주세요");
        }
    };
    const placeholderSet = ``;
    //meta title
    useEffect(() => {
        const isCheck = async () => {
            const response = await authService.getACToken()
            if(response.status===201) {
                setIsRetoken(()=>{return response.status})
            } else {
				return await authService.logout()
			}
        }
        isCheck().then(r=>r)
        getTransLimit();
    }, [isRetoken]);

    document.title = "Flowing";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="section-body">
                    <ToastContainer />
                    <Container fluid>
                        <Row>
                            <Col lg={12} className="">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">
                                        답변톡 이용권 가격설정
                                    </h4>
                                </div>
                            </Col>
                        </Row>
                        <Row className="clearfix">
                            <Col lg={12} className="">
                                <Card>
                                    <CardBody>
                                        <Row className="align-items-center mt-auto mb-4">
                                            <Col lg={9}>
                                                <i className="icon-bubble titIcon"></i>{" "}
                                                기본 무료 전송 글자수
                                            </Col>
                                            <Col lg={3}>
                                                <Row className="gutters-xs">
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <span>글자수</span>
                                                            <Input
                                                                type="number"
                                                                name="freeTransferCount"
                                                                className="form-control ml-2 mr-2"
                                                                placeholder="100자"
                                                                value={
                                                                    editData.freeTransferCount
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    // setFreeTransferCount(
                                                                    //     e
                                                                    //         .target
                                                                    //         .value
                                                                    // );
                                                                    handleData(
                                                                        e.target
                                                                    );
                                                                }}
                                                            />
                                                            자
                                                        </div>
                                                    </Col>
                                                    <span className="col-auto">
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="button"
                                                            onClick={() => {
                                                                handleSubmit(1);
                                                            }}
                                                        >
                                                            변경
                                                        </button>
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mt-auto mb-4">
                                            <Col lg={9}>
                                                <img
                                                    src={icTiket}
                                                    alt=""
                                                    className="titIcon"
                                                />
                                                답변톡 이용권 가격 설정
                                                <p className="d-block text-muted mt-2">
                                                    답변톡 이용권 가격은 업종과
                                                    답변 난이도에 따라 답변자가
                                                    직접 설정 가능합니다.
                                                </p>
                                            </Col>
                                            <Col lg={3} className="mb-2">
                                                <Row className="gutters-xs">
                                                    <Col>
                                                        <div className="d-flex align-items-center">
                                                            <span>보석수</span>
                                                            <Input
                                                                type="number"
                                                                value={
                                                                    editData.productPurchaseNeedsCount
                                                                }
                                                                name="productPurchaseNeedsCount"
                                                                className="form-control ml-2 mr-2"
                                                                placeholder=""
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleData(
                                                                        e.target
                                                                    );
                                                                }}
                                                            />
                                                            개
                                                        </div>
                                                    </Col>
                                                    <span className="col-auto">
                                                        <button
                                                            className="btn btn-secondary"
                                                            type="button"
                                                            onClick={() => {
                                                                handleSubmit(2);
                                                            }}
                                                        >
                                                            변경
                                                        </button>
                                                    </span>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Row className="align-items-center mt-auto mb-4">
                                            <Col md={12}>
                                                <div className="form-group mb-0">
                                                    <label className="form-label">
                                                        <i className="icon-speech titIcon"></i>{" "}
                                                        답변톡 이용권 상세 설명{" "}
                                                    </label>
                                                    <textarea
                                                        rows="6"
                                                        name="productDescription"
                                                        className="form-control"
                                                        placeholder="답변톡 이용권 내용 설명을 만들어 주세요.&#13;&#10;예시) 여행 하는 작가 별입니다.&#13;&#10;여행 관련 영상 촬영 편집하는 노하우 알려드려요.&#13;&#10;★ 여행 영상 촬영&#13;&#10;★ 영상 편집 법&#13;&#10;★ 사진 잘 찍는 법"
                                                        value={
                                                            editData.productDescription
                                                        }
                                                        onChange={(e) => {
                                                            handleData(
                                                                e.target
                                                            );
                                                        }}
                                                    ></textarea>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-center mt-auto">
                                            <Col md={12} className="text-right">
                                                <button
                                                    type="submit"
                                                    className="btn btn-secondary"
                                                    onClick={() => {
                                                        handleSubmit(3);
                                                    }}
                                                >
                                                    저장
                                                </button>
                                            </Col>
                                        </Row>

                                        <div className="max1000">
                                            <Row className="align-items-center mt-auto">
                                                <Col lg={5} className="mt-2">
                                                    <div className="bluebox">
                                                        <h5>
                                                            유료 답변톡 이용권
                                                            상품 구성
                                                        </h5>
                                                        <p>
                                                            <i className="icon-bubbles"></i>{" "}
                                                            전송 가능한 글자수
                                                            500 글자
                                                        </p>
                                                        <p>
                                                            <i className="icon-picture"></i>{" "}
                                                            이미지 전송 50글자로
                                                            인식
                                                        </p>
                                                        <p>
                                                            <i className="icon-social-youtube"></i>{" "}
                                                            영상 전송 50글자로
                                                            인식
                                                        </p>
                                                        <p className="mb-0">
                                                            <i className="icon-microphone"></i>{" "}
                                                            음성 전송 50글자로
                                                            인식
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col lg={5} className="mt-2">
                                                    <div className="bluebox">
                                                        <h5>구매 단위(보석)</h5>
                                                        <p>
                                                            다이아/루비로 구매
                                                        </p>
                                                        <p>
                                                            <img
                                                                src={imgBosuk01}
                                                                alt=""
                                                                className="titIcon"
                                                            />
                                                            다이아 1개 = 100원
                                                        </p>
                                                        <p>
                                                            <img
                                                                src={imgBosuk02}
                                                                alt=""
                                                                className="titIcon"
                                                            />
                                                            루비 1개 = 100원
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(ProductManagement));

ProductManagement.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
