import React from 'react';

import {connect} from 'react-redux';

import {withRouter} from 'react-router-dom';
// Import menuDropdown
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import {authService} from '../../services/auth.service';
//i18n
import {withTranslation} from 'react-i18next';
import {
	showRightSidebarAction,
	toggleLeftmenu,
	changeSidebarType,
} from '../../store/actions';

const Header = (props) => {
	return (
		<React.Fragment>
		{/* <div className="page">
		<div id="page_top" className="section-body"> */}
			<div id="page_top" className="section-body">
            	<div className="container-fluid">
					<div className="page-header">
						<div className="left"></div>
						<div className="right">
							<div className="notification d-flex">
								<LanguageDropdown/>
								<button
									onClick={() => {
										props.history.push('/qna/NoticeView');
									}}
									type="button"
									className="btn btn-facebook"
								>
									<i className="fa fa-info-circle mr-2"></i>
									{props.t('Notice')}
								</button>
								<button
									onClick={() => {
										props.history.push('/qna/QnaView');
									}}
									type="button"
									className="btn btn-facebook"
								>
									<i className="fa fa-file-text mr-2"></i>
									{props.t('Inquiry')}
								</button>
								<button
									type="button"
									className="btn btn-facebook"
									onClick={() => {
										authService.logout();
									}}
								>
									<i className="fa fa-power-off mr-2"></i>
									{props.t('Log out')}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		{/* </div>
		</div> */}
		</React.Fragment>
	);
};

const mapStatetoProps = (state) => {
	const {layoutType, showRightSidebar, leftMenu, leftSideBarType} =
		state.Layout;
	return {layoutType, showRightSidebar, leftMenu, leftSideBarType};
};

export default withRouter(
	connect(mapStatetoProps, {
		showRightSidebarAction,
		toggleLeftmenu,
		changeSidebarType,
	})(withTranslation()(Header)),
);
