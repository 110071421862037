import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import toastr from 'toastr';
import {withRouter, Link} from 'react-router-dom';
import config from "../../config/configuration";

import {createQna as postData, uploadQnAImage} from '../../services/qna.service';
import {
	Container,
	Row,
	Input,
	FormGroup,
	Table,
	Button,
	Col,
	Spinner,
} from 'reactstrap';
import axios from 'axios';
import { authService } from '../../services/auth.service';
import { toast, ToastContainer } from 'react-toastify';

function QnaWrite(props) {
	const [title, setTitle] = useState('');
	const [inquiry_type, setInquiry_type] = useState('');
	const [inquiry, setInquiry] = useState('');

	const [image, setImage] = useState();
	const [fileUpload, setFileUpload] = useState();
	const [loading, setLoading] = useState(false);

	// const userId = localStorage.getItem("user_id")

	//meta title
	const handleSubmit = async () => {
		const check = title.trim() !== "" && inquiry.trim() !== "" && inquiry_type.trim() !== ""
		if(check) {
			setLoading(true);
			const file = fileUpload.files[0];
			if (file !== null && file !== undefined) {
				const formData = new FormData();
				formData.append('upload', file);
				// const uploaded = await axios.post(
				// 	`${config.api.proxy_api}/common/files/upload/flowing-web-resources/image`,
				// 	formData,
				// );
				const uploaded = await uploadQnAImage(formData)
				console.log(uploaded)

				setImage(uploaded?.data?.url);
				await postService(uploaded?.data?.url);
				// setImage(uploaded?.url);
				// await postService(uploaded?.url);
			} else {
				await postService();
			}
		} else {
			toast.error('내용을 입력해 주세요')
		}
	};

	const postService = async (imageUrl = '') => {
		// console.log('imageUrl', imageUrl); return;
		try {
			const response = await authService.getUserId()
			if(response.status===201){
				const questionData = {
					title: title,
					inquiry: inquiry,
					inquiryType: inquiry_type,
					// writer: 'ganaa',
					userId: response.data.user_id,
					// userId: userId,
					images: imageUrl,
					// attachments: 'image/jpeg',
				};
				console.log(questionData)
				const newQna = await postData(questionData);
				if (newQna) {
					toastr.success('등록완료');
					setLoading(false);
					props.history.push('/qna/qnaView');
				}
			}
		} catch (err) {
		}
	};
	const inquiryTypes = [
		{
			label: "회원정보",
			value: "profile"
		},
		{
			label: "결제/구매",
			value: "payment"
		},
		{
			label: "오류신고",
			value: "error"
		},
		{
			label: "기타문의",
			value: "etc"
		},
		{
			label: "회원탈퇴",
			value: "withdrawal"
		},
		{
			label: "이용문의",
			value: "use"
		}
	]

	document.title = '플로잉 문의하기';
	return (
		<React.Fragment>
			<ToastContainer />
			{/* <div className="page-content"> */}
				{loading ? (
					<div className="text-center d-flex justify-content-center">
						<Spinner
							className=""
							color="primary"
							style={{
								height: '3rem',
								width: '3rem',
							}}
							type="grow"
						>
							Loading...
						</Spinner>
					</div>
				) : (
					<div className="section-body">
						<Container fluid>
							<Row className="align-items-center">
								<div className="col text-left">
									<h1 className="page-title">플로잉 문의하기</h1>
								</div>
								<div className="col text-right"></div>
							</Row>
							<div className="table-responsive">
								<Table className="table-vcenter text-nowrap tableView">
									<tbody>
									<tr>
										<th className="text-left BoardTitle">
											<FormGroup>
												<label className="form-label">
													문의 제목
													<span className="form-label-small">{title.length}/20</span>
												</label>

												<Input
													className="form-control"
													type="text"
													name="title"
													required
													placeholder="20자 이내로 문의 제목을 작성해 주세요."
													onChange={(e) => setTitle(e.target.value)}
												/>
											</FormGroup>
										</th>
										<td className="text-right BoardInfo">
											<FormGroup>
												<label className="form-label">문의 유형</label>
												<select
													className="form-control custom-select"
													name="inquiry_type"
													onChange={(e) => setInquiry_type(e.target.value)}
												>
													<option value="">유형 선택</option>
													{inquiryTypes.map((option, index) => (
														<option value={option.value}>{option.label}</option>
													))}
													{/*<option value="">Select</option>
													<option value="First">First</option>
													<option value="Second">Second</option>*/}
												</select>
											</FormGroup>
										</td>
									</tr>
									<tr>
										<td colSpan="2">
											<div className="form-group">
												<label className="form-label">
													문의 내용
													<span className="form-label-small">{inquiry.length}/500</span>
												</label>
												<textarea
													rows="6"
													className="form-control"
													name="inquiry"
													onChange={(e) => setInquiry(e.target.value)}
													placeholder="500자 이내로 문의 내용을 작성해 주세요."
												></textarea>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan="2" className="fileUp">
											<div className="input-group">
                          <span className="input-group-btn">
                            <span className="btn btn-default btn-file">
                              이미지 첨부
                              <form>
                                <input
									type="file"
									accept='image/*'
									id="selected-file"
									name="attachments"
									ref={(ref) => setFileUpload(ref)}
								/>
                              </form>
                            </span>
                          </span>
												<Input
													className="form-control"
													type="text"
													placeholder="첨부이미지가 있을 경우 파일을 선택해 주세요."
													readOnly
													name="writer"
												/>
											</div>
										</td>
									</tr>
									</tbody>
								</Table>
								<Row className="justify-content-center text-center">
									<Col lg="12">
										<Link
											className="btn btn-outline-secondary m-1"
											to={'./QnaView'}
										>
											취소
										</Link>
										<Button
											type="submit"
											className="btn btn-secondary m-1"
											onClick={handleSubmit}
										>
											작성완료
										</Button>
									</Col>
								</Row>
							</div>
						</Container>
					</div>
				)}
			{/* </div> */}
		</React.Fragment>
	);
}

QnaWrite.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
export default withRouter(withTranslation()(QnaWrite));
