import {memo} from 'react';

export const OutLinkItem = ({outLink, ...props}) => {
    return (
        <div className="adressArea noView partOn">
            <div className="d-flex align-items-center mt-auto">
                <img className="avatar avatar-md mr-2" src={outLink.iconLink || ''} alt={outLink.linkTitle || ''} onError={(ev) => {
                    ev.target.src = 'https://d1vb419ubgm46b.cloudfront.net/userbg.jpg';
                }} />
                <div>
                    <div className="d-block"><a href={outLink.linkUrl || undefined} target="_blank">{outLink.linkTitle || ''}</a></div>
                </div>
            </div>
        </div>
    )
}

export default memo(OutLinkItem);