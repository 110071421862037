import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {map} from 'lodash';
import ReactPlayer from 'react-player';

import {toggleRight} from '../../helpers/Functions/check';

const RightMenuContent = (props) => {
	const {previewFileArray} = props;

	useEffect(() => {
		console.log("previewFileArray:::",previewFileArray);
		for (let i = 0; i < previewFileArray?.length; i++) {
			if (
				previewFileArray[i].type === 'VIDEO' &&
				!previewFileArray[i].mediaUrl
			) {
				var videoNode1 = document.querySelector('.video-player' + i);
				var fileURL1 = previewFileArray[i].showFile;
				videoNode1.src = fileURL1;
			}
		}
	}, []);

	return (
		<React.Fragment>
			<div id="right-sidebar">
				<div className="d-flex justify-content-between brand_name">
					<div
						className="brand-name mb-4 d-flex"
						onClick={() => {toggleRight(false); props.setMenuValue("")}}
					>
						<div className=" d-flex">
							<div className="btnRightCon2">
								<i className="icon-close" style={{cursor:'pointer'}}></i>
							</div>
							<span id="rightMenuTitle" className="ml-2">
								멀티미디어 미리 보기
							</span>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-lg-12">
							<div id="DetailView" className="viewContents">
								{props.targetVideoAndImage?.name === 'PHOTO' && (
									<div className="photoArea">
										<img
											className="d-block w-100"
											alt=""
											src={props.targetVideoAndImage.value}
										/>
									</div>
								)}
								{props.targetVideoAndImage?.name === 'VIDEO' && (
									<ReactPlayer
										width={356}
										url={props.targetVideoAndImage.value}
										playing={false}
										controls={true}
									/>
								)}
							</div>
							<div id="contentArea" className="viewContents ">
								{previewFileArray &&
									map(previewFileArray, (item, index) => (
										<div key={index}>
											{item.type === 'PHOTO' && (
												<p className="photoArea">
													{item.mediaUrl ? (
														<img
															src={item.mediaUrl}
															alt="img"
															className="d-block w-100 "
														/>
													) : (
														<img
															src={item?.showFile}
															alt="img"
															className="d-block w-100 "
														/>
													)}
												</p>
											)}
											{item.type === 'VIDEO' && (
												/*<p className="mvArea">*/
												/*Error Occurred - <div> cannot appear as a descendant of <p>*/
												<div className="mvArea">
													{item.mediaUrl ? (
														<ReactPlayer
															width={356}
															url={item.mediaUrl}
															playing={false}
															controls={true}
														/>
													) : (
														<video
															controls
															// autoPlay="autoplay"
															className={'video-player' + index}
														></video>
													)}
												</div>
											)}
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

RightMenuContent.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(RightMenuContent));
