import React, {useEffect, useMemo} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import _, {map} from 'lodash';
import classnames from 'classnames';
import {
	Container,
	Table,
	Row,
	Col,
	Card,
	CardBody,
	InputGroup,
	Input,
	Button,
	Nav,
	NavItem,
	NavLink,
	Pagination,
	PaginationItem,
	PaginationLink,
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

//import Answer service
import {
	getAnswerRoomListSearch,
	goAnswerRoomEnter,
} from '../../services/answerProfile.service';
import { authService } from '../../services/auth.service';

const ProfileManagement = (props) => {
	//meta title
	const [customActiveTab, setcustomActiveTab] = useState('1');
	const [answerRooms, setAnswerRooms] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [type, setType] = useState('');
	const [isRetoken, setIsRetoken] = useState("")
	const LIMIT = 10
	const [totalCount, setTotalCount] = useState(0)


	const [currentPage, setCurrentPage] = useState(1)
	const pageSize = 5
	let nOffset = ( currentPage - 1 ) * LIMIT
	let lastPage = Math.ceil( totalCount / LIMIT )
	let currentPageUnit = Math.ceil( currentPage / pageSize )

	const toggleCustom = (tab) => {
		if (customActiveTab !== tab) {
			setcustomActiveTab(tab);
			if (tab === '1') setType('');
			if (tab === '2') setType('active');
			if (tab === '3') setType('inactive');
		}
	};

	useEffect(() => {
		// let userInfo = localStorage.getItem('user_data');
		// userInfo = JSON.parse(userInfo);

		// async function fetchDataFunc(searchText) {
		// 	const userId = localStorage.getItem('user_id');
		// 	const response = await getAnswerRoomListSearch(userId, searchText, type);
		// 	console.log("response.itemList:::",response.itemList);
		// 	setAnswerRooms(response.itemList);
		// }

		//fetchDataFunc(userInfo.nickname);
		const getId = async () => {
			const response = await authService.getACToken()
			if(response.status===201){
				setIsRetoken(()=>{return response.status})
			} else {
				return await authService.logout()
			}
		}
		getId().then(r=>r)
		fetchData().then(r => r);
	}, [type,isRetoken]);

	const fetchData = async () => {
		// let userInfo = localStorage.getItem('user_data');
		// userInfo = JSON.parse(userInfo);
		// const userId = localStorage.getItem('user_id');
		try {
			const res = await authService.getUserId()
			if(res.status===201) {
				// const response = await getAnswerRoomListSearch(userId, searchText, type);
				const response = await getAnswerRoomListSearch(res.data.user_id, searchText, type, nOffset, LIMIT).then(r=>{
					return r
				})
				setAnswerRooms(response.itemList);
				if(response.itemTotalCount) {
					setTotalCount(()=>{return response.itemTotalCount})
				}
				// console.log("response.itemList:::",response.itemList);
			}
		} catch(error){}
	};

	useEffect(() => {
		fetchData().then(r=>r)
	}, [currentPage])

	const enterRoomFunction = async (room) => {
		// console.log('room', roomId); return;
		const roomId = room && room.roomId || '';
		// let userInfo = localStorage.getItem('user_data');
		let userInfo = sessionStorage.getItem('user_data');
		userInfo = JSON.parse(userInfo);
		let body = {
			userId: userInfo.id,
			userName: userInfo.nickname,
			userImg:
				userInfo.image_url ||
				// 'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg',
				'https://d1vb419ubgm46b.cloudfront.net/userbg.jpg',
		};
		const response = await goAnswerRoomEnter(roomId, body);
		if (response.message) {
			// props.history.push('/answer/chatroom');
			props.history.push({
				pathname: '/answer/chatroom',
				// search: `?roomId=${roomId}`,
				data: room
			});
		}
	};

	// Enter keyboard action
	const onKeyPress = (e) => {
		const {key} = e;
		if (e.shiftKey) {
		} else if (key === 'Enter') {
			if (e.target.value && e.target.value !== '' && e.target.value !== '\n') {
				setSearchText(e.target.value);
				fetchData(e.target.value).then(r => r);
			} else {
				e.preventDefault();
			}
		}
	};

	const sortedAnswerRooms = useMemo(() => {
		return [...answerRooms].sort((a, b) => new Date(b.lastMessagedAt) - new Date(a.lastMessagedAt))
	}, [answerRooms])

	document.title = 'Search list';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<div className="section-body">
					<Container fluid>
						<div className="row">
							<div className="col-12">
								<div className="page-title-box d-sm-flex align-items-center justify-content-between">
									<h4 className="mb-0 font-size-18">참여자 관리</h4>
								</div>
							</div>
						</div>
						<Row className="clearfix">
							<Col lg={12} className="">
								<Card>
									<CardBody>
										<div className="d-md-flex justify-content-between">
											<Nav tabs className="nav nav-tabs b-none">
												<NavItem>
													<NavLink
														style={{cursor: 'pointer'}}
														className={classnames({
															active: customActiveTab === '1',
														})}
														onClick={() => {
															toggleCustom('1');
														}}
													>
														{props.t('All')}
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														style={{cursor: 'pointer'}}
														className={classnames({
															active: customActiveTab === '2',
														})}
														onClick={() => {
															toggleCustom('2');
														}}
													>
														{props.t('Answering in progress')}
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														style={{cursor: 'pointer'}}
														className={classnames({
															active: customActiveTab === '3',
														})}
														onClick={() => {
															toggleCustom('3');
														}}
													>
														{props.t('Unanswered')}
													</NavLink>
												</NavItem>
											</Nav>
										</div>
										<InputGroup className="mt-2">
											<Input
												className="form-control"
												type="text"
												placeholder={props.t('Please enter your search term.')}
												value={searchText}
												onKeyPress={onKeyPress}
												onChange={(e) => {
													setSearchText(e.target.value);
												}}
											/>
											<span className="input-group-append">
												<Button
													className="btn btn-primary"
													type="button"
													onClick={() => {
														fetchData(searchText).then(r => r);
													}}
												>
												<i className="icon-magnifier"></i>
												</Button>
											</span>
										</InputGroup>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="section-body">
					<Container fluid>
						<Table className="table table-hover table-vcenter text-nowrap table_custom list">
							<tbody>
							<tr>
								<th className="hidden-xs">{props.t('Profile')} </th>
								<th className="hidden-xs">{props.t('Nickname')}</th>
								<th className="hidden-xs">{props.t('Participant type')}</th>
								<th className="hidden-xs">상품구매수</th>
								{/*<th className="hidden-xs">{props.t('Account recent activity')}</th>*/}
								<th className="hidden-xs">{/*{props.t('Talk time')}*/}최근 톡시간</th>
								<th className="hidden-xs">
									{props.t('Answer room opening hours')}{' '}
								</th>
								<th className="hidden-xs">{props.t('Option')}</th>
							</tr>
							</tbody>
							<tbody>
							{map(sortedAnswerRooms, (item, index) => (
								<tr key={index}>
									<td className="text-center width40 hidden-xs">
										<div className="avatar d-block">
											<img
												className="avatar"
												src={item.userImg}
												alt="avatar"
											/>
										</div>
									</td>
									<td className="hidden-xs">
										<div>{item.userName}</div>
									</td>
									<td className="hidden-xs">
										<div className="text-muted">{item.inquiry && item.inquiry?.title}</div>
									</td>
									<td className="">
										<div className="text-muted">{item.purchaseCount}</div>
									</td>
									<td className="hidden-sm">
										<div className="text-muted">{item.lastMessagedAt}</div>
									</td>
									<td className="hidden-sm">
										<div className="text-muted">{item.createdAt}</div>
									</td>
									<td className="width220">
										{/* <Link className="btn btn-secondary mr-1"> Report it</Link> */}
										<button
											className="btn btn-secondary"
											onClick={() => {
												// enterRoomFunction(item.roomId).then(r => r);
												enterRoomFunction(item).then(r => r);
											}}
										>
											{props.t('Enter room')}
										</button>
									</td>
								</tr>
							))}
							{!answerRooms && (
								<tr>
									<td colSpan={7}>
										<p className="noResult">
											{props.t('Can\'t find user that matches')}
										</p>
									</td>
								</tr>
							)}
							</tbody>
						</Table>
					</Container>
				</div>
				{/* 페이지네이션 */}
				{
					totalCount !== null && answerRooms.length > 0 && (
						<Pagination className="pagination justify-content-center generic-pagination pr-1">
							<PaginationItem>
								<PaginationLink 
									aria-label="Previous"
									onClick={()=>{setCurrentPage(1);}}
								>
									First
								</PaginationLink>
							</PaginationItem>
							<PaginationItem>
								<PaginationLink
									aria-label="Previous"
									onClick={()=>{
										if( ((currentPageUnit -1 )*pageSize ) >= 1){
											setCurrentPage(((currentPageUnit -1 )*pageSize )); 
										}
										
									}}
								>
									<i className="fa fa-angle-left"></i>
								</PaginationLink>
							</PaginationItem>
							{[...Array(pageSize)].map((page, i) => {
								if(((currentPageUnit -1 )*pageSize + i+1) <= lastPage) {
									return(
										<PaginationItem
											active={((currentPageUnit -1 ) * pageSize + i + 1) === currentPage}
											key={i}
										>
										<PaginationLink
											onClick={()=>{setCurrentPage(((currentPageUnit -1 )*pageSize + i+1)); }}
										>{((currentPageUnit -1 )*pageSize + i+1)}
										</PaginationLink>
									</PaginationItem>
									)
								} else {
									return (
										<div key={i} />
									)
								}
								
							})}
							<PaginationItem>
								<PaginationLink
									aria-label="Next"
									onClick={()=>{
										if(currentPageUnit * pageSize + 1 <= lastPage)
										setCurrentPage(currentPageUnit * pageSize+1);
									}}
								>
									<i className="fa fa-angle-right"></i>
								</PaginationLink>
							</PaginationItem>
							<PaginationItem>
								<PaginationLink
									aria-label="Next"
									onClick={()=>{setCurrentPage(lastPage)}}
								>
									Last
								</PaginationLink>
							</PaginationItem>
						</Pagination>
					)
				}
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(ProfileManagement));

ProfileManagement.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
