import React, {useState, useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import flowingLogo from '../../../assets/images/flowing_logo.jpg';
import logo from '../../../assets/images/logo_landingFoot.png';
import logoW from '../../../assets/images/logo_landingFoot_w.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import pcBg01 from '../../../assets/images/pcBg01.png';
import pcBg02 from '../../../assets/images/pcBg02.png';
import pcBg03 from '../../../assets/images/pcBg03.png';
import pcBg04 from '../../../assets/images/pcBg04.png';
import pcBg05 from '../../../assets/images/pcBg05.png';
import pcBg06 from '../../../assets/images/pcBg06.png';
import pcBg07 from '../../../assets/images/pcBg07.png';
import pcBg08 from '../../../assets/images/pcBg08.png';
import imgGift from '../../../assets/images/img_gift.png';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
import {daysInKr} from '../../../constants/options';
import BannerItem from '../../../components/previews/spacehome/BannerItem';
import ScheduleList from '../../../components/previews/spacehome/ScheduleList';
//post forbidden word API's
const PcTypeTextBanner = (props) => {
	
    const [allArea, setAllArea] = useState(true);

	return (

           <>

            <div className="col-lg-12">

                <div id="basicmodal03" className="phone02 drop-shadow hauto min600 w-100 mt-2 nomalMode">
                    <div className="phone_over hauto">
                        <div className="PhoneArea hauto pcWhite">


                            <div className="topArea">
                                <div className="titArea"><a href="#"><img src={flowingLogo} /></a> <span className="namebar"></span> {props.info && props.info?.userName}</div>


                                <div className="PhoneTop pcVerprofile">

                                    <div className="profileArea">

                                        <div className="row">
                                            {/* <div className="col-12 text-left pb-2"> <img className="avatar avatar-lg mr-2" src={imgAvatar} alt="" /></div> */}
                                            <div className="col-12 text-left pb-2">
                                                {/*PC버전에서 사용할 프로필 이미지*/}
                                                <img className="avatar avatar-lg mr-2" src={props.pcTextBannerProfileImage || props.info && props.info?.userImg} alt="" />
                                            </div>
                                            <div className="col-12 text-left pb-2"><p>{props.info && props.info?.introduction || ''}</p></div>
                                            <div className="col-6 text-left">
                                                <div className="d-flex">
                                                    {/* <span className="slideleft"><i className="fe fe-chevron-left"></i></span> */}
                                                    <div className="iconsocial">
                                                        <i className="icon-social-youtube"></i>
                                                    </div>
                                                    <div className="iconsocial">
                                                        <i className="fe fe-instagram"></i>
                                                    </div>
                                                    <div className="iconsocial">
                                                        <i className="fe fe-facebook"></i>
                                                    </div>
                                                    <div className="iconsocial">
                                                        <i className="fe fe-twitter"></i>
                                                    </div>
                                                   {/* <span className="slideright"><i className="fe fe-chevron-right"></i></span> */}
                                                </div>
                                            </div>
                                            <div className="col-6 text-right">
                                                <small className="d-md-inline-block" style={{fontSize:"14px", color:"#333"}}><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small>
                                                <small className="d-md-inline-block ml-5" style={{fontSize:"14px", color:"#333"}}><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                <div className="topImgBg">
                                    {/* <span><img src={pcBg06} /></span> */}
                                    {/* <span><img src={props.pcBgImage[0]} /></span> */}
                                    <span><img src={props.pcBasicBgImage} /></span>
                                    <div className="gridBg"></div>
                                </div>

                            </div>

                            <div className="PhoneBody hauto">

                                <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || imgAvatarNew} alt="" /></div>

                                {props.designType === 'tab'?

                                    <div className="tabArea pagetab pctab partOn">
                                        <span
                                            className={allArea===true?"viewTabb07 tag tag-rounded active":"viewTabb07 tag tag-rounded  "}
                                            onClick={()=>{setAllArea(true)}}
                                            >전체보기
                                        </span>
                                        <span
                                            className={allArea === false && props.tabArea===0?"viewTab01 tag tag-rounded ml-2 active":"viewTab01 tag tag-rounded ml-2 "}
                                            onClick={()=>{props.setTabArea(0); setAllArea(false);}}
                                            >{props.menuOrder[0]}
                                        </span>
                                        <span
                                            className={allArea === false && props.tabArea===1?"viewTab02 tag tag-rounded ml-2 active":"viewTab02 tag tag-rounded ml-2 "}
                                            onClick={()=>{props.setTabArea(1); setAllArea(false);}}
                                            >{props.menuOrder[1]}
                                        </span>
                                        <span
                                            className={allArea === false && props.tabArea===2?"viewTab03 tag tag-rounded ml-2 active":"viewTab03 tag tag-rounded ml-2 "}
                                            onClick={()=>{props.setTabArea(2); setAllArea(false);}}
                                            >{props.menuOrder[2]}
                                        </span>
                                    </div>

                                :''}

                                <div className="conArea pb-100px">

                                    {
                                        allArea === true || props.designType ==='page'?
                                            <>
                                                {MenuOrder(props.menuOrder[0], props)}
                                                {MenuOrder(props.menuOrder[1], props)}
                                                {MenuOrder(props.menuOrder[2], props)}
                                                {MenuOrder(props.menuOrder[3], props)}
                                                {MenuOrder(props.menuOrder[4], props)}
                                            </>
                                        :
                                            props.tabArea === 0?
                                                MenuOrder(props.menuOrder[0], props)
                                            :props.tabArea ===1?
                                                MenuOrder(props.menuOrder[1], props)
                                            :props.tabArea ===2?
                                                MenuOrder(props.menuOrder[2], props)
                                            :props.tabArea ===3?
                                                MenuOrder(props.menuOrder[3], props)
                                            :props.tabArea ===4?
                                                MenuOrder(props.menuOrder[4], props)
                                            :''

                                    }

                                    <div className="footlogo"><img src={logo} /></div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div className="col-lg-12 text-right">
                <hr />
                <button className="btn btn btn-dark " type="button">저장하기</button>
            </div>

        </>

	);
};


function MenuOrder(menuName, props){
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];

    const shoppingEvent = props?.info?.shoppingEvent || [];
    const eventBanner = props?.info?.eventBanner || [];

    if(menuName ==='답변톡'){
        return(
            <>
            <div className="txtArea2 mt-2 noView partOn">
                <div className="mb-2 sectorTit">답변톡</div>
                <div className="d-block w-100 ">
                    <div className="d-block"><i className="fe fe-clock"></i> <b>20답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                </div>

                <div className="d-block w-100 ">
                    <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                </div>
                {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                    <div className="d-block w-100 mt-2">
                        <div className="d-block">공휴일에는 답변이 불가능합니다.
                            답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                            수 있습니다. 하지만 답변자의 답변은 운영시간을
                            기다려주세요. 감사합니다.
                        </div>
                    </div>
                }
            </div>

            <div className="mBox noView partOn boxbig ">
                <div className="titname d-block mb-2 mt-3"><b>답변키워드</b></div>
                <div className="answerInfo">
                    <div className=" align-items-center mt-auto">
                        <div className="d-block w-100 ">
                            {keywordList.map((item, index) => (
                                <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                            ))}
                        </div>
                        
                    </div>
                </div>
                <div className="titname d-block mb-2 mt-3"><b>답변지역</b></div>
                <div className="answerInfo">
                    <div className=" align-items-center mt-auto">
                        <div className="d-block w-100 ">
                            {regionList.map((item, index) => (
                                <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }else if(menuName === '외부링크 블록형'){
        return(
            <div className="linkInfo noView mt-3 partOn">
                {/*<div className="mb-2 sectorTit">외부링크</div>*/}
                {outLinkList.map((item, index) => (
                    item.iconLink ?
                    <div className="adressArea noView partOn" key={index}>
                        <div className="d-flex align-items-center mt-auto">
                            <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                            <div>
                                <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                            </div>
                        </div>
                    </div> : ""
                ))}
                {/*<div className="adressArea noView partOn">
                    <div className="d-flex align-items-center mt-auto">
                        <img className="avatar avatar-md mr-2" src={instar} alt="" />
                        <div>
                            <div className="d-block">instar.com</div>
                        </div>
                    </div>
                </div>*/}
                {/*<div className="mt-4 sectorTit subTit">피드</div>*/}
                <div className="row align-items-center mt-2">
                {videoFeedList.map((item, index) => (
                    <div className="col-3" key={index}>
                        <div className="listImg" style={{height: "180px"}}>
                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                        </div>
                        <div className="listTxt mt-2">
                            {item.videoTitle}
                        </div>
                        <div className="listday">
                            <div>{item.ownerChannelName}</div>
                            <div className="bar"></div>
                            <div>{item.publishDate}</div>
                        </div>
                    </div>
                ))}
                    {/*<div className="col-3">
                        <div className="listImg" style={{height: "180px"}}>
                        <img src={item.videoThumbnail} alt={item.videoTitle}/>
                        </div>
                    
                        <div className="listTxt mt-2">
                            {item.videoTitle}
                        </div>
                        <div className="listday">
                            <div>{item.ownerChannelName}</div>
                            <div className="bar"></div>
                            <div>2022.7.19</div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="listImg" style={{height: "180px"}}>
                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                        </div>
                    
                        <div className="listTxt mt-2">
                            {item.videoTitle}
                        </div>
                        <div className="listday">
                            <div>{item.ownerChannelName}</div>
                            <div className="bar"></div>
                            <div>2022.7.19</div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="listImg" style={{height: "180px"}}>
                        <img src={item.videoThumbnail} alt={item.videoTitle}/>
                        </div>
                    
                        <div className="listTxt mt-2">
                            {item.videoTitle}
                        </div>
                        <div className="listday">
                            <div>{item.ownerChannelName}</div>
                            <div className="bar"></div>
                            <div>2022.7.19</div>
                        </div>
                    </div>*/}
                    {/*<div className="col-12 text-center">
                        <a href="#" className="moreBtn2">더보기</a>
                    </div>*/}
                </div>
            </div>
        )
    }else if(menuName === '상품'){
        return(
            <div className="giftInfo noView mt-3 partOn">
                <div className="mb-2 sectorTit">상품</div>
                <div className="align-items-center mt-auto">
                    <div className="d-block w-100 ">
                        <div className="giftTit"><img src={imgGift} alt="" /> 플로잉 답변톡 상품18</div>
                        <p>
                            {props.info && props.info?.goodsUserProduct?.description || ''}
                        </p>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                    <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                    <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                    <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                </div>
            </div>
        )
    }else if(menuName === '외부링크 이미지형'){
        return (
            <div className="giftInfo noView mt-3 partOn" style={{display:'flex'}}>
                {eventBanner?.length ? eventBanner.map((item, index) => {
                    return <BannerItem key={index} {...item} />
                }) : <div>{menuName}</div>}
            </div>
        )
    }else if(menuName === '외부링크 스케줄형'){
        return (
            <div className="giftInfo noView mt-3 partOn">
                {shoppingEvent?.length ?
                    <ScheduleList list={shoppingEvent} />
                    : <div>{menuName}</div>}
            </div>
        )
    }

}

export default withRouter(withTranslation()(PcTypeTextBanner));

PcTypeTextBanner.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
