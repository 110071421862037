// import {get, put} from '../helpers/api_helper_chat';
import {get, put} from '../helpers/api_helper';

export async function getService(id) {
	try {
		return await get(`/talk2you/users/profile/${id}/transfer-limit`);
	} catch (err) {
		throw err;
	}
}

export async function putService(data, id) {
	try {
		return await put(`/talk2you/users/profile/${id}/transfer-limit`, data);
	} catch (err) {
		throw err;
	}
}
