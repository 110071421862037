import {combineReducers} from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication
import Login from './auth/login/reducer';
import Account from './auth/register/reducer';
import ForgetPassword from './auth/forgetpwd/reducer';
import Profile from './auth/profile/reducer';

//chat
import chat from './chat/reducer';

//Dashboard

//Dasboard saas

const rootReducer = combineReducers({
	// public
	Layout,
	Login,
	Account,
	ForgetPassword,
	Profile,
	chat,
});

export default rootReducer;
