import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {get, patch, post} from '../helpers/api_helper_chat';
import {get, patch, post} from '../helpers/api_helper';

export async function getAnswerRoomListSearch(userId, searchText, type, offset, limit) {
	try {
		return await get(
			// `/talk2you/rooms/${userId}/questioner/search?searchUserName=${searchText}&type=${type}`, // 참여자 관리 > 참여자 검색
			`/talk2you/rooms/${userId}/questioner?searchUserName=${searchText}&type=${type}&offset=${offset}&limit=${limit}`, // 참여자 관리 > 참여자 리스트 조회 API에 검색 기능을 추가해 통합
		);
	} catch (err) {
		throw err;
	}
}

export async function goAnswerRoomEnter(roomId, body) {
	try {
		return await post(`/talk2you/rooms/${roomId}/enter`, body);
	} catch (err) {
		throw err;
	}
}

export async function getAnswerRoomList(userId) {
	try {
		return await get(`/talk2you/rooms/${userId}/questioner?type=&offset=&limit`);
	} catch (err) {
		throw err;
	}
}

// 프로필 생성
export async function createProfile(body, userId) {
	try {
		return await post(`/talk2you/users/console/profile/${userId}`,body);
	} catch (err) {
		toast.error(err.response.data.message);
		return err;
	}
}

// 프로필 수정
export async function updateProfile(body, userId) {
	try {
		return await patch(`/talk2you/users/profile/${userId}`, body);
	} catch (err) {
		toast.error(err.response.data.message);
		return err;
	}
}

// 프로필 조회
export async function getProfile(userId) {
	try {
		return await get(`/talk2you/users/profile/${userId}`);
	} catch (err) {
		throw err;
	}
}

// 프로필 조회(Web) - 더 많은 항목 응답
export async function getMoreProfileItems(userId) {
	try {
		return await get(`/talk2you/users/web/profile/${userId}`);
	} catch (err) {
		throw err;
	}
}

