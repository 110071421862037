import React, {useState, useEffect, useRef, useMemo} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import {landingPageService} from '../../../services/landing-page.service';
import { Button } from 'reactstrap';
import {checkUrlPattern, addPrefixToUrl, checkUrlScheme} from '../../../common/utils/functions';
import BannerItem from '../../../components/previews/spacehome/BannerItem';

const ShoppingBannerSetting = (props) => {

	const [targetIndex, setTargetIndex] = useState(-1);
	const bannerImageForm = useRef(null);
	const [bannerImageUrl, setBannerImageUrl] = useState('');
	const [bannerTitle, setBannerTitle] = useState('');
	const [bannerUrl, setBannerUrl] = useState('');

	const urlRef = useRef(null);

	const changeTitle = (e) => {
		setBannerTitle(e.target.value)
	}
	const changeUrl = (e) => {
		setBannerUrl(e.target.value)
	}

	const clearEvent = () => {
		setBannerImageUrl('')
		setBannerTitle('')
		setBannerUrl('')
		setTargetIndex(-1)
		bannerImageForm.current.value = '';
	}

	const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			// let type = fileUploaded.files[0].type.substring(0, 5);
			let type = fileUploaded.files[0].type.split("/")[0];

			if (type === 'image') {
				if (originFileSize > 20) {
					toast.error('파일업로드는 20MB 까지 가능합니다. 파일용량을 확인해 주세요.');
					return;
				}
			} else {
				toast.error('이미지 파일만 업로드 가능합니다. 확인 후 다시 시도해 주세요.');
				return;
			}

			let bucketName = 'flowing-web-resources';
			let uploadType = 'image';
			let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;

			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

			const response = await landingPageService.uploadVAFService(formData,bucketName,uploadType);
			console.log('response', response)
			if(response?.msg === 'OK' && response?.url && response?.url.length > 0){
				setBannerImageUrl(response?.url[0])
			}

		}
	};

	const registerEventBanner = () => {
		// console.log('eventBanner : ', props.eventBanner);
		let eventBannerCopy = [...props.eventBanner];
		// console.log('eventBannerCopy : ', eventBannerCopy);
		// console.log('targetIndex : ', targetIndex); //return;

		if(!checkUrlPattern(bannerUrl)) {
			window.alert('링크를 URL 형식에 맞게 입력해 주세요.');
			// console.log('링크를 URL 형식에 맞게 입력해 주세요.');
			urlRef.current.focus();
			return false;
		} else {
			if(!checkUrlScheme(bannerUrl)) {
				window.alert('링크를 URL 형식에 맞게 변경합니다.');
				// console.log('링크를 URL 형식에 맞게 입력해 주세요.');
				setBannerUrl(addPrefixToUrl(bannerUrl));
				urlRef.current.focus();
				return false;
			}
		}

		if(targetIndex < 0) {
			console.log('added')
			if(bannerImageUrl && bannerTitle && bannerUrl){
				eventBannerCopy.push({
					bannerImageUrl,
					bannerTitle,
					bannerUrl,
				})
			} else {
				window.alert('이미지 또는 제목, 링크 URL 모두 입력해 주세요.')
			}
		} else {
			console.log('replaced');
			eventBannerCopy.splice(targetIndex, 1, {
				bannerImageUrl,
				bannerTitle,
				bannerUrl,
			})
		}

		props.setEventBanner(eventBannerCopy)
		setBannerImageUrl('')
		setBannerTitle('')
		setBannerUrl('')
		setTargetIndex(-1)
		bannerImageForm.current.value = '';
	}

	const modifyEventBanner = (index) =>{
		console.log('index', index)
		setTargetIndex(index)
		// console.log('targetIndex', targetIndex)
		setBannerImageUrl(props.eventBanner[index].bannerImageUrl)
		setBannerTitle(props.eventBanner[index].bannerTitle)
		setBannerUrl(props.eventBanner[index].bannerUrl)
	};

	const handleSaveData =() => {

		let eventBannerCopy = [...props.eventBanner];
		let eventBannerFiltered = [];
		let notConfirmedData = []
		for(let i=0; eventBannerCopy.length > i;i++){
			if(eventBannerCopy[i].startDate !=="" && eventBannerCopy[i].finishDate !==""
				&& eventBannerCopy[i].bannerTitle !==""){
				eventBannerFiltered.push(eventBannerCopy[i]);
			} else {
				notConfirmedData.push(eventBannerCopy[i])
			}
		}
		if(notConfirmedData.length===0) {
			props.setEventBanner(eventBannerFiltered);
			props.setIsChange(true)
			window.alert('저장했습니다.')
		} else {
			toast.warning('내용을 모두 입력해 주세요.')
		}

	}

	return (
		<li className="active">
			<div className="row">
				<div className="col-lg-6">
					{/*<p>공구이벤트배너를 등록해 주세요.</p>*/}
					<div className="custom-controls mt-4">
						<div className="sbox title">
							<span className="iconBox01"><i className="fa fa-calendar"></i></span> {/*공구이벤트배너*/}이미지형 링크
						</div>

						{
							props.eventBanner.map((item, index)=>{
								return(
									<EventList key={index}
										{...props}
										item={item}
										index={index}
										eventBanner={props.eventBanner}
										setEventBanner={props.setEventBanner}
										setIsChange={props.setIsChange}
										modifyEventBanner={modifyEventBanner}
									/>
								)
							})
						}

						<div className="sbox">
							<div className="form-group">
								<label className="form-label">{/*공구이벤트배너*/}이미지</label>
								<div className="row gutters-xs">
									<div>
										{bannerImageUrl ?
											(
												<div className="preview mb-2" style={{
													width: '95%',
													maxHeight: 300,
													overflow: 'hidden',
													backgroundImage: `url(${bannerImageUrl})`,
													backgroundRepeat: 'no-repeat',
													backgroundSize: 'contain',
													backgroundPosition: '50% 50%',
												}}>
													<img src={bannerImageUrl} alt={bannerTitle} style={{
														width:'auto',
														maxWidth: '100%',
														// height: 'auto',
														objectFit: 'contain',
														visibility: 'hidden',
													}} />
												</div>
											) : ''
										}
										<input
											ref={bannerImageForm}
											name="file-upload-field"
											type="file"
											className="file-upload-field form-control"
											// value={bannerImageUrl || ''}
											onChange={selectFileFunction}
											accept={'image/*'}
										/>
									</div>
								</div>
							</div>

							<div className="form-group">
								<label className="form-label">{/*공구이벤트배너*/}제목</label>
								<div className="row gutters-xs">
									<div className="col">
										<input
											type="text"
											className="form-control"
											name="bannerTitle"
											// defaultValue={''}
											value={bannerTitle || ''}
											placeholder={'제목을 입력해주세요.'}
											onChange ={changeTitle}
										/>
									</div>
								</div>
							</div>

							<div className="form-group">
								<label className="form-label">{/*공구이벤트배너*/}링크 주소(URL)</label>
								<div className="row gutters-xs">
									<div className="col">
										<input
											type="text"
											className="form-control"
											name="bannerUrl"
											ref={urlRef}
											// defaultValue={''}
											value={bannerUrl || ''}
											placeholder={'링크 주소(URL)를 입력해주세요.'}
											onChange ={changeUrl}
										/>
									</div>
									{/*<p className="mt-2 iconGuide">http:// 혹은 https:// 는 편집화면에서는 자동으로 사라집니다.(실제 연결링크에는 그대로 저장됩니다.) </p>*/}
									{/* <span className="col-auto"><button className="btn btn-secondary" type="button">미리보기반영</button></span> */}
								</div>
							</div>
							<button
								className={targetIndex < 0 ? "btn btn btn-dark" : "btn btn btn-warning"}
								type="button"
								onClick={registerEventBanner}
							>
								이벤트 배너 {targetIndex < 0 ? '등록' : '수정'}
							</button>
							{bannerTitle.length > 0 ?
								<button
									className="btn btn btn-dark"
									type="button"
									onClick={clearEvent}
									style={{marginLeft:"5px"}}
								>
									수정 취소
								</button>
								: ''}
						</div>

					</div>
				</div>

				<div className="col-lg-6 text-center">
					<p className="mb-2">적용 부분만 편집한 화면입니다. </p>
					<div className="phone02 drop-shadow hauto w320 min600" style={{
						position: 'sticky',
						top: 10,
						bottom: 10,
					}}>
						<div className="phone_over hauto">
							<div className="PhoneArea hauto">
								{/* <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /></div> */}
								<div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage || imgAvatarNew } alt="" /></div>

								<div className="PhoneTop designCase01">
									<div className="d-flex align-items-center mt-auto">
										{/* <img className="avatar avatar-lg mr-2" src={imgAvatar} alt="" /> */}
										<img className="avatar avatar-lg mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
										<div className="d-block w-100 ">
											<div className="d-flex">
												<small className="d-block">{props.info && props.info?.userName} </small>
												<a href="#" className="icon d-none d-md-inline-block ml-auto"><i className="icon-home"></i></a>
											</div>
											<div className="d-flex mt-2">
												<small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small><small className="d-block ml-2"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
											</div>

										</div>

									</div>
									<div className="ChArea"><img src={char} /></div>
								</div>

								<div className="PhoneTop designCase02 d-none">
									<div className="d-flex align-items-center mt-auto">
										{/* <img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /> */}
										<img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
										<div>
											<small className="d-block">{props.info && props.info?.userName}</small>
										</div>
										<div className="ml-auto">
											<a href="#" className="icon d-none d-md-inline-block ml-3"><i className="icon-home"></i></a>
										</div>
									</div>
									<div className="MainImg mt-2">
										<div className="ChArea"><img src={char} /></div>
										<img src="assets/img/img_landingSample_new.jpg" />
									</div>
									<div className="row mt-2">
										<div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
										<div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
									</div>
								</div>
								<div className="PhoneBody hauto">
									<div className="conArea pb-100px">
										<div className="linkInfo noView mt-3">

											{/* 공구이벤트배너 세팅==================================================== */}
											<div className="mb-2 sectorTit subTit">{/*공구이벤트배너*/}이미지형 링크</div>

											{!!props?.eventBanner && !!props?.eventBanner.length ? (
												props.eventBanner.map((item, idx) => (
													<BannerItem key={idx} {...item}></BannerItem>
												))
											) : ''}
										</div>
									</div>

									<div className="footlogo"><img src={logo} /></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-12 text-right">
					<hr />
					<button
						className="btn btn btn-dark "
						type="button"
						onClick={(e) => {
							e.preventDefault()
							handleSaveData()
						}}
					>저장하기</button>
				</div>

			</div>
		</li>
	);
};

function EventList({item, index, eventBanner, setEventBanner, setIsChange, modifyEventBanner}){

	const removeEventBanner = (index) =>{
		if(window.confirm('삭제하시겠습니까?')) {
			let eventBannerCopy = [...eventBanner];
			eventBannerCopy.splice(index,1);
			setEventBanner(eventBannerCopy);
			setIsChange(true)
		}
	};

	const changeOrder = (direction, index) => {
		if (!direction) return false;
		try {
			const toIndex = direction === 'up' ? index - 1 : index + 1;

			const nList = [...eventBanner];
			const changeItem = nList.splice(index, 1);
			// nList[toIndex] = changeItem;
			nList.splice(toIndex, 0, changeItem[0]);

			setEventBanner(nList);
			setIsChange(true);

		} catch (e) {
			console.error(e);
		}
	}

	return(
		<>
			<div className="sbox" key={index}>
				<div className="d-flex">
					<div className="flex-grow-0">
						<div style={{
							width: 80,
							maxHeight: 250,
							overflowY: 'hidden',
						}}>
							{item.bannerImageUrl ?
								<img src={item.bannerImageUrl} alt={item.bannerTitle} style={{
									width: '100%',
								}} />
								: ''
							}
						</div>
					</div>
					<div className="flex-grow-1 mx-1">
						<div className="row">
							<b>{item.bannerTitle}</b>
						</div>
						<div className="row">
							<small>{item.bannerUrl}</small>
						</div>
					</div>
					<div className="d-flex flex-column justify-content-between">
						<div className="d-flex mb-3 openBox justify-content-end">
							<Button className="mr-1 console-icon-btn" size='sm' color="light" disabled={index === 0}
									onClick={() => {changeOrder('up', index)}}>
								<i className="fe fe-chevron-up"/>
							</Button>
							<Button className="mr-1 console-icon-btn" size='sm' color="light" disabled={index + 1 >= eventBanner.length}
									onClick={() => {changeOrder('down', index)}}>
								<i className="fe fe-chevron-down"/>
							</Button>
						</div>

						<div className="d-flex">
							<Button className="mr-1 console-icon-btn" size='sm' color="light"
									onClick={() => {modifyEventBanner(index)}}>
								<i className="fa fa-edit"/>
							</Button>
							<Button className="mr-1 console-icon-btn" size='sm' color="light"
									onClick={() => {removeEventBanner(index)}}>
								<i className="fa fa-remove"/>
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	)}

export default withRouter(withTranslation()(ShoppingBannerSetting));

ShoppingBannerSetting.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
