import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import HeaderTypeText from './HeaderTypeText';
import HeaderTypeCustom from './HeaderTypeCustom';


import SelectHeaderDesignMenu from './SelectHeaderDesignMenu';
import SelectMenuPageMenu from './SelectMenuPageMenu'
import SelectMenuOrderMenu from './SelectMenuOrderMenu';
import SelectMobileBgColorTextMenu from './SelectMobileBgColorTextMenu';
import SelectMobileBgColorCustomMenu from './SelectMobileBgColorCustomMenu';
import SelectPcBgColorMenu from './SelectPcBgColorMenu';

import PcTypeTextBanner from './PcTypeTextBanner';
import PcTypeCustom from './PcTypeCustom';

const HeaderDesignSetting = (props) => {
	document.title = 'Flowing';

	return (
		<li className="active">
			<div className="row">
                {
                    props.tabId === 2?  //1.상단디자인 메뉴부분
                        <SelectHeaderDesignMenu {...props}  />
                    :props.tabId === 3? //2.메뉴 페이지 디자인 메뉴부분
                    	<SelectMenuPageMenu {...props} />
                    :props.tabId === 4? //3. 메뉴 순서편집 메뉴부분
                        <SelectMenuOrderMenu {...props} />
                    :props.tabId === 5 && (props.headerType ==='text' || props.headerType ==='banner')? //4.모바일 배경설정 텍스트형 또는 배너형
                        <SelectMobileBgColorTextMenu {...props} />
                    :props.tabId === 5 && props.headerType ==='custom'? //4.모바일 배경설정 커스텀형
                        <SelectMobileBgColorCustomMenu {...props} />
                    :props.tabId === 6 ?    //5.pc 배경설정
                        <SelectPcBgColorMenu {...props} />
                    :''
                }   
                {typeSelect(props)}
            </div>
		</li>
	);
};

function typeSelect(props){
    if (props.tabId === 6){ //5.PC배경설정
        return(
            <>
                {props.headerType ==='text' || props.headerType ==='banner'? 
                    <PcTypeTextBanner {...props} />
                :   
                    <PcTypeCustom {...props} />
                    
                }
            </>
        )
    }else{  //1. 모바일 상단디자인
        return(
            <>
                {props.headerType ==='text' || props.headerType ==='banner'? 
                    <HeaderTypeText {...props} />
                :   
                    <HeaderTypeCustom {...props} />
                }
            </>
        )
    }
}

export default withRouter(withTranslation()(HeaderDesignSetting));

HeaderDesignSetting.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
