import { Container } from "reactstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Pagination, FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import _ from "lodash";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { categoryService } from "../../services/category.service";
import { getQna } from "../../services/noticeView.service";
//import auth services
import { authService } from "../../services/auth.service";
import { getUnreadMessagesCount } from "../../services/talk2youMessage.service";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getMyProfile } from "../../services/talk2youRooms.service";
import {
    createProfile,
    updateProfile,
} from "../../services/answerProfile.service";
import smartToy from '../../assets/images/ava01_3.gif'

const Dashboard = (props) => {
    const [catagoriesList, setCatagoriesList] = useState([]);
    const [noticeList, setNoticeList] = useState([]);
    const [keyWords, setKeyWords] = useState([]);
    const [keyWordList, setKeyWordList] = useState([]);
    const [unreadMessage, setUnreadMessage] = useState("");
    const [isProfile, setIsProfile] = useState();
    const [isRetoken, setIsRetoken] = useState("")

    const getCategoryList = async () => {
        const result = await categoryService.getCategories();
        console.log("catagoriesList", result);
        if (result && result.talk_category_list.length > 0) {
            setCatagoriesList(result.talk_category_list);
            await setCategoryId(result.talk_category_list[0].id);
        }
    };

    const getUnreadMessage = async () => {
        const res = await authService.getUserId()
        if(res.status===201) {
            const result = await getUnreadMessagesCount(res.data.user_id);
            // console.log('unreadMessage', result);
            setUnreadMessage(result && result?.unreadCount || 0); // DDB 응답값 용량 문제로 API 변경함
        }
    };

    const getNotice = async () => {
        const result = await getQna();
        result.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0
        );
        // console.log("result_reverse", result);
        setNoticeList(result);
    };

    const setCategoryId = async (id) => {

        const result = await authService.getUserId()
        if(result.status===201) {
            const response = await categoryService.getKeyWordList(
                id,
                // localStorage.getItem("user_id")
                result.data.user_id
            );
            setKeyWords(response.talk_category_keyword_list);
        }
    };

    // const getUserInfo = async () => {
    //     const checkedName = await authService.getUserInfo();
    // };

    const addKeyWords = async (e) => {
        if (keyWordList.length < 10) {
            keyWordList.push(e);

            const body = {
                keywordList: keyWordList,
            };

            if (isProfile) {
                const result = await authService.getUserId()
                if(result.status===201) {
                    await updateProfile(body, result.data.user_id);
                    // await updateProfile(body, localStorage.getItem("user_id"));
                    window.alert("키워드가 등록되었습니다.");
                }
            }

            // } else window.alert('Please enter maximium 10 keywords');
        } else window.alert("키워드를 최대 10개 까지 입력할 수 있습니다.");
    };

    useEffect(() => {
        // getUserInfo().then(r => r);
        getCategoryList().then(r => r);
        getNotice().then(r => r);
        getUnreadMessage().then(r => r);
    }, [isRetoken]);

    useEffect(() => {
        const isAuth = async () => {
            if(!sessionStorage.getItem("access_token")) {
                try {
                    const result = await authService.getACToken()
                    console.log(result)
                    if(result.status===201) {
                        console.log("is Here Right??11")
                        setIsRetoken(()=>{return result.status})
                    } else {
                        return await authService.logout()
                    }
                    console.log("is Here Right??22")
                } catch(err) {
                    console.log(err)
                    window.location.href = "/login"
                }
            }
            // else {
            //     // return await authService.logout()
            // }
        }

        const getProfile = async () => {
            const result = await authService.getUserId()
            if(result.status===201) {
                let response = await getMyProfile(result.data.user_id);
                // let response = await getMyProfile(localStorage.getItem("user_id"));
                // console.log(response, 'WITRTER');
                if (response && response?.itemList[0]) {
                    let data = response.itemList[0];
                    console.log(data.keywordList);
                    setIsProfile(data.introduction || false);
                    setKeyWordList(data.keywordList);
                }
            }
        }
        isAuth().then(r=>r)
        getProfile().then(r => r);
    }, [isRetoken]);

    //meta title
    return (
        <React.Fragment>
            <>
                <Container fluid>
                    <div className="section-body">
                        <div className="container-fluid">
                            {/* <div className="row"> */}
                            {/* <div className="col-xl-12 col-md-12 col-sm-12 mainFlex"> */}
                            <Breadcrumbs
                                breadcrumbItem={props.t(
                                    "Flowing answer management service"
                                )}
                            />
                            <div className="mainFlex">
                                <div className="dashboard700">
                                    <div className="quickMenuArea">
                                        <div className="row">
                                            <div className="col-6 col-md-6 col-xl-6">
                                                <div className="card height100">
                                                    <div className="card-body ribbon">
                                                        <Link
                                                            to="/answer/ProfileManagement"
                                                            className="my_sort_cut text-muted"
                                                        >
                                                            <i className="icon-graduation"></i>
                                                            <span>
                                                                {props.t(
                                                                    "Register/manage profile"
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-xl-6">
                                                <div className="card height100">
                                                    <div className="card-body">
                                                        <Link
                                                            to="/answer/chatroom"
                                                            className="my_sort_cut text-muted"
                                                        >
                                                            {/*{unreadMessage ? (
                                                                <span className="bell">
                                                                    {
                                                                        unreadMessage
                                                                    }
                                                                </span>
                                                            ) : null
                                                            }*/}
                                                            <span className="bell">{unreadMessage}</span>
                                                            <i className="icon-bubbles"></i>
                                                            <span>
                                                                {props.t(
                                                                    "Manage answering chat"
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-xl-6">
                                                <div className="card height100">
                                                    <div className="card-body ribbon">
                                                        <Link
                                                            to="/settings/ChatbotSettings"
                                                            className="my_sort_cut text-muted"
                                                        >
                                                            <i className="icon-ghost"></i>
                                                            <span>
                                                                {props.t(
                                                                    "Chat bot setting"
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-xl-6">
                                                <div className="card height100">
                                                    <div className="card-body ribbon">
                                                        <Link
                                                            to="settings/landing-page"
                                                            className="my_sort_cut text-muted"
                                                        >
                                                            <i className="icon-screen-desktop"></i>
                                                            <span>
                                                                {props.t(
                                                                    "Mange profile landing page"
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-xl-6">
                                                <div className="card height100">
                                                    <div className="card-body">
                                                        <a
                                                            href="/qna/QnaView"
                                                            className="my_sort_cut text-muted"
                                                        >
                                                            <i className="icon-question"></i>
                                                            <span>
                                                                {props.t(
                                                                    "Inquiry"
                                                                )}
                                                            </span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-6 col-xl-6">
                                                <div className="card height100">
                                                    <div className="card-body">
                                                        <Link
                                                            to="/settlementManagement/CalculateList"
                                                            className="my_sort_cut text-muted"
                                                        >
                                                            <i className="icon-doc"></i>
                                                            <span>
                                                                {props.t(
                                                                    "Settlement report"
                                                                )}
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="graphArea">
                                        {/* <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    {props.t(
                                                        "My billing history"
                                                    )}
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <span>{props.t("Price")}</span>
                                                <h4>
                                                    <span className="counter">
                                                        20,508
                                                    </span>
                                                    {props.t("Won")}
                                                </h4>
                                                <div
                                                    id="apexspark1"
                                                    className="mb-4"
                                                ></div>
                                                <div className="form-group">
                                                    <label className="d-block">
                                                        {props.t("Sell")}{" "}
                                                        <span className="float-right">
                                                            <span className="counter">
                                                                15,025
                                                            </span>
                                                            {props.t("Won")}
                                                        </span>
                                                    </label>
                                                    <div className="progress progress-xs">
                                                        <div
                                                            className="progress-bar bg-azure"
                                                            role="progressbar"
                                                            aria-valuenow="77"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                                width: "50%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="d-block">
                                                        {props.t("Use")}{" "}
                                                        <span className="float-right">
                                                            <span className="counter">
                                                                1,843
                                                            </span>
                                                            {props.t("Won")}
                                                        </span>
                                                    </label>
                                                    <div className="progress progress-xs">
                                                        <div
                                                            className="progress-bar bg-green"
                                                            role="progressbar"
                                                            aria-valuenow="50"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                                width: "50%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="d-block">
                                                        {props.t("Cancel")}{" "}
                                                        <span className="float-right">
                                                            <span className="counter">
                                                                3,641
                                                            </span>
                                                            {props.t("Won")}
                                                        </span>
                                                    </label>
                                                    <div className="progress progress-xs">
                                                        <div
                                                            className="progress-bar bg-blue"
                                                            role="progressbar"
                                                            aria-valuenow="23"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{
                                                                width: "23%",
                                                            }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <button className="btn btn-block btn-info btn-sm">
                                                    {props.t("View more")}
                                                </button>
                                            </div>
                                        </div> */}
                                        <div className="solution-main">
                                            <div className="card">
                                                <div className="card-header">
                                                    <img src={smartToy} className="tit-imo" alt="gif 이미지"/>
                                                    <h3 className="card-title"> 효과적인 답변톡 설정 방법 </h3>
                                                    {/* <h3 className="card-title"><img src="./ava01_3.gif" className="tit-imo"/> 효과적인 답변톡 설정 방법 </h3> */}
                                                </div>
                                                <div className="card-body text-center">
                                                    <div className="row">
                                                        <div className="col-lg-6 p-4">
                                                            <button onClick={()=>{props.history.push('/answer/WriteProfile')}}><i className="fe fe-user" /> 답변프로필 작성</button>
                                                        </div>
                                                        <div className="col-lg-6 p-4">
                                                            <button onClick={()=>{props.history.push('/settings/landing-page')}}><i className="fe fe-paperclip" /> 답변톡 링크</button>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2 mb-5">
                                                        <div className="col-lg-6 p-4">
                                                            <button onClick={()=>{props.history.push('/settings/ChatbotSettings')}}><i className="material-symbols-outlined">smart_toy</i> 챗봇 만들기</button>
                                                        </div>
                                                        <div className="col-lg-6 p-4">
                                                            <button onClick={()=>{props.history.push('/answer/ParticipantManagement')}}><i className="fe fe-message-circle" data-toggle="tooltip" /> 답변톡</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="width700">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                {props.t(
                                                    "Recommended keywords"
                                                )}
                                            </h3>
                                        </div>
                                        <div className="card-body pt-0">
                                            <Swiper
                                                slidesPerView={4}
                                                navigation={true}
                                                modules={[
                                                    Pagination,
                                                    FreeMode,
                                                    Navigation,
                                                ]}
                                                breakpoints={{
                                                    768: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 10,
                                                    },
                                                    1024: {
                                                        slidesPerView: 3,
                                                        spaceBetween: 10,
                                                    },
                                                    1600: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 10,
                                                    },
                                                }}
                                                className="mainSwiper text-center"
                                            >
                                                {catagoriesList.map(
                                                    (item, index) => {
                                                        return (
                                                            <SwiperSlide
                                                                key={index}
                                                            >
                                                                <div
                                                                    className="carouselItemArea"
                                                                    key={index}
                                                                >
                                                                    <img
                                                                        alt=""
                                                                        src={
                                                                            item.image_url
                                                                        }
                                                                        onClick={() =>
                                                                            setCategoryId(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    />
                                                                    <span
                                                                        className="carousel-caption"
                                                                        onClick={() =>
                                                                            setCategoryId(
                                                                                item.id
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </SwiperSlide>
                                                        );
                                                    }
                                                )}
                                            </Swiper>
                                            <div className="table-responsive ">
                                                <table className="table mt-3 mb-0 text-nowrap">
                                                    <tbody>
                                                        {keyWords &&
                                                            keyWords.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    return (
                                                                        <tr
                                                                            className="justify-content-center align-self-center"
                                                                            key={
                                                                                index
                                                                            }
                                                                        >
                                                                            <td>{`#${item.name}`}</td>
                                                                            <td className="text-right">
                                                                                <button
                                                                                    onClick={() => {
                                                                                        addKeyWords(
                                                                                            item.name
                                                                                        );
                                                                                    }}
                                                                                    className="btn btn-outline-secondary"
                                                                                >
                                                                                    {props.t(
                                                                                        "Register keywords"
                                                                                    )}
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }
                                                            )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                {props.t("Notice")}
                                            </h3>
                                        </div>
                                        {noticeList.map((item, index) => {
                                            /*return noticeList.length - 1 === index ? (*/
                                            return index === 0 ? (
                                                <div
                                                    className="card-body pt-0"
                                                    key={index}
                                                >
                                                    <b className="mr-1">
                                                        {item.title}
                                                    </b>
                                                    <span>
                                                        {moment(
                                                            item.created_at
                                                        ).format("YYYY-MM-DD")}
                                                    </span>
                                                    <p className="mainNoticeArea">
                                                        {item.content}
                                                    </p>
                                                </div>
                                            ) : (
                                                ""
                                            );
                                        })}

                                        <div className="card-footer">
                                            <button
                                                onClick={() => {
                                                    props.history.push(
                                                        "/qna/NoticeView"
                                                    );
                                                }}
                                                className="btn btn-block btn-info btn-sm"
                                            >
                                                {props.t("View more")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </>
        </React.Fragment>
    );
};
Dashboard.propTypes = {
    t: PropTypes.any,
};

export default withRouter(withTranslation()(Dashboard));
