import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {
	Card,
	CardBody,
	Row,
	Col,
	Input,
	Button,
	InputGroup,
} from 'reactstrap';
//question API's
import {categoryService} from '../../services/category.service';
import SuggestedQuestion from './SuggestedQuestion';

//searching questions with key word
const SearchQuestion = (props) => {
	const [roomList, setRoomList] = useState([]);
	const limit = 5
	const [searchInput, setSearchInput] = useState('');

	const [currentPage, setCurrentPage] = useState(1)
	const [totalCount, setToTalCount] = useState(0)
	const pageSize = 5
	let nOffset = (currentPage - 1) * limit
	let lastPage = Math.ceil(totalCount / limit)
	let currentPageUnit = Math.ceil(currentPage / pageSize)


	const getQuestionRooms = async () => {
		// console.warn('::::::::::::::::::::::::::::::')
		const result = await categoryService.getQuestionList(
			searchInput,
			nOffset,
			limit,
		);

		setRoomList(result?.itemList || []);
		if (result?.totalHitCount) {
			setToTalCount(()=> result.totalHitCount)
		}
	};
	useEffect(() => {
		getQuestionRooms().then(r => r);
	}, [currentPage]);

	document.title = 'Flowing';
	return (
		<React.Fragment>
			<div className="row">
				<div className="col-12">
					<div className="page-title-box d-sm-flex align-items-center justify-content-between">
						<h4 className="mb-0 font-size-18">공개톡 질문검색</h4>
					</div>
				</div>
					</div>
			<Row>
				<Col lg={12}>
					<Card>
						<CardBody>
							<InputGroup>
								<Input
									className="form-control"
									placeholder={props.t('Please enter your search term.')}
									onChange={(e) => setSearchInput(e.target.value)}
									value={searchInput}
									id="searchInput"
								></Input>
								
								<Button
									className="btn_search_close"
									type="button"
									onClick={()=>setSearchInput("")}
								>
									<i className="icon-close"></i>
								</Button>
								
								<span className="input-group-append">
								<Button
									className="btn btn-primary"
									type="button"
									onClick={getQuestionRooms}
								>
									<i className="icon-magnifier"></i>
								</Button>
								</span>
							</InputGroup>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<SuggestedQuestion
				limit={limit}
				roomList={roomList}

				nCurrentPage={currentPage}
				setCurrentPage={setCurrentPage}
				pageSize={pageSize}
				nOffset={nOffset}
				totalCount={totalCount}
				lastPage={lastPage}
				currentPageUnit={currentPageUnit}
			/>
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(SearchQuestion));

SearchQuestion.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
