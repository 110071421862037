import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import flowingLogo from '../../../assets/images/flowing_logo.jpg';
import logo from '../../../assets/images/logo_landingFoot.png';
import logoW from '../../../assets/images/logo_landingFoot_w.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg3 from '../../../assets/images/img_landingSample3.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import pcBg01 from '../../../assets/images/pcBg01.png';
import pcBg02 from '../../../assets/images/pcBg02.png';
import pcBg03 from '../../../assets/images/pcBg03.png';
import pcBg04 from '../../../assets/images/pcBg04.png';
import pcBg05 from '../../../assets/images/pcBg05.png';
import pcBg06 from '../../../assets/images/pcBg06.png';
import pcBg07 from '../../../assets/images/pcBg07.png';
import pcBg08 from '../../../assets/images/pcBg08.png';
import imgGift from '../../../assets/images/img_gift.png';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';
import imgCoupon from '../../../assets/images/img_coupon.png'
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';
import {daysInKr} from '../../../constants/options';

const PcBackgroundSetting = (props) => {
    const days = props.info && props.info?.activeTime?.days || [];
    const newDays = days.map(item => daysInKr[item])
    const keywordList = props.info && props.info?.keywordList || [];
    const regionList = props.info && props.info?.regionList || [];
    const outLinkList = props.info && props.info?.outLink || [];
    const videoFeedList = props.info && props.info?.videoFeed || [];

	return (

		<li className="active">
			<div className="row">

                <div className="col-lg-12">

                    <div className="premiummodal phone02 drop-shadow hauto min600 w-100 mt-2">
                        <div className="phone_over hauto">
                            <div className="PhoneArea hauto">
                                <div className="TackBtnArea" style={{right: "20px"}}>
                                    <span>톡하기</span>
                                    <div className="imgboard">
                                        {/* <img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                    </div>
                                </div>

                                <div className="topArea">
                                    <div className="titArea">
                                        <a href="#"><img src={flowingLogo} /></a> 
                                        <span className="namebar"></span> {props.info && props.info?.userName}
                                    </div>    
                                        {/* <a href="#" className="homeBtn"><i className="icon-home"></i></a> */}
                                    
                                    <div className="topImgBg pcmode">
                                        <span><img src={landingSampleImg} /> </span>
                                        <div className="gridBg"></div>
                                    </div>             
                                </div>  
                                
                            <div className="PhoneBody hauto"> 
                                <div className="socialArea">
                                    <div className="row">
                                        <div className="col-12 text-left pb-2"><p>{props.info && props.info?.introduction || ''}</p></div>
                                        <div className="col-6 text-left">
                                            <div className="d-flex">
                                                    {/* <span className="slideleft"><i className="fe fe-chevron-left"></i></span> */}
                                                    <div className="iconsocial">
                                                        <i className="icon-social-youtube"></i>
                                                    </div>
                                                    <div className="iconsocial">
                                                        <i className="fe fe-instagram"></i> 
                                                    </div>                                                
                                                    <div className="iconsocial">
                                                        <i className="fe fe-facebook"></i>
                                                    </div>
                                                    <div className="iconsocial">
                                                        <i className="fe fe-twitter"></i>
                                                    </div>
                                                {/* <span className="slideright"><i className="fe fe-chevron-right"></i></span> */}
                                            </div>
                                        </div>    
                                        <div className="col-6 text-right">
                                            <p className="d-md-inline-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</p>
                                            <p className="d-md-inline-block ml-5"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</p>
                                        </div>
                                    </div>
                                </div>  
                                {/* <!-- 모바일화면 탭버튼 --> */}
                                <div className="tabArea pagetab mt-4">
                                        <span 
                                            className="viewTabb04  tag  active"
                                            onClick={()=>{props.setTabArea(0)}}
                                        >전체보기
                                        </span>
                                        <span 
                                            className="viewTabb04  tag  ml-2"
                                            onClick={()=>{props.setTabArea(1)}}
                                        >답변톡</span>
                                        <span 
                                            className="viewTabb05 tag  ml-2"
                                            onClick={()=>{props.setTabArea(2)}}
                                        >외부링크</span>
                                        <span 
                                            className="viewTabb06 tag  ml-2"
                                            onClick={()=>{props.setTabArea(3)}}
                                        >상품</span>
                                    </div>
                                    {/* <!-- 모바일 화면 탭버튼--> */}
                                    {props.tabArea === 0?(
                                        <div className="conArea pb-100px">
                                            
                                            <div  className="txtArea2 mt-2 noView partOn">
                                                <div className="mb-2 sectorTit d-none">답변톡</div>
                                                <div className="d-block w-100 ">
                                                    <div className="d-block"><i className="fe fe-clock"></i> <b>12답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                                                </div>

                                                <div className="d-block w-100 ">
                                                    <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                                                </div>
                                                {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                                                    <div className="d-block w-100 mt-2">
                                                        <div className="d-block">공휴일에는 답변이 불가능합니다.
                                                            답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                                            수 있습니다. 하지만 답변자의 답변은 운영시간을
                                                            기다려주세요. 감사합니다.
                                                        </div>
                                                    </div>
                                                }
                                                <div className="mb-2 mt-4 sectorTit subTit">답변키워드</div>
                                                <div className="answerInfo">
                                                    <div className=" align-items-center mt-auto">
                                                        <div className="d-block w-100 ">
                                                            {/*<span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>*/}
                                                            {keywordList.map((item, index) => (
                                                                <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                                                            ))}
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                                <div className="mb-2 mt-4 sectorTit subTit">답변지역</div>
                                                <div className="answerInfo">
                                                    <div className=" align-items-center mt-auto">
                                                        <div className="d-block w-100 ">
                                                            {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                                            {regionList.map((item, index) => (
                                                                <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ):props.tabArea === 1?(
                                    <div className="conArea pb-100px">
                                        <div className="giftInfo mt-3 noView partOn">
                                            {/*<div className="mb-2 sectorTit d-none">외부링크</div>*/}
                                            {outLinkList.map((item, index) => (
                                                item.iconLink ?
                                                <div className="adressArea position-relative" key={index}>
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                                        <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                                                        <div className="arrowBtn">
                                                            <i className="fe fe-chevron-right"></i>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            ))}
                                            {/*<div className="adressArea position-relative">
                                                <div className="d-flex align-items-center mt-auto">
                                                    <img className="avatar avatar-md mr-2" src={instar} alt="" />
                                                    <div className="d-block">Instar.com</div>
                                                        <div className="arrowBtn">
                                                        <i className="fe fe-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className="mt-4 sectorTit subTit">피드</div>*/}
                                            <div className="row align-items-center mt-2">
                                                {videoFeedList.map((item, index) => (
                                                    <div className="col-3" key={index}>
                                                        <div className="listImg" style={{height: "180px"}}>
                                                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                                                        </div>
                                                        <div className="listTxt mt-2">
                                                            {item.videoTitle}
                                                        </div>
                                                        <div className="listday">
                                                            <div>{item.ownerChannelName}</div>
                                                            <div className="bar"></div>
                                                            <div>{item.publishDate}</div>
                                                        </div>
                                                    </div>
                                                ))}
                                                {/*<div className="col-3">
                                                    <div className="listImg" style={{height: "180px"}}>
                                                    <img src={landingSampleImg} />
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="listImg" style={{height: "180px"}}>
                                                    <img src={landingSampleImg} />
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="listImg" style={{height: "180px"}}>
                                                    <img src={landingSampleImg} />
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>*/}
                                                {/*<div className="col-12 text-center">
                                                    <a href="#" className="moreBtn">더보기</a>
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>/* 외부링크 end */
                                    ):(    
                                    // <div className="conArea pb-100px">
                                    //     <div className="giftInfo mt-3 noView partOn">
                                    //         <div className="mb-2 sectorTit d-none">상품</div>
                                    //         <div className="align-items-center mt-auto">
                                    //             <div className="d-block w-100 ">
                                    //                 <div className="giftTit"><img src={imgGift} alt="" /> 플로잉 답변톡 상품</div>
                                    //                 <p>
                                    //                     플로잉 답변톡 상품은 답변방에서 글자 전송 제한을 500글자 추가시켜주는 상품입니다.
                                    //                 </p>
                                    //             </div>
                                    //         </div>
                                    //         <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                    //             <div><img src={diamond} alt="" /> 100</div>
                                    //             <div><img src={ruby} alt="" /> 100</div>
                                    //             <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                                    //         </div>
                                    //     </div>
                                    // </div>/* 상품 end */

                                    <div className="conArea pb-100px">
                                        <div  className="txtArea2 mt-2 noView partOn">
                                            <div className="mb-2 sectorTit d-none">답변톡</div>
                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-clock"></i> <b>13답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                                            </div>

                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                                            </div>
                                            {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                                                <div className="d-block w-100 mt-2">
                                                    <div className="d-block">공휴일에는 답변이 불가능합니다.
                                                        답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                                        수 있습니다. 하지만 답변자의 답변은 운영시간을
                                                        기다려주세요. 감사합니다.
                                                    </div>
                                                </div>
                                            }
                                            <div className="mb-2 mt-4 sectorTit subTit">답변키워드</div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>*/}
                                                        {keywordList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                                                        ))}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="mb-2 mt-4 sectorTit subTit">답변지역</div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                                        {regionList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="giftInfo mt-3 noView partOn">
                                            {/*<div className="mb-2 sectorTit d-none">외부링크</div>*/}
                                            {outLinkList.map((item, index) => (
                                                item.iconLink ?
                                                <div className="adressArea position-relative" key={index}>
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                                        <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                                                        <div className="arrowBtn">
                                                            <i className="fe fe-chevron-right"></i>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            ))}
                                            {/*<div className="adressArea position-relative">
                                                <div className="d-flex align-items-center mt-auto">
                                                    <img className="avatar avatar-md mr-2" src={instar} alt="" />
                                                    <div className="d-block">Instar.com</div>
                                                        <div className="arrowBtn">
                                                        <i className="fe fe-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className="mt-4 sectorTit subTit">피드</div>*/}
                                            <div className="row align-items-center mt-2">
                                            {videoFeedList.map((item, index) => (
                                                <div className="col-3" key={index}>
                                                    <div className="listImg" style={{height: "180px"}}>
                                                        <img src={item.videoThumbnail} alt={item.videoTitle}/>
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        {item.videoTitle}
                                                    </div>
                                                    <div className="listday">
                                                        <div>{item.ownerChannelName}</div>
                                                        <div className="bar"></div>
                                                        <div>{item.publishDate}</div>
                                                    </div>
                                                </div>
                                            ))}
                                                {/*<div className="col-3">
                                                    <div className="listImg" style={{height: "180px"}}>
                                                    <img src={landingSampleImg} />
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="listImg" style={{height: "180px"}}>
                                                    <img src={landingSampleImg} />
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="listImg" style={{height: "180px"}}>
                                                    <img src={landingSampleImg} />
                                                    </div>
                                                
                                                    <div className="listTxt mt-2">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>*/}
                                                {/*<div className="col-12 text-center">
                                                    <a href="#" className="moreBtn">더보기</a>
                                                </div>*/}
                                            </div>
                                        </div>
                                        <div className="giftInfo mt-3 noView partOn">
                                          <div className="mb-2 sectorTit d-none">상품</div>
                                           <div className="align-items-center mt-auto">
                                               <div className="d-block w-100 ">
                                                   <div className="giftTit"><img src={imgGift} alt="" /> 플로잉 답변톡 상품12</div>
                                                   <p>
                                                       {props.info && props.info?.goodsUserProduct?.description || ''}
                                                   </p>
                                               </div>
                                           </div>
                                           <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                               <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                                               <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                                               <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                                           </div>
                                       </div>
                                    </div>/* 전체보기 */
                                    )}

                                    <div className="footlogo"><img src={logoW} /></div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div className="col-lg-12 text-right">
                    <hr />
                    <button className="btn btn btn-dark " type="button">저장하기</button>
                </div>
            </div>
             {/* 커스텀 모바일 배경 설정 */}
            <div className="row">
                <div className="col-lg-12">
                <p className="stepTit">
                    <span>1. 구분형 </span><span className="stepArrow">&gt;</span> 
                    <span>2. 노출순서 </span><span className="stepArrow">&gt;</span>  
                    <span>3. 텍스트형 </span><span className="stepArrow">&gt;</span>  
                    4.모바일 배경을 설정해 주세요.
                </p>
                </div>
                <div className="col-lg-6">
                    <div className="colorArea mt-3 d-none" style={{maxWidth : "600px"}}>
                        <div className="row m-0 justify-content-left">
                            <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                                <label className="imagecheck mr-2 mb-2">
                                    <input name="imagecheck" type="radio" value="1" className="imagecheck-input" checked="" readOnly />
                                    <figure className="imagecheck-figure">
                                        <div className="imagecheck-image CheckColor01"></div>
                                    </figure>
                                </label>
                                {/* <div className="textColor"> 화이트</div>  */}
                            </div>

                            <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                                <label className="imagecheck mr-2 mb-2">
                                    <input name="imagecheck" type="radio" value="1" className="imagecheck-input" />
                                    <figure className="imagecheck-figure">
                                        <div className="imagecheck-image CheckColor02"></div>
                                    </figure>
                                </label>
                                {/* <div className="textColor"> 그린</div> */}
                            </div>
                        
                        
                            <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                                <label className="imagecheck mr-2 mb-2">
                                    <input name="imagecheck" type="radio" value="1" className="imagecheck-input" />
                                    <figure className="imagecheck-figure">
                                        <div className="imagecheck-image CheckColor03"></div>
                                    </figure>
                                </label>
                                {/* <div className="textColor"> 블루</div> */}
                            </div>
                        
                        
                            <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                                <label className="imagecheck mr-2 mb-2">
                                    <input name="imagecheck" type="radio" value="1" className="imagecheck-input" />
                                    <figure className="imagecheck-figure">
                                        <div className="imagecheck-image CheckColor04"></div>
                                    </figure>
                                </label>
                                {/* <div className="textColor"> 보라</div> */}
                            </div>
                        
                        
                            <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                                <label className="imagecheck mr-2 mb-2">
                                    <input name="imagecheck" type="radio" value="1" className="imagecheck-input" />
                                    <figure className="imagecheck-figure">
                                        <div className="imagecheck-image CheckColor05"></div>
                                    </figure>
                                </label>
                                {/* <div className="textColor"> 핑크</div> */}
                            </div>
                        
                            <div className="col-auto col-xl-2 d-flex justify-content-center p-0">
                                <label className="imagecheck mr-2 mb-2">
                                    <input name="imagecheck" type="radio" value="1" className="imagecheck-input" />
                                    <figure className="imagecheck-figure">
                                        <div className="imagecheck-image CheckColor06"></div>
                                    </figure>
                                </label>
                                {/* <div className="textColor"> 그래이</div> */}
                            </div>

                        </div>
                    </div>
                    <div id="bgcolorArea" className="">

                        <div className="custom-controls d-flex">
                            <div className="sbox text-left col mr-1">
                                <label className="custom-control custom-radio custom-control-inline m-0">
                                    <input id="checkbg01" type="radio" className="custom-control-input" name="bgcolor" value="option1" checked="" readOnly />
                                    <span className="custom-control-label">검은색 배경 </span>
                                </label>

                                
                            </div>
                            <div className="sbox text-left col ml-1">
                                <label className="custom-control custom-radio custom-control-inline m-0">
                                    <input id="checkbg02" type="radio" className="custom-control-input" name="bgcolor" value="option2" />
                                    <span className="custom-control-label">흰색 배경 </span>
                                </label>
                                
                            </div> 
                        </div>

                    </div>
                    <div className="selectImg">
                        <div className="form-group mt-3">
                            <label className="form-label">이미지 찾기</label>
                            <div className="row gutters-xs">
                                <div className="col">
                                    <input type="text" className="form-control" placeholder="업로드할 이미지를 찾아주세요." />
                                </div>
                                <span className="col-auto"><button className="btn btn-secondary" type="button">찾기</button></span>
                            </div>
                            <p className="mt-2 imgGuidetxt">640px * 800px의 최적화된 이미지를 올려주셔야 합니다.</p>
                        </div>
                    </div>

                    
                </div>

                
                <div className="col-lg-6 ">
                    
                    <div id="basicmodal02" className="phone02 drop-shadow hauto w320 min600 d-none">
                        <div className="phone_over hauto">
                            <div className="PhoneArea hauto">
                                <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" /></div>
                                    
                                <div className="PhoneTop designCase01">
                                    <div className="d-flex align-items-center mt-auto">
                                        <img className="avatar avatar-lg mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div className="d-block w-100 ">
                                            <div className="d-flex">
                                            <small className="d-block">{props.info && props.info?.userName} </small>
                                                <a href="#" className="icon d-none d-md-inline-block ml-auto"><i className="icon-home"></i></a>
                                            </div>
                                            <div className="d-flex mt-2">
                                                <small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small><small className="d-block ml-2"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="ChArea"><img src={char} /></div>  
                                </div>

                                <div className="PhoneTop designCase02 d-none">
                                    <div className="d-flex align-items-center mt-auto">
                                        {/* <img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /> */}
                                        <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage ||imgAvatarNew } alt="" />
                                        <div>
                                            <small className="d-block">{props.info && props.info?.userName}</small>
                                        </div>
                                        <div className="ml-auto">
                                            <a href="#" className="icon d-none d-md-inline-block ml-3"><i className="icon-home"></i></a>
                                        </div>
                                    </div>
                                    <div className="MainImg mt-2">
                                        <div className="ChArea"><img src={char} /></div>
                                        <img src={landingSampleImg} />
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                                    </div>
                                </div>

                                <div className="PhoneBody hauto">

                                    {/* 모바일화면 탭버튼 */}
                                    <div className="tabArea pagetab">
                                        <span className="viewTab01 tag active">답변톡</span>
                                        <span className="viewTab02 tag  ml-2">외부링크</span>
                                        <span className="viewTab03 tag  ml-2">충전</span>
                                    </div>
                                    {/* 모바일 화면 탭버튼 */}
                                    
                                    <div className="conArea pb-100px">
                                        
                                        <div className="txtArea2 mt-2 noView partOn">
                                            <div className="mb-2 sectorTit d-none">탑변톡</div>
                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-clock"></i> <b>14답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                                            </div>
        
                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                                            </div>
                                            {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                                                <div className="d-block w-100 mt-2">
                                                    <div className="d-block">공휴일에는 답변이 불가능합니다.
                                                        답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                                        수 있습니다. 하지만 답변자의 답변은 운영시간을
                                                        기다려주세요. 감사합니다.
                                                    </div>
                                                </div>
                                            }
                                        </div>
            

                                        <div className="txtArea2 mt-2 noView partOn">
                                            {props.info && props.info?.introduction || ''}
                                        </div>
                                        <div className="mBox boxbig noView partOn">
                                            <div className="titname d-block mb-2 mt-3"><b>답변키워드</b></div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>*/}
                                                        {keywordList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>#{item}</span>
                                                        ))}
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className="titname d-block mb-2 mt-3"><b>답변지역</b></div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                                        {regionList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="linkInfo noView mt-3">
                                            {/*<div className="mb-2 sectorTit d-none">외부링크</div>*/}
                                            {outLinkList.map((item, index) => (
                                                item.iconLink ?
                                                <div className="adressArea noView" key={index}>
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                                        <div>
                                                            <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            ))}
                                            {/*<div className="adressArea noView">
                                                <div className="d-flex align-items-center mt-auto">
                                                    <img className="avatar avatar-md mr-2" src={instar} alt="" />
                                                    <div>
                                                        <div className="d-block">instar.com</div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className=" mt-4 sectorTit subTit">피드</div>*/}
                                            {videoFeedList.map((item, index) => (
                                                <div className="row align-items-center mt-2" key={index}>
                                                    <div className="col-5 text-center">
                                                        <div className="listImg">
                                                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-7 text-left">
                                                        <div className="listTxt">
                                                            {item.videoTitle}
                                                        </div>
                                                        <div className="listday">
                                                            <div>{item.ownerChannelName}</div>
                                                            <div className="bar"></div>
                                                            <div>{item.publishDate}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/*<div className="row align-items-center mt-2">
                                                <div className="col-5 text-center">
                                                    <div className="listImg">
                                                        <img src={landingSampleImg} />
                                                    </div>
                                                </div>
                                                <div className="col-7 text-left">
                                                    <div className="listTxt">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className="row">
                                                <div className="col-12 text-center">
                                                    <a href="#" className="moreBtnSm">더보기</a>
                                                </div>
                                            </div>*/}
                                        </div>
                                        
                                        
                                        
                                        <div className="giftInfo noView mt-3">
                                            <div className="mb-2 sectorTit d-none">충전</div>
                                            <div className="align-items-center mt-auto">
                                                <div className="d-block w-100 ">
                                                    <div className="giftTit"><img src={imgGift} alt="" />  충전</div>
                                                    
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-12 mb-4">
                                                    <div className="conponimg">
                                                        <img src={imgCoupon} />
                                                        
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-md-12 text-left mb-4">
                                                    <p>답변톡 이용권 : 답변방에서 글자 전송 500글자 추가시켜주는 이용권입니다.</p>
                                                    <p>구매 방법 : 충전한 다이아/루비로 구매 가능합니다</p>
                                                    <p>구매 장소 : 답변방안에서 구매 가능합니다.</p>
                                                </div>
                                                
                                            </div>
                                            <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                                <div><img src={diamond} alt="" /> 100</div>
                                                <div><img src={ruby} alt="" /> 100</div>
                                                <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                                            </div>
                                        </div>
                                        <div className="footlogo"><img src={logo} /></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="premiummodal phone02 drop-shadow hauto w320 min600 darkBg">
                        <div className="phone_over hauto">
                            <div className="PhoneArea hauto">
                                <div className="TackBtnArea"><span>톡하기</span><div className="imgboard"><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || imgAvatarNew} alt="" /></div></div>

                                <div className="topArea">
                                <div className="titArea"><a href="#"><img src={flowingLogo} /></a> <span className="namebar"></span> {props.info && props.info?.userName}</div>

                                    <div className="topImgBg">
                                        <img src={landingSampleImg} />
                                        <div className="gridBg"></div>
                                    </div>

                                    <div className="row infoTopTxt">
                                        <div className="col-12 text-center pt-5 pb-2"><p className="maxtxt">{props.info && props.info?.introduction || ''}</p></div>
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                        <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                                    </div>
                                </div>    
                                <div className="PhoneBody hauto"> 
                                    <div className="socialArea">
                                        {/* <span className="slideleft"><i className="fe fe-chevron-left"></i></span> */}
                                        <div className="iconsocial">
                                            <i className="icon-social-youtube"></i>
                                        </div>
                                        <div className="iconsocial">
                                            <i className="fe fe-instagram"></i> 
                                        </div>                                                
                                        <div className="iconsocial">
                                            <i className="fe fe-facebook"></i>
                                        </div>
                                        <div className="iconsocial">
                                            <i className="fe fe-twitter"></i>
                                        </div>
                                        {/* <span className="slideright"><i className="fe fe-chevron-right"></i></span> */}
                                    </div>
                                    
                                    <div className="tabArea pagetab mt-4">
                                        <span className="viewTab01 tag active">답변톡</span>
                                        <span className="viewTab02 tag ml-2">외부링크</span>
                                        <span className="viewTab03 tag ml-2">충전</span>
                                    </div>
                                    <div className="conArea pb-100px mt-4">
                                        <div className="txtArea2 noView partOn">
                                            <div className="d-block w-100">
                                                <div className="d-block"><i className="fe fe-clock"></i> <b>15답변가능시간 :</b> {props.info && props.info?.activeTime?.from || ''} ~ {props.info && props.info?.activeTime?.to || ''} </div>
                                            </div>
                                            <div className="d-block w-100 ">
                                                <div className="d-block"><i className="fe fe-calendar"></i> <b>답변가능요일 :</b> {newDays.join(', ') || ''}</div>
                                            </div>
                                            {props.info && props.info?.activeTime?.exceptHoliday === 'on' &&
                                                <div className="d-block w-100 mt-2">
                                                    <div className="d-block">공휴일에는 답변이 불가능합니다.
                                                        답변시간이 아니어도 톡에 입장하셔서 톡 전송을 하실
                                                        수 있습니다. 하지만 답변자의 답변은 운영시간을
                                                        기다려주세요. 감사합니다.
                                                    </div>
                                                </div>
                                            }
                                            <div className="mb-2 mt-4 sectorTit subTit">답변 키워드</div>
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        <span className="tag tag-rounded mr-1">#영어</span><span className="tag tag-rounded mr-1">#유학원</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mb-2 mt-4 sectorTit subTit">답변 지역</div>
                                            
                                            <div className="answerInfo">
                                                <div className=" align-items-center mt-auto">
                                                    <div className="d-block w-100 ">
                                                        {/*<span className="tag tag-rounded mr-1">길동</span><span className="tag tag-rounded mr-1">천호동1</span><span className="tag tag-rounded mr-1">천호동2</span>*/}
                                                        {regionList.map((item, index) => (
                                                            <span className="tag tag-rounded mr-1" key={index}>{item.upmyeondong_name}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="giftInfo mt-3 noView">
                                            {outLinkList.map((item, index) => (
                                                item.iconLink ?
                                                <div className="adressArea" key={index}>
                                                    <div className="d-flex align-items-center mt-auto">
                                                        <img className="avatar avatar-md mr-2" src={item.iconLink || ''} alt={item.linkTitle || ''} />
                                                        <div className="d-block"><a href={item.linkUrl} target="_blank">{item.linkTitle || ''}</a></div>
                                                        <div className="arrowBtn">
                                                            <i className="fe fe-chevron-right"></i>
                                                        </div>
                                                    </div>
                                                </div> : ""
                                            ))}
                                            {/*<div className="adressArea">
                                                <div className="d-flex align-items-center mt-auto">
                                                    <img className="avatar avatar-md mr-2" src={instar} alt="" />
                                                    <div className="d-block">Instar.com</div>
                                                    <div className="arrowBtn">
                                                        <i className="fe fe-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className=" mt-4 sectorTit subTit">피드</div>*/}
                                            {videoFeedList.map((item, index) => (
                                                <div className="row align-items-center mt-2" key={index}>
                                                    <div className="col-5 text-center">
                                                        <div className="listImg">
                                                            <img src={item.videoThumbnail} alt={item.videoTitle}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-7 text-left">
                                                        <div className="listTxt">
                                                            {item.videoTitle}
                                                        </div>
                                                        <div className="listday">
                                                            <div>{item.ownerChannelName}</div>
                                                            <div className="bar"></div>
                                                            <div>{item.publishDate}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {/*<div className="row align-items-center mt-2">
                                                <div className="col-5 text-center">
                                                    <div className="listImg">
                                                        <img src={landingSampleImg} />
                                                    </div>
                                                </div>
                                                <div className="col-7 text-left">
                                                    <div className="listTxt">
                                                        [플로잉 인터뷰] 플로잉 친구가 되고 싶으세요? 다양한  ...
                                                    </div>
                                                    <div className="listday">
                                                        <div>youtube</div>
                                                        <div className="bar"></div>
                                                        <div>2022.7.19</div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                            {/*<div className="row">
                                                <div className="col-12 text-center">
                                                    <a href="#" className="moreBtnSm">더보기</a>
                                                </div>
                                            </div>*/}
                                        </div>
                                        <div id="gift01" className="giftInfo mt-3 noView partOn">
                                            
                                            <div className="align-items-center mt-auto">
                                                <div className="d-block w-100 ">
                                                    <div className="giftTit"><img src={imgGift} alt="" /> 플로잉 답변톡 상품13</div>
                                                    <p>
                                                        {props.info && props.info?.goodsUserProduct?.description || ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                                <div><img src={diamond} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                                                <div><img src={ruby} alt="" /> {props.info && props.info?.goodsUserProduct?.depositAmount || 0}</div>
                                                <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                                            </div>
                                        </div>
                                        
                                        {/* <div className="giftInfo mt-3 noView">
                                            
                                            <div className="align-items-center mt-auto">
                                                <div className="d-block w-100 ">
                                                    <div className="giftTit"><img src={imgGift} alt="" /> 충전</div>
                                                    
                                                </div>
                                            </div>
                                            <div className="row align-items-center">
                                                <div className="col-md-12 mb-4">
                                                    <div className="conponimg">
                                                        <img src={imgCoupon} />
                                                        
                                                    </div>
                                                    
                                                </div>
                                                <div className="col-md-12 text-left mb-4">
                                                    <p>답변톡 이용권 : 답변방에서 글자 전송 500글자 추가시켜주는 이용권입니다.</p>
                                                    <p>구매 방법 : 충전한 다이아/루비로 구매 가능합니다</p>
                                                    <p>구매 장소 : 답변방안에서 구매 가능합니다.</p>
                                                </div>
                                                
                                            </div>
                                            <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                                                <div><img src={diamond} alt="" /> 100</div>
                                                <div><img src={ruby} alt=""/> 100</div>
                                                <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
                                            </div>
                                        </div> */}

                                        <div className="footlogo"><img src={logoW} /></div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-lg-12 text-right ">
                    <hr />
                    <button className="btn btn btn-dark " type="button">저장하기</button>
                </div>
            </div>
		</li>
				
	);
};
export default withRouter(withTranslation()(PcBackgroundSetting));

PcBackgroundSetting.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
