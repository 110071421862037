/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import {userProfileChatRoomService} from '../../../services/talk2youMessage.service';

const ChatHeader = (props) => {
	// user profile ON / OFF
	const userProfileChatRoomFunction = async () => {
		let data = {
			userId: props.user.id,
			push: props.roomData.myInfo.push === 'on' ? 'off' : 'on',
		};
		const response = await userProfileChatRoomService(props.roomId, data);

		if (response.message === 'Created') {
			props.headerChangeProfile();
		}
	};

	return (
		<React.Fragment>
			<div className="card-header pt-0 pl-0 pr-0">
				{/* <h3 className="card-title font-weight-bolder">{props.room.name}</h3> */}
				<h3 className="card-title font-weight-bolder">{props.room.userName}</h3>
				<div className="card-options">
					<div className=" b-none bg-none p-1 time">
						{/*{props.transferCount?.transferCount}/
						{props.transferCount?.transferMaxCount} 답변톡상품: 0*/}
						질문자의 전송 가능 글자수 : {props.transferCount?.transferMaxCount - props.transferCount?.transferCount || 0}
					</div>
					{props.isOwner && (
						<button
							onClick={() => userProfileChatRoomFunction()}
							className="b-none bg-none p-1"
						>
							{props.roomData.myInfo.push === 'on' ? (
								<i className="fas fa-bell-slash"></i>
							) : (
								<i className="fa fa-bell"></i>
							)}
						</button>
					)}
					<button
						onClick={() => {
							props.headerSettingsAction();
						}}
						className="b-none bg-none p-1"
					>
						<i className="fa fa-cog"></i>
					</button>
				</div>
			</div>
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(ChatHeader));
