import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';

import HeaderTypeText from './HeaderTypeText';
import HeaderTypeCustom from './HeaderTypeCustom';

//post forbidden word API's
const SelectHeaderDesignMenu = (props) => {
    
	document.title = 'Flowing';

	return (

				
            <>
                <div className="col-lg-12">
                <p className="stepTit">
                    1. 상단 부분 디자인 형태를 선택해 주세요.
                    {/* <span>1. 구분형 </span><span className="stepArrow">&gt;</span>
                    <span>2. 노출순서 </span><span className="stepArrow">&gt;</span>   */}
                </p>
                </div>
                <div className="col-lg-6">
                     <div className="custom-controls"> {/*"headerType": "text", // 상단 디자인 : text(텍스트형), banner(배너형), custom(커스텀형) */}
                        <div className="sbox">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input 
                                    id="checkTop01" 
                                    type="radio" 
                                    className="custom-control-input" 
                                    name="topDesign" 
                                    checked={props.headerType === 'text'}
                                    onClick={()=>{
                                        props.setHeaderType('text')
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                />
                                <span className="custom-control-label">텍스트형</span>
                            </label>
                            <img src={msample01} className="topup" />
                            <div>
                                # 공동구매 #쇼핑몰 운영 # 이벤트<br/>
                                # 단순 SNS링크 공유<br/><br/>
                                SIMPLE  디자인에 적합
                            </div>
                        </div>
                        <div className="sbox">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input 
                                    id="checkTop02" 
                                    type="radio" 
                                    className="custom-control-input" 
                                    name="topDesign" 
                                    checked={props.headerType === 'banner'}
                                    onClick={()=>{
                                        props.setHeaderType('banner')
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                    
                                    />
                                <span className="custom-control-label">배너형</span>
                            </label>
                            <img src={msample02} className="topup" />
                            <div>
                                # 공동구매 #쇼핑몰 운영 # 이벤트<br/>
                                # 단순 SNS링크 공유<br/><br/>
                                SIMPLE  디자인에 적합 + 상담 이미지 디자인
                            </div>
                        </div>
                        <div className="sbox">
                            <label className="custom-control custom-radio custom-control-inline m-0">
                                <input 
                                    id="checkTop03" 
                                    type="radio" 
                                    className="custom-control-input" 
                                    name="topDesign" 
                                    checked={props.headerType === 'custom'}
                                    onClick={()=>{
                                        props.setHeaderType('custom')
                                        props.setIsChange(true)
                                    }}
                                    readOnly
                                    
                                />
                                <span className="custom-control-label">커스텀형 </span>
                            </label>
                            <img src={msample0202} className="topup" />
                            <div>
                                # 개인 브랜딩 # 전문가<br/><br/>
                                개인 브랜딩 이미지 홍보 하기에 적합
                            </div>
                        </div>  
                    </div>
                </div>
                
            </>

	);
};
export default withRouter(withTranslation()(SelectHeaderDesignMenu));

SelectHeaderDesignMenu.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
