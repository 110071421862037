import React, {memo} from 'react';
import diamond from '../../../assets/images/imgbosuk01.png';
import ruby from '../../../assets/images/imgbosuk02.png';
import giftImg from '../../../assets/images/img_gift.png'

const PurchaseGems = ({description, depositAmount}) => {
    return (
        <>
            <div className="align-items-center mt-auto">
                <div className="d-block w-100 ">
                    <div className="giftTit"><img src={giftImg} alt="" />
                        플로잉 답변톡 상품
                    </div>
                    <p>
                        {description || ''}
                    </p>
                </div>
            </div>
            <div className="d-flex align-items-center justify-content-around  giftBox mt-2">
                <div><img src={diamond} alt="" /> {depositAmount || 0}</div>
                <div><img src={ruby} alt="" /> {depositAmount || 0}</div>
                <div className="tag tag-rounded ml-1 p-2 pl-3 pr-3">충전하기</div>
            </div>
        </>
    )
}

export default memo(PurchaseGems);