import React, { useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import ResponsetimeSettings from "././ResponsetimeSettings.js";
import GreetingSettings from "././GreetingSettings.js";
import ForbiddenWords from "././ForbiddenWords.js";
import AnswerGuideSettings from "././AnswerGuideSettings.js";
import AnswerGuideSettings2 from "././AnswerGuideSettings2.js";
import "../../assets/css/myStyle2.css";
import { toast, ToastContainer } from "react-toastify";

import {
    Container,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import { authService } from "../../services/auth.service.js";
import { chatbotSettingsService } from "../../services/chatbotSettings.service.js";

const ChatbotSettings = (props) => {
    // Main tab function
    const [verticalActiveTab, setverticalActiveTab] = useState("1");
    const toggleVertical = (tab) => {
        if (verticalActiveTab !== tab) {
            setverticalActiveTab(tab);
        }
    };

    useEffect(() => {
        const getProfile = async () => {
            const result = await authService.getUserId()
            if(result.status===201) {
                try {
                    const response = await chatbotSettingsService.getUserProfileInfo(result.data.user_id)
                    if(response.status===204) {
                        window.alert("유저프로필 정보가 없습니다. 답변 프로필을 먼저 등록해주세요");
                        return;
                    }
                    if(response.status===201) {
                        window.alert("앱에서 답변 프로필 등록을 모두 완료해 주세요");
                        return;
                    }
                } catch(err) {
                    throw err
                }
            }
        }
        getProfile().then(r=>r)
    }, [])

    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
            <ToastContainer />
            <div className="section-body">
                <Container fluid>
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">챗봇 설정</h4>
                            </div>
                        </div>
                    </div>
                    <Row className="clearfix">
                        <Col lg={12} className="text-left">
                            <div className="naccs">
                                <div className="d-flex">
                                    <div className="menuArea">
                                        <div className="menu">
                                            <Nav pills className="flex-column">
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active:
                                                                verticalActiveTab ===
                                                                "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleVertical("1");
                                                        }}
                                                    >
                                                        {/* 1. {props.t('Greeting Settings')} */}
                                                        1. 인사말 세팅
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active:
                                                                verticalActiveTab ===
                                                                "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleVertical("2");
                                                        }}
                                                    >
                                                        2. 답변 시간 세팅
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            "mb-2": true,
                                                            active:
                                                                verticalActiveTab ===
                                                                "3",
                                                        })}
                                                        onClick={() => {
                                                            toggleVertical("3");
                                                        }}
                                                    >
                                                        {/* 2. {props.t('Answer guide settings')} */}
                                                        3. 답변 분야 안내 설정
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        className={classnames({
                                                            active:
                                                                verticalActiveTab ===
                                                                "4",
                                                        })}
                                                        onClick={() => {
                                                            toggleVertical("4");
                                                        }}
                                                    >
                                                        {/* 4. {props.t('Request for forbidden words')} */}
                                                        4. 금지어 요청
                                                    </NavLink>
                                                </NavItem>
                                                {/* <NavItem>
														<NavLink
															style={{cursor: 'pointer'}}
															className={classnames({
																active: verticalActiveTab === '5',
															})}
															onClick={() => {
																toggleVertical('5');
															}}
														>
															2. 답변분야 백업본
														</NavLink>
													</NavItem> */}
                                            </Nav>
                                        </div>
                                    </div>
                                    <div className="phoneArea">
                                        <ul
                                            className="nacc"
                                            style={{ height: "800px" }}
                                        >
                                            <TabContent
                                                activeTab={verticalActiveTab}
                                                className="text-muted mt-4 mt-md-0"
                                            >
                                                <TabPane tabId="1">
                                                    <GreetingSettings />
                                                </TabPane>

                                                <TabPane tabId="2">
                                                    <ResponsetimeSettings />
                                                </TabPane>

                                                <TabPane tabId="3">
                                                    <AnswerGuideSettings2 />
                                                </TabPane>
                                                

                                                <TabPane tabId="4">
                                                    <ForbiddenWords />
                                                </TabPane>
                                                {/* <TabPane tabId="5">
														<AnswerGuideSettings/>
													</TabPane> */}
                                            </TabContent>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* </div> */}
        </React.Fragment>
    );
};
export default withRouter(withTranslation()(ChatbotSettings));

ChatbotSettings.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
