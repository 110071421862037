// import {get, put} from '../helpers/api_helper_chat';
import {get, put} from '../helpers/api_helper';

export async function getService(userId) {
	try {
		return await get(`/talk2you/users/profile/${userId}/active-time`);
	} catch (err) {
		throw err;
	}
}

export async function putService(userId, data) {
	try {
		return await put(`/talk2you/users/profile/${userId}/active-time`, data);
	} catch (err) {
		throw err;
	}
}

export async function getResponsetimeData (userId) {
	try{
		return await get(`/talk2you/users/profile/${userId}/active-time`, {}, true)
	} catch(err){return err}
}
export async function putResponsetimeData (userId, data) {
	try{
		return await put(`/talk2you/users/profile/${userId}/active-time`, data)
	} catch(err){return err}
}