import {Amplify} from 'aws-amplify';
import {AWSIoTProvider} from '@aws-amplify/pubsub/lib/Providers';
import config from '../config/configuration';

Amplify.configure({
	Auth: {
		identityPoolId: config.aws.identityPoolId,
		region: config.aws.region,
	},
});

Amplify.PubSub.addPluggable(
	new AWSIoTProvider({
		aws_pubsub_region: config.aws.region,
		aws_pubsub_endpoint: config.aws.endpoint,
	}),
);
