import {memo, useMemo} from 'react';
import {generateUUIDv4} from '../../../../utils/uuid';
import ScheduleItem from './ScheduleItem';
import './ScheduleList.scoped.scss';
import {calculateDateDiff} from '../../../../utils/displayDateTime';

export const ScheduleList = ({list, ...props}) => {
    list = list || [];

    const computedList = useMemo(() => {
        const originalList = list;
        const nList = [];

        originalList.forEach((item, index) => {
           const nItem = {...item}

            nItem.id = generateUUIDv4();
            nItem.calculatedDate = calculateDateDiff(item?.startDate, item?.finishDate);

            nList.push(nItem);
        });

        return nList.filter(o => o.calculatedDate !== 'CLOSED');
    }, [list])

    return (
        <div className="space-home-preview-ui">
            <div className="schedule_list">
                {props.children ? props.children : computedList.map((item, idx) => (
                    <ScheduleItem key={item.id || idx} {...item} />
                ))}
            </div>
        </div>
    )
}

export const ScheduleListItem = ScheduleItem;

export default memo(ScheduleList);