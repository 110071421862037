import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {
	Row,
	Col,
	Alert,
	Card,
	CardBody,
	Container,
	FormFeedback,
	Input,
	Label,
	Form,
} from 'reactstrap';

import {withRouter, Link} from 'react-router-dom';

// import images
import logoFoot from '../../assets/images/logo_landingFoot.png';
import {authService} from '../../services/auth.service';
import {ToastContainer} from 'react-toastify';

const ForgetPasswordPage = (props) => {
	//meta title
	document.title = 'Forget Password | Skote - React Admin & Dashboard Template';

	const [email, setEmail] = useState('');
	const [isSent, setIsSent] = useState(false);
	const [isAlert, setIsAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState('이메일 주소를 입력해 주세요.');

	const sendEmail = async (e) => {
		if (email === null || email === '') {
			// setAlertMessage("Please enter your account (email).");
			setAlertMessage("이메일 주소를 입력해 주세요.");
			setIsAlert(true);
		}else{
			setIsAlert(false);
			const result = await authService.emailSend(email);
			// console.log('result', result);
			if(result && result.status === 201) {
				setAlertMessage('비밀번호를 재설정하기 위한 페이지의 링크URL을 입력하신 이메일로 전송했습니다. 메일을 확인해 주세요.'); //Email is required
			} else {
				setAlertMessage(result.response.data.place_holder); //Email is required
			}
			setIsAlert(true);
			
		}
		
	};

	return (
		<React.Fragment>
			<ToastContainer/>
			<div className="home-btn d-none d-sm-block">
				<Link to="/" className="text-dark">
					<i className="fas fa-home h2"/>
				</Link>
			</div>
			<div className="auth">
				<div className="logo">
					<Link to="/">
						<img src={logoFoot} alt="logo"/>
					</Link>
				</div>
				<div className="auth_left">
					<Card>
						<div className="card-top">
							<div className="card-title">{/*{props.t('Forgot Password')}*/}비밀번호 재설정</div>
						</div>
						<CardBody>
							{isAlert === true && (
								<Alert
									color="success"
									className="alert alert-icon alert-success fs12"
									role={alert}
								>
									<i className="fe fe-alert-triangle"></i>
									{/*{props.t(alertMessage)}*/}{alertMessage}
								</Alert>
							)}

							<div className="mb-3">
								<Input
									name="email"
									className="form-control"
									// placeholder={props.t('Please enter your account (email).')}
									placeholder="이메일 주소를 입력해 주세요."
									type="email"
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<Row className="">
								<Col className="fluid">
									<button
										className="btn btn-primary btn-block w-100"
										type="submit"
										onClick={async () => await sendEmail()}
									>
										{/*{props.t('initialization')}*/}재설정
									</button>
								</Col>
							</Row>
						</CardBody>
					</Card>
					<div className="mt-4 text-center">
						<p className="pb-4">
							{/*{props.t('What if you forgot your password?')}
							<Link to="login" className="font-weight-medium text-primary">
								{props.t('Login')}
							</Link>{' '}*/}
							플로잉 회원이 아니신가요?{' '}
							<Link to="/register" className="font-weight-medium text-primary">
								지금 가입하세요.
							</Link>{' '}
						</p>
						<p className="text-center text-muted mt-2 Copyright">
							© {new Date().getFullYear()}{' '}
							{/*{props.t('Copyright Tpkorea Co.,Ltd.')}*/}
							Copyright TPKOREA Co.,Ltd.
						</p>
					</div>
				</div>
				<div className="auth_right"></div>
			</div>
		</React.Fragment>
	);
};

ForgetPasswordPage.propTypes = {
	history: PropTypes.object,
};

export default withRouter(withTranslation()(ForgetPasswordPage));
