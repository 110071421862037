import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import {
	Container,
	Card,
	CardBody,
	Row,
	Col,
	Input,
	Button,
	InputGroup,
	Label,
} from 'reactstrap';
import {getBalanceAccountInfo, getSearchAddressData, putBalanceAccountInfo as putData} from '../../services/user.deposit.service';
// import toastr from 'toastr';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config/configuration';
import { authService } from '../../services/auth.service';

const CalculateInfo = (props) => {

	const [userId, setUserId] = useState("");
	const [accountHolder, setAccountHolder] = useState();
	const [address, setAddress] = useState();
	const [addressDetail, setAddressDetail] = useState();
	const [bank, setBank] = useState();
	const [bankAccount, setBankAccount] = useState();
	const [isAddressList, setIsAddressList] = useState(false)
	const [addressList, setAddressList] = useState([])

	const getData = async (userId) => {
		const item = await getBalanceAccountInfo(userId);
		// console.log('result', item);
		if(item) {
			setAccountHolder(item.account_holder || '');
			setAddress(item.address || '');
			setAddressDetail(item.address_detail || '');
			setBank(item.bank || '');
			setBankAccount(item.bank_account);
		}
	}

	const handleSubmit = async () => {
		await postService();
	};

	const postService = async (imageUrl = '') => {
		const validCheck = accountHolder !== "" && address !== "" && addressDetail !== "" && bank !== "" && bankAccount
		const bodyData = {
			userId,
			accountHolder,
			address,
			addressDetail,
			bank,
			bankAccount,
		};
		// console.log('body', bodyData);
		if(!validCheck) {
			toast.warning('입력 항목을 전부 입력해 주세요')
		} else {
			try {
				const result = await putData(bodyData);
				if (result) {
					toast.success('정산정보를 등록완료했습니다.');
					// setLoading(false);
					// props.history.push('/qna/qnaView');
				}
			} catch (err) {
				toast.error('다시 한번 시도해 주세요')
			}
		}
	};
	const bankList = [
		{
			label: "한국산업은행",
			value: "002"
		},
		{
			label: "중소기업은행",
			value: "003"
		},
		{
			label: "KB국민은행",
			value: "004"
		},
		{
			label: "하나은행",
			value: "005"
		},
		{
			label: "수협은행",
			value: "회원수협"
		},
		{
			label: "한국수출입은행",
			value: "008"
		},
		{
			label: "NH농협은행",
			value: "010"
		},
		{
			label: "지역농·축협",
			value: "012"
		},
		{
			label: "우리은행",
			value: "020"
		},
		{
			label: "신한은행",
			value: "021"
		},
		{
			label: "SC제일은행",
			value: "023"
		},
		{
			label: "한국씨티은행",
			value: "027"
		},
		{
			label: "DGB대구은행",
			value: "031"
		},
		{
			label: "BNK부산은행",
			value: "032"
		},
		{
			label: "광주은행",
			value: "034"
		},
		{
			label: "제주은행",
			value: "035"
		},
		{
			label: "전북은행",
			value: "037"
		},
		{
			label: "BNK경남은행",
			value: "039"
		},
		{
			label: "새마을금고",
			value: "045"
		},
		{
			label: "신용협동조합",
			value: "047"
		},
		{
			label: "상호저축은행",
			value: "050"
		},
		{
			label: "산림조합",
			value: "064"
		},
		{
			label: "우체국예금",
			value: "071"
		},
		{
			label: "케이뱅크",
			value: "089"
		},
		{
			label: "카카오뱅크",
			value: "090"
		},
		{
			label: "토스뱅크",
			value: "092"
		}
	]

	useEffect(() => {
		// const user_id = localStorage.getItem('user_id');

		const settingUserId = async () => {
			try {
				const res = await authService.getUserId()
				if(res.status===201) {
					setUserId(()=>{return res.data.user_id})
					// getData(user_id).then(r => r);
					getData(res.data.user_id).then(r => r);
				} else {
					return await authService.logout()
				}
			} catch(err){}
		}
		settingUserId().then(r=>r)
		// setUserId(user_id);
	}, [userId]);

	const searchAddress = async () => {
		if(address && address.trim() !== "") {
			try {
				// const res = await axios.get(`${config.api.proxy_api}/common/search/address?query=${address}`)
				const res = await getSearchAddressData(address)
				console.log(res)
				if(res.status === 200) {
					console.log(res.data.documents.length)
					setAddressList(res.data.documents)
					setIsAddressList(true)
					if (res.data.documents.length === 0) {
						toast.warning('검색 결과가 없습니다.')
					}
				} else {
					toast.error('다시 한번 시도해 주세요')
				}
			} catch(err) {
				toast.error('다시 한번 시도해 주세요')
				throw new Error(err)
			}
		} else {
			toast.warning('주소를 입력해 주세요')
		}
	}

	document.title = '플로잉 정산정보 입력';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
			<ToastContainer />
			<div className="section-body">
				<Container fluid>
					<Row>
						<Col lg={12}>
							<div className="page-title-box d-sm-flex align-items-center justify-content-between">
								{/*<h4 className="mb-0 font-size-18">정산 정보 입력</h4>*/}
								<h4 className="mb-0 font-size-18">{props.t('Enter settlement information')}</h4>
							</div>
						</Col>
					</Row>
					<Row className="clearfix">
						<Col lg={12} className="text-center">
							<Card>
								<CardBody>
									<div className="form-group">
										<Label className="text-left">이름</Label>
										<Input
											type="text"
											name="accountHolder"
											value={accountHolder || ''}
											className="form-control"
											placeholder="이름을 입력하세요"
											onChange={(e) => setAccountHolder(e.target.value)}
										/>
									</div>
									{/* <div className="form-group">
											<Label className="text-left">
												{props.t('Social Security Number')}
											</Label>
											<InputGroup>
												<Input
													type="email"
													className="form-control"
													placeholder="front seat"
												/>
												<span className="middleMargin">-</span>
												<Input
													type="email"
													className="form-control"
													placeholder="back seat"
												/>
											</InputGroup>
										</div> */}
									<div className="form-group">
										<Label className="text-left">주소</Label>
										<InputGroup>
											<input
												type="text"
												name="address"
												value={address || ''}
												className="form-control"
												placeholder="주소를 입력해 주세요"
												onChange={(e) => setAddress(e.target.value)}
											/>
											<span className="input-group-append">
												<Button className="btn btn-secondary" onClick={searchAddress} type="button">
													주소 검색
												</Button>
											</span>
										</InputGroup>
										{isAddressList && (
											<div style={{minHeight:"200px", marginTop:"20px"}}>
												{addressList.map((x, idx) => {
													// if (addressList.length === 0) return <p>검색 결과가 없습니다.</p> TODO: 이거 안먹음
													return (
														<ul
															key={idx}
															onClick={()=>{
																setAddress(x.address_name)
																toast.success('상세주소를 입력해 주세요')
																setIsAddressList(false)
															}}
															style={{
																cursor:"pointer",
																display:"flex",
																justifyContent:"flex-start",
															}}
														>
															<li>{x.address_name}</li>
														</ul>
													)
												})}
											</div>
										)}
									</div>
									<Input
										className="form-control "
										type="text"
										name="addressDetail"
										value={addressDetail || ''}
										placeholder="상세주소를 입력하세요"
										onChange={(e) => setAddressDetail((e.target.value))}
									/>
									<div className="form-group mt-3">
										<Label className="text-left">
											{props.t('Bank and account number')}
										</Label>
										<InputGroup>
											<select
												className="custom-select col-4 form-control"
												name={bank || ''}
												onChange={(e) => setBank(e.target.value)}
												value={bank}
											>
												<option>은행을 선택하세요</option>
												{bankList.map((option, index) => (
													<option value={option.value} key={option.value}>{option.label}</option>
												))}
											</select>
											<Input
												type="number"
												name="bankAccount"
												value={bankAccount || ''}
												className="form-control"
												placeholder="계좌번호를 입력하세요 (숫자만)"
												onChange={(e) => setBankAccount(e.target.value)}
											/>
										</InputGroup>
									</div>
									<div className="form-footer">
										{/*<Link className="btn btn-secondary btn-block" to="/">
											{props.t('Applying Changes')}
										</Link>*/}
										<Button
											type="submit"
											className="btn btn-secondary btn-block"
											onClick={handleSubmit}
										>
										변경 적용하기
										</Button>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(CalculateInfo));

CalculateInfo.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
