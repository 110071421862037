// import {get, put, post} from '../helpers/api_helper_landing';
import {get, post, put} from '../helpers/api_helper';
import config from '../config/configuration';
import moment from 'moment';

export async function getUserDepositProductHistory(userId, type, offset, limit) {
	try {
		if(!userId || !type) return null;
		const result = await get(`/payment/${userId}/console/deposit/product-history?type=${type}&offset=${offset}&limit=${limit}`
			,{baseURL: config.api.payment_api});
		return result && result.itemList || [];

	} catch (err) {
		throw err;
	}
}

export async function putBalanceAccountInfo(body) {
	try {
		if(!body) return {};
		return await put('/payment/settlement', body, {baseURL: config.api.payment_api});

	} catch (err) {
		throw err;
	}
}

export async function getBalanceAccountInfo(userId) {
	try {
		if(!userId) return {};
		const result = await post(`/payment/settlement`, {userId}, {baseURL: config.api.payment_api});
		return result && result.itemList && Array.isArray(result.itemList) && result.itemList.length > 0 && result.itemList[0] || {};

	} catch (err) {
		throw err;
	}
}

export async function getBalanceAccountReport(userId, searchDate) {
	try {
		if(!userId) return {};
		if(!searchDate) searchDate = moment(new Date()).format("YYYYMM").toString();
		const result = await post(`/payment/settlement/report`, {userId, searchDate}, {baseURL: config.api.payment_api});
		return result && result.itemList && Array.isArray(result.itemList) && result.itemList.length > 0 && result.itemList[0] || {};

	} catch (err) {
		throw err;
	}
}

export async function getCalculateBoardData (body) {
	try {
		return await post(`/payment/settlement/download/report`, {...body}, {baseURL:config.api.payment_api}, true)
	} catch(err){return err}
}

export async function getSearchAddressData (address) {
	console.log(config)
	try {
		return await get(`/common/search/address?query=${address}`, {baseURL:config.api.proxy_api}, true)
	} catch(err) {return err}
}

export async function getMonths (userId) {
	try {
		return await get(`/payment/settlement/report/months?userId=${userId}`, {baseURL: config.api.payment_api}, true)
	} catch(err) {return err}
}