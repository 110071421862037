import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import ReactPlayer from 'react-player';
import gallery from '../../assets/images/gallery/6.jpg';
import moment from 'moment';
import {enterRoom} from '../../services/talk2meRooms.service';
import _ from 'lodash';
import {toast} from 'react-toastify';
import { checkDeadlineTimer, startDeadlineTimer } from '../../helpers/Functions/deadlineTimer';

const RightQeustionLayout = (props) => {
	const [ownerInformation, setOwnerInformation] = useState();
	const [userInfo, setUserInfo] = useState({});
	const value = props.selectedValue;
	const [timer, setTimer] = useState('00:00:00')
	let deadlineTimeId;

	// Image and video show right menu
	const toggleRight = (action) => {
		if (!action) {
			document.getElementById('mainContents').classList.remove('sectionRight');
			document.getElementById('right-sidebar').classList.remove('rightView');
		}
	};

	const body = {
		userId: userInfo.id,
		userName: userInfo.nickname,
		userImg:
			userInfo.image_url ||
			'https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg',
	};

	//entering room with body
	const enterRoomFunction = async () => {
		// console.log('value', value); //return;
		const result = await enterRoom(body, value.roomId);
		console.log('result', result);	//return
		console.log(value)
		if(result && (result.status === 200 || result.status === 201)) {
			props.history.push({
				pathname: '/question/chatroom',
				data: value
			});

		} else {
			if (result?.data?.message) {
				toast.warning(result?.data?.message);
			}
		}
	};

	const clearDeadlineTimer = (duration) => {
		const interval = 1000;
		deadlineTimeId = setInterval(() => {
			duration = moment.duration(duration - interval, 'milliseconds')
			let result = startDeadlineTimer(duration._data)
			if(result===false) {
				setTimer(()=>{return '00:00:00'})
				clearInterval(deadlineTimeId)
				return
			} else setTimer(()=>{return result})
		}, interval);
	}

	//getting owner information
	useEffect(() => {
		// const userData = JSON.parse(localStorage.getItem('user_data'));
		const userData = JSON.parse(sessionStorage.getItem('user_data'));
		setUserInfo(userData);
		let OwnerData = _.find(value.activeUsers, function (o) {
			return o.userId === value.ownerId;
		});
		setOwnerInformation(OwnerData);
	}, [value]);
	useEffect(()=>{
		if(value.deadline) {
			let duration = checkDeadlineTimer(value.deadline, deadlineTimeId)
			if(duration === false) {
				setTimer(()=>{return '00:00:00'});
				return;
			}
			clearDeadlineTimer(duration)
		}
		return () => {
			clearInterval(deadlineTimeId)
		}
	}, [value])

	return (
		<React.Fragment>
			<div id="right-sidebar">
				<div className="d-flex justify-content-between brand_name">
					<div
						className="brand-name mb-4 d-flex"
						onClick={() => toggleRight(false)}
					>
						<div className=" d-flex">
							<div className="btnRightCon2">
								<i className="icon-close" style={{cursor:'pointer'}}></i>
							</div>
							<span id="rightMenuTitle" className="ml-2">
                				{props.t('View questions')}
              				</span>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					<div className="row clearfix">
						<div className="col-lg-12">
							<div className="viewContents" id="DetailQuestion">
								<h5>{value.name}</h5>
								<p className="textArea">{value.introduction}</p>
								<p className="photoArea">
									<img
										className="d-block w-100"
										alt=""
										src={
											value.question == ''
												? gallery
												: value.question?.images?.url
										}
									/>
								</p>
							{value.question?.video ? (
								<p className="mvArea">
									{/*{value.question?.video ? (*/}
										<ReactPlayer
											width={356}
											height={180}
											src={value.question?.video}
											playing={false}
											controls={true}
											config={{
												file: {
													attributes: {
														controlsList: 'nodownload',
													},
												},
											}}
										/>
									{/*) : (
										<video
											src={value.question?.video}
											height="180px"
											controls
										></video>
									)}*/}
								</p>
							) : ''}
								<p className="cateArea test">
									<i className="fe fe-grid"></i> {props.t('Category')} :
									{(value.relCategory ? value.relCategory : []).map(
										(itemCategory, idx) => {
											return ` ${itemCategory.name}  `;
										},
									).join(', ')}
								</p>
								<p className="cateArea">
									<i className="fe fe-map-pin"></i> 관련지역 : {/*길동, 천호동*/}
									{(value.relRegion ? value.relRegion : []).map(
										(itemRegion, idx) => {
											return ` ${itemRegion.upmyeondongName}  `;
										},
									).join(', ')}
								</p>

								<div className="userImg text-justify mt-4">
									<div className="avatar">
										<img
											src={
												value.owner?.imageUrl == ''
													? gallery
													: value.owner?.imageUrl
											}
											alt="avatar"
										/>
									</div>
									<div className="userInfo">
										<p>
											<span>
												{value.owner ? value.owner.nickname : 'No name'}
											</span>{' '}
											<span>
												{/*{props.t('Adoption point : ')}*/}
												{/* {value.rewardPoints} */}
												채택 포인트 :
												{value.rewardType === 'dia'
												? ` 다이아 ${value.rewardCount}`
												: (value.rewardType === 'ruby'
													?
													` 루비 ${value.rewardCount}`
													: ` ${value.rewardCount} 포인트`)
												}
											</span>
										</p>
										<p>
											<span>{value.activeUsersCount}명</span>{' '}
											<span>
                        						{props.t('Remaining time')}:{' '}
												{/* {moment
													.utc(
														moment(value.createdAt)
															.add(2, 'days')
															.diff(moment().format()),
													)
													.format('HH:mm:ss')} */}
													{timer}
                      						</span>
										</p>
									</div>
								</div>
							</div>
							<button
								type="button"
								className="btn btn-secondary btn-block mt-3 mb-5"
								onClick={enterRoomFunction}
							>
								{props.t('Participate chat to ask')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

RightQeustionLayout.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(RightQeustionLayout));
