/* eslint-disable jsx-a11y/iframe-has-title */
import React, {useEffect, useState} from 'react';
import _, {map} from 'lodash';
import moment from 'moment';
import {Spinner} from 'reactstrap';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
	Button,
	Modal,
	Input,
	Label,
	ModalBody,
	ModalFooter,
	FormGroup,
} from 'reactstrap';
import {toast} from 'react-toastify';
//import room service
import {
	leaveChatRoomService,
	sendReportUserService,
	getBlockedUsersService,
	maskMessageChatRoomService,
	unblockUserFromChatRoomService,
} from '../../../services/talk2youMessage.service';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
// defualt image
import profile from '../../../assets/images/users/defualt-image.png';
import owner_icon from '../../../assets/images/chatroom/owner_icon.png';
import winner_icon from '../../../assets/images/chatroom/winner_icon.png';

import SetChatLimit from './SetChatLimit';

const SettingsSideBar = (props) => {
	// Report information
	const [reportTextCount, setReportTextCount] = useState(0);
	const [reportText, setReportText] = useState('');
	const [reportAbuseCase, setReportAbuseCase] = useState();
	const [limit, setLimit] = useState();

	// loading
	const [subMenuLoading, setSubMenuLoading] = useState(false);

	// sub menu screens and modals
	const [unBlockModal, setUnBlockModal] = useState(false);
	const [exitChatModal, setExitChatRoomModal] = useState(false);
	const [deleteChatModal, setDeleteChatModal] = useState(false);
	const [blockedUsersScreen, setBlockedUsersScreen] = useState(false);
	const [chatLimitsScreen, setChatLimitsScreen] = useState(false);

	// target user information ( block, leave rooom ... etc )
	const [targetUserId, setTargetUserId] = useState();
	const [targetUserName, setTargetUserName] = useState();

	// blocked users
	const [blockedUsers, setBlockedUsers] = useState([]);

	// IOT new message
	useEffect(() => {
		resetRoomDataFunction();
	}, [props.roomId]);

	const resetRoomDataFunction = () => {
		setBlockedUsersScreen(false);
		setChatLimitsScreen(false);
		setUnBlockModal(false);
	};

	// get block users
	const getBlockedUserService = async () => {
		setBlockedUsersScreen(true);
		setSubMenuLoading(true);
		const response = await getBlockedUsersService(props.roomId, props.user.id);
		setBlockedUsers(
			response && response.itemList.length > 0 ? response.itemList : [],
		);
		setSubMenuLoading(false);
	};

	// user unblock
	const unBlockUserFunction = async () => {
		let data = {
			userId: props.currentRoomOwnerData.userId,
			userName: props.currentRoomOwnerData.userName,
			targetId: targetUserId,
			targetName: targetUserName,
		};
		const response = await unblockUserFromChatRoomService(props.roomId, data);
		if (response.message) {
			// toast.success('unblock user successful');
			toast.success('사용자를 차단해제 했습니다.');
		} else {
			// toast.error('unblock user unsuccessful');
			toast.error('사용자를 차단해제 했습니다.');
		}
		resetRoomDataFunction();
	};

	// User Report
	const sendReportFunction = async () => {
		if (reportAbuseCase && reportText) {
			let data = {
				userId: props.currentRoomOwnerData.userId,
				targetId: props.targetUserId,
				abuseCase: Number(reportAbuseCase),
				abuseDesc: reportText,
				evidenceList: [],
			};
			setSubMenuLoading(true);
			const response = await sendReportUserService(data);
			setSubMenuLoading(false);
			if (response.message) {
				// toast.success('report user successful');
				toast.success('사용자를 신고했습니다.');
			} else {
				// toast.error('report user unsuccessful');
				toast.error('사용자 신고를 완료하지 못했습니다.');
			}
			props.hideReportBar();
		} else {
			// toast.error('report information is missing');
			toast.error('신고 내용 중 누락된 정보가 있습니다.');
		}
	};

	// leave chat room
	const leaveChatRoomFunction = async () => {
		if (!props.isOwner) {
			let data = {
				userId: props.user.id,
				userName: props.user.nickname,
			};
			const response = await leaveChatRoomService(props.roomId, data);
			if (response.message) {
				// toast.success('leave chat room successful');
				toast.success('채팅방을 나왔습니다.');
			} else {
				// toast.error('leave chat room unsuccessful');
				toast.error('채팅방을 나오지 못했습니다.');
			}
			props.onChange();
		}
	};

	// message mask
	const maskAllMessageFunction = async () => {
		let data = {
			userId: props.user.id,
			maskingTime: moment().toISOString(),
		};
		const response = await maskMessageChatRoomService(props.roomId, data);

		if (response.message) {
			// toast.success('Delete all message');
			toast.success('모든 메시지를 삭제했습니다.');
		} else {
			// toast.error('Can\'t delete messages');
			toast.error('메시지를 삭제하지 못했습니다.');
		}
		setDeleteChatModal(false);
		props.onChange();
	};

	return (
		<React.Fragment>
			{/* Start Setting right menu  */}
			<div
				id="right-sidebar1"
				className={'col-lg-3'}
				style={{borderLeft: '1px solid #eee'}}
			>
				{/* Block user screen */}
				{blockedUsersScreen ? (
					<div>
						<div className="d-flex justify-content-between brand_name mt-2">
							<h5
								className="mb-4 d-flex"
								style={{
									height: '23px',
								}}
							>
								<button
									onClick={() => setBlockedUsersScreen(false)}
									className="b-none bg-none btnRightCon"
								>
									<i className="icon-close"></i>
								</button>
								{/*내보내기*/}차단 목록
							</h5>
						</div>
						{subMenuLoading ? (
							<Spinner type="grow" color="warning"/>
						) : (
							<div className="container-fluid">
								<div className="row clearfix">
									<div className="col-lg-12">
										<div className="viewContents">
											{map(blockedUsers, (user, index) => (
												<div className="userImg text-justify mt-2 block_user_container">
													<div>
														<div className="avatar mt-0">
															<img
																src={user.userImg || profile}
																alt="avatar"
																style={{
																	width: '100%',
																	height:'100%',
																	objectFit: 'cover',
																	objectPosition: '50% 50%',
																}}
															/>
														</div>
														<div className="userInfo">
															<p className="block_user_text">{user.userName}</p>
														</div>
													</div>
													<button
														onClick={() => {
															setTargetUserId(user.userId);
															setTargetUserName(user.userName);
															setUnBlockModal(true);
														}}
														className="b-none btnRightCon block_button"
													>
														<span>해제</span>
													</button>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				) : chatLimitsScreen ? (
					// chat limit screen
					<div>
						<div className="d-flex justify-content-between brand_name mt-2">
							<h5
								className="mb-4 d-flex"
								style={{
									height: '23px',
								}}
							>
								<button
									onClick={() => setChatLimitsScreen(false)}
									className="b-none bg-none btnRightCon"
								>
									<i className="icon-close"></i>
								</button>
								유료 답변톡 설정
							</h5>
						</div>
						{<SetChatLimit {...props} />}
					</div>
				) : props.reportScreen ? (
					// report screen
					<div>
						<div className="d-flex justify-content-between brand_name mt-2">
							<h5
								className="mb-4 d-flex"
								style={{
									height: '23px',
								}}
							>
								<button
									onClick={() => props.hideReportBar()}
									className="b-none bg-none btnRightCon"
								>
									<i className="icon-close"></i>
								</button>
								신고하기
							</h5>
						</div>
						{subMenuLoading ? (
							<Spinner type="grow" color="warning"/>
						) : (
							<div className="container-fluid">
								<div className="row clearfix">
									<div className="col-lg-12">
										<div className="viewContents">
											주의 : 의도적으로 잘못된 정보를 신고하는 하는 경우 신고한
											사람이 제재를 받을 수 있습니다.
											<br/>
											<p> 신고 유형 신고 </p>
											<div
												onChange={(value) => {
													setReportAbuseCase(value.target.value);
												}}
											>
												<input
													type="radio"
													value="1"
													name="report"
													className="mb-3 mr-2"
												/>
												욕설 또는 인신공격
												<br/>
												<input
													type="radio"
													value="2"
													name="report"
													className="mb-3 mr-2"
												/>
												음란 또는 성적인 비하
												<br/>
												<input
													type="radio"
													value="3"
													name="report"
													className="mb-3 mr-2"
												/>
												기타 (불법정보 등)
											</div>
											<FormGroup>
												<Label for="exampleText" style={{textAlign: 'end'}}>
													{reportTextCount}/200
												</Label>
												<Input
													id="exampleText"
													name="text"
													type="textarea"
													rows="5"
													onChange={(e) => {
														if (e.target.value.length <= 200) {
															setReportTextCount(e.target.value.length);
															setReportText(e.target.value);
														}
													}}
												/>
											</FormGroup>
											<span className="input-group-append">
                        <button
							onClick={() => sendReportFunction()}
							className="btn btn-secondary"
							style={{width: '100%'}}
							type="button"
						>
                          신고하기
                        </button>
                      </span>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				) : (
					<div>
						<div className="d-flex justify-content-between brand_name mt-2">
							<h5
								className="mb-4 d-flex"
								style={{
									height: '23px',
								}}
							>
								<button
									onClick={() => props.hideSettingsBar()}
									className="b-none bg-none btnRightCon"
								>
									<i className="icon-close"></i>
								</button>
								설정
							</h5>
						</div>
						<div className="container-fluid">
							<div className="row clearfix">
								<div className="col-lg-12">
									<div className="viewContents">
										{!props.isOwner && (
											<div>
												<button
													onClick={() => setExitChatRoomModal(true)}
													className="b-none bg-none"
												>
													<p className="cateArea">나가기</p>
												</button>
												<br/>
											</div>
										)}
										<button
											onClick={() => setDeleteChatModal(true)}
											className="b-none bg-none mt-2"
										>
											<p className="cateArea">대화 내용 삭제</p>
										</button>
										<br/>
										{props.isOwner && (
											<div>
												<button
													onClick={() => getBlockedUserService()}
													className="b-none bg-none mt-2"
												>
													<p className="cateArea">{/*내보내기*/}차단 목록</p>
												</button>
												<br/>
											</div>
										)}
										{props.isOwner && (
											<div>
												<button
													onClick={() => setChatLimitsScreen(true)}
													className="b-none bg-none mt-2"
												>
													<p className="cateArea"> 유료 답변톡 설정</p>
												</button>
												<br/>
											</div>
										)}

										<button className="b-none bg-none mt-2">
											<p className="cateArea">대화 상대 ( 2 )</p>
										</button>
										<br/>
										{map(props.roomData?.activeUsers, (user, index) => (
											<div
												key={index}
												className="userImg text-justify mt-2"
												style={{
													alignItems: 'center',
													display: 'flex',
												}}
											>
												<div className="avatar mt-0 avatar-overflow-show">
													<img
														src={user.userImg || profile}
														alt="avatar"
														className="rounded-circle"
													/>
													{user.userId ===
														props.currentRoomOwnerData?.userId && (
															<img
																src={owner_icon}
																alt=""
																className="avatar-owner"
															/>
														)}
												</div>
												<div className="userInfo" style={{height: '30px'}}>
													<p>
														{user.userName}
														{props.user.id === user.userId && (
															<span style={{color: 'red'}}> My</span>
														)}
													</p>
												</div>
											</div>
										))}
										{map(props.roomData?.invitedUsers, (user, index) => (
											<div
												key={index}
												className="userImg text-justify mt-2"
												style={{
													alignItems: 'center',
													display: 'flex',
												}}
											>
												<div className="avatar mt-0 avatar-overflow-show">
													<img
														src={user.userImg || profile}
														alt="avatar"
														className="rounded-circle"
													/>
													{user.userId ===
														props.currentRoomOwnerData?.userId && (
															<img
																src={owner_icon}
																alt=""
																className="avatar-owner"
															/>
														)}
												</div>
												<div className="userInfo" style={{height: '30px'}}>
													<p>
														{user.userName}
														{props.user.id === user.userId && (
															<span style={{color: 'red'}}> My</span>
														)}
													</p>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{/* End Setting right menu  */}
			{/* Start Modals */}
			<Modal returnFocusAfterClose={true} isOpen={unBlockModal} centered={true}>
				<ModalBody className="text-center">
					{/*해당 톡유저를 내보내기에서 <br/> 해제하시겠습니까? 해제하시면 해당
					<br/>
					톡유저가 톡방에 다시 입장할 수 <br/> 있습니다.*/}
					해당 유저를 차단 해제하시겠습니까?<br/>
					해제 하시면 해당 유저가<br/>
					다시 톡을 전송할 수 있습니다.
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => {
							setTargetUserId();
							setTargetUserName();
							setUnBlockModal(false);
						}}
					>
						취소
					</Button>
					<Button color="primary" onClick={() => unBlockUserFunction()}>
						해제
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				returnFocusAfterClose={true}
				isOpen={exitChatModal}
				centered={true}
			>
				<ModalBody className="text-center">
					톡방을 나가시면 톡 내용이 모두 <br/> 사라지고 톡목록도 사라집니다.
					<br/>
					톡방을 나가시겠습니까?.
				</ModalBody>
				<ModalFooter>
					<Button
						color="primary"
						onClick={() => {
							setExitChatRoomModal(false);
						}}
					>
						취소
					</Button>
					<Button color="primary" onClick={() => leaveChatRoomFunction()}>
						나가기
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				returnFocusAfterClose={true}
				isOpen={deleteChatModal}
				centered={true}
			>
				<ModalBody className="text-center">
					대화 내용을 삭제하시겠습니까? <br/> 현재 톡방의 이전 모든 대화
					<br/>
					내용이 삭제됩니다.
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={() => setDeleteChatModal(false)}>
						취소
					</Button>
					<Button color="primary" onClick={() => maskAllMessageFunction()}>
						삭제
					</Button>
				</ModalFooter>
			</Modal>
			{/* End Modals */}
		</React.Fragment>
	);
};

export default withRouter(withTranslation()(SettingsSideBar));
