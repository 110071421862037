import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import char from '../../../assets/images/img_ch01.png';
import logo from '../../../assets/images/logo_landingFoot.png';
import imgAvatar from '../../../assets/images/img_avatar.jpg'
import imgAvatarNew from '../../../assets/images/img_avatar_new.jpg'
import msample01 from '../../../assets/images/msample01.jpg';
import msample02 from '../../../assets/images/msample02.jpg';
import msample0202 from '../../../assets/images/msample02_2.jpg';
import msample03 from '../../../assets/images/msample03.jpg';
import msample04 from '../../../assets/images/msample04.jpg';
import youtube from '../../../assets/images/ic_social01.jpg';
import instar from '../../../assets/images/ic_social02.jpg';
import landingSampleImg2 from '../../../assets/images/img_landingSample5.jpg';
import landingSampleImg from '../../../assets/images/img_landingSample4.jpg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {landingPageService} from '../../../services/landing-page.service';
import {ToastContainer} from 'react-toastify';
import { sectionFooterPrimaryContent } from 'aws-amplify';

import DesignTypeTab from './DesignTypeTab';
import DesignTypePage from './DesignTypePage';

const HeaderTypeText = (props) => {

	return (

		<div className="col-lg-6 text-center">
            <div className="phone02 drop-shadow hauto w320 min600">
                <div className="phone_over hauto">
                    {/* <div className={`PhoneArea hauto ${props.moblieBgColorList[props.mobileBgColor[0]]}`}> */}
                    <div className={`PhoneArea hauto ${props.moblieBgColorList[props.mobileTextBannerBgColor]}`}>
                        {/* <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={imgAvatar} alt="" /></div> */}
                        <div className="TackBtnArea"><span>톡하기</span><img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || imgAvatarNew} alt="" /></div>
                            
                        <div className={props.headerType === 'text'?"PhoneTop designCase01":"PhoneTop designCase01 d-none"}>
                            <div className="d-flex align-items-center mt-auto">
                                {/*<img className="avatar avatar-lg mr-2" src={props.pcTextBannerProfileImage || imgAvatar} alt="" />*/}
                                <img className="avatar avatar-lg mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage || imgAvatarNew} alt="" />
                                <div className="d-block w-style">
                                    <div className="d-flex">
                                    <small className="d-block">{props.info && props.info?.userName}</small>
                                        <a href="#" className="icon d-none d-md-inline-block ml-auto"><i className="icon-home"></i></a>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small>
                                        <small className="d-block ml-2"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small>
                                    </div>
                                    
                                </div>
                                
                            </div>
                            <div className="ChArea"><img src={char} /></div>  
                        </div>
                        <div className={props.headerType === 'banner'?"PhoneTop designCase02":"PhoneTop designCase02 d-none"}>
                            <div className="d-flex align-items-center mt-auto">
                                <img className="avatar avatar-md mr-2" src={props.info && props.info?.userImg || props.pcTextBannerProfileImage || imgAvatarNew} alt="" />
                                <div>
                                    <small className="d-block">{props.info && props.info?.userName}</small>
                                </div>
                                <div className="ml-auto">
                                    <a href="#" className="icon d-none d-md-inline-block ml-3"><i className="icon-home"></i></a>
                                </div>
                            </div>
                            <div className="MainImg mt-2">
                                <div className="ChArea"><img src={char} /></div>
                                <img src={props.mobileBannerImage ||landingSampleImg2} />
                            </div>
                            <div className="row mt-2">
                                <div className="col-6 text-center"><small className="d-block"><i className="fe fe-user"></i> {props.info && props.info?.questionerCount}명</small></div>
                                <div className="col-6 text-center"><small className="d-block"><i className="fe fe-message-circle"></i> {props.info && props.info?.talk2meRoomCount}명</small></div>
                            </div>
                        </div>
                        
                        {props.designType === 'tab'?
                            <DesignTypeTab {...props} />
                        :
                            <DesignTypePage {...props} />
                        }
                            
                    </div>
                </div>
            </div>
        </div>		
		
				


	);
};
export default withRouter(withTranslation()(HeaderTypeText));

HeaderTypeText.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
