import React, {useEffect} from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {withRouter, Link} from 'react-router-dom';
import {getQna as getData} from '../../services/qna.service';

import {Container, Row, Table} from 'reactstrap';
import moment from 'moment';
import { authService } from '../../services/auth.service';

const QnaView = (props) => {
	//meta title
	const [qnaList, setQnaList] = useState([]);
	const getQna2 = async () => {
		// const userId = 'bae88cf8-9877-4b99-b022-90ece9d09b88' // TODO : 테스트용 임시 아이디 적용
		// const userId = localStorage.getItem("user_id")
		const response = await authService.getUserId()
		if(response.status===201){
			const result = await getData(response.data.user_id);
			// const result = await getData(userId);
			console.log('result', result);
			setQnaList(result);
		}
	};
	useEffect(() => {
		getQna2().then(r => r);
	}, []);
	const handleClick = (event) => {
		const id = event.currentTarget.id;
		console.log('event.currentTarget.id', id); //return ;
		// withRouter(props.history.push(`/qna/NoticeDetail?id=${id}`));
		props.history.push({
			pathname: '/qna/QnaDetail',
			id: id,
		});
	};

	document.title = '플로잉 문의하기';
	return (
		<React.Fragment>
			{/* <div className="page-content"> */}
				<Container fluid>
					<Row className="align-items-center">
						<div className="col text-left">
							<h1 className="page-title">플로잉 문의하기</h1>
						</div>
						<div className="col text-right">
							<Link className="btn btn-outline-secondary m-1" to={'/qna/QnaWrite'}>문의 등록</Link>
						</div>
					</Row>
					<div className="table-responsive">
						<Table className="table m-b-0 table-vcenter text-nowrap tableList">
							<thead className="thead-light">
							<tr>
								<th>번호</th>
								<th>등록일</th>
								<th>문의유형</th>
								<th>제목</th>
								<th>처리상태</th>
							</tr>
							</thead>
							<tbody>
							{qnaList.map((item, index) => {
								return (
									<tr
										key={item.id}
										id={item.id}
										onClick={handleClick}
									>
										<th>{qnaList.length - index}</th>
										<th>{moment(item.createdAt).format("YYYY-MM-DD")}</th>
										<th>{item.inquiryType.name}</th>
										<th>{item.title}</th>
										<th>{item.answerStatus === "reception" ? "접수" : "답변완료"}</th>
									</tr>
								);
							})}
							</tbody>
						</Table>
					</div>
				</Container>
			{/* </div> */}
		</React.Fragment>
	);
};
export default withRouter(withTranslation()(QnaView));

QnaView.propTypes = {
	history: PropTypes.object,
	t: PropTypes.any,
};
