import React, {memo} from 'react';

export const VideoFeedItem = ({feed, ...props}) => {
    return (
        <div className="row align-items-center mt-2">
            <div className="col-5 text-center">
                <div className="listImg">
                    <img src={feed.videoThumbnail} alt={feed.videoTitle}/>
                </div>
            </div>
            <div className="col-7 text-left">
                <div className="listTxt">
                    {feed.videoTitle}
                </div>
                <div className="listday" style={{flexWrap: 'wrap'}}>
                    <div>{feed.ownerChannelName}</div>
                    <div className="bar"></div>
                    <div>{feed.publishDate}</div>
                </div>
            </div>
        </div>
    )
}

export default memo(VideoFeedItem);