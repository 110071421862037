import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

//i18n
import {withTranslation} from 'react-i18next';
// Redux
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// users
// import user1 from '../../../assets/images/users/avatar-1.jpg';
import user1 from '../../../assets/images/profile_blank.png';
import {toast} from 'react-toastify';
import {userService} from '../../../services/user.service';
import {uploadImageService} from '../../../services/talk2meMessage.service';
import {authService} from '../../../services/auth.service';

const ProfileMenu = (props) => {
	// Declare a new state variable, which we'll call "menu"
	const [menu, setMenu] = useState(false);
	const [email, setEmail] = useState('');
	const [nickname, setNickname] = useState('');
	const [age, setAge] = useState('');
	const [gender, setGender] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [userImg, setUserImg] = useState('');
	const inputRef = useRef(null);
	const [loadingFile, setLoadingFile] = useState();
	const [userId, setUserId] = useState('');
	const [loading, setLoading] = useState('');
	const [status, setStatus] = useState("")

	const getUserImg = async (valueId) => {
		const response = await userService.getUserImg(
			// localStorage.getItem('user_id'),
			valueId,
		);
		// setUserImg(response?.image_list[0]?.image_url); // Error Occurred
		setUserImg(response && response.hasOwnProperty('image_url') && response.image_url || '');
	};

	//upload user image
	const imageUpload = async (formData, fileSize, fileName) => {
		let msgType = 'PHOTO';
		formData.append('limitedImageSize', 1280);
		const imageResponse = await uploadImageService(formData);
		if (imageResponse.msg === 'OK') {
			setUserImg(imageResponse.url[0]);
			let resp = await userService.uploadUserImg(userId, imageResponse.url[0]);
			if (resp.image_url) {
				window.location.reload();
			}
		} else {
			toast.error(imageResponse.msg);
		}

		setLoadingFile(false);
	};

	//toggling profile sidebar
	const toggleRight = (action) => {
		if (!action) {
			document.getElementById('user_div').classList.remove('sectionLeft');
			// document.getElementById("mainContents").classList.remove("sectionRight");
		}
	};
	const updateGender = async (e) => {
		setGender(e);
		const response = await userService.updateProfile(
			// localStorage.getItem('user_id'),
			userId,
			age,
			e,
		);
	};

	//routing to forgot password menu
	function changePassword() {
		localStorage.clear();
		window.location.href = '/forgot-password';
	}

	const getUser = async (userId) => {
		const response = await authService.getUserInfo(userId);
		setAge(response.age_group);
		setGender(response.gender);
		setNickname(response.nickname);
		setEmail(response.email);
		setPhoneNumber(response.phone_number);
		setUserId(response.id);
		setUserImg(response.image_url);
		setLoading(true);
	};
	const updateAge = async (e) => {
		setAge(e);
		const response = await userService.updateProfile(
			// localStorage.getItem('user_id'),
			userId,
			e,
			gender,
		);
	};

	//routing to forgot password menu
	function changePassword() {
		localStorage.clear();
		window.location.href = '/forgot-password';
	}

	useEffect(() => {
	}, [sessionStorage.getItem('user_data')]);

	useEffect(() => {
		const settingUserId = async () => {
			const res = await authService.getUserId()
			if(res?.status===201) setUserId(()=>{return res?.data?.user_id})
			await getUserImg(res?.data?.user_id);
			await getUser(res?.data?.user_id);
			setStatus(()=>{return res.status})
		}
		settingUserId().then(r=>r)
	}, [status])
	//calling upload function from input file
	const selectFileFunction = async (event) => {
		const fileUploaded = event.target;
		const formData = new FormData();

		if (
			fileUploaded &&
			fileUploaded.files &&
			fileUploaded.files[0] &&
			fileUploaded.files[0].type
		) {
			setLoadingFile(true);

			let originFileSize = fileUploaded?.files[0]?.size / (1024 * 1024);
			let type = fileUploaded.files[0].type.substring(0, 5);

			if (type === 'video') {
				if (originFileSize > 200) {
					// toast.error('This file is larger than 200mb');
					toast.error('선택하신 파일은 200MB를 초과하기 때문에 업로드 할 수 없습니다.');
					setLoadingFile(false);
					return;
				}
			} else {
				if (originFileSize > 20) {
					// toast.error('This file is larger than 20mb');
					toast.error('선택하신 파일은 20MB를 초과하기 때문에 업로드 할 수 없습니다.');
					setLoadingFile(false);
					return;
				}
			}

			let bucketName = 'flowing-web-resources';
			let fileSize = originFileSize.toFixed(2);
			let fileName = fileUploaded?.files[0]?.name;
			formData.append('upload', fileUploaded.files[0]);

			if (fileSize < 1) {
				fileSize = (fileUploaded?.files[0]?.size / 1000).toFixed(2) + 'KB';
			} else {
				fileSize += 'MB';
			}

			imageUpload(formData, fileSize, fileName);
		}
	};

	return (
		<React.Fragment>
			<div id="profile-sidebar">
				<div className="user_div" id="user_div">
					<h5 className="brand-name mb-4">
						{props.t('Manage profile')}
						<div className="user_btn" onClick={() => toggleRight(false)}>
							<i className="icon-close"></i>
						</div>
					</h5>
					<div className="card">
						<div
							className="profileImgArea"
							// style="background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),url(../assets/images/gallery/6.jpg);"
						>
							<div className="logo_profile">
								<a
									onClick={() => {
										inputRef.current.click();
									}}
									className="b-none bg-none"
								>
									<form>
										<input
											type="file"
											id="input-file"
											className="d-none"
											ref={inputRef}
											onChange={selectFileFunction}
											accept="image/gif, image/jpeg, image/jpg, image/png"
										/>

										<label className="Phonebutton">
											<i className="fe fe-camera"></i>
										</label>
									</form>
								</a>

								<img src={userImg ? userImg : user1}/>
							</div>
						</div>

						<div className="card-body">
							<h5 className="card-title">{nickname}</h5>
						</div>
						<ul className="list-group list-group-flush">
							<li className="list-group-item">{email}</li>
							<li className="list-group-item">{phoneNumber}</li>
							{/*<li className="list-group-item">2022년 6월 1일 가입</li>*/}
						</ul>
						<button
							type="button"
							className="btn btn-secondary btn-block mt-3"
							onClick={changePassword}
						>
							{props.t('Change password')}
						</button>
					</div>

					<div className="form-group">
						<label className="form-label">{props.t('Gender')}</label>
						<div className="selectgroup w-100">
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="gender"
										value="m"
										className="selectgroup-input"
										onChange={(e) => (
											console.log(gender, 'gender'),
												updateGender(e.target.value)
										)}
										defaultChecked={gender === 'm' ? true : false}
									/>
								)}
								<span className="selectgroup-button">{props.t('Male')}</span>
							</label>
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="gender"
										value="f"
										className="selectgroup-input"
										onChange={(e) => updateGender(e.target.value)}
										defaultChecked={gender === 'f' ? true : false}
									/>
								)}
								<span className="selectgroup-button">{props.t('Female')}</span>
							</label>
						</div>
					</div>
					<div className="form-group">
						<label className="form-label">{props.t('Teenage')}</label>
						<div className="selectgroup w-100">
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="age"
										value="10"
										className="selectgroup-input"
										onChange={(e) => updateAge(e.target.value)}
										defaultChecked={age === '10' ? true : false}
									/>
								)}
								<span className="selectgroup-button">10대</span>
							</label>
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="age"
										value="20"
										className="selectgroup-input"
										onChange={(e) => updateAge(e.target.value)}
										defaultChecked={age === '20' ? true : false}
									/>
								)}
								<span className="selectgroup-button">{props.t('Twenty')}</span>
							</label>
						</div>
						<div className="selectgroup w-100">
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="age"
										value="30"
										className="selectgroup-input"
										onChange={(e) => updateAge(e.target.value)}
										defaultChecked={age === '30' ? true : false}
									/>
								)}
								<span className="selectgroup-button">{props.t('Thirty')}</span>
							</label>
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="age"
										value="40"
										className="selectgroup-input"
										onChange={(e) => updateAge(e.target.value)}
										defaultChecked={age === '40' ? true : false}
									/>
								)}
								<span className="selectgroup-button">{props.t('Forty')}</span>
							</label>
						</div>
						<div className="selectgroup w-100">
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="age"
										value="50"
										className="selectgroup-input"
										onChange={(e) => updateAge(e.target.value)}
										defaultChecked={age === '50' ? true : false}
									/>
								)}
								<span className="selectgroup-button">{props.t('fifty')}</span>
							</label>
							<label className="selectgroup-item">
								{loading == true && (
									<input
										type="radio"
										name="age"
										value="60"
										className="selectgroup-input"
										onChange={(e) => updateAge(e.target.value)}
										defaultChecked={age === '60' ? true : false}
									/>
								)}
								<span className="selectgroup-button">
                  {props.t('Over sixty')}
                </span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

ProfileMenu.propTypes = {
	success: PropTypes.any,
	t: PropTypes.any,
};

const mapStatetoProps = (state) => {
	const {error, success} = state.Profile;
	return {error, success};
};

export default withRouter(
	connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
);
