import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import _, {map} from 'lodash';
import {categoryService} from '../../services/category.service';

const RightCategoryMenu = (props) => {
	const [categories, setCategories] = useState([]);
	const [keyword, setKeyword] = useState('');
	const [offset, setOffset] = useState('0');
	const [limit, setLimit] = useState('20');
	const [selectedCategories, setSelectedCategories] = useState(
		props.selCategories,
	);

	const getCategories = async () => {
		let data = await categoryService.getCategories();
		setCategories(data.talk_category_list);
	};
	const addItem = (item) => {
		selectedCategories.push(item);
		props.onChange(selectedCategories);
	};
	const removeItem = (item) => {
		const newCategories = selectedCategories.filter(
			(cate) => cate.id !== item.id,
		);
		setSelectedCategories(newCategories);
		props.onChange(newCategories);
	};
	// const options: [
	//   { label: "Mustard", value: "Mustard" },
	//   { label: "Ketchup", value: "Ketchup" },
	//   { label: "Relish", value: "Relish" }
	// ]
	const searchCategories = async () => {
		let response = await categoryService.searchCategories(
			keyword,
			offset,
			limit,
		);
		setCategories(response.talk_category_list);

		return response;
	};

	useEffect(() => {
		getCategories();
	}, []);

	useEffect(() => {
		// console.log()
		setSelectedCategories(props.selCategories)
	}, [props.selCategories])

	// Image and video show right menu

	return (
		<React.Fragment>
			<div id="cateArea" className="viewCon">
				<h5>{props.t('Choose category you want to answer (maximum 3)')}</h5>
				<p className="textArea">
					{props.t('You can seach category by keyword')}
				</p>

				<div>
					<form className="input-group" onSubmit={async ev => {
						ev.preventDefault();
						await searchCategories(res => {
							console.log('search-res', res);
						})
					}}>
						<input
							type="text"
							className="form-control"
							placeholder={props.t('Enter keyword')}
							value={keyword}
							onChange={(e) => setKeyword(e.target.value)}
						/>
						<span className="input-group-append">
						  <button
							  className="btn btn-secondary"
							  type="button"
							  onClick={searchCategories}
						  >
							{props.t('Search')}
						  </button>
						</span>
					</form>
				</div>
				<div className="ReaultArea">
					<ul>
						{categories.map((item, index) => {
							return (
								<li key={index}>
									<div className="d-flex align-items-center mt-auto">
										<img
											className="avatar avatar-md mr-2"
											src={item.image_url}
											alt=""
										/>
										<div className="ml-2">{item.name}</div>
										<div className="ml-auto text-muted">
											{selectedCategories.find(
												({name}) => name == item.name,
											) == null ? (
												<button
													className="btn btn-outline-secondary btn-sm"
													onClick={() => addItem(item)}
												>
													{props.t('Add')}
												</button>
											) : (
												<button
													className="btn btn-outline-secondary btn-sm"
													onClick={() => removeItem(item)}
												>
													{props.t('Cancel')}
												</button>
											)}
										</div>
									</div>
								</li>
							);
						})}
						{categories&&categories.length===0 && (
							<li>
								<p className="noResult">{props.t('No result')}</p>
							</li>
						)}
					</ul>
				</div>
			</div>
		</React.Fragment>
	);
};

RightCategoryMenu.propTypes = {
	location: PropTypes.object,
	t: PropTypes.any,
};

export default withRouter(withTranslation()(RightCategoryMenu));
